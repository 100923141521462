import React from 'react'
import styled from 'styled-components'
import ProfileCaseListItem from '../ProfileCaseListItem'
import { connect } from 'react-redux'
import ListItem from '../ProfileCaseListItem/ListItem'
import I18n from '../I18n'
import { isValidUUID } from '../../utils'
import { RELATION } from '../../connectionTypes'
import { USER_OWNERSHIP_CASE, USER_ROLE_CASE } from '../../caseTypes'
import { NOT_RELEVANT, PENDING } from '../../CaseStatus'

const ProfileCaseListCustomRelevant = (props) => {
  if (props.items?.length == 0 && !isValidUUID(props.activeConnection?.id)) {
    return (
      <div>
        <I18n id="connection_form.No_connections_registered" />{' '}
      </div>
    )
  }

  const displayDraft = () => {
    const isTransientConnection = isValidUUID(props.activeConnection?.id)
    const isConfirmingPublicData = props.activeConnection?.connectionType
      ?.toLowerCase()
      .includes('public')
    const belongsToRelationCaseList =
      props.relationId === props.activeConnection?.relationId
    return (
      isTransientConnection &&
      !isConfirmingPublicData &&
      belongsToRelationCaseList
    )
  }
  return (
    <Scrollbox>
      <List>
        {displayDraft() && (
          <li>
            <ListItem draft={true} />
          </li>
        )}
        {props.relevantItems
          ?.filter((r) => !isValidUUID(r.id))
          .map((item, index) => (
            <ProfileCaseListItem
              key={props.caseType + item.id}
              {...props}
              {...item}
              item={item}
              active={
                (props.caseType !== RELATION &&
                  props.activeConnection?.caseId === item.id) ||
                (props.caseType === RELATION &&
                  props.activeConnection?.id === item.id)
              }
            />
          ))}
      </List>
      <List>
        {props.noneRelevantItems.length > 0 && (
          <NonRelevantTitle>
            <I18n id="Non_relevant" />
          </NonRelevantTitle>
        )}
        {props.noneRelevantItems
          ?.filter((r) => !isValidUUID(r.id))
          .map((item, index) => (
            <ProfileCaseListItem
              key={props.caseType + item.id}
              {...props}
              {...item}
              item={item}
              active={
                (props.caseType !== RELATION &&
                  props.activeConnection?.caseId === item.id) ||
                (props.caseType === RELATION &&
                  props.activeConnection?.id === item.id)
              }
            />
          ))}
      </List>
    </Scrollbox>
  )
}

const Scrollbox = styled.div`
  margin: 0;
  border-radius: 5px;

  position: relative;
  left: -50px;

  padding: 0em 1em;
  max-height: 600px;
  overflow: auto;
  --scrollbarBG: rgba(0, 0, 0, 0.1);
  --thumbBG: #90a4ae;

  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
`

const List = styled.ul`
  list-style: none;

  & > :not(:last-child) {
    margin-bottom: 1em;
  }
`

const NonRelevantTitle = styled.div`
  font-size: ${({ theme: { largeText } }) => largeText};
  font-weight: bold;
  padding: 0.25em 0.5em;
  margin-right: 1em;
`

const mapStateToProps = (state, props) => {
  const { caseType, items, relationId } = props
  let relevantItems = []
  let noneRelevantItems = []
  if (caseType == USER_OWNERSHIP_CASE) {
    relevantItems = items.filter(
      (c) =>
        (c['userOwnership']?.isCaseRelevant == true ||
          c.status == PENDING ||
          c['ownershipApplications']) &&
        c.status != NOT_RELEVANT,
    )
    noneRelevantItems = items.filter(
      (c) =>
        c['userOwnership']?.isCaseRelevant == false || c.status == NOT_RELEVANT,
    )
  } else if (caseType == USER_ROLE_CASE) {
    relevantItems = items.filter(
      (c) =>
        c['userRole']?.isCaseRelevant == true ||
        c.status == PENDING ||
        (c['roleApplications'] && c.status != NOT_RELEVANT),
    )
    noneRelevantItems = items.filter(
      (c) => c['userRole']?.isCaseRelevant == false || c.status == NOT_RELEVANT,
    )
  } else if (relationId) {
    relevantItems = items.filter(
      (c) =>
        c['relationRole']?.isCaseRelevant == true ||
        c['relationOwnership']?.isCaseRelevant == true ||
        c.status == PENDING ||
        c['relationOtherConnection'] ||
        c.status != NOT_RELEVANT,
    )
    noneRelevantItems = items.filter(
      (c) =>
        c['relationRole']?.isCaseRelevant == false ||
        c['relationOwnership']?.isCaseRelevant == false ||
        c.status == NOT_RELEVANT,
    )
  } else {
    relevantItems = props.items
  }
  return {
    relevantItems: relevantItems,
    noneRelevantItems: noneRelevantItems,
  }
}

export default connect(mapStateToProps)(ProfileCaseListCustomRelevant)
