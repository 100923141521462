import { compose, applyMiddleware } from 'redux'
import { legacy_createStore as createStore} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { createHashHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './reducers'
import saga from './sagas'
import { checkAuthStatus } from './auth'

const sagaMiddleware = createSagaMiddleware()

export const history = createHashHistory()

const store = createStore(
  createRootReducer(history),
  composeWithDevTools(
    compose(applyMiddleware(routerMiddleware(history), sagaMiddleware)),
  ),
)

sagaMiddleware.run(saga)

store.dispatch(checkAuthStatus())

export default store
