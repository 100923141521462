import React, { useState, createContext, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  getCase,
  getActiveConnectionHasUpdates,
  isConnectionFormPending,
  isCurrentUserFetching,
  getCurrentUser,
  hasConfirmedRelationProffSelection,
  shouldFetchUserProfileData,
} from '../../redux/selectors'
import {
  changeConnection,
  confirmConnection,
  removeConnection,
  createConnectionApplication,
  confirmRelation,
  removeRelation,
  fetchUserProfileData,
} from '../../redux/actions'
import ErrorMessage from '../ErrorMessage'
import useValidation from '../../hooks/useValidation'
import useVisited from '../../hooks/useVisited'
import { Field, Label } from '../form'
import Button from '../Button'
import I18n from '../I18n'
import checkmarkGrayIcon from '../../icons/checkmark_gray.svg'
import { enforceConnectionFormDependencyRules } from './formDependencyRules'
import FormOptions from './FormOptions'
import { isValidUUID } from '../../utils'
import { RELATION } from '../../connectionTypes'
import ProffSelectorModal from '../ProffSelector/ProffSelectorModal'
export const ConnectionFormContext = createContext({})

const ConnectionForm = ({
  caseId,
  lockedFields,
  connectionType,
  connection,
  connectionIsDraft,
  change,
  confirm,
  apply,
  remove,
  heading,
  fields,
  schema,
  connectionHasUpdates,
  unsetActiveConnection,
  isConnectionFormPending,
  Case,
  currentUser,
  hasConfirmedProffSelection,
  fetchUserProfileData,
  shouldFetchUserProfileData,
  userId,
}) => {
  const [triedToSubmit, setTriedToSubmit] = useState(false)
  const { errors, validate } = useValidation(schema)
  const { visited, visitField, visitFields } = useVisited()
  const [showProffSelectorModal, setShowProffSelectorModal] = useState(false)
  const onShowProffSelectorModalButtonClicked = () =>
    setShowProffSelectorModal(true)

  const handleChange = (e) => {
    change({
      [e.target.name]: e.target.value === '' ? null : e.target.value,
    })
    enforceConnectionFormDependencyRules(change, e.target)
  }

  const handleBlur = (e) => visitField(e.target.name)

  const hasErrors = Object.keys(errors).length > 0

  const handleSubmit = (e) => {
    e.preventDefault()
    if (hasErrors) {
      setTriedToSubmit(true)
      visitFields(Object.keys(errors))
    } else {
      if (connection.isApplication) {
        apply()
      } else {
        confirm()
      }
    }
  }

  const handleClose = () => {
    unsetActiveConnection()
  }

  useEffect(() => {
    const cancelablePromise = validate(connection)
    return cancelablePromise.cancel
  }, [validate, connection])

  useEffect(() => {
    !connectionIsDraft &&
      !connection?.isApplication &&
      connectionType === RELATION &&
      !hasConfirmedProffSelection &&
      onShowProffSelectorModalButtonClicked()
  }, [connectionIsDraft])

  useEffect(() => {
    currentUser
    hasConfirmedProffSelection &&
      shouldFetchUserProfileData &&
      fetchUserProfileData(userId)
  }, [
    fetchUserProfileData,
    shouldFetchUserProfileData,
    hasConfirmedProffSelection,
  ])
  return ( connection &&
    <ConnectionFormContext.Provider
      value={{ connection, handleChange, handleBlur, visited, errors }}
    >
      {showProffSelectorModal &&
        !connectionIsDraft &&
        !hasConfirmedProffSelection && (
          <ProffSelectorModal
            relationId={connection.id}
            onClose={() => setShowProffSelectorModal(false)}
          />
        )}
      <FormHeader connection={connection}>
        <h2>
          <I18n
            id="connection_form.headings.datasource_prefixes"
            connection={connection}
          />
          {heading}{' '}
        </h2>
        {!connectionIsDraft && !connection.isApplication && (
          <FormOptions Case={Case} deleteConnection={remove} />
        )}
      </FormHeader>
      <Form onSubmit={handleSubmit}>
        {fields.map(
          ({ key, label, Input, helpText, condition = () => true }) =>
            Input &&
            condition(connection) && (
              <Field key={key + '_field'} helpText={helpText}>
                <Label htmlFor={key}>{label}</Label>
                <Input
                  key={key}
                  {...connection}
                  locked={lockedFields && lockedFields.includes(key)}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  visited={visited}
                  errors={errors}
                  disabled
                />
              </Field>
            ),
        )}
        <RequiredText>
          <I18n id="connection_form.required_text" />
        </RequiredText>
        <Buttons>
          <Button
            onClick={handleClose}
            small
            subtle
            style={{ marginRight: 'auto' }}
          >
            {connectionIsDraft ? <I18n id="Cancel" /> : <I18n id="Close" />}
          </Button>

          <Button
            type="submit"
            small
            onClick={handleSubmit}
            inactive={hasErrors || !connectionHasUpdates}
            disabled={!connectionHasUpdates && !hasErrors}
            pending={isConnectionFormPending}
          >
            {connectionHasUpdates || hasErrors ? (
              <ConfirmButton>
                <I18n id="Confirm" />
              </ConfirmButton>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ConfirmedButton>
                  <img
                    src={checkmarkGrayIcon}
                    alt=""
                    style={{
                      marginRight: 4,
                    }}
                  />
                  <span>
                    <I18n id="Confirmed" />
                  </span>
                </ConfirmedButton>
              </div>
            )}
          </Button>
        </Buttons>
        {triedToSubmit && hasErrors && (
          <ErrorMessage>
            <I18n id="connection_form.errors" />
          </ErrorMessage>
        )}
      </Form>
    </ConnectionFormContext.Provider>
  )
}

const FormHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5em;
  border-radius: 10px 10px 0px 0px;
  min-height: 70px;
  color: ${({ theme: { inverseText } }) => inverseText};
  background-color: ${(props) =>
    props.connection.isApplication
      ? props.theme.secondary
      : props.theme.primary};

  & > h2 {
    margin: 0;
    color: ${({ theme: { inverseText } }) => inverseText};
  }
`

const Form = styled.form`
  background-color: ${({ theme: { pageBackground } }) => pageBackground};
  box-shadow: rgb(219 219 219) 0px 2px 8px;
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  border-radius: 0px 0px 10px 10px;

  & > :not(:last-child) {
    margin-bottom: 0.5em;
  }
`

const Buttons = styled.div`
  margin-top: 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ConfirmButton = styled.div`
  padding: 0.2em 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ConfirmedButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const RequiredText = styled.div`
  padding-top: 0.5em;
  color: ${({ theme: { error } }) => error};
`

const mapStateToProps = (state, props) => {
  const pending = isCurrentUserFetching(state)
  if (pending)
    return {
      pending: true,
    }
  const currentUser = getCurrentUser(state)
  const userId = props.userId || currentUser.id
  const connectionIsDraft = isValidUUID(props.connection?.id)
  const hasConfirmedProffSelection =
    connectionIsDraft || props.connectionType != RELATION
      ? undefined
      : hasConfirmedRelationProffSelection(state, props.connection?.id)
  return {
    Case: getCase(state, props.caseId),
    isConnectionFormPending: isConnectionFormPending(state),
    connectionIsDraft,
    connectionHasUpdates: getActiveConnectionHasUpdates(state),
    currentUser: currentUser,
    userId: userId,
    hasConfirmedProffSelection: hasConfirmedProffSelection,
    shouldFetchUserProfileData: shouldFetchUserProfileData(state, userId),
  }
}

const mapDispatchToProps = (
  dispatch,
  { userId, id, connectionType, caseId, unsetActiveConnection },
) => ({
  change: (props) =>
    dispatch(changeConnection({ userId, id, connectionType, props })),
  confirm: () =>
    dispatch(
      connectionType === RELATION
        ? confirmRelation({ id, userId })
        : confirmConnection({ userId, id, connectionType }),
    ),
  remove: () => {
    dispatch(
      connectionType === RELATION
        ? removeRelation({ id, userId })
        : removeConnection({ userId, id, connectionType, caseId }),
    )
    unsetActiveConnection()},
  apply: () =>
    dispatch(
      createConnectionApplication({ userId, id, connectionType, caseId }),
    ),
  fetchUserProfileData: () => dispatch(fetchUserProfileData(userId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionForm)
