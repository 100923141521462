import React from 'react'
import styled from 'styled-components'
import { height } from '../components/Header'
import Page from '../components/Page'
import { footerHeight } from '../components/Footer'
import { connect } from 'react-redux'
import { isCurrentUserFetching } from '../redux/selectors'
import I18n, { useI18nFunction } from '../components/I18n'
import ImageComponent from '../components/ImageComponent'


const PrivacyStatement = ({ pending }) => {
  const I18nFunction = useI18nFunction();
  const pageNumber = I18nFunction({ id: 'onboarding.privacy_slide_number' })
  const image = I18nFunction({id: `onboarding.slides.slideContent[${pageNumber}].image`})
  return (
    <Page pending={pending}>
      <Wrapper>
        <Content>
          <ImageComponent fileName={image} />
          <I18n id={`onboarding.slides.slideContent[${pageNumber}].text`} />
        </Content>
      </Wrapper>
    </Page>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${height} - ${footerHeight});
`

const Content = styled.div`
  font-size: ${({ theme: { largeText } }) => largeText};
  margin: auto;
  flex-grow: 1;
  display: flex;
  align-items: center;
  width: 90%;
  max-width: ${({ theme: { maxWidth } }) => maxWidth};

  & > * {
    flex-grow: 1;
    flex-basis: 0px;
    flex-shrink: 0;
    min-width: 0px;
  }

  & > :not(:last-child) {
    margin-right: 4em;
  }
`
const mapStateToProps = (state) => ({
  pending: isCurrentUserFetching(state),
})

export default connect(mapStateToProps)(PrivacyStatement)
