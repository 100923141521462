import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import Button from '../components/Button'
import caretLeftIcon from '../icons/caret-left.svg'
import caretLeftIconSecondary from '../icons/caret-left_secondary.svg'
  
const Back = (text) => {
    const history = useHistory()
    return(
    <Button onClick={() => history.goBack()}>
        <img src={caretLeftIcon} alt="" />
        <div></div>
        {text}
    </Button>
    )
  }

const SubtleBack = (text) => {
    const history = useHistory()
    return (
    <SubtleBackWrapper>
        <SubtleBackDiv onClick={() => history.goBack()}>
        <img src={caretLeftIconSecondary} alt="" />
        <div>
            {text}
        </div>
        </SubtleBackDiv>
    </SubtleBackWrapper>
    )
  }
  
const SubtleBackWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5em auto;
  max-width: ${(props) => props.theme.maxWidth};
  font-size: ${(props) => props.theme.largeText};
  `
  
const SubtleBackDiv = styled.div`
  color: ${({ theme }) => theme.secondary}
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
  font-size: ${(props) => props.theme.subtleText};
  background-color: transparent;
  border: none;
  display: flex;
  & > :not(:last-child) {
    margin-right: 0.5em;
  }
  `

const BackButton = ({text, subtle}) => {
    return ( subtle ? SubtleBack(text) : Back(text)
    )
  }

export default BackButton
