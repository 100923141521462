import React, { useState } from 'react'
import { connect } from 'react-redux'

import I18n, { useI18nFunction } from '../I18n'
import styled, { keyframes } from 'styled-components'
import Button from '../Button'
import { Link } from 'react-router-dom'
import checkmarkIcon from '../../icons/checkmark_circled_filled.svg'
import attentionIcon from '../../icons/circled_alert_icon.svg'
import { hasValidConfirmationStatus } from '../../utils'
import { prettyDate } from '../../dateUtils'
import ConfirmProfileButton from '../ConfirmProfileButton'
import EventList from '../EventList'
import {
  isReminderFetching,
  getUser,
  getCurrentUser,
} from '../../redux/selectors'
import PositionOfInfluenceModal from './PositionOfInfluenceModal'

const ProfileStatusAndActionBar = ({
  isCurrentUsersProfile,
  userId,
  confirmedFormOn,
  lastLoggedIn,
  isReminderFetching,
  positionOfInfluence,
  positionOfInfluenceDescription,
  isAdmin,
}) => {
  const I18nFunction = useI18nFunction();
  const [isHistoryOpen, setIsHistoryOpen] = useState(false)
  const [isPositionOfInfluenceOpen, setIsPositionOfInfluenceOpen] =
    useState(false)
  
  const positionOfInfluenceButton = () => {
    if (
      process.env.REACT_APP_SET_POSITION_OF_INFLUENCE_ENABLED?.toLowerCase() ===
      'true'
    ) {
      return (
        <Button
          onClick={() => {
            setIsPositionOfInfluenceOpen((is) => !is)
          }}
        >
          {positionOfInfluence ? (
            positionOfInfluenceDescription ? (
              <I18n id="action_bar.change_position_of_influence_description" />
            ) : (
              <I18n id="action_bar.set_position_of_influence_description" />
            )
          ) : (
            <I18n id="action_bar.set_position_of_influence" />
          )}
        </Button>
      )
    }
  }
  return (
    <Wrapper>
      <Content>
        <LastUpdated>
          <img
            src={
              hasValidConfirmationStatus(confirmedFormOn)
                ? checkmarkIcon
                : attentionIcon
            }
            title={
              hasValidConfirmationStatus(confirmedFormOn)
                ? ''
                : `${I18nFunction({ id: 'connection_form.tabs.Confirm_all_sections_of_profile_to_enable_completion'}) || ''}`
            }
          />
          <span>
            {confirmedFormOn ? (
              <>
                <I18n id="User_last_confirmed" />
                {prettyDate(confirmedFormOn)}
              </>
            ) : isCurrentUsersProfile ? (
              <I18n id="Profile_confirmation_required" />
            ) : (
              <I18n id="User_never_confirmed" />
            )}
          </span>
          {isCurrentUsersProfile && (
            <ConfirmProfileButton
              userId={userId}
              confirmedFormOn={confirmedFormOn}
            />
          )}
        </LastUpdated>
        {!isCurrentUsersProfile && (
          <LastLogin>
            <span>
              {lastLoggedIn ? (
                <>
                  <I18n id="User_last_login" />
                  {prettyDate(lastLoggedIn)}
                </>
              ) : (
                <I18n id="User_never_logged_in" />
              )}
            </span>
          </LastLogin>
        )}
        <ActionButtonWrapper>
          {!isCurrentUsersProfile && isAdmin && positionOfInfluenceButton()}
          {!isCurrentUsersProfile && (
            <Button
              pending={isReminderFetching ? 1 : 0}
              as={Link}
              to={`/reminder/confirm?users=${userId}`}
            >
              <I18n id="Send_reminder" />
            </Button>
          )}
          {(isCurrentUsersProfile || isAdmin) &&
            process.env.REACT_APP_USER_PDF_EXPORT_ENABLED?.toLowerCase() ===
              'true' && (
              <Button
                onClick={() =>
                  (window.location.href = `${window.location.origin}/api/users/${userId}/generate_user_pdf`)
                }
              >
                <I18n id="Export_pdf" />
              </Button>
            )}
          <Button
            onClick={() => {
              setIsHistoryOpen((is) => !is)
            }}
          >
            {isHistoryOpen ? (
              <I18n id="Hide_profile_history" />
            ) : (
              <I18n id="Show_profile_history" />
            )}
          </Button>
        </ActionButtonWrapper>
      </Content>
      {isHistoryOpen && (
        <HistoryWrapper>
          <History>
            <HistoryTitle>
              <I18n id="Profile_history" />
            </HistoryTitle>
            <EventList userId={userId} />
          </History>
        </HistoryWrapper>
      )}

      {isPositionOfInfluenceOpen && (
        <PositionOfInfluenceModal
          userId={userId}
          positionOfInfluenceDescription={positionOfInfluenceDescription}
          positionOfInfluence={positionOfInfluence}
          onClose={() => setIsPositionOfInfluenceOpen(false)}
        />
      )}
    </Wrapper>
  )
}

const Content = styled.div`
  background-color: ${({ theme: { secondaryBackground } }) =>
    secondaryBackground};
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding-bottom: 1em;
`
const ActionButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > :not(:last-child) {
    margin-right: 0.5em;
  }
  & > :last-child {
    min-width: 200px;
  }
`

const Wrapper = styled.div`
  background-color: ${({ theme: { secondaryBackground } }) =>
    secondaryBackground};
`

const LastLogin = styled.div`
  border-radius: 5px;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme: { primaryBackground } }) => primaryBackground};
  max-width: 150px;
`

const LastUpdated = styled.div`
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0.7em 1em;
  background-color: ${({ theme: { primaryBackground } }) => primaryBackground};
  max-width: 45%;

  & > :not(:last-child) {
    margin-right: 1em;
  }
`

const HistoryTitle = styled.h2`
  margin: 0;
`

const float = keyframes`
  from {
    transform: translateX(700px);
  }
  to {
    transform: translateX(0px);
  }
`

const HistoryWrapper = styled.div`
  position: absolute;
  top: 400px;
  right: 100px;
  width: 600px;
  background-color: ${({ theme: { primaryBackground } }) => primaryBackground};
  z-index: 1;
  animation: ${float} 200ms alternate ease-in-out;
  border-radius: 5px;
  box-shadow: rgb(219 219 219) 0px 2px 8px;
`

const History = styled.div`
  overflow: hidden;
  padding: 2em 2.25em;
`

const mapStateToProps = (state, props) => {
  const user = getUser(state, props.userId) || {}
  const currentUser = getCurrentUser(state)
  const isAdmin = currentUser.admin
  return {
    isCurrentUsersProfile: currentUser.id == props.userId,
    confirmedFormOn: user.confirmedFormOn,
    lastLoggedIn: user.lastLoggedIn,
    positionOfInfluence: user.positionOfInfluence,
    isReminderFetching: isReminderFetching(state),
    positionOfInfluenceDescription:
      user.customProperties?.positionOfInfluenceDescription || '',
    isAdmin: isAdmin,
  }
}

export default connect(mapStateToProps)(ProfileStatusAndActionBar)
