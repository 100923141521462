import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import NotificationItem from '../NotificationCenter/NotificationItem'
import bellIcon from '../../icons/bell.svg'
import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down-solid.svg'
import { ReactComponent as ChevronUpIcon } from '../../icons/chevron-up-solid.svg'
import checkmarkGrayIcon from '../../icons/checkmark_gray.svg'
import theme from '../../theme'
import { getNotifications, getUserSessionExpired } from '../../redux/selectors'
import {
  updateNotificationsStatus,
  fetchNotifications,
} from '../../redux/actions'
import I18n from '../I18n'

const NotificationCenter = ({
  notifications,
  updateNotificationsStatus,
  userSessionExpired,
  fetchNotifications,
  selected,
  onToolClicked,
  Backdrop,
}) => {
  const [shouldPollNotifications, setShouldPollNotifications] = useState(true)
  const [expanded, setExpanded] = useState(false)
  const [controlsExpanded, setControlsExpanded] = useState(false)
  const [readFilter, setReadFilter] = useState(false)
  const [allNotifications, setAllNotifications] = useState([])
  const [filteredNotifications, setFilteredNotifications] = useState([])
  const isOpen = selected

  useEffect(() => {
    setShouldPollNotifications(!userSessionExpired)
  }, [userSessionExpired])

  useEffect(() => {
    const poller = pollNotifications(shouldPollNotifications)

    return () => {
      clearInterval(poller)
    }
  }, [shouldPollNotifications])

  useEffect(() => {
    setAllNotifications(notifications)
    setFilteredNotifications(notifications)
  }, [notifications])

  useEffect(() => {
    setFilteredNotifications(allNotifications)
  }, [allNotifications])

  const pollNotifications = (shouldPollNotifications) => {
    if (shouldPollNotifications) {
      return setInterval(maybefetchNotifications, 10000)
    }
    return null
  }

  const maybefetchNotifications = () => {
    document.visibilityState === 'visible' && fetchNotifications()
  }

  const unseenNotificationIds = () =>
    allNotifications.filter((n) => !n.seen).map((n) => n.id)

  const unreadNotificationIds = () =>
    allNotifications.filter((n) => !n.read).map((n) => n.id)

  const onMarkAllReadClicked = () => {
    unreadNotificationIds().length > 0 &&
      updateNotificationsStatus({ ids: unreadNotificationIds(), read: true })
    setAllNotifications(
      allNotifications.map((n) => {
        return {
          ...n,
          read: true,
        }
      }),
    )
    setControlsExpanded(false)
  }

  const onToggleNotificationCenter = () => {
    onToolClicked()
    const newOpenStatus = !isOpen
    if (newOpenStatus === true) {
      unseenNotificationIds().length > 0 &&
        updateNotificationsStatus({ ids: unseenNotificationIds(), seen: true })
      setAllNotifications(
        allNotifications.map((n) => {
          return {
            ...n,
            seen: true,
          }
        }),
      )
    }
  }

  const filterNotificationsByRead = (shouldFilter) => {
    setReadFilter(shouldFilter)

    setFilteredNotifications(
      shouldFilter
        ? allNotifications.filter((not) => !not.read)
        : allNotifications,
    )
  }

  return (
    <Wrapper>
      <Bell onClick={() => onToggleNotificationCenter()}>
        <img src={bellIcon}></img>

        {allNotifications.filter((l) => !l.seen)?.length > 0 && (
          <NotificationCountWrapper>
            <NotificationCount>
              {allNotifications.filter((l) => !l.seen).length < 100
                ? allNotifications.filter((l) => !l.seen).length
                : '99'}
            </NotificationCount>
          </NotificationCountWrapper>
        )}
      </Bell>
      {isOpen && (
        <>
          <Backdrop />
          <Dropdown expanded={expanded}>
            <NotificationsHeader>
              <NotificationsHeaderHeader>
                <NotificationsTitle onClick={() => setExpanded(!expanded)}>
                  <img src={bellIcon}></img>
                  <span>
                    <I18n id="notification_title" />{' '}
                  </span>
                  <span>
                    {expanded ? (
                      <ChevronUpIcon style={chevronStyle} />
                    ) : (
                      <ChevronDownIcon style={chevronStyle} />
                    )}
                  </span>
                </NotificationsTitle>
                <NotificationOptionsBtn
                  onClick={() => setControlsExpanded(!controlsExpanded)}
                >
                  <div></div>
                  <div></div>
                  <div></div>
                </NotificationOptionsBtn>
              </NotificationsHeaderHeader>

              <NotificationsFilters>
                <FilterOption
                  onClick={() => filterNotificationsByRead(false)}
                  className={!readFilter ? 'active' : ''}
                >
                  <I18n id="All" />
                </FilterOption>
                <FilterOption
                  onClick={() => filterNotificationsByRead(true)}
                  className={readFilter ? 'active' : ''}
                >
                  <I18n id="Unread" />
                </FilterOption>
              </NotificationsFilters>
              <NotificationOptions controlsExpanded={controlsExpanded}>
                <div onClick={() => onMarkAllReadClicked()}>
                  <img src={checkmarkGrayIcon} />
                  <div>
                    <I18n id="Mark_all_read" />
                  </div>
                </div>
              </NotificationOptions>
            </NotificationsHeader>

            {false && <p notifications={filteredNotifications}></p>}
            {filteredNotifications?.map((n) => (
              <NotificationItem
                key={n.id}
                updateNotificationsStatus={updateNotificationsStatus}
                notification={n}
              />
            ))}
          </Dropdown>
        </>
      )}
    </Wrapper>
  )
}

const chevronStyle = {
  cursor: 'pointer',
  margin: 'auto',
  fill: theme.secondary,
  width: '1em',
  height: '1em',
  alignmentBaseline: 'central',
}

const NotificationOptions = styled.div`
  background-color: white;
  flex-direction: row;
  border: 1px solid #ccc;
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  color: black;
  background-color: #fff;
  z-index: 1;

  display: ${({ controlsExpanded }) => (controlsExpanded ? 'block' : 'none')};
  align-items: center;
  div {
    cursor: pointer;
    display: flex;
    padding: 0.8em 1em;
    font-weight: bold;
    :hover {
      background-color: #ddd;
    }
  }
`

const NotificationOptionsBtn = styled.div`
  color: black;
  font-size: 2em;
  display: flex;
  margin: 0 0.5em;
  cursor: pointer;
  align-items: center;
  border-radius: 50%;
  padding: 0.5em;

  :hover {
    background-color: #ddd;
  }

  div {
    display: block;
    width: 0.2em;
    height: 0.2em;
    background-color: ${({ theme: { secondary } }) => secondary};
    border-radius: 50%;
    margin: 1px;
  }
`

const NotificationsHeaderHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const NotificationsHeader = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const NotificationsFilters = styled.div`
  display: flex;
  padding: 0.5em 1em;
  border-top: 1px solid #eee;
`

const FilterOption = styled.div`
  display: flex;
  margin: 0 0.5em;
  border-radius: 8px;
  padding: 0.2em;
  cursor: pointer;
  color: #666;
  font-weight: bold;

  &.active {
    color: ${({ theme: { inverseText } }) => inverseText};
    background-color: ${({ theme: { secondary } }) => secondary};
  }
`

const NotificationsTitle = styled.h1`
  font-size: ${({ theme: { largeText } }) => largeText};
  color: black;
  padding: 1em;
  margin: 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    font-weight: bold;
    color: #666;
  }
  img {
    width: 1.5em;
    height: 1.5em;
  }

  * {
    margin: 0 0.2em;
  }
`

const Wrapper = styled.div`
  z-index: 1;
  position: relative;
  margin-left: auto;
  align-self: stretch;
  align-items: center;
  display: flex;
`

const Bell = styled.div`
  position: relative;
  cursor: pointer;
  img {
    width: 1.8em;
    height: 1.8em;
  }
  transform: translateY(0.125em);
  &:hover {
    opacity: 0.8;
  }
`

const NotificationCount = styled.div`
  font-size: 10px;
`

const NotificationCountWrapper = styled.div`
  position: absolute;
  top: -5%;
  right: -15%;
  width: 1em;
  height: 1em;
  line-height: 1em;
  background-color: #ff9800;
  border-radius: 50%;
  padding: 0.6em;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Dropdown = styled.div`
  background-color: white;
  display: block;
  box-shadow: rgb(219 219 219) 0px 2px 8px;
  border-radius: 5px;
  top: 100%;
  right: 0;
  min-width: 100%;
  position: absolute;
  margin-top: 1em;
  max-height: ${({ expanded }) => (expanded ? '100vh' : '600px')};

  & > :not(:last-child) {
    border-bottom: 1px solid #eee;
  }

  overflow-y: auto;
  overflow-x: hidden;

  --scrollbarBG: rgba(0, 0, 0, 0.1);
  --thumbBG: #90a4ae;

  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
`

const mapDispatchToProps = {
  updateNotificationsStatus,
  fetchNotifications,
}

const mapStateToProps = (state) => ({
  notifications: getNotifications(state) || [],
  userSessionExpired: getUserSessionExpired(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationCenter)
