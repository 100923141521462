import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import * as dateFns from 'date-fns'
import notificationProps from './notificationProps'
import I18n from '../I18n'

const NotificationItem = ({ notification, updateNotificationsStatus }) => {
  const [read, setRead] = useState(notification.read)
  const { title, message } = notificationProps[notification.type] || {}

  useEffect(() => {
    setRead(notification.read)
  }, [notification.read])

  const onNotificationClicked = () => {
    if (!notification.read) {
      notification.read = true
      setRead(true)
      updateNotificationsStatus({ ids: [notification.id], read: true })
    }
  }
  if (title) {
    return (
      <Wrapper
        style={{ textDecoration: 'none' }}
        to={notification.link ? notification.link : ''}
        onClick={() => onNotificationClicked()}
      >
        <ReadMarker
          title={
            read ? (
              <I18n id='Read' />
            ) : (
              <I18n id='Unread' />
            )
          }
        >
          {!read && <div></div>}
        </ReadMarker>

        <Content>
          <Top>
            <NotificationTitle>{title(notification)}</NotificationTitle>
            <NotificationAge>
              {dateFns.formatDistanceToNowStrict(
                dateFns.subMinutes(
                  new Date(notification.createdOn),
                  new Date().getTimezoneOffset(),
                ),
              )}
            </NotificationAge>
          </Top>
          <NotificationMessage>{message(notification)}</NotificationMessage>
        </Content>
      </Wrapper>
    )
  }
}

const ReadMarker = styled.div`
  width: 2em;
  height: 2em;
  margin: 0 0.5em;
  display: flex;
  align-items: center;

  div {
    display: block;
    width: 0.7em;
    height: 0.7em;
    background-color: ${({ theme: { secondary } }) => secondary};
    border-radius: 50%;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 2em);
  position: relative;
  text-decoration: none;
`

const Top = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
`

const NotificationTitle = styled.div`
  font-weight: bold;
  font-size: 12px;
  margin-right: 1em;
  width: 80%;
`

const NotificationMessage = styled.div`
  font-size: 10px;
`

const NotificationAge = styled.div`
  color: #aaa;
  font-size: 10px;
  width: 20%;
`

const Wrapper = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  width: 350px;
  background-color: transparent;
  border: none;
  padding: 1em;
  color: ${({ theme: { text } }) => text};
  background-color: ${({ theme: { primaryBackground } }) => primaryBackground};
  cursor: pointer;

  * {
  }

  &:hover {
    background-color: #efefef;
  }

  & > :not(:last-child) {
    margin-right: 0.5em;
  }
`

export default NotificationItem
