import React from 'react'
import { connect } from 'react-redux'
import CaseListItem from './CaseListItem'
import I18n, { useI18nFunction } from '../I18n'
import Button from '../Button'
import uuid from 'uuid/v4'
import styled from 'styled-components'
import { addConnection } from '../../redux/actions'
import { getRelationCases } from '../../redux/selectors'
import CaseList from '../CaseList'
import caseProps from '../caseProps'
import { RELATION } from '../../connectionTypes'

const RelationCaseListItem = (props) => {
  const I18nFunction = useI18nFunction();
  return (
    <li>
      <CaseListItem
        {...props}
        id={props.relation.id}
        image={caseProps[RELATION].image}
        title={
          props.relation.firstName && props.relation.lastName
            ? `${props.relation.firstName} ${props.relation.lastName}`
            : props.relation.firstName
            ? props.relation.firstName
            : props.relation.lastName
            ? props.relation.lastName
            : undefined
        }
        titleFallback={
          <I18n id="connection_list_item.relation_title_fallback" />
        }
        description={I18nFunction({ id: `connection_form.relation_types.${props.relation.relationType}` })}
        descriptionFallback={
          <I18n id="connection_list_item.relation_description_fallback" />
        }
        userConnection={props.relation}
      />
      <Children>
        {props.editable && (
          <ButtonsWrapper>
            <Label>
              <I18n id="Add" />:
            </Label>
            <Buttons>
              <Button small="true" onClick={() => null}>
                <I18n id="Ownership" />
              </Button>
              <Button small="true" onClick={() => null}>
                <I18n id="Role" />
              </Button>
              <Button small="true" onClick={() => null}>
                <I18n id="Other" />
              </Button>
            </Buttons>
          </ButtonsWrapper>
        )}
        <CaseList
          cases={props.relationCases}
          activeConnection={props.activeConnection}
          editable={props.editable}
          small
        />
      </Children>
    </li>
  )
}

const ButtonsWrapper = styled.div`
  margin: 1em 0;
  display: flex;
  align-items: center;
`

const Label = styled.div`
  color: ${({ theme: { subtleText } }) => subtleText};
  margin-right: 1em;
`

const Buttons = styled.div`
  flex-grow: 1;
  display: flex;

  & > * {
    flex-grow: 1;
    flex-basis: 0px;
    flex-shrink: 0;
    min-width: 0px;
  }

  & > :not(:last-child) {
    margin-right: 1em;
  }
`

const Children = styled.div`
  margin-left: 7em;
`

const mapStateToProps = (state, props) => {
  const relationCases = getRelationCases(state, props.relation.id)
  return {
    relationCases,
  }
}

const mapDispatchToProps = (dispatch, { id: relationId }) => ({
  addConnection: (connectionType) =>
    dispatch(
      addConnection({
        id: uuid(),
        relationId,
        connectionType,
      }),
    ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RelationCaseListItem)
