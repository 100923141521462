import React from 'react'
import styled from 'styled-components'
import Button from '../Button'
import { DatePicker } from '../form'

const DateFilter = ({
  prop,
  dateFilter,
  setFilterHandler,
  placeholder,
  buttonDescription,
}) => {
  const handleChange = (timeValue) => {
    dateFilter = timeValue
    setFilterHandler(dateFilter)
  }

  const resetDateFilter = () => {
    handleChange('')
  }

  return (
    <Wrapper>
      <DateLabel>{prop.label}</DateLabel>
      <ResetDateFilterButton
        small
        subtle
        inactive={!dateFilter}
        disabled={!dateFilter}
        onClick={() => resetDateFilter()}
      >
        <span>{buttonDescription}</span>
      </ResetDateFilterButton>
      <DatePicker
        placeholderText={placeholder}
        props={prop}
        onChange={(e) => handleChange(e.target.value)}
        value={dateFilter}
        id={prop.id}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 0.5em 0.5em 0.5em 0.5em;
  position: relative;
`

const DateLabel = styled.div`
  padding-top: 0.5em;
  padding-left: 0.6em;
`

const ResetDateFilterButton = styled(Button)`
  color: ${(props) => (props.inactive ? 'grey' : '')};
  font-weight: bold;
  padding-top: 0.5em;
  padding-left: 0.6em;
`

export default DateFilter
