import React from 'react'
import ConnectionForm from './ConnectionForm'
import * as schema from '../../validation'
import I18n, { useI18nFunction } from '../I18n'
import {
  comment,
  postCodeAndPlaceRelation,
  firstName,
  lastName,
  isResidentAbroad,
  birthDate,
  address,
  relationType,
  email,
} from './fields'

const RelationForm = (props) => {
  const I18nFunction = useI18nFunction()

  return (
    <ConnectionForm
      {...props}
      heading={<I18n id="connection_form.headings.relation" />}
      schema={schema.relation(I18nFunction)}
      fields={fields}
    />
  )
}

export const fields = [
  firstName(),
  lastName(),
  birthDate(),
  email(),
  isResidentAbroad(),
  address(),
  postCodeAndPlaceRelation(),
  relationType(),
  comment(),
]
export default RelationForm
