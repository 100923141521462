import React from 'react'
import styled from 'styled-components'
import Page from '../components/Page'
import Button from '../components/Button'
import { height } from '../components/Header'
import { footerHeight } from '../components/Footer'
import I18n from '../components/I18n'

const Login = () => {
  return (
    <Page>
      <Wrapper>
        <Content>
          <h1>
            <I18n id="Welcome" />
          </h1>
          <p>
            <I18n id="Click_log_in" />
          </p>
          <Button as="a" href="/login">
            <I18n id="Log_in" />
          </Button>
        </Content>
      </Wrapper>
    </Page>
  )
}

const Content = styled.div`
  margin: auto;
  text-align: center;
  font-size: ${(props) => props.theme.largeText};
  padding-bottom: 3em;
`

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - ${height} - ${footerHeight});
`

export default Login
