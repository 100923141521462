import { call, put, takeEvery, select } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { get, post, remove } from '../fetch'
import { fetchSaga } from '../sagas'

import {
  CONFIRM_RELATION,
  CREATE_RELATION,
  CREATE_RELATION_FAILED,
  CREATE_RELATION_SUCCEEDED,
  UPDATE_RELATION,
  UPDATE_RELATION_FAILED,
  UPDATE_RELATION_SUCCEEDED,
  REMOVE_RELATION,
  REMOVE_RELATION_FAILED,
  REMOVE_RELATION_SUCCEEDED,
  FETCH_RELATION,
  FETCH_RELATION_FAILED,
  FETCH_RELATION_SUCCEEDED,
  CONFIRM_RELATION_SUCCEEDED,
} from './index'
import { getActiveConnection } from '../selectors'
import { isValidUUID } from '../../utils'
import { UNSET_ACTIVE_CONNECTION } from '../connectionsForm'

import * as schema from '../schema'
// import { SET_RELATION_PROFF_PERSON_SUCCEEDED } from '../proffSelector'

function* confirmRelation({ id, userId }) {
  const isNewConnection = isValidUUID(id)
  yield put({
    type: isNewConnection ? CREATE_RELATION : UPDATE_RELATION,
    id,
    userId,
  })
}

function* createRelation({ id, userId }) {
  try {
    const relation = yield select(getActiveConnection)

    const body = {
      ...relation,
      birthdate: `${relation.birthdate_year}-${relation.birthdate_month}-${relation.birthdate_day}`,
    }

    const { data, errors } = yield call(() =>
      post(`/users/${userId}/relations`, body),
    )

    if (errors) {
      yield put({
        type: CREATE_RELATION_FAILED,
        id,
        userId,
        errors,
      })
    } else {
      yield put({
        type: CREATE_RELATION_SUCCEEDED,
        id: data[0].id,
        userId,
        payload: normalize(data, schema.arrayOfRelations),
      })
    }
  } catch (error) {
    console.error(error)
    yield put({
      type: CREATE_RELATION_FAILED,
      id,
      userId,
      errors: [{ detail: error.message }],
    })
  }
}

function* updateRelation({ id, userId }) {
  try {
    const {
      birthdate_year,
      birthdate_month,
      birthdate_day,
      updates = {},
    } = yield select(getActiveConnection)

    const { data, errors } = yield call(() =>
      post(`/users/${userId}/relations/${id}`, {
        ...updates,
        birthdate: `${updates.birthdate_year || birthdate_year}-${
          updates.birthdate_month || birthdate_month
        }-${updates.birthdate_day || birthdate_day}`,
      }),
    )
    if (errors) {
      yield put({
        type: UPDATE_RELATION_FAILED,
        id,
        userId,
        errors,
      })
    } else {
      yield put({
        type: UPDATE_RELATION_SUCCEEDED,
        id,
        userId,
        payload: normalize(data, schema.arrayOfRelations),
      })
    }
  } catch (error) {
    console.error(error)
    yield put({
      type: UPDATE_RELATION_FAILED,
      id,
      userId,
      errors: [{ detail: error.message }],
    })
  }
}

function* removeRelation({ id, userId }) {
  try {
    const { errors } = yield call(() =>
      remove(`/users/${userId}/relations/${id}`),
    )
    if (errors) {
      yield put({
        type: REMOVE_RELATION_FAILED,
        userId,
        id,
        errors,
      })
    } else {
      yield put({
        type: REMOVE_RELATION_SUCCEEDED,
        userId,
        id,
      })
    }
  } catch (error) {
    console.error(error)
    yield put({
      type: REMOVE_RELATION_FAILED,
      userId,
      id,
      errors: [{ detail: error.message }],
    })
  }
}

function* closeActiveConnection() {
  yield put({
    type: UNSET_ACTIVE_CONNECTION,
  })
}

function* saga() {
  yield takeEvery(CONFIRM_RELATION, confirmRelation)
  yield takeEvery(CREATE_RELATION, createRelation)
  yield takeEvery(UPDATE_RELATION, updateRelation)
  yield takeEvery(REMOVE_RELATION, removeRelation)
  yield takeEvery(
    FETCH_RELATION,
    fetchSaga({
      SUCCEEDED: FETCH_RELATION_SUCCEEDED,
      FAILED: FETCH_RELATION_FAILED,
      schema: schema.arrayOfRelations,
      fetch: ({ id, userId }) => get(`/users/${userId}/relations/${id}`),
    }),
  )
  yield takeEvery(UPDATE_RELATION_SUCCEEDED, closeActiveConnection)
  yield takeEvery(CONFIRM_RELATION_SUCCEEDED, closeActiveConnection)
}

export default saga
