import React from 'react'
import eventProps from './eventProps'
import EventPayload from './EventPayload'
import ToggleEvent from './ToggleEvent'

const Event = (props) => {
  const { payloadProps, doNotRenderPayload } = eventProps[props.eventType] || {}

  if (payloadProps) {
    return (
      <ToggleEvent
        {...props}
        hasContent={
          !!props.payload &&
          Object.keys(props.payload).length > 0 &&
          !doNotRenderPayload
        }
      >
        {!doNotRenderPayload && (
          <EventPayload payloadProps={payloadProps} payload={props.payload} />
        )}
      </ToggleEvent>
    )
  }
  return
}

export default Event
