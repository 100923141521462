import React from 'react'
import styled from 'styled-components'
import Header from './Header'
import Spinner from './Spinner'
import FlashErrorsComponent from './FlashErrors'
import Footer from './Footer'


const Page = ({ children, pending, errorMessage }) => {
  return (
    <Wrapper>
      <Header pending={pending}/>
      <MainContent>
        <FlashErrors />
        {pending ? <Spinner /> : <Content>{children}</Content>}
      </MainContent>
      <FooterContent>
        <Footer pending={pending} />
      </FooterContent>
    </Wrapper>
  )
}

const FlashErrors = styled(FlashErrorsComponent)`
  position: fixed;
  top: 2em;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
`

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`
const MainContent = styled.div`
  flex: 1;
`

export const height = '3.8em'

const FooterContent = styled.div`
  position: relative;
  min-height: ${height};
  margin: 0 auto;
  align-items: center;
  text-align: center;
  padding: 0 0.75em;
  background-color: ${({ theme: { primary } }) => primary};
  width: 100%;
  height: 100%;
`

const Content = styled.div``

export default Page
