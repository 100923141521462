import React, {useState} from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import UserMenu from './UserMenu'
import LanguageSelector from './LanguageSelector'
import NotificationCenter from '../NotificationCenter/NotificationCenter'
import {
    isAdmin,
    isAuthenticated,
    getCurrentUser,
    isManager,
  } from '../../redux/selectors'

const Toolbar = ({pending, isAuthenticated, currentUser}) => {

    const NOTIFICATION_CENTER = "NOTIFICATION_CENTER";
    const USER_MENU = "USER_MENU";
    const LANGUAGE_SELECTOR = "LANGUAGE_SELECTOR";

    const [selected, setSelected] = useState(undefined);

    const onToolClicked = (type) => {
      selected !== type ? setSelected(type) : setSelected(undefined)
    }


    const DropdownBackdrop = () => {

      return <DropdownBackdropStyle onClick={() => setSelected(undefined)}/>
    }

    const buttons = [
      {
        type: NOTIFICATION_CENTER,
        enabled: isAuthenticated,
        onClick: () => onToolClicked(NOTIFICATION_CENTER),
        ToolComponent: (props) => <NotificationCenter {...props}/>,
      },
      {
        type: USER_MENU,
        enabled: isAuthenticated,
        onClick: () => onToolClicked(USER_MENU),
        ToolComponent: (props) => <UserMenu {...props}/>,
      },
      {
        type: LANGUAGE_SELECTOR,
        enabled: true,
        onClick: () => onToolClicked(LANGUAGE_SELECTOR),
        ToolComponent: (props) => <LanguageSelector {...props}/>,
      },
    ]
    


    return (
        <Right>
          {
            !pending && buttons.map(({type, enabled, ToolComponent}) => 
            enabled && 
            (
            <ToolComponent 
              key={type} 
              selected={type === selected} 
              onToolClicked={() => onToolClicked(type)}
              Backdrop={DropdownBackdrop}/>
            )
          )}
        


          
        </Right>
    )
}


const DropdownBackdropStyle = styled.div`
  position: fixed;
  top: 3.6em;
  left: 0;
  bottom: 0;
  right: 0;
`

const Right = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  & > * {
    margin-left: 1em;
  }
`

const mapStateToProps = (state) => ({
    isManager: isManager(state),
    isAdmin: isAdmin(state),
    isAuthenticated: isAuthenticated(state),
    currentUser: getCurrentUser(state),
  })
  
export default connect(mapStateToProps)(Toolbar)
