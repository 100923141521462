import { call, put, takeEvery, select } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import {
  CONFIRM_CONNECTION,
  CREATE_CONNECTION,
  CREATE_CONNECTION_SUCCEEDED,
  CREATE_CONNECTION_FAILED,
  UPDATE_CONNECTION,
  UPDATE_CONNECTION_SUCCEEDED,
  UPDATE_CONNECTION_FAILED,
  REMOVE_CONNECTION,
  REMOVE_CONNECTION_SUCCEEDED,
  REMOVE_CONNECTION_FAILED,
  CREATE_CONNECTION_APPLICATION,
  CREATE_CONNECTION_APPLICATION_SUCCEEDED,
  CREATE_CONNECTION_APPLICATION_FAILED,
} from './index'
import * as schema from '../schema'
import { getActiveConnection } from '../selectors'
import {
  USER_ROLE,
  PUBLIC_DATA_ROLE,
  USER_OWNERSHIP,
  PUBLIC_DATA_OWNERSHIP,
  USER_OTHER_CONNECTION,
  RELATION,
  RELATION_ROLE,
  RELATION_OWNERSHIP,
  RELATION_OTHER_CONNECTION,
  RELATION_PUBLIC_DATA_ROLE,
  RELATION_PUBLIC_DATA_OWNERSHIP,
} from '../../connectionTypes'
import { post, remove } from '../fetch'
import { FETCH_CASE } from '../cases'
import { isValidUUID } from '../../utils'
import { UNSET_ACTIVE_CONNECTION } from '../connectionsForm'

const connectionTypeUrl = ({ relationId, connectionType }) => {
  switch (connectionType) {
    case USER_ROLE:
    case PUBLIC_DATA_ROLE:
      return '/roles'
    case USER_OWNERSHIP:
    case PUBLIC_DATA_OWNERSHIP:
      return '/ownerships'
    case USER_OTHER_CONNECTION:
      return '/other_connections'
    case RELATION:
      return '/relations'
    case RELATION_ROLE:
    case RELATION_PUBLIC_DATA_ROLE:
      return `/relations/${relationId}/roles`
    case RELATION_OWNERSHIP:
    case RELATION_PUBLIC_DATA_OWNERSHIP:
      return `/relations/${relationId}/ownerships`
    case RELATION_OTHER_CONNECTION:
      return `/relations/${relationId}/other_connections`
    default:
      throw new Error('Unknown connection type')
  }
}

function* confirmConnection({ userId, id, connectionType }) {
  const isNewConnection = isValidUUID(id)
  yield put({
    type: isNewConnection ? CREATE_CONNECTION : UPDATE_CONNECTION,
    userId,
    id,
    connectionType,
  })
}

function* createConnectionApplication({ id, userId, connectionType, caseId }) {
  try {
    const connection = yield select(getActiveConnection)

    const { data, errors } = yield call(() =>
      post(
        `/users/${userId}${connectionTypeUrl({
          relationId: connection.relationId,
          connectionType,
        })}/application`,
        {
          ...connection,
          organizationNumber:
            connection.organizationNumber &&
            typeof connection.organizationNumber === 'string'
              ? connection.organizationNumber.replace(/ /g, '')
              : connection.organizationNumber,
        },
      ),
    )
    if (errors) {
      yield put({
        type: CREATE_CONNECTION_APPLICATION_FAILED,
        id,
        userId,
        connectionType,
        errors,
      })
    } else {
      yield put({
        type: CREATE_CONNECTION_APPLICATION_SUCCEEDED,
        id,
        userId,
        connectionType: connectionType,
        payload: normalize(data, schema.arrayOfCase),
      })

      if (caseId) {
        yield put({
          type: FETCH_CASE,
          caseId: caseId,
        })
      }
    }
  } catch (error) {
    console.error(error)
    yield put({
      type: CREATE_CONNECTION_APPLICATION_FAILED,
      id,
      userId,
      connectionType,
      errors: [{ detail: error.message }],
    })
  }
}

function* createConnection({ id, userId, connectionType }) {
  try {
    const connection = yield select(getActiveConnection)

    const body = {
      ...connection,
      organizationNumber:
        connection.organizationNumber &&
        typeof connection.organizationNumber === 'string'
          ? connection.organizationNumber.replace(/ /g, '')
          : connection.organizationNumber,
    }

    const { data, errors } = yield call(() =>
      post(
        `/users/${userId}${connectionTypeUrl({
          relationId: connection.relationId,
          connectionType,
        })}`,
        body,
      ),
    )

    if (errors) {
      yield put({
        type: CREATE_CONNECTION_FAILED,
        id,
        userId,
        connectionType,
        errors,
      })
    } else {
      yield put({
        type: CREATE_CONNECTION_SUCCEEDED,
        id,
        userId,
        connectionType,
        payload: normalize(data, schema.arrayOfCase),
      })
    }
  } catch (error) {
    console.error(error)
    yield put({
      type: CREATE_CONNECTION_FAILED,
      id,
      userId,
      connectionType,
      errors: [{ detail: error.message }],
    })
  }
}

function* updateConnection({ id, userId, connectionType }) {
  try {
    const {
      birthdate_year,
      birthdate_month,
      birthdate_day,
      relationId,
      updates = {},
    } = yield select(getActiveConnection)

    const { data, errors } = yield call(() =>
      post(
        `/users/${userId}${connectionTypeUrl({
          relationId,
          connectionType,
        })}/${id}`,
        {
          ...updates,
          birthdate: `${updates.birthdate_year || birthdate_year}-${
            updates.birthdate_month || birthdate_month
          }-${updates.birthdate_day || birthdate_day}`,
          organizationNumber:
            typeof updates.organizationNumber === 'string'
              ? updates.organizationNumber.replace(/ /g, '')
              : updates.organizationNumber,
        },
      ),
    )
    if (errors) {
      yield put({
        type: UPDATE_CONNECTION_FAILED,
        id,
        userId,
        connectionType,
        errors,
      })
    } else {
      yield put({
        type: UPDATE_CONNECTION_SUCCEEDED,
        id,
        userId,
        connectionType,
        payload: normalize(data, schema.arrayOfCase),
      })
    }
  } catch (error) {
    console.error(error)
    yield put({
      type: UPDATE_CONNECTION_FAILED,
      id,
      userId,
      connectionType,
      errors: [{ detail: error.message }],
    })
  }
}

function* removeConnection({ userId, id, connectionType, caseId }) {
  try {
    const { relationId } = yield select(getActiveConnection, connectionType, id)

    const { errors } = yield call(() =>
      remove(
        `/users/${userId}${connectionTypeUrl({
          relationId,
          connectionType,
        })}/${id}`,
      ),
    )
    if (errors) {
      yield put({
        type: REMOVE_CONNECTION_FAILED,
        userId,
        id,
        connectionType,
        errors,
      })
    } else {
      yield put({
        type: REMOVE_CONNECTION_SUCCEEDED,
        userId,
        id,
        connectionType,
        caseId,
      })
    }
  } catch (error) {
    console.error(error)
    yield put({
      type: REMOVE_CONNECTION_FAILED,
      userId,
      id,
      connectionType,
      errors: [{ detail: error.message }],
    })
  }
}

function* closeActiveConnection() {
  yield put({
    type: UNSET_ACTIVE_CONNECTION,
  })
}

function* saga() {
  yield takeEvery(CREATE_CONNECTION_APPLICATION, createConnectionApplication)
  yield takeEvery(CONFIRM_CONNECTION, confirmConnection)
  yield takeEvery(CREATE_CONNECTION, createConnection)
  yield takeEvery(UPDATE_CONNECTION, updateConnection)
  yield takeEvery(REMOVE_CONNECTION, removeConnection)
  yield takeEvery(UPDATE_CONNECTION_SUCCEEDED, closeActiveConnection)
  yield takeEvery(CREATE_CONNECTION_SUCCEEDED, closeActiveConnection)
}

export default saga
