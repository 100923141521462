import { LOG_OUT, LOG_OUT_SUCCEEDED, LOG_OUT_FAILED } from './actionTypes'

const logOut = (state = false, action) => {
  switch (action.type) {
    case LOG_OUT:
      return true

    case LOG_OUT_SUCCEEDED:
    case LOG_OUT_FAILED:
      return false

    default:
      return state
  }
}

export const isPending = (state) => state

export default logOut
