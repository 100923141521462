import React from 'react'
import styled from 'styled-components'
import { Input, Label } from './index'
import ErrorMessage from '../ErrorMessage'
import I18n from '../I18n'

const BirthdateInput = ({
  name,
  day,
  month,
  year,
  onChange,
  onBlur,
  visited,
  errors,
  autoFocus,
}) => {
  return (
    <Wrapper>
      <Inputs>
        <Day>
          <Label htmlFor="day">
            <I18n id="Day" />
          </Label>
          <Input
            name="birthdate_day"
            value={day}
            onChange={onChange}
            onBlur={onBlur}
            error={!!errors.birthdate_day}
            visited={visited.birthdate_day}
            maxLength="2"
            placeholder="dd"
            autoFocus={autoFocus}
          />
        </Day>
        <Month>
          <Label htmlFor="month">
            <I18n id="Month" />
          </Label>
          <Input
            name="birthdate_month"
            value={month}
            onChange={onChange}
            onBlur={onBlur}
            error={!!errors.birthdate_month}
            visited={visited.birthdate_month}
            placeholder="mm"
            maxLength="2"
          />
        </Month>
        <Year>
          <Label htmlFor="year">
            <I18n id="Year" />
          </Label>
          <Input
            name="birthdate_year"
            value={year}
            onChange={onChange}
            onBlur={onBlur}
            error={!!errors.birthdate_year}
            visited={visited.birthdate_year}
            placeholder="yyyy"
            maxLength="4"
          />
        </Year>
      </Inputs>
      <ErrorMessage>
        {visited.birthdate_day && errors.birthdate_day}
      </ErrorMessage>
      <ErrorMessage>
        {visited.birthdate_month && errors.birthdate_month}
      </ErrorMessage>
      <ErrorMessage>
        {visited.birthdate_year && errors.birthdate_year}
      </ErrorMessage>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Inputs = styled.div`
  display: flex;
  max-width: 200px;

  & > * {
    flex-grow: 1;
    flex-basis: 0px;
    flex-shrink: 0;
    min-width: 0px;
  }

  & > :not(:last-child) {
    margin-right: 1em;
  }

  & label {
    margin-bottom: 0.5em;
    display: block;
  }
`

const Day = styled.div``

const Month = styled.div``

const Year = styled.div`
  flex-grow: 2;
`

export default BirthdateInput
