import React from 'react'
import styled from 'styled-components'
import SmallSpinner from '../SmallSpinner'
import ProfileCaseListCustomRelevant from './ProfileCaseListCustomRelevant'
import ProfileCaseListDefault from './ProfileCaseListDefault'

const ProfileCaseList = (props) => {
  if (props.isUserProfileDataFetching) {
    return (
      <CenterFlex>
        <SmallSpinner size={'3em'} color={'secondary'} />
      </CenterFlex>
    )
  }
  if (
    process.env.REACT_APP_SET_RELEVANT_CONNECTION_ENABLED?.toLowerCase() ===
    'true'
  ) {
    return <ProfileCaseListCustomRelevant {...props} />
  } else {
    return <ProfileCaseListDefault {...props} />
  }
}

const CenterFlex = styled.div`
  display: flex;
  justify-content: center;
  padding: 2em;
`

export default ProfileCaseList
