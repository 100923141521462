import * as dateFns from 'date-fns'

const newDate = (date) =>
  dateFns.subMinutes(new Date(date), new Date().getTimezoneOffset())

export const prettyDate = (date) =>
  date !== undefined ? dateFns.format(newDate(date), 'dd-MM-yyyy') : undefined

export const prettyTime = (date) => dateFns.format(newDate(date), 'HH:mm')

export const prettyDateTime = (date) =>
  date !== undefined
    ? dateFns.format(newDate(date), 'dd-MM-yyyy HH:mm')
    : undefined


export const isSameDay = (a, b) => dateFns.isSameDay(newDate(a), newDate(b))

export const getDateWithoutTime = (date) => {
  return date !== undefined
    ? dateFns.format(newDate(date), 'dd-MM-yyyy')
    : undefined
}
