import React, { useState } from 'react'
import ReactTextarea from 'react-textarea-autosize'
import styled from 'styled-components'
import Button from '../Button'
import {
  setPositionOfInfluence,
  setPositionOfInfluenceDescription,
} from '../../redux/actions'
import { connect } from 'react-redux'
import I18n, { useI18nFunction } from '../I18n'
import { getUser, isSetPositionOfInfluencePending } from '../../redux/selectors'

const PositionOfInfluenceModal = ({
  userId,
  positionOfInfluenceDescription,
  onClose,
  positionOfInfluence,
  setPositionOfInfluence,
  isSetPositionOfInfluencePending,
  setPositionOfInfluenceDescription,
  name,
}) => {
  const I18nFunction = useI18nFunction();
  const [description, setDescription] = useState(positionOfInfluenceDescription)
  const [newPOIValue, setNewPOIValue] = useState(positionOfInfluence)
  const descriptionChanged = description != positionOfInfluenceDescription
  const POIvalueChanged = positionOfInfluence != newPOIValue

  const onSubmitPOIChanges = () => {
    if (POIvalueChanged) {
      setPositionOfInfluence(userId, newPOIValue)
    }
    if (descriptionChanged) {
      setPositionOfInfluenceDescription(userId, description)
    }
    onClose()
  }
  return (
    <Overlay onClick={onClose}>
      <ContentWrapper onClick={(e) => e.stopPropagation()}>
        <Header>
          <CurrentStatusWrapper>
            <CurrentStatusPrefix>
              <I18n id="action_bar.set_position_of_influence" />
            </CurrentStatusPrefix>
          </CurrentStatusWrapper>
        </Header>
        <Body>
          <Wrapper>
            <Label>{name}</Label>
            <Container>
              <Toggle
                type="checkbox"
                checked={newPOIValue}
                onChange={(e) => setNewPOIValue(!newPOIValue)}
              ></Toggle>
              <Slider className={newPOIValue ? 'toggled' : ''}></Slider>
            </Container>
          </Wrapper>
          {newPOIValue && (
            <div>
              <Title>
                <I18n id="action_bar.poi_modal_subheader" />:
              </Title>
              <SubTitle>
                <I18n id="action_bar.change_description_disclaimer" />
              </SubTitle>
              <StatementTextArea
                placeholder={
                  description || I18nFunction({ id: 'position_of_influence_placeholder' })
                }
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                minRows={2}
                maxRows={5}
              ></StatementTextArea>
            </div>
          )}
        </Body>
        <ModalActions>
          <Button
            inactive={!descriptionChanged && !POIvalueChanged}
            disabled={!descriptionChanged && !POIvalueChanged}
            onClick={() => onSubmitPOIChanges()}
            small
            pending={isSetPositionOfInfluencePending}
          >
            <I18n id="Save" />
          </Button>
          <Button subtle onClick={onClose} small>
            <I18n id="Close" />
          </Button>
        </ModalActions>
      </ContentWrapper>
    </Overlay>
  )
}

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
`

const ContentWrapper = styled.div`
  background-color: ${(props) => props.theme.primaryBackground};
  margin: 0 auto;
  min-height: 350px;
  min-width: 450px;
  -moz-box-shadow: 0px 0px 18px 6px #ccc;
  -webkit-box-shadow: 0px 0px 18px 6px #ccc;
  box-shadow: 0px 0px 18px 6px #ccc;
  padding: 2em;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  margin: 0;
  padding-bottom: 12px;
  border-bottom: 2px solid #eee;
`

const CurrentStatusPrefix = styled.h1`
  margin: 0;
`

const CurrentStatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const Body = styled.div`
  flex-grow: 7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 0 2em;
`

const Title = styled.p`
  font-weight: bold;
`

const SubTitle = styled.p`
  font-style: italic;
`

const StatementTextArea = styled(ReactTextarea)`
  display: block;
  width: 100%;
  resize: none;
  padding: 1em;
  margin: 2em;
  border-radius: 0.5em;
  border-style: dashed;
  line-height: inherit;
  margin: 1em auto;
`

const ModalActions = styled.div`
  height: 50px;
  display: flex;
  justify-content: flex-end;
  border-top: 2px solid #eee;
  padding-top: 12px;
  & > * {
    margin: 0 1em;
  }
`

const Wrapper = styled.div`
  display: flex;
  padding: 0.1em;
`

const Label = styled.label`
  flex: 1;
`

const Container = styled.label`
  margin-right: 3em;
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`

const Toggle = styled.input`
  :checked + .slider {
    background-color: #2196f3;
  }
`

const Slider = styled.span`
  border-radius: 17px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &:before {
    border-radius: 50%;
    position: absolute;
    content: '';
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  &.toggled {
    background-color: #2196f3;
    box-shadow: 0 0 1px #2196f3;

    &:before {
      -webkit-transform: translateX(13px);
      -ms-transform: translateX(13px);
      transform: translateX(13px);
    }
  }
`

const mapStateToProps = (state, props) => ({
  ...getUser(state, props.userId),
  isSetPositionOfInfluencPending: isSetPositionOfInfluencePending(
    state,
    props?.userId,
  ),
})

const mapDispatchToProps = {
  setPositionOfInfluence,
  setPositionOfInfluenceDescription,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PositionOfInfluenceModal)
