import React from 'react'
import styled from 'styled-components'
import withErrorMessage from './withErrorMessage'

const RadioButtons = ({ name, value, options, onChange, onBlur, disabled }) => {
  return (
    <OptionList>
      {options.map((option) => (
        <Option key={option.value}>
          <RadioButton
            type="radio"
            id={option.value + name}
            name={name}
            checked={value === option.value}
            onChange={() => onChange({ target: { name, value: option.value } })}
            onBlur={onBlur}
          />
          <Label htmlFor={option.value + name}>{option.label}</Label>
        </Option>
      ))}
    </OptionList>
  )
}

const OptionList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;

  & > :not(:last-child) {
    margin-right: 1.2em;
  }
`

const RadioButton = styled.input``

const Option = styled.li`
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 0.5em;
  }
`

const Label = styled.label`
  color: ${({ theme: { text } }) => text};
`

export default withErrorMessage(RadioButtons)
