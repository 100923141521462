import React from 'react'
import styled from 'styled-components'
import { height } from './Header'
import { footerHeight } from './Footer'

const Wrapper = styled.div`
  height: calc(100vh - ${height} - ${footerHeight});
  display: flex;
  justify-content: center;
  align-items: center;
`;


const Wave = styled.div`
  & > div {
    width: 50px;
    height: 50px;
    background-color: ${({ theme: { primary } }) => primary};
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
  }
  @-webkit-keyframes sk-rotateplane {
    0% {
      -webkit-transform: perspective(120px);
    }
    50% {
      -webkit-transform: perspective(120px) rotateY(180deg);
    }
    100% {
      -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
    }
  }
  @keyframes sk-rotateplane {
    0% {
      transform: perspective(120px) rotateX(0deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
      transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
      transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
      -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
  }
`

const Spinner = () => (
  <Wrapper>
    <Wave>
      <div />
    </Wave>
  </Wrapper>
)

export default Spinner
