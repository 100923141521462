import {
  FETCH_ALL_CASES,
  FETCH_ALL_CASES_SUCCEEDED,
  FETCH_ALL_CASES_FAILED,
} from './index'

const fetchStatus = (state = 'not fetched', action) => {
  switch (action.type) {
    case FETCH_ALL_CASES:
      return 'fetching'

    case FETCH_ALL_CASES_SUCCEEDED:
      return 'succeeded'

    case FETCH_ALL_CASES_FAILED:
      return 'failed'

    default:
      return state
  }
}

export const shouldFetchCases = (state) =>
  !['succeeded', 'failed'].includes(state)

export const isCasesPending = (state) =>
  ['fetching', 'not fetched'].includes(state)

export default fetchStatus
