import React, { useState } from 'react'
import styled from 'styled-components'
import { prettyDate } from '../dateUtils'
import CardDataList from './CardDataList'
import { fields as role } from './ConnectionForm/RoleForm'
import { fields as ownership } from './ConnectionForm/OwnershipForm'
import { fields as otherConnection } from './ConnectionForm/OtherConnectionForm'
import { fields as relation } from './ConnectionForm/RelationForm'
import {
  USER_ROLE_CASE,
  USER_OWNERSHIP_CASE,
  RELATION_ROLE_CASE,
  RELATION_OWNERSHIP_CASE,
} from '../caseTypes'
import Link from './Link'
import I18n from './I18n'
import { capitalize } from '../utils'

const i18nConnectionValue = (key, value) => {
  switch (key) {
    case 'roleType':
      return <I18n id={`connection_form.role_types.${value}`} />
    case 'relationType':
      return <I18n id={`connection_form.relation_types.${value}`} />
    case 'country':
      return <I18n country={value} />
    case 'isResidentAbroad':
    case 'isCompanyListed':
    case 'isSupplier':
      return value && <I18n id={capitalize(value)} />
    default:
      return value
  }
}

const CaseListItemDetails = ({
  companyConnection,
  userConnection,
  publicConnection,
  caseType,
  Case,
}) => {
  const [showPublic, setShowPublic] = useState(false)

  return (
    <React.Fragment>
      {userConnection && (
        <Wrapper>
          <CardDataList
            data={(listItems[caseType] || []).map(({ key, label }) => [
              label,
              i18nConnectionValue(key, userConnection[key]),
            ])}
          />
          {[
            USER_OWNERSHIP_CASE,
            USER_ROLE_CASE,
            RELATION_OWNERSHIP_CASE,
            RELATION_ROLE_CASE,
          ].includes(caseType) && (
            <LinkGroup>
              {!(
                [RELATION_ROLE_CASE, RELATION_OWNERSHIP_CASE].includes(
                  caseType,
                ) && companyConnection
              ) && (
                <Link
                  to={
                    companyConnection
                      ? `/users/${userConnection.userId}`
                      : `/companies/${userConnection.organizationNumber}`
                  }
                >
                  <I18n id="Go_to_profile" />
                </Link>
              )}

              {[
                USER_OWNERSHIP_CASE,
                USER_ROLE_CASE,
                RELATION_ROLE_CASE,
                RELATION_OWNERSHIP_CASE,
              ].includes(caseType) &&
                publicConnection && (
                  <Link
                    to=""
                    as="button"
                    onClick={() => setShowPublic((old) => !old)}
                  >
                    {showPublic ? (
                      <I18n id="Hide_public_data" />
                    ) : (
                      <I18n id="Show_public_data" />
                    )}
                  </Link>
                )}
            </LinkGroup>
          )}
        </Wrapper>
      )}
      {((publicConnection && showPublic) ||
        (!userConnection && publicConnection)) && (
        <PublicDataWrapper>
          <PublicDataYear>
            {[USER_OWNERSHIP_CASE, RELATION_OWNERSHIP_CASE].includes(
              caseType,
            ) ? (
              <React.Fragment>
                <I18n id="Shareholder_registry" />
                {publicConnection.Shareholder_registry &&
                  `, ${publicConnection.shareholdersLastUpdatedYear}`}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <I18n id="National_company_registry" />
                {`, ${prettyDate(publicConnection.fetchedOn)}`}
              </React.Fragment>
            )}
          </PublicDataYear>
          <CardDataList
            data={(publicListItems[caseType] || []).map(({ key, label }) => [
              label,
              i18nConnectionValue(key, publicConnection[key]),
            ])}
          />
        </PublicDataWrapper>
      )}
    </React.Fragment>
  )
}

const PublicDataYear = styled.p`
  text-align: right;
  color: ${({ theme }) => theme.subtleText};
`

const LinkGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5em;
`

const publicDataOwnership = ownership.filter((o) =>
  [
    'organizationName',
    'organizationNumber',
    'stakePercentage',
    'stockCount',
    'isCompanyListed',
  ].includes(o.key),
)

const publicDataRole = role.filter((o) =>
  ['organizationName', 'organizationNumber', 'title'].includes(o.key),
)

const listItems = {
  userOwnershipCase: ownership,
  userRoleCase: role,
  userOtherConnectionCase: otherConnection,
  relationCase: relation,
  relationOwnershipCase: ownership,
  relationRoleCase: role,
  relationOtherConnectionCase: otherConnection,
}

const publicListItems = {
  userOwnershipCase: publicDataOwnership,
  userRoleCase: publicDataRole,
  relationOwnershipCase: publicDataOwnership,
  relationRoleCase: publicDataRole,
}

const Wrapper = styled.div`
  overflow-x: auto;
  word-break: break-word;
  margin: 0.33em 0 0 4em;
  padding: 1.5em;
  background-color: ${({ theme: { primaryBackground } }) => primaryBackground};
`

const PublicDataWrapper = styled.div`
  overflow-x: auto;
  word-break: break-word;
  margin: 0 0 0 4em;
  padding: 1.5em;
  background-color: ${({ theme: { secondaryBackground } }) =>
    secondaryBackground};
`

export default CaseListItemDetails
