import React from 'react'
import styled from 'styled-components'

const CardDataList = ({ data }) => (
  <List>
    {data.map((item, i) => (
      <ListElement key={i} item={item} />
    ))}
  </List>
)

const ListElement = ({ item }) => {
  const [label, value, icon, hideIfValueIsFalsy] = item
  if (hideIfValueIsFalsy && !value) {
    return
  }
  return (
    <Element>
      {icon == '' || !icon ? (
        <Label>{label}</Label>
      ) : (
        <Icon src={icon} alt="" />
      )}

      <Value>{value}</Value>
    </Element>
  )
}

const Icon = styled.img`
  height: 1em;
  width: 1m;
  margin: 0 1em;
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const Element = styled.li`
  display: flex;
  padding: 0.5em;
`

const Label = styled.div`
  margin-left: 1em;
  width: 35%
  color: ${({ theme: { subtleText } }) => subtleText};
`

const Value = styled.div``

export default CardDataList
