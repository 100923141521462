import React from 'react'
import styled from 'styled-components'
import { Select } from './form'
import I18n from './I18n'
import { IN_PROGRESS, APPROVED, NOT_APPROVED } from '../CaseStatus'

const CaseStatusSelector = ({ currentStatus, onChange }) => {
  const statuses = [IN_PROGRESS, APPROVED, NOT_APPROVED]
  return (
    <StatusSelector
      options={statuses.map((status) => ({
        label: <I18n id={`case_statuses.${status}`} />,
        value: status,
      }))}
      value={{
        label: <I18n id={`case_statuses.${currentStatus}`} />,
        value: currentStatus,
      }}
      onChange={({ value }, { name }) => onChange(value)}
    />
  )
}

const StatusSelector = styled(Select)`
  width: 200px;
`

export default CaseStatusSelector
