import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import uuid from 'uuid/v4'
import styled from 'styled-components'
import CaseApplicationListItem from './CaseApplicationListItem'
import { getActiveConnection, getCurrentUser } from '../redux/selectors'
import { applyConnection } from '../redux/actions'

const CaseApplicationList = ({
  Case,
  activeConnection,
  addApplication,
  isAdmin,
  currentUser,
}) => {
  const [applications, setApplications] = useState([])

  useEffect(() => {
    if (Case.ownershipApplications.length > 0)
      setApplications(Case.ownershipApplications)
    if (Case.roleApplications.length > 0) setApplications(Case.roleApplications)
    if (Case.otherConnectionApplications.length > 0)
      setApplications(Case.otherConnectionApplications)
  }, [Case])
  return (
    <React.Fragment>
      <List>
        {applications
          .sort((a, b) => (a.createdOn < b.createdOn ? 1 : -1))
          .map((application, index) => {
            if (['approved', 'not_approved'].includes(application?.status)) { 
              return (
                <CaseApplicationListItem
                  key={application.id}
                  Case={Case}
                  application={application}
                  isLatest={index === 0}
                  isAdmin={isAdmin}
                  activeView={false}
                />
              )
            }
          })}
      </List>
    </React.Fragment>
  )
}

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  & > li:nth-child(2n) {
    background-color: ${({ theme: { pageBackground } }) => pageBackground};
  }

  & > li:not(:last-child) {
  }
`

const mapStateToProps = (state, props) => {
  return {
    activeConnection: getActiveConnection(state),
    currentUser: getCurrentUser(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  addApplication: (Case, connectionType, prefilledProperties) =>
    dispatch(
      applyConnection({
        id: uuid(),
        userId: Case.userId,
        connectionType,
        prefilledProperties,
      }),
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(CaseApplicationList)
