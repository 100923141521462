import { takeEvery } from 'redux-saga/effects'
import {
  FETCH_COMPANY,
  FETCH_COMPANY_SUCCEEDED,
  FETCH_COMPANY_FAILED,
  FETCH_COMPANY_CONNECTIONS,
  FETCH_COMPANY_CONNECTIONS_SUCCEEDED,
  FETCH_COMPANY_CONNECTIONS_FAILED,
  FETCH_COMPANIES,
  FETCH_COMPANIES_SUCCEEDED,
  FETCH_COMPANIES_FAILED,
  POST_SUPPLIERS,
  POST_SUPPLIERS_SUCCEEDED,
  POST_SUPPLIERS_FAILED,
  POST_CUSTOMERS,
  POST_CUSTOMERS_SUCCEEDED,
  POST_CUSTOMERS_FAILED,
  FETCH_LATEST_SUPPLIER_UPDATE_LOG,
  FETCH_LATEST_SUPPLIER_UPDATE_LOG_SUCCEEDED,
  FETCH_LATEST_SUPPLIER_UPDATE_LOG_FAILED,
  FETCH_LATEST_CUSTOMER_UPDATE_LOG,
  FETCH_LATEST_CUSTOMER_UPDATE_LOG_SUCCEEDED,
  FETCH_LATEST_CUSTOMER_UPDATE_LOG_FAILED
} from './index'
import * as schema from '../schema'
import { fetchSaga, fetchSagaPagination } from '../sagas'
import { uploadSuppliers, uploadCustomers, dataUpdateLog as dataUpdateLog } from '../schema'
import { get, postFile } from '../fetch'

function* saga() {
  yield takeEvery(
    FETCH_COMPANIES,
    fetchSagaPagination({
      SUCCEEDED: FETCH_COMPANIES_SUCCEEDED,
      FAILED: FETCH_COMPANIES_FAILED,
      schema: schema.arrayOfCompanies,
      fetch: ({
        pagination: {
          query,
          filters,
          sortOption,
          pageIndex,
          perPage,
        } }) =>
        get(
          `/companies?query=${query}&filters=${filters}&sort=${sortOption}&pageIndex=${pageIndex}&perPage=${perPage}`,
        ),
    }),
  )
  yield takeEvery(
    FETCH_COMPANY_CONNECTIONS,
    fetchSaga({
      SUCCEEDED: FETCH_COMPANY_CONNECTIONS_SUCCEEDED,
      FAILED: FETCH_COMPANY_CONNECTIONS_FAILED,
      schema: schema.company,
      fetch: ({ companyId }) =>
        get(`/companies/connections/${encodeURIComponent(companyId)}`),
    }),
  )
  yield takeEvery(
    FETCH_COMPANY,
    fetchSaga({
      SUCCEEDED: FETCH_COMPANY_SUCCEEDED,
      FAILED: FETCH_COMPANY_FAILED,
      schema: schema.company,
      fetch: ({ companyId }) =>
        get(`/companies/${encodeURIComponent(companyId)}`),
    }),
  )
  yield takeEvery(
    POST_SUPPLIERS,
    fetchSaga({
      SUCCEEDED: POST_SUPPLIERS_SUCCEEDED,
      FAILED: POST_SUPPLIERS_FAILED,
      schema: uploadSuppliers,
      fetch: ({ file }) => postFile(`/companies/upload_suppliers`, file),
    }),
  )
  yield takeEvery(
    POST_CUSTOMERS,
    fetchSaga({
      SUCCEEDED: POST_CUSTOMERS_SUCCEEDED,
      FAILED: POST_CUSTOMERS_FAILED,
      schema: uploadCustomers,
      fetch: ({ file }) => postFile(`/companies/upload_customers`, file),
    }),
  )
  yield takeEvery(
    FETCH_LATEST_SUPPLIER_UPDATE_LOG,
    fetchSaga({
      SUCCEEDED: FETCH_LATEST_SUPPLIER_UPDATE_LOG_SUCCEEDED,
      FAILED: FETCH_LATEST_SUPPLIER_UPDATE_LOG_FAILED,
      schema: dataUpdateLog,
      fetch: () => get('/companies/supplier_update_log'),
    }),
  )
  yield takeEvery(
    FETCH_LATEST_CUSTOMER_UPDATE_LOG,
    fetchSaga({
      SUCCEEDED: FETCH_LATEST_CUSTOMER_UPDATE_LOG_SUCCEEDED,
      FAILED: FETCH_LATEST_CUSTOMER_UPDATE_LOG_FAILED,
      schema: dataUpdateLog,
      fetch: () => get('/companies/customer_update_log'),
    }),
  )
}

export default saga
