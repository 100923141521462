/* eslint-disable */
import React from 'react'
import { renderToStaticMarkup } from 'react-dom/server';

// Helper functions that are utilized by main functions below

// This component reAssembles the object created by the htmlToJson function
export function AssembleContent({ data }) {
  let keyCounter = 0
  const validTypes = ['p', 'ul', 'li', 'div', 'h1', 'h2', 'span']
  const renderElement = (node) => {
    if (!validTypes.includes(node.type)) {
      return ""; // Return blank string if node.type is not in validTypes
    }

    const Component = node.type;
    const content = node.content;

    const parseContent = (content) => {
      const parts = content.split(
        /(\n|\r|\r\n|\\link\((?:[^()]|\([^()]*\))*\)|\\b\((?:[^()]|\([^()]*\))*\)|\\i\((?:[^()]|\([^()]*\))*\)|\\em\((?:[^()]|\([^()]*\))*\)|\\u\((?:[^()]|\([^()]*\))*\)|\\s\((?:[^()]|\([^()]*\))*\)|\\code\((?:[^()]|\([^()]*\))*\)|\\strong\((?:[^()]|\([^()]*\))*\)|\\mark\((?:[^()]|\([^()]*\))*\)|\\span\((?:[^()]|\([^()]*\))*\)|\\li\((?:[^()]|\([^()]*\))*\))/
      ); // Extend regex to handle other tags
      return parts.map((part, index) => {
        if (!part.startsWith("\\")) return part;

        if (part.startsWith("\\link")) {
          const match = part.match(
            /\\link\(href="([^"]*)" target="([^"]*)" rel="([^"]*)" text="([^"]*)"\)/
          );
          if (match) {
            const href = match[1];
            const target = match[2];
            const rel = match[3];
            const text = match[4];
            return (
              <a key={index} href={href} target={target} rel={rel}>
                {text}
              </a>
            );
          }
        } else if (part.startsWith("\\b")) {
          const match = part.match(/\\b\(((?:[^()]|\([^()]*\))*)\)/);
          if (match) return <b key={index}>{match[1]}</b>;
        } else if (part.startsWith("\\i")) {
          const match = part.match(/\\i\(((?:[^()]|\([^()]*\))*)\)/);
          if (match) return <i key={index}>{match[1]}</i>;
        } else if (part.startsWith("\\u")) {
          const match = part.match(/\\u\(((?:[^()]|\([^()]*\))*)\)/);
          if (match) return <u key={index}>{match[1]}</u>;
        } else if (part.startsWith("\\code")) {
          const match = part.match(/\\code\(((?:[^()]|\([^()]*\))*)\)/);
          if (match) return <code key={index}>{match[1]}</code>;
        } else if (part.startsWith("\\em")) {
          const match = part.match(/\\em\(((?:[^()]|\([^()]*\))*)\)/);
          if (match) return <em key={index}>{match[1]}</em>;
        } else if (part.startsWith("\\mark")) {
          const match = part.match(/\\mark\(((?:[^()]|\([^()]*\))*)\)/);
          if (match) return <mark key={index}>{match[1]}</mark>;
        } else if (part.startsWith("\\span")) {
          const match = part.match(/\\span\(((?:[^()]|\([^()]*\))*)\)/);
          if (match) return <span key={index}>{match[1]}</span>;
        } else if (part.startsWith("\\strong")) {
          const match = part.match(/\\strong\(((?:[^()]|\([^()]*\))*)\)/);
          if (match) return <strong key={index}>{match[1]}</strong>;
        } else if (part.startsWith("\\s")) {
          const match = part.match(/\\s\(((?:[^()]|\([^()]*\))*)\)/);
          if (match) return <s key={index}>{match[1]}</s>;
        } else if (part.startsWith("\\li")) {
          const match = part.match(/\\li\(((?:[^()]|\([^()]*\))*)\)/);
          if (match) return <li key={index}>{match[1]}</li>;
        }
        // Add more tag parsing here...
        return part;
      });
    };

    if (node.type === "ul") {
      // Only for <ul>, render its children elements as well
      return (
        <Component key={keyCounter++}>
          {node.children.map(renderElement)}
        </Component>
      );
    } else {
      // For other components, rely on parsed content
      return (
        <Component key={keyCounter++}>{parseContent(content)}</Component>
      );
    }
  };

  return <div>{data.contentStructure.map((node) => renderElement(node))}</div>;
}


// This is the main function to use on a <SomeComponent/> that renders HTML. Send in Component = (<SomeComponent {...props} someVariable={var} />) and it will return the data to be stored in the database.
export function componentToJSON(Component) {
  const stringifiedHTML = normalizeString(renderToStaticMarkup(Component));
  return htmlToJson(stringifiedHTML);
}

// This function Takes in an HTML as a string (i.e. `HTML CODE HERE`) and creates an HTML object that can be stored in the backend database.
export function htmlToJson(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  const contentStructure = [];


  function processElement(element) {
    
    const node = {
      type: element.tagName.toLowerCase(),
      content: element.textContent,
      children: [],
    };

    if (element.children.length === 0) {
      node.content = element.textContent; // Store text content without HTML tags
    } else {
      for (const child of element.children) {
        node.children.push(processElement(child));
      }

      if (
        element.tagName.toLowerCase() === "p" ||
        element.tagName.toLowerCase() === "div" ||
        element.tagName.toLowerCase() === "li" ||
        element.tagName.toLowerCase() === "h1" ||
        element.tagName.toLowerCase() === "h2"
      ) {
        node.content = Array.from(element.childNodes)
          .map((node) => {
            if (node.nodeType === 3) {
              return node.nodeValue;
            }
            if (node.tagName.toLowerCase() === "a") {
              const href = node.getAttribute("href") || "";
              const target = node.getAttribute("target") || "";
              const rel = node.getAttribute("rel") || "";
              const text = node.textContent;
              return `\\link(href="${href}" target="${target}" rel="${rel}" text="${text}")`;
            }
            if (node.tagName.toLowerCase() === "b")
              return `\\b(${node.textContent})`;
            if (node.tagName.toLowerCase() === "i")
              return `\\i(${node.textContent})`;
            if (node.tagName.toLowerCase() === "u")
              return `\\u(${node.textContent})`;
            if (node.tagName.toLowerCase() === "s")
              return `\\s(${node.textContent})`;
            if (node.tagName.toLowerCase() === "code")
              return `\\code(${node.textContent})`;
            if (node.tagName.toLowerCase() === "em")
              return `\\em(${node.textContent})`;
            if (node.tagName.toLowerCase() === "strong")
              return `\\strong(${node.textContent})`;
            if (node.tagName.toLowerCase() === "mark")
              return `\\mark(${node.textContent})`;
            if (node.tagName.toLowerCase() === "span")
              return `\\span(${node.textContent})`;
            return ""; // Handle other cases as needed
          })
          .join("");
      } else if (element.tagName.toLowerCase() === "ul") {
        node.content = ""; // Do not store the aggregated content of ul
      } else {
        node.content = element.textContent;
      }
    }
    return node;
  }

  // Process only the children of the first child of the body (which is the outer div)
  for (const child of doc.body.firstChild.children) {
    contentStructure.push(processElement(child));
  }

  return {
    contentStructure,
  };
}

// This function takes in an HTML object (functionData) and replaces all instances of (string) with (replacement) and returns an updated HTML object.
export const updatedHTMLObjectData = (functionData, string, replacement) => {
  
    // Recursive function to update content in each node
    const updateContent = (node) => {
      if (node.content) {
        node.content = node.content.replace(string, replacement);
      }
      if (node.children && node.children.length > 0) {
        node.children.forEach(child => updateContent(child));
      }
    };
  
    // Clone the contentStructure to avoid mutating the original data
    let newContentStructure = functionData.contentStructure;
  
    // Apply updates to each node in the contentStructure
    newContentStructure.forEach(node => updateContent(node));
  
    return {
      contentStructure: newContentStructure
    };
  };


export function normalizeString(str) {
  return str.replace(/[\r\n]+/g, '')
}

export function checkIdentical(string1, string2) {
  if (string1 !== string2) {
    console.log('Strings are not identical')
    for (let i = 0; i < Math.min(string1.length, string2.length); i++) {
      if (string1.charCodeAt(i) !== string2.charCodeAt(i)) {
        console.log(`Difference at position ${i}:`)

        const start = Math.max(0, i - 10)
        const end1 = Math.min(string1.length, i + 11)
        const end2 = Math.min(string2.length, i + 11)

        console.log(`string1[${i}] = '${string1.substring(start, end1)}'`)
        console.log(`string2[${i}] = '${string2.substring(start, end2)}'`)

        break
      }
    }
  } else {
    console.log('Strings are identical')
  }
}