import React from 'react'
import { Select, withErrorMessage } from './index'

const SimpeSelect = (props) => (
  <Select
    {...props}
    value={
      props.value !== undefined &&
      props.options.find((option) => option.value === props.value)
    }
    onChange={({ value }, { name }) =>
      props.onChange({ target: { name, value } })
    }
  />
)

export default withErrorMessage(SimpeSelect)
