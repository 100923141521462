import React from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import I18n from '../../components/I18n'
import caretLeftIcon from '../../icons/caret-left.svg'
import caretRightIcon from '../../icons/caret-right.svg'
import caretLeftIconGray from '../../icons/caret-left_gray.svg'
import caretRightIconGray from '../../icons/caret-right_gray.svg'

const Pagination = ({
  perPage,
  setPerPageHandler,
  pageIndex,
  setPageIndex,
  paginationResult,
  perPageOptions,
}) => {

  const firstDisplayedItemIndex =
    paginationResult?.pageIndex * paginationResult?.perPage -
    paginationResult?.perPage +
    1
  const lastDisplayedItemIndex =
    paginationResult?.pageIndex * paginationResult?.perPage >
    paginationResult?.total
      ? paginationResult?.total
      : paginationResult?.pageIndex * paginationResult?.perPage

  return (
    <PageControls>
      <ListCount>
        <Count>
          <I18n id="Showing" /> {firstDisplayedItemIndex}-
          {lastDisplayedItemIndex} <I18n id="of" /> {paginationResult?.total}
        </Count>
        <InputGroup>
          <Label htmlFor={'prevPage'}>
            {paginationResult?.pageIndex > 1
              ? <I18n id='pagination.prev_page_label' />
              : ''}
          </Label>
          <PageStepper
            id={'prevPage'}
            src={
              paginationResult?.pageIndex > 1
                ? caretLeftIcon
                : caretLeftIconGray
            }
            onClick={() => {
              paginationResult?.pageIndex > 1 ? setPageIndex(pageIndex - 1) : {}
            }}
          />
          <PageStepper
            id={'nextPage'}
            src={
              paginationResult?.pageIndex < paginationResult?.pages
                ? caretRightIcon
                : caretRightIconGray
            }
            onClick={() => {
              paginationResult?.pageIndex < paginationResult?.pages
                ? setPageIndex(pageIndex + 1)
                : {}
            }}
          />
          <Label htmlFor={'nextPage'}>
            {paginationResult?.pageIndex < paginationResult?.pages
              ? <I18n id='pagination.next_page_label' />
              : ''}
          </Label>
        </InputGroup>
        <InputGroup>
          <Label htmlFor={'perPage'}> 
            <I18n id='pagination.per_page_label' />
          </Label>
          <SelectWrapper>
            <Select
              id={'perPage'}
              options={perPageOptions.map((value) => ({
                label: value,
                value: value,
              }))}
              onChange={({ value, label }) => setPerPageHandler(value)}
              value={{ perPage, label: perPage }}
            />
          </SelectWrapper>
        </InputGroup>
      </ListCount>
    </PageControls>
  )
}

const ListCount = styled.div`
  margin: 0 0;
  color: ${({ theme: { inverseText } }) => inverseText};
  background-color: ${({ theme: { primary } }) => primary};
  width: 100%;
  height: 50px;
  border-radius: 0.8em 0.8em 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Count = styled.div`
  padding-left: 2em;
`

const PageControls = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-left: 0em;
  }
`
const InputGroup = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-right: 1em;
  }
`

const PageStepper = styled.img``

const Label = styled.label``

const SelectWrapper = styled.div`
  color: ${({ theme: { primary } }) => primary};
  padding-right: 1em;
  position: relative;
`

export default Pagination
