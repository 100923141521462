import React, { useState } from 'react'
import styled from 'styled-components'
import Pagination from './Pagination'
import QueryInput from './QueryInput'
import MultiSelectFilter from './MultiSelectFilter'
import SingleSelectFilter from './SingleSelectFilter'
import DateFilter from './DateFilter'

const DataListHeader = ({
  pageProps,
  query,
  pageIndex,
  perPage,
  paginationResult,
  filters,
  sortOption,
  dateRangeOption,
  dateRangeFrom,
  dateRangeTo,
  setQuery,
  setPageIndex,
  setPerPage,
  setQueryHandler,
  setFiltersHandler,
  setSortOptionHandler,
  setDateRangeOptionHandler,
  setDateRangeFromHandler,
  setDateRangeToHandler,
  setPerPageHandler,
}) => {
  const [activeFilters, setActiveFilters] = useState(filters.split(' '))

  return (
    <HeaderContent>
      {pageProps?.paginationProps && (
        <Pagination
          perPage={perPage}
          pageIndex={pageIndex}
          paginationResult={paginationResult}
          setQueryHandler={setQueryHandler}
          setPerPageHandler={setPerPageHandler}
          setPageIndex={setPageIndex}
          perPageOptions={pageProps.paginationProps?.perPageOptions}
        />
      )}
      <Filters>
        <FilterWrapper>
          {pageProps?.searchInputProps && (
            <FilterWrapper>
              <QueryInput
                query={query}
                setQueryHandler={setQueryHandler}
                placeholder={pageProps.searchInputProps?.placeholder}
              />
            </FilterWrapper>
          )}
          {pageProps?.sortProps && (
            <FilterWrapper>
              <SingleSelectFilter
                filterOptions={pageProps.sortProps?.options}
                placeholder={pageProps.sortProps?.placeholder}
                setFilterHandler={setSortOptionHandler}
                selectedOption={sortOption}
              />
            </FilterWrapper>
          )}
        </FilterWrapper>
        {pageProps?.multiSelectProps &&
          pageProps.multiSelectProps?.filters.map((filter) => (
            <FilterWrapper key={filter.key}>
              <MultiSelectFilter
                filterOptions={filter.options}
                placeholder={filter.placeholder}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
                setFilterHandler={setFiltersHandler}
              />
            </FilterWrapper>
          ))}
        {pageProps?.dateRangeFilterProps && (
          <>
            <FilterWrapper>
              <SingleSelectFilter
                filterOptions={
                  pageProps.dateRangeFilterProps?.dateType?.options
                }
                placeholder={
                  pageProps.dateRangeFilterProps?.dateType?.placeholder
                }
                setFilterHandler={setDateRangeOptionHandler}
                selectedOption={dateRangeOption}
              />
            </FilterWrapper>
            {dateRangeOption && (
              <>
                <FilterWrapper>
                  <DateFilter
                    prop={pageProps.dateRangeFilterProps?.fromDate}
                    placeholder={
                      pageProps.dateRangeFilterProps?.fromDate?.placeholder
                    }
                    buttonDescription={
                      pageProps.dateRangeFilterProps?.toDate?.buttonDescription
                    }
                    dateFilter={dateRangeFrom}
                    setFilterHandler={setDateRangeFromHandler}
                  />
                </FilterWrapper>
                <FilterWrapper>
                  <DateFilter
                    prop={pageProps.dateRangeFilterProps?.toDate}
                    placeholder={
                      pageProps.dateRangeFilterProps?.toDate?.placeholder
                    }
                    buttonDescription={
                      pageProps.dateRangeFilterProps?.toDate?.buttonDescription
                    }
                    dateFilter={dateRangeTo}
                    setFilterHandler={setDateRangeToHandler}
                  />
                </FilterWrapper>
              </>
            )}
          </>
        )}
      </Filters>
    </HeaderContent>
  )
}

const HeaderContent = styled.div`
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
`

const Filters = styled.div`
  display: flex;
  padding: 0.5em 0.5em 0.5em 0.5em;
  flex-direction: row;
  width: 100%;
  background-color: ${({ theme: { primaryBackground } }) => primaryBackground};
`

const FilterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`

export default DataListHeader
