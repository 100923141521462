import {
  FETCH_APP_CONTENT,
  FETCH_APP_CONTENT_SUCCEEDED,
  FETCH_APP_CONTENT_FAILED,
} from './index'

const pendingContent = (state = false, action) => {
  switch (action.type) {
    case FETCH_APP_CONTENT:
      return true

    case FETCH_APP_CONTENT_SUCCEEDED:
    case FETCH_APP_CONTENT_FAILED:
      return false

    default:
      return state
  }
}



export default pendingContent
