import React, { useState } from 'react'
import styled from 'styled-components'
import Button from './Button'
import {
  getLatestSupplierFileUploadEvent,
  getLatestCustomerFileUploadEvent,
  getLatestSupplierUpdateLog,
  getLatestCustomerUpdateLog,
  isPostSuppliersPending,
  isPostCustomersPending,
} from '../redux/selectors'
import { postSuppliers, postCustomers, fetchLatestSupplierUpdateLog, fetchLatestCustomerUpdateLog } from '../redux/actions'
import UploadStatistics from './UploadStatistics'
import UploadEvent from './UploadEvent'
import { connect } from 'react-redux'
import I18n, { useI18nFunction } from './I18n'
import UploadButton from './UploadButton'
import { Select } from './form'

const UploadFileModal = ({
  postSuppliers,
  isPostSuppliersPending,
  postCustomers,
  isPostCustomersPending,
  onClose,
  fetchLatestSupplierUpdateLog,
  latestSupplierUpdateLog,
  supplierFileUploadEvent,
  fetchLatestCustomerUpdateLog,
  latestCustomerUpdateLog,
  customerFileUploadEvent,
}) => {
  const I18nFunction = useI18nFunction()

  const [selectedOption, setSelectedOption] = useState({ value: 'supplier', label: I18nFunction({id:'Supplier_option'})});

  const options = [
    { value: 'supplier', label: I18nFunction({id:'Supplier_option'}) },
    { value: 'customer', label: I18nFunction({id:'Customer_option'}) },
  ]
  const onChange = ({ value, label }) => {
    setSelectedOption({ value: value, label: label })
    if (value === 'supplier') {
      fetchLatestSupplierUpdateLog()
    } else if (value === 'customer') {
      fetchLatestCustomerUpdateLog()
    }
  }

  return (
    <Overlay onClick={onClose}>
      <ContentWrapper onClick={(e) => e.stopPropagation()}>
        <Header>
          <h1>
            <I18n id="Upload_third_party_list" />
          </h1>
          <SelectWrapper>
            <div>
              <I18n id="Upload_options_help_text" />
            </div>
            <Select
              options={options}
              onChange={onChange}
              value={{
                label: selectedOption.label,
                value: selectedOption.value,
              }}
            />
          </SelectWrapper>
        </Header>
        {selectedOption.value === 'supplier' &&
          <div>
            <Body>
              <Wrapper>
                <div>
                  <Title>
                    <I18n id="upload_supplier_info_text" />
                  </Title>
                  <SubTitle>
                    <I18n id="upload_supplier_help_text" />
                  </SubTitle>
                </div>
                {latestSupplierUpdateLog && (
                  <UploadStatistics stats={latestSupplierUpdateLog} />
                )}
                {supplierFileUploadEvent && <UploadEvent event={supplierFileUploadEvent} />}
              </Wrapper>
            </Body>
            <ModalActions>
              <UploadButton
                postAction={postSuppliers}
                isPostPending={isPostSuppliersPending}
                btnLabel={<I18n id='Upload_supplier_list' />}
                fileType={'.csv'}
              />
              <Button subtle onClick={onClose} small>
                <I18n id="Close" />
              </Button>
            </ModalActions>
          </div>
          }
          {selectedOption.value === 'customer' &&
          <div>
            <Body>
              <Wrapper>
                <div>
                  <Title>
                    <I18n id="upload_customer_info_text" />
                  </Title>
                  <SubTitle>
                    <I18n id="upload_customer_help_text" />
                  </SubTitle>
                </div>
                {latestCustomerUpdateLog && (
                  <UploadStatistics stats={latestCustomerUpdateLog} />
                )}
                {customerFileUploadEvent && <UploadEvent event={customerFileUploadEvent} />}
              </Wrapper>
            </Body>
            <ModalActions>
              <UploadButton
                postAction={postCustomers}
                isPostPending={isPostCustomersPending}
                btnLabel={<I18n id='Upload_customer_list' />}
                fileType={'.csv'}
              />
              <Button subtle onClick={onClose} small>
                <I18n id="Close" />
              </Button>
            </ModalActions>
          </div>
          }
      </ContentWrapper>
    </Overlay>
  )
}

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
`

const Wrapper = styled.div`
  width: 666px;
  margin: 0 auto;
  margin-bottom: 0.75em;
`

const ContentWrapper = styled.div`
  background-color: ${(props) => props.theme.primaryBackground};
  margin: 0 auto;
  min-height: 350px;
  min-width: 450px;
  -moz-box-shadow: 0px 0px 18px 6px #ccc;
  -webkit-box-shadow: 0px 0px 18px 6px #ccc;
  box-shadow: 0px 0px 18px 6px #ccc;
  padding: 2em;
  display: flex;
  flex-direction: column;
`
const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 50%;
  padding-bottom: 1em;
` 

const Header = styled.div`
  margin: 0;
  padding-bottom: 12px;
  border-bottom: 2px solid #eee;
`

const Body = styled.div`
  flex-grow: 7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 0 2em;
`

const Title = styled.p`
  font-weight: bold;
`

const SubTitle = styled.p`
  font-style: italic;
`

const ModalActions = styled.div`
  height: 50px;
  display: flex;
  justify-content: flex-end;
  border-top: 2px solid #eee;
  padding-top: 12px;
  & > * {
    margin: 0 1em;
  }
`

const mapStateToProps = (state) => ({
  latestSupplierUpdateLog: getLatestSupplierUpdateLog(state),
  latestCustomerUpdateLog: getLatestCustomerUpdateLog(state),
  supplierFileUploadEvent: getLatestSupplierFileUploadEvent(state),
  customerFileUploadEvent: getLatestCustomerFileUploadEvent(state),
  isPostSuppliersPending: isPostSuppliersPending(state),
  isPostCustomersPending: isPostCustomersPending(state)
})

const mapDispatchToProps = { postSuppliers, postCustomers, fetchLatestSupplierUpdateLog,fetchLatestCustomerUpdateLog }

export default connect(mapStateToProps, mapDispatchToProps)(UploadFileModal)
