import React from 'react'
import { connect } from 'react-redux'
import uuid from 'uuid/v4'
import styled from 'styled-components'
import Button from './Button'

const Plus = styled.span`
  font-size: 2em;
  line-height: 0;
`

const AddConnectionButton = ({
  children,
  addConnection,
  hasTransientConnection,
}) => (
  <Button
    onClick={addConnection}
    inactive={hasTransientConnection}
    disabled={hasTransientConnection}
  >
    <Plus>+</Plus>
    <span>{children}</span>
  </Button>
)

const mapStateToProps = () => ({})

const mapDispatchToProps = (
  dispatch,
  { userId, connectionType, relationId, action },
) => ({
  addConnection: () =>
    dispatch(action({ id: uuid(), userId, relationId, connectionType })),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddConnectionButton)
