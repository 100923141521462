import { isBefore, subYears } from 'date-fns'

export const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1)

export const confirmedNoConnectionsProp = (connectionType) =>
  `confirmedNo${capitalize(connectionType)}sOn`

export const hasValidConfirmationStatus = (confirmedFormOn) => {
  return confirmedFormOn &&
    isBefore(subYears(new Date(), 1), new Date(confirmedFormOn))
    ? true
    : false
}

export const isValidUUID = (id) => {
  // Regular expression to check if string is a valid UUID
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

  return regexExp.test(id)
}
