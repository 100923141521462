import React from 'react'

import I18n from './I18n'

export default {
  case_company_updated: {
    title: (payload) => <I18n {...payload} id="case_events.case_company_updated" />,
  },
  case_created: {
    title: (props) => <I18n {...props} id="case_events.case_created" />,
  },
  case_updated: {
    title: (payload) => (
      <I18n
        {...payload}
        property={payload?.property}
        propertyOldValue={payload?.propertyOldValue}
        propertyNewValue={payload?.propertyNewValue}
        id="case_events.case_updated"
      />
    ),
  },
  case_data_mismatch: {
    title: (payload) => (
      <I18n {...payload} id="case_events.case_data_mismatch" />
    ),
  },
  application_created: {
    title: (payload) => (
      <I18n {...payload} id="case_events.application_created" />
    ),
  },
  application_updated: {
    title: (payload) => (
      <I18n
        {...payload}
        property={payload?.property}
        propertyOldValue={payload?.propertyOldValue}
        propertyNewValue={payload?.propertyNewValue}
        id="case_events.application_updated"
      />
    ),
  },
}
