import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import Button from './Button'

const UploadButton = ({
  postAction,
  actionParams,
  isPostPending,
  btnLabel,
  fileType,
}) => {
  const onDrop = useCallback(
    ([file]) => {
      if (actionParams) {
        postAction(file, actionParams[0], actionParams[1])
      } else {
        postAction(file)
      }
    },
    [postAction],
  )
  const { getRootProps, getInputProps } = useDropzone({
    accept: fileType,
    onDrop,
  })

  return (
    <>
      <Button small {...getRootProps()} ref={null} pending={isPostPending}>
        <input {...getInputProps()} />
        {btnLabel}
      </Button>
    </>
  )
}

export default UploadButton
