import React, { useContext } from 'react'
import { RadioButtons } from '../form'
import I18n from '../I18n'
import { ConnectionFormContext } from './ConnectionForm'

const ConnectionInput = (props) => {
  const { connection, handleChange, handleBlur, visited, errors } = useContext(
    ConnectionFormContext,
  )

  return (
    <RadioButtons
      name="ownershipStatus"
      value={connection.ownershipStatus}
      options={[
        { label: <I18n id="Active" />, value: 'active' },
        { label: <I18n id="Inactive" />, value: 'inactive' },
      ]}
      visited={visited.ownershipStatus}
      error={errors.ownershipStatus}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  )
}

export default ConnectionInput
