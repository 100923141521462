import { combineReducers } from 'redux'

import {
  FETCH_ALL_APPLICATIONS,
  FETCH_ALL_APPLICATIONS_FAILED,
  FETCH_ALL_APPLICATIONS_SUCCEEDED,
} from './index'
import {UPDATE_APPLICATION_STATUS_SUCCEEDED} from '../cases'

const byId =
  (applicationType) =>
  (state = {}, action) => {
    if (action.type === FETCH_ALL_APPLICATIONS_SUCCEEDED) {
      return {
        ...action.payload.entities[applicationType],
      }
    }

    if (
      action.type === FETCH_ALL_APPLICATIONS ||
      action.type === FETCH_ALL_APPLICATIONS_FAILED
    ) {
      return state
    }

    if (action.type === UPDATE_APPLICATION_STATUS_SUCCEEDED) {

      const caseApplications = Object.entries(action.payload.entities.case || {})
        .map(([caseId, Case]) => Object.entries(getCaseApplicationsAsDict(Case, applicationType)))[0]

      return caseApplications.reduce(
        (state, [id, application]) => ({
          ...state,
          [application.id]: {
            ...application
          },
        }),
        state
      )

    }

    return state
  }


  export const getCaseApplicationsAsDict = (Case, applicationType) => {
    if (applicationType === 'ownershipApplication') {
      return Object.assign({}, ...Case.ownershipApplications.map((x) => ({[x.id]: {...x, applicationType}})))
    }
    if (applicationType === 'roleApplication') {
      return Object.assign({}, ...Case.roleApplications.map((x) => ({[x.id]: {...x, applicationType}})))
    }
    return {}
  }
  

export default (applicationType) =>
  combineReducers({
    byId: byId(applicationType),
  })
