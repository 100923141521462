import React from 'react'
import styled from 'styled-components'
import helpIcon from '../icons/help_active.svg'
// import { AssembleContent } from '../renderUtils/I18nRenderUtils'
import I18n from './I18n'

const FaqListItem = ({ question, answer, index }) => {
  return (
    question &&
    answer && (
      <Wrapper>
        <IconWrapper>
          <Icon src={helpIcon} alt="" />
        </IconWrapper>
        <QAWrapper>
          <Question>
            <I18n id={`faq.faqs[${index}].question`} />
          </Question>
          <Answer>
            <I18n id={`faq.faqs[${index}].answer`} />
          </Answer>
        </QAWrapper>
      </Wrapper>
    )
  )
}

const Icon = styled.img`
  width: 2em;
`

const Wrapper = styled.div`
  padding: 0.75em 0.75em;
  display: flex;
  align-items: center;
  padding: 1em;
  background-color: ${({ theme: { secondaryBackground } }) =>
    secondaryBackground};

  margin: 0.25em 0;

  & > :not(:last-child) {
    margin-bottom: 0.5em;
  }
`

const IconWrapper = styled.div`
  float: left;
  display: flex;
  margin: 0em 1em 0em 0em;
`

const QAWrapper = styled.div`
  overflow: hidden;
`

const Question = styled.div`
  display: flex;
  font-weight: 600;
  align-items: center;
  margin-bottom: 0.5em;
`

const Answer = styled.div`
  overflow-wrap: break-word;
  white-space: pre-wrap;
`

export default FaqListItem
