import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  getCurrentUser,
  isCurrentUserFetching,
  hasUnsavedChanges,
  shouldFetchUserEvents,
  shouldFetchUser,
  shouldFetchUserProfileData,
} from '../redux/selectors'
import {
  fetchUserProfileData,
  fetchUserEvents,
  fetchUser,
} from '../redux/actions'
import Page from '../components/Page'
import UserHeader from '../components/UserHeader'
import ProfileConnectionTabs from '../components/Profile/ProfileConnectionTabs'
import ProfileStatusAndActionBar from '../components/Profile/ProfileStatusAndActionBar'
import { useI18nFunction } from '../components/I18n'

const Profile = ({
  userId,
  hasUnsavedChanges,
  fetchUserProfileData,
  fetchUserEvents,
  fetchUser,
  shouldFetchUser,
  pending,
}) => {
  const I18nFunction = useI18nFunction();
  
  useEffect(() => {
    userId && shouldFetchUser && fetchUser(userId)
  }, [shouldFetchUser, fetchUser, userId])

  useEffect(() => {
    const beforeunload = (e) => {
      if (hasUnsavedChanges) {
        e.preventDefault()
        const text = I18nFunction({ id: 'Unsaved_changes' })
        if (e) {
          e.returnValue = text
        }
        return text
      }
    }
    window.addEventListener('beforeunload', beforeunload)
    return () => window.removeEventListener('beforeunload', beforeunload)
  }, [hasUnsavedChanges])

  useEffect(() => {
    userId && shouldFetchUserEvents && fetchUserEvents(userId)
  }, [shouldFetchUserEvents, fetchUserEvents, userId])

  useEffect(() => {
    userId && shouldFetchUserProfileData && fetchUserProfileData(userId)
  }, [fetchUserProfileData, shouldFetchUserProfileData, userId])

  return (
    <Page pending={pending}>
      <UserHeader userId={userId} />
      <ProfileStatusAndActionBar userId={userId} />
      <ProfileConnectionTabs userId={userId} />
    </Page>
  )
}

const mapStateToProps = (state, props) => {
  const pending = isCurrentUserFetching(state)
  if (pending) {
    return {
      pending: true,
    }
  }
  const currentUser = getCurrentUser(state)
  const userId = props.userId || currentUser.id

  return {
    userId: userId,
    hasUnsavedChanges: hasUnsavedChanges(state),
    shouldFetchUser: shouldFetchUser(state, userId),
    pending,
  }
}

const mapDispatchToProps = {
  fetchUserProfileData,
  fetchUserEvents,
  fetchUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
