import React from 'react'
import styled from 'styled-components'
import { HelpText } from './index'

const Field = ({ helpText, children }) => (
  <Wrapper>
    <FieldWrapper>{children}</FieldWrapper>
    {helpText && <HelpText>{helpText}</HelpText>}
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const FieldWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;

  & > * {
    flex-grow: 1;
    flex-basis: 0px;
    flex-shrink: 0;
    min-width: 0px;
  }

  & > label {
    flex-grow: 10;
    max-width: 13em;
    margin-right: 1.5em;
  }
`

export default Field
