import React from 'react'
import styled from 'styled-components'
import Input from './Input'
import{ useI18nFunction } from '../I18n'

const PostCodeInput = (props) => {
  const I18nFunction = useI18nFunction();
    return (    
    <Wrapper>
      <Input 
              name="postCode" 
              placeholder={I18nFunction({ id: 'Post_code' })}
              maxLength={props.country === 'NO' ? '4' : '6'} 
          />
  </Wrapper>)
  }

const Wrapper = styled.div`
  display: flex;

  & > * {
    flex-grow: 2;
    flex-basis: 0px;
  }

  & > :not(:last-child) {
    margin-right: 0.5em;
    flex-grow: 1;
  }
`
export default PostCodeInput
