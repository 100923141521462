import lodash from 'lodash'
import deepdash from 'deepdash'
import uuid from 'uuid/v4'
import {
  FETCH_APP_SETTINGS,
  FETCH_APP_SETTINGS_SUCCEEDED,
  SAVE_APP_SETTINGS,
  SAVE_APP_SETTINGS_SUCCEEDED,
  UPDATE_APP_SETTING,
} from './index'

const _ = deepdash(lodash)

export const eventSettings = (state = {}, action) => {
  switch (action.type) {
    case FETCH_APP_SETTINGS:
    case SAVE_APP_SETTINGS:
      return {
        ...state,
      }

    case FETCH_APP_SETTINGS_SUCCEEDED:
      return {
        ...addValueRefs(action.payload.result.eventSettings),
      }
    case SAVE_APP_SETTINGS_SUCCEEDED:
      return {
        ...addValueRefs(action.payload.eventSettings),
      }
    case UPDATE_APP_SETTING:
      return {
        ...state,
        ...updateValueByRef(state, action.valueRef, action.newValue),
      }
    default:
      return state
  }
}

export const isLoading = (state = {}, action) => {
  switch (action.type) {
    case FETCH_APP_SETTINGS:
    case SAVE_APP_SETTINGS:
      return true

    case FETCH_APP_SETTINGS_SUCCEEDED:
      return false
    case SAVE_APP_SETTINGS_SUCCEEDED:
      return false
    default:
      return state
  }
}

export const hasLoaded = (state = {}, action) => {
  switch (action.type) {
    case FETCH_APP_SETTINGS:
    case SAVE_APP_SETTINGS:
      return false
    case FETCH_APP_SETTINGS_SUCCEEDED:
      return true
    case SAVE_APP_SETTINGS_SUCCEEDED:
      return true
    default:
      return state
  }
}

export const updates = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_APP_SETTING:
      if (action.valueRef in state) {
        if (action.newValue === state[action.valueRef].originalValue) {
          const nextState = { ...state }
          delete nextState[action.valueRef]
          return nextState
        }
        return {
          ...state,
          ...{
            [action.valueRef]: {
              originalValue: action.previousValue,
              updatedValue: action.newValue,
            },
          },
        }
      } else {
        return {
          ...state,
          ...{
            [action.valueRef]: {
              originalValue: action.previousValue,
              updatedValue: action.newValue,
            },
          },
        }
      }
    case FETCH_APP_SETTINGS_SUCCEEDED:
    case SAVE_APP_SETTINGS_SUCCEEDED:
      return {}
    default:
      return state
  }
}

const addValueRefs = (o) => {
  return structuredClone(
    _.eachDeep(o, (value, key, parentValue, context) => {
      if (key === 'value') {
        parentValue.valueRef = uuid()
      }
    }),
  )
}

const updateValueByRef = (o, valueRef, newValue) => {
  return structuredClone(
    _.eachDeep(structuredClone(o), (value, key, parentValue, context) => {
      if (value === valueRef) {
        parentValue.value = newValue
      }
    }),
  )
}
