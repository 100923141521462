import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  getUserList,
  isAdmin,
  isUserListFetching,
  getUserPagination,
} from '../redux/selectors'
import { fetchUsers } from '../redux/actions'
import Page from '../components/Page'
import UserList from '../components/UserList'
import Button from '../components/Button'
import useSort from '../hooks/useSort'
import LinkStyled from '../components/Link'
import I18n, { useI18nFunction } from '../components/I18n'
import usePagination from '../hooks/usePagination'
import useToggleUsers from '../hooks/useToggleUsers'
import DataListHeader from '../components/DataList/DataListHeader'
import DataListColumns from '../components/DataList/DataListColumns'

const Users = ({ users, pending, fetchUsers, isAdmin, userPagination }) => {
  const I18nFunction = useI18nFunction();
  
  const usersPageProps = {
    paginationProps: {
      perPageOptions: [10, 15, 25, 50, 100],
    },
    searchInputProps: {
      placeholder: I18nFunction( { id: 'Search_for_users' } ),
    },
    sortProps: {
      placeholder: I18nFunction( { id: 'Sort_by' } ),
      options: [
        {
          label: <I18n id="Name" />,
          key: 'name_alphabetic',
          value: {
            iteratee: ({ name }) => name.toLowerCase(),
            order: 'asc',
          },
        },
        {
          label: <I18n id="Last_logged_in" />,
          key: 'logged_in_desc',
          value: {
            iteratee: ({ lastLoggedIn }) =>
              lastLoggedIn ? lastLoggedIn : '1970-01-01',
            order: 'desc',
          },
        },
        {
          label: <I18n id="Last_confirmed_on" />,
          key: 'confirmed_on_desc',
          value: {
            iteratee: ({ confirmedFormOn }) =>
              confirmedFormOn ? confirmedFormOn : '1970-01-01',
            order: 'desc',
          },
        },
      ],
    },
    multiSelectProps: {
      filters: [
        {
          key: 'caseRiskFilter',
          placeholder: I18nFunction( { id: 'filterType',  payload: 'toggle' } ),
          options: [
            {
              label: I18nFunction({ id: 'case_risk_categories.position_of_influence' }),
              value: 'position_of_influence',
              key: 1,
            },
            {
              label: I18nFunction({ id: 'case_risk_categories.is_board_member' }),
              value: 'is_board_member',
              key: 2,
            },
            {
              label: I18nFunction({ id: 'Missing_data_users' }),
              value: 'missing_user_data',
              key: 3,
            },
          ],
        },
      ],
    },
    listColumnProps: {
      columns: [
        {
          label: I18nFunction({ id: 'Name' }),
          key: 'name',
          width: '25%',
        },
        {
          label: I18nFunction({ id: 'Permissions' }),
          key: 'permission',
          width: '25%',
        },
        {
          label: I18nFunction({ id: 'Last_logged_in' }),
          key: 'lastUpdated',
          width: '20%',
        },
        {
          label: I18nFunction({ id: 'Last_confirmed_on' }),
          key: 'lastCompleted',
          width: '20%',
        },
      ],
    },
  }

  const {
    query,
    pageIndex,
    perPage,
    paginationResult,
    filters,
    sortOption,
    dateRangeOption,
    dateRangeFrom,
    dateRangeTo,
    setQuery,
    setPageIndex,
    setPerPage,
    setQueryHandler,
    setFiltersHandler,
    setSortOptionHandler,
    setDateRangeOptionHandler,
    setDateRangeFromHandler,
    setDateRangeToHandler,
    setPerPageHandler,
  } = usePagination(fetchUsers, getUserPagination, userPagination)

  const { sort, setSort, sortFunction } = useSort(
    usersPageProps?.sortProps?.options[0],
  )

  const sortUsers = () => {
    if (userPagination?.sortOption && userPagination?.sortOption != sort?.key) {
      let idx = usersPageProps?.sortProps?.options.findIndex(
        (o) => o.key === userPagination?.sortOption,
      )
      setSort(usersPageProps?.sortProps?.options[idx])
    }
    return sortFunction(users)
  }

  const { toggleAllUsers, selectedUserIds, selectedUsers, toggleUser } =
    useToggleUsers(users)

  const buttonDisabled = selectedUserIds.length === 0 ? 1 : 0

  const handleToggleAll = () => {
    setPerPage(-1)
    toggleAllUsers()
  }

  return (
    <Page>
      <Content>
        <h1>
          <I18n id="Users" />
        </h1>
        {isAdmin && (
          <Options>
            <ActionButton
              small
              inactive={buttonDisabled}
              disabled={buttonDisabled}
              onClick={() =>
                (window.location.href = `${
                  window.location.origin
                }/#/reminder/confirm?users=${selectedUserIds.join(',')}`)
              }
            >
              <I18n id="Send_reminder" />
            </ActionButton>
            <ActionButton
              small
              onClick={() =>
                (window.location.href = `${window.location.origin}/api/users/download_users`)
              }
            >
              <I18n id="Export_users" />
            </ActionButton>
          </Options>
        )}
        <DataListHeader
          pageProps={usersPageProps}
          query={query}
          pageIndex={pageIndex}
          perPage={perPage}
          paginationResult={paginationResult}
          filters={filters}
          sortOption={sortOption}
          dateRangeOption={dateRangeOption}
          dateRangeFrom={dateRangeFrom}
          dateRangeTo={dateRangeTo}
          setQuery={setQuery}
          setPageIndex={setPageIndex}
          setPerPage={setPerPage}
          setQueryHandler={setQueryHandler}
          setFiltersHandler={setFiltersHandler}
          setSortOptionHandler={setSortOptionHandler}
          setDateRangeOptionHandler={setDateRangeOptionHandler}
          setDateRangeFromHandler={setDateRangeFromHandler}
          setDateRangeToHandler={setDateRangeToHandler}
          setPerPageHandler={setPerPageHandler}
        />
        <HeaderWrapper>
          <DataListColumns props={usersPageProps?.listColumnProps} />
          <ToggleAllButton small as="button" onClick={handleToggleAll} to="">
            {selectedUserIds.length === 0 ? (
              <I18n id="Select_all" />
            ) : (
              <I18n id="Remove_selection" />
            )}
          </ToggleAllButton>
        </HeaderWrapper>
        <UserList
          selectedUsers={selectedUsers}
          toggleUser={toggleUser}
          users={sortUsers()}
          pending={pending}
        />
      </Content>
    </Page>
  )
}

const ToggleAllButton = styled(LinkStyled)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: calc(100% + 1em);
  white-space: nowrap;
`

const HeaderWrapper = styled.div`
  position: relative;
`

const Options = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  padding: 0.5em 0.5em 0.5em 0.5em;
  max-width: ${({ theme: { maxWidth } }) => maxWidth};

  & > * {
    margin: 0 0.5em;
  }
`

const ActionButton = styled(Button)`
  background: #0c3689;
  border: 2px solid #0c3689;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  width: 13em;
`

const Content = styled.div`
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
  margin: 0 auto;
  padding: 0 6em;
`

const mapStateToProps = (state) => ({
  pending: isUserListFetching(state),
  users: getUserList(state),
  userPagination: getUserPagination(state),
  isAdmin: isAdmin(state),
})

const mapDispatchToProps = {
  fetchUsers,
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
