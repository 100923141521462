import React from 'react'
import Input from './Input'
import Textarea from './Textarea'
import OrganizationNameInput from './OrganizationNameInput'
import OrganizationNumberInput from './OrganizationNumberInput'
import CountrySelector from './CountrySelector'
import ShareTypeSelector from './ShareTypeSelector'
import OwnershipStatusSelector from './OwnershipStatusSelector'
import { DatePicker, RadioButtons } from '../form'
import I18n from '../I18n'
import styled from 'styled-components'
import infoIcon from '../../icons/info.svg'
import PostCodeInput from './PostCodeInput'
import PostPlaceInput from './PostPlaceInput'
import { SimpleSelect as Select } from '../form'
import BirthdateInput from './BirthdateInput'
import { getDateWithoutTime } from '../../dateUtils'

export const isCompanyRegistered = () => ({
  condition: (props) => props.isApplication,
  key: 'isCompanyRegistered',
  label: (
    <Wrapper>
      <I18n id="connection_form.labels.is_company_registered" />
      <RequiredField>
        <I18n id="connection_form.required_fields.is_company_registered" />
      </RequiredField>
    </Wrapper>
  ),
  Input: ({
    isCompanyRegistered,
    fetchedOn,
    visited,
    errors,
    handleChange,
    handleBlur,
    locked,
  }) => (
    <RadioButtons
      name="isCompanyRegistered"
      value={isCompanyRegistered}
      options={[
        { label: <I18n id="Yes" />, value: 'yes' },
        { label: <I18n id="No" />, value: 'no' },
      ]}
      visited={visited.isCompanyRegistered}
      error={errors.isCompanyRegistered}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={locked || fetchedOn}
    />
  ),
  renderValue: (value) =>
    value ? <I18n id={`connection_form.is_company_registered.${value}`} /> : '',
})

export const isCompanyListed = () => ({
  condition: (props) => props.isCompanyRegistered !== 'no',
  key: 'isCompanyListed',
  label: (
    <Wrapper>
      <I18n id="connection_form.labels.is_company_listed" />
      <RequiredField>
        <I18n id="connection_form.required_fields.is_company_listed" />
      </RequiredField>
    </Wrapper>
  ),
  Input: ({
    isCompanyListed,
    visited,
    errors,
    handleChange,
    handleBlur,
    locked,
  }) => {
    return (
      <RadioButtons
        name="isCompanyListed"
        value={isCompanyListed}
        options={[
          { label: <I18n id="Yes" />, value: 'yes' },
          { label: <I18n id="No" />, value: 'no' },
        ]}
        visited={visited.isCompanyListed}
        error={errors.isCompanyListed}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={locked}
      />
    )
  },
  renderValue: (value) =>
    value ? <I18n id={`connection_form.is_company_listed.${value}`} /> : '',
})

export const organizationName = () => ({
  key: 'organizationName',
  label: (
    <Wrapper>
      <I18n id="connection_form.labels.organization_name" />
      <RequiredField>
        <I18n id="connection_form.required_fields.organization_name" />
      </RequiredField>
    </Wrapper>
  ),
  Input: ({
    organizationName,
    country,
    isCompanyRegistered,
    locked,
    connectionType,
  }) =>
    (country === 'NO' && isCompanyRegistered !== 'no') ||
    connectionType == 'userOtherConnection' ||
    connectionType == 'relationOtherConnection' ? (
      <OrganizationNameInput
        type="text"
        name="organizationName"
        value={organizationName}
        country={country}
        disabled={locked}
      />
    ) : (
      <Input
        type="text"
        name="organizationName"
        disabled={locked}
        value={organizationName}
      />
    ),
})

export const organizationNumber = () => ({
  condition: (props) => props.isCompanyRegistered !== 'no',
  key: 'organizationNumber',
  label: (
    <Wrapper>
      <I18n id="connection_form.labels.organization_number" />
      <RequiredField>
        <I18n id="connection_form.required_fields.organization_number" />
      </RequiredField>
    </Wrapper>
  ),
  Input: ({ locked }) => (
    <OrganizationNumberInput
      type="text"
      disabled={locked}
      name="organizationNumber"
    />
  ),
})

export const country = () => ({
  key: 'country',
  label: (
    <Wrapper>
      <I18n id="connection_form.labels.country" />{' '}
      <RequiredField>
        <I18n id="connection_form.required_fields.country" />
      </RequiredField>
    </Wrapper>
  ),
  Input: ({ locked }) => <CountrySelector disabled={locked} />,
})

export const shareType = () => ({
  key: 'shareType',
  label: (
    <Wrapper>
      <I18n id="connection_form.labels.share_type" />
      <RequiredField>
        <I18n id="connection_form.required_fields.share_type" />
      </RequiredField>
    </Wrapper>
  ),
  Input: ({ locked }) => <ShareTypeSelector disabled={locked} />,
  helpText: <I18n id="connection_form.help_texts.share_type" />,
  renderValue: (value) =>
    value ? <I18n id={`connection_form.share_type.${value}`} /> : '',
})

export const isSupplierInfo = () => ({
  condition: (props) => props.supplierRelations?.length > 0,
  key: 'isSupplierInfo',
  label: undefined,
  Input: (props) => (
    <WarningText>
      <Flex>
        <Icon src={infoIcon} /> {props.organizationName} <I18n id="is" />{' '}
        <I18n id="Is_supplier" /> for:
      </Flex>

      <ul style={{ marginBottom: '0px' }}>
        {props.supplierRelations.map((e) => (
          <li style={{ margin: '0px' }}>
            {e.supplierOfOrganizationName} ({e.supplierOfOrganizationNumber})
          </li>
        ))}
      </ul>
    </WarningText>
  ),
  helpText: <I18n id="connection_form.help_texts.is_supplier" />,
})

export const isClientInfo = () => ({
  condition: (props) => props.customerRelations?.length > 0,
  key: 'isClientInfo',
  label: undefined,
  Input: (props) => (
    <WarningText>
      <Flex>
        <Icon src={infoIcon} /> {props.organizationName} <I18n id="is" />{' '}
        <I18n id="Is_client" /> for
      </Flex>
      <ul style={{ marginBottom: '0px' }}>
        {props.customerRelations.map((e) => (
          <li style={{ margin: '0px' }}>
            {e.customerOfOrganizationName} ({e.customerOfOrganizationNumber})
          </li>
        ))}
      </ul>
    </WarningText>
  ),
  helpText: <I18n id="connection_form.help_texts.is_client" />,
})

export const stockCount = () => ({
  key: 'stockCount',
  label: (
    <Wrapper>
      <I18n id="connection_form.labels.stock_count" />
      <RequiredField>
        <I18n id="connection_form.required_fields.stock_count" />
      </RequiredField>
    </Wrapper>
  ),
  Input: ({ locked }) => (
    <Input style={{ width: '50%' }} type="number" name="stockCount" min="0" />
  ),
})

export const ownershipStatus = () => ({
  key: 'ownershipStatus',
  label: (
    <Wrapper>
      <I18n id="connection_form.labels.ownership_status" />
      <RequiredField>
        <I18n id="connection_form.required_fields.ownership_status" />
      </RequiredField>
    </Wrapper>
  ),
  Input: () => <OwnershipStatusSelector />,
  helpText: <I18n id="connection_form.help_texts.ownership_status" />,
  renderValue: (value) =>
    value ? <I18n id={`connection_form.ownership_status.${value}`} /> : '',
})

export const postCodeAndPlace = () => ({
  key: 'postPlace',
  label: (
    <Wrapper>
      <I18n id="connection_form.labels.engagement_location" />
      <RequiredField>
        <I18n id="connection_form.required_fields.engagement_location" />
      </RequiredField>
    </Wrapper>
  ),
  Input: (props) => (
    <Wrapper>
      <PostCodeInput {...props} name="postCode" />
      <PostPlaceInput name="postPlace" />
    </Wrapper>
  ),
  helpText: <I18n id="connection_form.help_texts.engagement_location" />,
})

export const postCodeAndPlaceRelation = () => ({
  key: 'postPlace',
  Input: (props) => (
    <Wrapper>
      <PostCodeInput {...props} name="postCode" />{' '}
      <PostPlaceInput name="postPlace" />
    </Wrapper>
  ),
})

export const monthlyReward = () => ({
  key: 'monthlyReward',
  label: (
    <Wrapper>
      <I18n id="connection_form.labels.monthly_reward" />
      <RequiredField>
        <I18n id="connection_form.required_fields.monthly_reward" />
      </RequiredField>
    </Wrapper>
  ),
  Input: () => (
    <Input
      style={{ width: '50%' }}
      type="number"
      name="monthlyReward"
      min="0"
    />
  ),
  helpText: <I18n id="connection_form.help_texts.monthly_reward" />,
})

export const monthlyTimeCommitment = () => ({
  key: 'monthlyTimeCommitment',
  label: (
    <Wrapper>
      <I18n id="connection_form.labels.monthly_time_commitment" />
      <RequiredField>
        <I18n id="connection_form.required_fields.monthly_time_commitment" />
      </RequiredField>
    </Wrapper>
  ),
  Input: () => (
    <Input
      style={{ width: '50%' }}
      type="number"
      name="monthlyTimeCommitment"
      min="0"
    />
  ),
  helpText: <I18n id="connection_form.help_texts.monthly_time_commitment" />,
})

export const fromDate = (label, helpText) => ({
  key: 'fromDate',
  label: (
    <React.Fragment>
      {label}{' '}
      <span style={{ whiteSpace: 'nowrap' }}>
        (<I18n id="connection_form.labels.not_required" />)
      </span>
    </React.Fragment>
  ),
  renderValue: (value) => (value ? getDateWithoutTime(value) : ''),
  Input: ({
    fromDate,
    visited,
    errors,
    handleChange,
    handleBlur,
    isApplication,
  }) => (
    <DatePicker
      name="fromDate"
      value={fromDate}
      visited={visited.fromDate}
      error={errors.fromDate}
      onChange={handleChange}
      onBlur={handleBlur}
      isApplication={isApplication}
    />
  ),
  helpText: helpText,
})

export const toDate = (label, helpText, value) => ({
  key: 'toDate',
  label: (
    <React.Fragment>
      {label}{' '}
      <span style={{ whiteSpace: 'nowrap' }}>
        (<I18n id="connection_form.labels.not_required" />)
      </span>
    </React.Fragment>
  ),
  renderValue: (value) => (value ? getDateWithoutTime(value) : ''),
  Input: ({
    toDate,
    handleChange,
    handleBlur,
    errors,
    visited,
    isApplication,
  }) => (
    <DatePicker
      name="toDate"
      value={toDate}
      visited={visited.toDate}
      error={errors.toDate}
      onChange={handleChange}
      onBlur={handleBlur}
      isApplication={isApplication}
    />
  ),
  helpText: helpText,
})

export const comment = () => ({
  key: 'comment',
  label: <I18n id="connection_form.labels.comment" />,
  Input: () => <Textarea name="comment" />,
})

export const createdOn = () => ({
  key: 'createdOn',
  label: <I18n id="connection_form.labels.created_on" />,
  Input: () => <Textarea name="createdOn" />,
  renderValue: (value) => (value ? getDateWithoutTime(value) : ''),
})

export const updatedOn = () => ({
  key: 'updatedOn',
  label: <I18n id="connection_form.labels.updated_on" />,
  Input: () => <Textarea name="updatedOn" />,
  renderValue: (value) => (value ? getDateWithoutTime(value) : ''),
})

export const roleType = () => ({
  key: 'roleType',
  label: (
    <Wrapper>
      <I18n id="connection_form.labels.role_type" />
      <RequiredField>
        <I18n id="connection_form.required_fields.role_type" />
      </RequiredField>
    </Wrapper>
  ),
  Input: ({ roleType, handleChange, handleBlur, errors, visited }) => (
    <Select
      name="roleType"
      options={[
        {
          value: 'full_time',
          label: <I18n id="connection_form.role_types.full_time" />,
        },
        {
          value: 'part_time',
          label: <I18n id="connection_form.role_types.part_time" />,
        },
        {
          value: 'board_position',
          label: <I18n id="connection_form.role_types.board_position" />,
        },
        {
          value: 'other',
          label: <I18n id="connection_form.role_types.other" />,
        },
      ]}
      value={roleType}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errors.roleType}
      visited={visited.roleType}
    />
  ),
  renderValue: (value) =>
    value ? <I18n id={`connection_form.role_type.${value}`} /> : '',
})
export const roleTitle = () => ({
  key: 'title',
  label: (
    <Wrapper>
      <I18n id="connection_form.labels.title" />
      <RequiredField>
        <I18n id="connection_form.required_fields.title" />
      </RequiredField>
    </Wrapper>
  ),
  Input: ({ fetchedOn, locked }) => (
    <Input type="text" name="title" disabled={locked || fetchedOn} />
  ),
})

export const firstName = () => ({
  key: 'firstName',
  label: (
    <Wrapper>
      <I18n id="connection_form.labels.first_name" />
      <RequiredField>
        <I18n id="connection_form.required_fields.first_name" />
      </RequiredField>
    </Wrapper>
  ),
  Input: () => <Input type="text" name="firstName" />,
})
export const lastName = () => ({
  key: 'lastName',
  label: (
    <Wrapper>
      <I18n id="connection_form.labels.last_name" />
      <RequiredField>
        <I18n id="connection_form.required_fields.last_name" />
      </RequiredField>
    </Wrapper>
  ),
  Input: () => <Input type="text" name="lastName" />,
})
export const birthDate = () => ({
  key: 'birthdate',
  label: (
    <Wrapper>
      <I18n id="Birthdate" />
      <RequiredField>
        <I18n id="connection_form.required_fields.birthdate" />
      </RequiredField>
    </Wrapper>
  ),
  Input: () => <BirthdateInput />,
  renderValue: (value) => (value ? getDateWithoutTime(value) : ''),
})

export const isResidentAbroad = () => ({
  key: 'isResidentAbroad',
  label: (
    <Wrapper>
      <I18n id="connection_form.labels.is_resident_abroad" />
      <RequiredField>
        <I18n id="connection_form.required_fields.is_resident_abroad" />
      </RequiredField>
    </Wrapper>
  ),
  Input: ({ isResidentAbroad, visited, errors, handleChange, handleBlur }) => (
    <RadioButtons
      name="isResidentAbroad"
      value={isResidentAbroad}
      options={[
        { label: <I18n id="Yes" />, value: 'yes' },
        { label: <I18n id="No" />, value: 'no' },
      ]}
      visited={visited.isResidentAbroad}
      error={errors.isResidentAbroad}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  ),
  renderValue: (value) =>
    value ? <I18n id={`connection_form.is_resident_abroad.${value}`} /> : '',
})
export const address = () => ({
  key: 'address',
  label: (
    <Wrapper>
      <I18n id="Address" />
      <RequiredField>
        <I18n id="connection_form.required_fields.address" />
      </RequiredField>
    </Wrapper>
  ),
  Input: () => <Input type="text" name="address" />,
})

export const relationType = () => ({
  key: 'relationType',
  label: (
    <Wrapper>
      <I18n id="connection_form.labels.relation_type" />
      <RequiredField>
        {' '}
        <I18n id="connection_form.required_fields.relation_type" />
      </RequiredField>
    </Wrapper>
  ),
  Input: ({ relationType, handleChange, handleBlur, errors, visited }) => (
    <Select
      name="relationType"
      options={[
        {
          value: 'spouse',
          label: <I18n id="connection_form.relation_types.spouse" />,
        },
        {
          value: 'parent',
          label: <I18n id="connection_form.relation_types.parent" />,
        },
        {
          value: 'child',
          label: <I18n id="connection_form.relation_types.child" />,
        },
        {
          value: 'other',
          label: <I18n id="connection_form.relation_types.other" />,
        },
      ]}
      value={relationType}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errors.relationType}
      visited={visited.relationType}
    />
  ),
  renderValue: (value) =>
    value ? <I18n id={`connection_form.relation_types.${value}`} /> : '',
})

export const stakePercentage = () => ({
  // condition: (props) => props.isCompanyListed === 'yes',
  key: 'stakePercentage',
  label: <I18n id="connection_form.labels.stake_percentage" />,
  Input: () => (
    <Input
      style={{ width: '50%' }}
      type="number"
      step="any"
      name="stakePercentage"
      min="0"
      max="1"
    />
  ),
})

export const isCaseRelevant = () => ({
  key: 'isCaseRelevant',
  label: (
    <Wrapper>
      <I18n id="connection_form.labels.is_connection_relevant" />
      <RequiredField>
        <I18n id="connection_form.required_fields.is_connection_relevant" />
      </RequiredField>
    </Wrapper>
  ),
  Input: ({
    isCaseRelevant,
    fetchedOn,
    visited,
    errors,
    handleChange,
    handleBlur,
    locked,
  }) => (
    <RadioButtons
      name="isCaseRelevant"
      value={isCaseRelevant}
      options={[
        { label: <I18n id="Yes" />, value: true },
        { label: <I18n id="No" />, value: false },
      ]}
      visited={visited.isCaseRelevant}
      error={errors.isCaseRelevant}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={locked || fetchedOn}
    />
  ),
  helpText: <I18n id="connection_form.help_texts.is_connection_relevant" />,
  renderValue: (value) =>
    value ? <I18n id={`connection_form.is_case_relevant.${value}`} /> : '',
})

export const email = () => ({
  key: 'email',
  label: (
    <Wrapper>
      <I18n id="Email" />
      <RequiredField>
        <I18n id="connection_form.required_fields.email" />
      </RequiredField>
    </Wrapper>
  ),
  Input: () => <Input type="text" name="email" />,
})

const WarningText = styled.div`
  display: flex;
  flex-direction: column;
`

const Flex = styled.div`
  display: flex;
`

const Icon = styled.img`
  margin: 0 0.5em;
  height: 1em;
  width: 1em;
  filter: invert(52%) sepia(91%) saturate(1360%) hue-rotate(181deg)
    brightness(89%) contrast(85%);
`
const Wrapper = styled.div`
  display: flex;

  & > * {
    flex-grow: 2;
    flex-basis: 0px;
    margin-left: 0.2em;
  }

  & > :not(:last-child) {
    margin-right: 0.5em;
    flex-grow: 1;
  }
`
const RequiredField = styled.div`
  color: ${({ theme: { error } }) => error};
`
