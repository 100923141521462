import { takeEvery, call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { SEND_REMINDER, SEND_REMINDER_SUCCESS, SEND_REMINDER_FAILED } from './'
import { post } from '../fetch'

function* sendReminder({ users, message }) {
  try {
    const { errors } = yield call(post, '/email/reminder', { users, message })
    if (errors) {
      yield put({ type: SEND_REMINDER_FAILED, errors })
    } else {
      yield put({ type: SEND_REMINDER_SUCCESS })
    }
  } catch (e) {
    console.error(e)
    yield put({ type: SEND_REMINDER_FAILED, errors: [{ detail: e.message }] })
  }
}

function* goToSuccess() {
  yield put(push('/reminder/success'))
}

function* saga() {
  yield takeEvery(SEND_REMINDER, sendReminder)
  yield takeEvery(SEND_REMINDER_SUCCESS, goToSuccess)
}

export default saga
