import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { isSameDay } from '../../dateUtils'
import { getUserEvents, isUserEventsFetching } from '../../redux/selectors'
import eventProps from './eventProps'
import Event from './Event'
import SubEvent from './SubEvent'
import ToggleEvent from './ToggleEvent'
import Spinner from '../Spinner'

const isGroupable = (event) => (eventProps[event.eventType] || {}).group

const groupEvents = (events) =>
  (events || []).reduce((groups, event) => {
    if (groups.length === 0) return [[event]]

    const lastGroup = groups[groups.length - 1]
    const lastEvent = lastGroup[lastGroup.length - 1]

    if (
      isGroupable(event) &&
      isGroupable(lastEvent) &&
      isSameDay(event.createdOn, lastEvent.createdOn)
    ) {
      let newGroups = [...groups]
      newGroups[groups.length - 1].push(event)
      return newGroups
    } else {
      return [...groups, [event]]
    }
  }, [])

const EventList = ({ events, isFetching }) => {
  if (isFetching) return <Spinner />

  const groupedEvents = groupEvents(events)

  return (
    <List>
      {groupedEvents.map((group, i) => (
        <li key={i}>
          {group.length > 1 ? (
            <ToggleEvent
              hasContent
              createdOn={group[0].createdOn}
              eventType="grouped_event"
            >
              <SubList>
                {group.map((event) => (
                  <li key={event.id}>
                    <SubEvent {...event} />
                  </li>
                ))}
              </SubList>
            </ToggleEvent>
          ) : (
            <Event {...group[0]} />
          )}
        </li>
      ))}
    </List>
  )
}

const List = styled.ul`
  list-style: none;
  margin: 0 0 0 0;
  padding: 0;
  padding-right: 1em;
  max-height: 600px;
  overflow: auto;
  & > :not(:last-child) {
    margin-bottom: 0.75em;
  }
  & > :nth-child(1) {
    padding-top: 1.35em;
  }

  --scrollbarBG: rgba(0,0,0,0.1);
  --thumbBG: #90a4ae;

  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
`

const SubList = styled(List)`
  margin-left: 4em;
`

const mapStateToProps = (state, props) => ({
  events: getUserEvents(state, props.userId) || [],
  isFetching: isUserEventsFetching(state, props.userId),
})

export default connect(mapStateToProps)(EventList)
