import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import I18n, { useI18nFunction } from './I18n'
import UserHeader from './UserHeader'
import Button from './Button'
import { Input, Label, BirthdateInput } from './form'
import useValidation from '../hooks/useValidation'
import useVisited from '../hooks/useVisited'
import * as schema from '../validation'

const BirthdateForm = ({ setBirthdateAndAddress, pending, currentUser }) => {
  const I18nFunction = useI18nFunction();

  const split = currentUser.birthdate
    ? currentUser.birthdate.split('-')
    : [null, null, null]

  const [state, setState] = useState({
    birthdate_day: split[2],
    birthdate_month: split[1],
    birthdate_year: split[0],
    address: currentUser.address,
    postCode: currentUser.postCode,
    postPlace: currentUser.postPlace,
  })
  
  const { errors, validate } = useValidation(
    schema.birthdateForm(I18nFunction),
  )

  useEffect(() => {
    const cancelablePromise = validate(state)
    return cancelablePromise.cancel
  }, [validate, state])

  const { visited, visitField, visitFields } = useVisited()

  const handleBlur = (e) => visitField(e.target.name)

  const handleChange = (e) =>
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })

  const hasErrors = Object.keys(errors).length > 0

  const handleSubmit = () => {
    //e.preventDefault()
    if (hasErrors) {
      visitFields(Object.keys(errors))
    } else {
      setBirthdateAndAddress({
        birthdate: `${state.birthdate_year}-${state.birthdate_month}-${state.birthdate_day}`,
        address: state.address,
        postCode: state.postCode,
        postPlace: state.postPlace,
      })
    }
  }

  return (
    <div>
      <Header>
        <h1>
          <I18n id="birthdate_form.data_from_ad" />
        </h1>
        <p>
          <I18n id="birthdate_form.contact_if_wrong" />
        </p>
      </Header>
      <UserHeader 
        userId={currentUser.id}
        hideEditBirthdate = {true} 
      />
      <Form onSubmit={handleSubmit}>
        <h1>
          <I18n id="birthdate_form.birthdate_and_address" />
        </h1>
        <p>
          <I18n id="birthdate_form.birthdate_and_address_used_for" />
        </p>
        <Heading htmlFor="birthdate_day">
          <I18n id="Birthdate" />
        </Heading>
        <BirthdateInput
          name="birthdate"
          day={state.birthdate_day}
          month={state.birthdate_month}
          year={state.birthdate_year}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors}
          visited={visited}
          autoFocus
        />
        <Address>
          <Heading htmlFor="address">
            <I18n id="Address" />
          </Heading>
          <Input
            name="address"
            id="address"
            value={state.address}
            onChange={handleChange}
            placeholder={I18nFunction( { id: 'Address_placeholder'} )}
            onBlur={handleBlur}
            error={errors.address}
            visited={visited.address}
          />
          <Place>
            <div>
              <Label htmlFor="postCode">
                <I18n id="Post_code" />
              </Label>
              <Input
                name="postCode"
                id="postCode"
                value={state.postCode}
                onChange={handleChange}
                placeholder={I18nFunction( { id: 'Post_code'} )}
                onBlur={handleBlur}
                error={errors.postCode}
                visited={visited.postCode}
              />
            </div>
            <div>
              <Label htmlFor="postPlace">
                <I18n id="Post_place" />
              </Label>
              <Input
                name="postPlace"
                id="postPlace"
                value={state.postPlace}
                onChange={handleChange}
                placeholder={I18nFunction( { id: 'Post_place'} )} 
                onBlur={handleBlur}
                error={errors.postPlace}
                visited={visited.postPlace}
              />
            </div>
          </Place>
        </Address>
        <Button
          type="submit"
          onClick={() => handleSubmit()}
          inactive={hasErrors}
          pending={pending}
        >
          <I18n id="Confirm" />
        </Button>
      </Form>
    </div>
  )
}

const Place = styled.div`
  display: flex;

  & > * {
    flex-grow: 1;
    flex-basis: 0px;
    flex-shrink: 0;
    min-width: 0px;
  }

  & > :last-child {
    flex-grow: 3;
  }

  & > :not(:last-child) {
    margin-right: 1em;
  }

  & label {
    margin: 0.5em 0;
    display: block;
  }
`

const Address = styled.div`
  max-width: 300px;
  margin-bottom: 1em;
`

const Heading = styled.label`
  font-size: ${(props) => props.theme.largeText};
  display: block;
  margin: 1.5em 0 0.5em;
`

const Form = styled.form`
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
  margin: 0 auto;
`

const Header = styled.section`
  background-color: ${({ theme: { secondaryBackground } }) =>
    secondaryBackground};
  padding: 4em 0 1em;

  & > * {
    max-width: ${({ theme: { maxWidth } }) => maxWidth};
    margin: 0.5em auto;
  }

  & > p {
    font-size: ${(props) => props.theme.largeText};
  }
`

export default BirthdateForm
