import React from 'react'
import styled from 'styled-components'
import I18n from './I18n'

const DataListCompare = ({
  userData,
  publicData,
  application,
  shortData,
  data,
  showMore,
}) => {
  const hasDiff = (userReg, publicData, latestApplication) => {
    if (hasValue(userReg) && hasValue(publicData)) {
      return userReg != publicData
    }

    return false
  }

  const hasValue = (val) =>
    val !== undefined && val !== null && val !== '' && typeof val !== 'object'
  return (
    <List>
      <Element>
        <Label></Label>
        <Value>
          <I18n id="case_details.user" />
        </Value>
        <PublicValue>
          <I18n id="case_details.public" />
        </PublicValue>
        {process.env.REACT_APP_APPLICATIONS_ENABLED?.toLowerCase() === 'true' &&
          application && (
            <LatestApplicationValue>
              <I18n id="case_details.application" />
            </LatestApplicationValue>
          )}
      </Element>
      {showMore
        ? data.map(
            ([label, value, publicValue, latestApplicationValue, diff], i) => (
              <Element
                key={i}
                className={
                  hasDiff(value, publicValue, latestApplicationValue) && 'diff'
                }
              >
                <div className={'label'}>{label}</div>
                <Value> {value}</Value>
                <PublicValue>{publicValue}</PublicValue>
                {process.env.REACT_APP_APPLICATIONS_ENABLED?.toLowerCase() ===
                  'true' &&
                  application && (
                    <LatestApplicationValue>
                      {latestApplicationValue}
                    </LatestApplicationValue>
                  )}
              </Element>
            ),
          )
        : shortData.map(
            ([label, value, publicValue, latestApplicationValue, diff], i) => (
              <Element
                key={i}
                className={
                  hasDiff(value, publicValue, latestApplicationValue) && 'diff'
                }
              >
                <div className={'label'}>{label}</div>
                <Value>{value}</Value>
                <PublicValue>{publicValue}</PublicValue>
                {process.env.REACT_APP_APPLICATIONS_ENABLED?.toLowerCase() ===
                  'true' &&
                  application && (
                    <LatestApplicationValue>
                      {latestApplicationValue}
                    </LatestApplicationValue>
                  )}
              </Element>
            ),
          )}
    </List>
  )
}

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  & > li:nth-child(2n) {
    &.diff {
    }
  }

  & > li:not(:last-child) {
  }
`

const Element = styled.li`
  display: flex;
  padding: 0.5em 0.25em;
  border-bottom: 1px solid #aaa;

  & > .label {
    flex-grow: 2;
    margin-right: 0.75em;
    color: ${({ theme: { subtleText } }) => subtleText};
  }

  & > * {
    flex-basis: 0px;
    flex-shrink: 0;
    min-width: 0px;
  }

  &.diff {
    font-weight: bold;
    background: #d6e4ff;

    & > .label {
      flex-grow: 2;
      margin-right: 0.75em;
      color: ${({ theme: { text } }) => text};
    }
  }
`

const Label = styled.div`
  flex-grow: 2;
  margin-right: 0.75em;
  color: ${({ theme: { subtleText } }) => subtleText};
`

const Value = styled.div`
  flex-grow: 2;
  max-width: 115px;
`

const PublicValue = styled.div`
  flex-grow: 2;
`

const LatestApplicationValue = styled.div`
  flex-grow: 2;
`

export default DataListCompare
