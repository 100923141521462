export const enforceConnectionFormDependencyRules = (changeFunc, target) => {
  if (target.name === 'isCompanyRegistered' && target.value === 'no') {
    changeFunc({
      organizationNumber: null,
      isCompanyListed: 'no',
      stockCount: null,
      stakePercentage: null,
      fromDate: null,
      toDate: null,
    })
  }
  if (target.name === 'isCompanyRegistered' && target.value === 'yes') {
    changeFunc({
      organizationNumber: null,
      isCompanyListed: null,
      stockCount: null,
      stakePercentage: null,
      fromDate: null,
      toDate: null,
    })
  }
}
