import React, { useState } from 'react'
import styled from 'styled-components'
import helpActiveIcon from '../../icons/help_active.svg'
import helpIcon from '../../icons/help.svg'

const HelpText = ({ children }) => {
  const [active, setActive] = useState(false)

  return (
    <HelpTextWrapper className={active && 'active'}>
      <HelpTextButton
        type="button"
        onClick={() => setActive(!active)}
        onBlur={() => setActive(false)}
      >
        <HelpTextIcon src={active ? helpActiveIcon : helpIcon} alt="" />
      </HelpTextButton>
      <div className="helpText">{children}</div>
    </HelpTextWrapper>
  )
}

const HelpTextWrapper = styled.div`
  position: relative;
  line-height: 0;
  margin-left: 0.33em;

  & > .helpText {
    display: none;
    position: absolute;
    line-height: 1.15;
    z-index: 1;
    right: 0;
    top: calc(100% + 0.33em);
    background-color: ${({ theme: { primary } }) => primary};
    color: ${({ theme: { inverseText } }) => inverseText};
    padding: 1em;
    width: max-content;
    max-width: 14em;
  }

  &.active > .helpText {
    display: block;
  }
`

const HelpTextButton = styled.button`
  padding: 0;
  line-height: 0;
  border: 0;
  background-color: transparent;
`

const HelpTextIcon = styled.img``

export default HelpText
