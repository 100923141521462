import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Input } from '../index'
import { getSuggestions } from '../../../redux/selectors'
import { fetchSuggestions } from '../../../redux/actions'
import SuggestionList from './SuggestionList'

const ConnectionInput = (props) => {
  const [active, setActive] = useState()
  const [hover, setHover] = useState()
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [timeoutId, setTimeoutId] = useState()

  const onBlur = (e) => {
    props.onBlur && props.onBlur(e)
    setShowSuggestions(false)
  }

  const select = (suggestion) => {
    props.onChange({
      target: { name: 'organizationName', value: suggestion.organizationName },
    })
    props.onChange({
      target: {
        name: 'organizationNumber',
        value: suggestion.organizationNumber + '',
      },
    })
    setShowSuggestions(false)
  }

  const onInputEnter = (e) => {
    if (showSuggestions) {
      e.preventDefault()
      setShowSuggestions(false)
      select(props.suggestions[active])
      setActive(undefined)
      setHover(undefined)
    }
  }

  const onChange = (e) => {
    props.onChange(e)
    setShowSuggestions(true)
    setActive(undefined)
    setHover(undefined)
    const value = e.target.value
    clearTimeout(timeoutId)
    setTimeoutId(
      setTimeout(() => {
        props.fetchSuggestions(value)
      }, 100),
    )
  }

  const incrementActive = () => {
    setActive((active) => {
      if (!props.suggestions || props.suggestions.length === 0) return undefined
      else if (hover !== undefined)
        return (hover + 1) % props.suggestions.length
      else if (active !== undefined)
        return (active + 1) % props.suggestions.length
      else return 0
    })
    setHover(undefined)
  }

  const decrementActive = () => {
    setActive((active) => {
      if (!props.suggestions || props.suggestions.length === 0) return undefined
      else if (hover !== undefined)
        return (hover - 1 + props.suggestions.length) % props.suggestions.length
      else if (active !== undefined)
        return (
          (active - 1 + props.suggestions.length) % props.suggestions.length
        )
      else return 0
    })
    setHover(undefined)
  }

  const onKeyDown = (e) => {
    if (e.key === 'Escape') {
      setShowSuggestions(false)
    } else if (e.key === 'Enter') {
      onInputEnter(e)
    } else if (e.key === 'ArrowDown') {
      e.preventDefault()
      incrementActive()
    } else if (e.key === 'ArrowUp') {
      e.preventDefault()
      decrementActive()
    }
  }

  return (
    <Wrapper>
      <Input
        {...props}
        name="organizationName"
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        autoComplete="off"
        disabled={props.disabled}
      />
      {showSuggestions && props.suggestions && props.suggestions.length > 0 && (
        <SuggestionList
          suggestions={props.suggestions}
          active={active !== undefined ? active : hover}
          setHover={(hover) => {
            setHover(hover)
            setActive(undefined)
          }}
          unsetHover={() => setHover(undefined)}
          select={select}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`

const mapStateToProps = (state, props) => ({
  suggestions: getSuggestions(state, props.value),
})

const mapDispatchToProps = { fetchSuggestions }

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionInput)
