import React from 'react'
import styled from 'styled-components'
import infoIcon from '../icons/info.svg'

const Wrapper = styled.div`
  border-radius: 5px;
  color: ${({ theme: { text } }) => text};
  box-shadow: rgb(219 219 219) 0px 2px 8px;
 
  padding-right: 2em;
  display: flex;
  align-items: flex-start;
  background-color: ${({ theme: { secondaryBackground } }) => secondaryBackground};

  & > div {
    background-color: ${({ theme: { secondary } }) => secondary};
    border-radius: 2em;
    margin: 1em;
    min-width: 32px;
    min-height: 32px;
    max-width: 32px;
    max-height: 32px;
  }
`

const Logo = styled.img`
  margin: 4px;
  width: 24px;
  height: 24px;
`

const InfoBox = ({ className, children }) => (
  <Wrapper className={className}>
    <div>
      <Logo src={infoIcon} alt="" />
    </div>

    <span>{children}</span>
  </Wrapper>
)

export default InfoBox
