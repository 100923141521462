import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { fetchCompanyConnections, fetchAllCases } from '../redux/actions'
import {
  getCompanyConnections,
  getCompanyCases,
  shouldFetchCompanyConnections,
  isCompanyConnectionsPending,
  shouldFetchCases,
} from '../redux/selectors'
import Page from '../components/Page'
import CompanyHeader from '../components/CompanyHeader'
import CaseList from '../components/CaseList'
import CompanyRelationCaseList from '../components/CompanyRelationCaseList'
import {
  USER_ROLE_CASE,
  USER_OWNERSHIP_CASE,
  RELATION_OWNERSHIP_CASE,
  RELATION_ROLE_CASE,
} from '../caseTypes'
import I18n from '../components/I18n'
import Button from '../components/Button'
import CompanyEvents from '../components/CompanyEvents'

const Company = ({
  companyId,
  shouldFetchCompanyConnections,
  fetchCompanyConnections,
  company,
  pending,
  cases,
}) => {
  const [isHistoryOpen, setIsHistoryOpen] = useState(false)

  useEffect(() => {
    shouldFetchCompanyConnections && fetchCompanyConnections(companyId)
  }, [shouldFetchCompanyConnections, fetchCompanyConnections, companyId])

  const userOwnershipCases = cases.filter(
    (Case) => Case.caseType === USER_OWNERSHIP_CASE,
  )
  const userRoleCases = cases.filter((Case) => Case.caseType === USER_ROLE_CASE)

  return (
    <Page pending={pending}>
      <CompanyHeader {...company} />
      <ActionBarWrapper>
        <ActionBar>
          <ActionBarContent>
            <Button styled onClick={() => setIsHistoryOpen((is) => !is)}>
              {true == userRoleCases ? (
                <I18n id="Hide_profile_history" />
              ) : (
                <I18n id="Show_profile_history" />
              )}
            </Button>
            <Button
              onClick={() =>
                (window.location.href = `${window.location.origin}/api/companies/${companyId}/generate_company_pdf`)
              }
            >
              <I18n id="Export_company_pdf" />
            </Button>
          </ActionBarContent>
        </ActionBar>
      </ActionBarWrapper>
      <Content>
        <Wrapper>
          <div>
            <h2>
              <I18n id="Ownerships" />
            </h2>
            <CaseList cases={userOwnershipCases} companyConnection />
            <CompanyRelationCaseList
              cases={cases.filter((Case) =>
                [RELATION_OWNERSHIP_CASE].includes(Case.caseType),
              )}
            />
          </div>

          <div>
            <h2>
              <I18n id="Roles" />
            </h2>
            <CaseList cases={userRoleCases} companyConnection />
            <CompanyRelationCaseList
              cases={cases.filter((Case) =>
                [RELATION_ROLE_CASE].includes(Case.caseType),
              )}
            />
          </div>
        </Wrapper>
        {isHistoryOpen && (
          <History>
            <Inner>
              <h2>
                <I18n id="Profile_history" />
              </h2>
              <CompanyEvents events={company.events} />
            </Inner>
          </History>
        )}
      </Content>
    </Page>
  )
}

const History = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50%;
`

const ActionBarWrapper = styled.div`
  background-color: ${({ theme: { secondaryBackground } }) =>
    secondaryBackground};
`

const Inner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: auto;
  padding: 0 2em;
  padding-bottom: 2em;
  background-color: ${(props) => props.theme.primaryBackground};
`

const ActionBar = styled.div`
  background-color: ${({ theme: { secondaryBackground } }) =>
    secondaryBackground};
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  padding-bottom: 1em;
`

const ActionBarContent = styled.div`
  display: flex;
  & > :not(:last-child) {
    margin-right: 0.5em;
  }
`

const Content = styled.div`
  position: relative;
  display: flex;
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
  margin: 0 auto;

  & > * {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0px;
    min-width: 0px;
  }
`

const Wrapper = styled.div`
  display: flex;

  & > * {
    flex-grow: 1;
    flex-basis: 0px;
    flex-shrink: 0;
    min-width: 0px;
  }

  & > :not(:last-child) {
    margin-right: 2em;
  }
`

const mapStateToProps = (state, props) => ({
  shouldFetchCases: shouldFetchCases(state),
  company: getCompanyConnections(state, props.companyId),
  cases: getCompanyCases(state, props.companyId),
  shouldFetchCompanyConnections: shouldFetchCompanyConnections(
    state,
    props.companyId,
  ),
  pending: isCompanyConnectionsPending(state, props.companyId),
})

const mapDispatchToProps = { fetchCompanyConnections, fetchAllCases }

export default connect(mapStateToProps, mapDispatchToProps)(Company)
