import { takeEvery } from 'redux-saga/effects'
import { get } from '../fetch'
import { fetchSagaPagination } from '../sagas'

import {
  FETCH_ALL_APPLICATIONS,
  FETCH_ALL_APPLICATIONS_SUCCEEDED,
  FETCH_ALL_APPLICATIONS_FAILED,
} from './index'

import * as schema from '../schema'

function* saga() {
  yield takeEvery(
    FETCH_ALL_APPLICATIONS,
    fetchSagaPagination({
      SUCCEEDED: FETCH_ALL_APPLICATIONS_SUCCEEDED,
      FAILED: FETCH_ALL_APPLICATIONS_FAILED,
      schema: schema.applicationResponse,
      fetch: ({
        pagination: {
          query,
          filters,
          sortOption,
          dateRangeOption,
          dateRangeFrom,
          dateRangeTo,
          pageIndex,
          perPage,
        },
      }) =>
        get(
          `/applications?query=${query}&filters=${filters}&sort=${sortOption}&dateRange=${dateRangeOption}&from=${dateRangeFrom}&to=${dateRangeTo}&pageIndex=${pageIndex}&perPage=${perPage}`,
        ),
    }),
  )



}

export default saga
