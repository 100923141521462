import React from 'react'
import styled from 'styled-components'
import searchIcon from '../../icons/search.svg'

const QueryInput = ({ query, setQueryHandler, placeholder }) => {
  return (
    <Wrapper>
      <SearchIcon src={searchIcon} alt="" />
      <SearchQueryInput
        type="search"
        name="search-form"
        value={query}
        onChange={(e) => setQueryHandler(e.target.value)}
        placeholder={placeholder}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 0.5em 0.5em 0.5em 0.5em;
  position: relative;
`

const SearchIcon = styled.img`
  position: absolute;
  left: 1em;
  top: 35%;
`

const SearchQueryInput = styled.input`
  border: 1px solid #cccccc;
  padding-left: 1.75em;
  height: 37px;
  width: 100%;
  border-radius: 4px;
  outline: none;
`

export default QueryInput
