import React from 'react'
import styled from 'styled-components'
import I18n, { useI18nFunction } from '../I18n'
import { ReactComponent as PublicInfoIcon } from '../../icons/building-columns-solid.svg'

const ProffPersonList = ({
  Person,
  active,
  setActiveProffPerson,
  unsetActiveProffPerson,
  listIndex,
  userId,
}) => {
  const I18nFunction = useI18nFunction();
  const onClick = () => {
    unsetActiveProffPerson()
    active
      ? unsetActiveProffPerson()
      : setActiveProffPerson({
          index: listIndex,
          userId: userId,
          proffPersonId: Person.personId,
          proffShareholderId: Person.shareholderId,
        })
  }
  return (
    <Wrapper className={[active && 'active']} onClick={() => onClick()}>
      {Person && (
        <>
          <Content>
            <PublicInfoIcon
              style={{
                position: 'absolute',
                stroke: '#ccc',
                fill: '#ccc',
                width: '1em',
                height: '1em',
                top: '0.25em',
                left: '1em',
              }}
              title={I18nFunction({ id: 'case_details.public' })}
            />
            <Title small={false} title={Person.name}>
              {Person.name}
            </Title>
            <Subtitle>
              <Description small={true} description={Person.yearOfBirth}>
                <I18n id="Birthyear" /> {Person.yearOfBirth}{' '}
                <I18n id="Post_code" />{' '}
                {Person.postalAddress
                  ? Person.postalAddress.zipCode
                  : Person.zipCode}
              </Description>
            </Subtitle>
          </Content>
          <RightFlexBox>
            <StatusWrapper>
              <ConnectionCount>
                <I18n id="proff_id_form.Ownership_count" />{' '}
                {Person.ownerships ? Person.ownerships.length : 0}
              </ConnectionCount>
              <ConnectionCount>
                <I18n id="proff_id_form.Role_count" />{' '}
                {Person.roles ? Person.roles.length : 0}
              </ConnectionCount>
            </StatusWrapper>
          </RightFlexBox>
        </>
      )}
    </Wrapper>
  )
}

const Content = styled.div`
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
`

const Wrapper = styled.li`
  box-shadow: rgb(219 219 219) 0px 2px 8px;
  border-radius: 5px;
  position: relative;
  background-color: ${({ theme }) => theme.primaryBackground};
  display: flex;
  align-items: center;
  padding: 1em;
  border: solid 2px ${({ theme: { primaryBackground } }) => primaryBackground};
  cursor: pointer;

  &.active {
    border-color: ${({ theme: { primary } }) => primary};
    box-shadow: rgb(180 180 200) 0px 2px 8px;
  }
`

const Title = styled.div`
  font-size: ${({ small, theme: { normalText, largeText } }) =>
    small ? normalText : largeText};
  margin-bottom: 0.2em;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Subtitle = styled.div`
  display: flex;
  justify-content: start;
  overflow: hidden;
`

const Description = styled.span`
  ${({ description, small, theme: { secondary, subtleText } }) =>
    !description ? 'color: ' + secondary : small && 'color: ' + subtleText}
  overflow: hidden;
  text-overflow: ellipsis;
`

const RightFlexBox = styled.div`
  display: flex;

  justify-content: flex-end;
`
const StatusWrapper = styled.div`
  width: 100%;
  align-items: center;
`

const ConnectionCount = styled.div`
  margin: 0.5em 0;
`

export default ProffPersonList
