import React from 'react'
import styled from 'styled-components'
import I18n from './I18n'
import Link from './Link'
import CardDataList from './CardDataList'

const CaseCompanyCard = ({ Case, company }) => {

  const displayedCompanyFields = [
    {
      label: <I18n id="company_header.organization_number" />, 
      key: 'organizationNumber',
    },
    {
      label: <I18n id="company_header.employee_count" />,
      key: 'numberOfEmployees',
    },
    {
      label: <I18n id="company_header.established" />,
      key: 'foundationDate',
    },
    {
      label: <I18n id="Is_supplier" />,
      key: 'organizationIsSupplierForUserCompany',
    },
    {
      label: <I18n id="Is_client" />,
      key: 'organizationIsCustomerForUserCompany',
    },
  ]

  if (Object.keys(company).length === 0 && company.constructor === Object) {
    return <CompanyDataNotFound />
  }
  if (company?.status.includes('failed')) {
    return <CompanyDataNotFound />
  }
  return (
    <React.Fragment>
      <DatalistWrapper>
        <Title>
          {company.organizationName ? (
            <Link to={`/companies/${company.organizationNumber}`}>
              {company.organizationName}
            </Link>
          ) : (
            <I18n id="No_public_data" />
          )}
        </Title>
        <CardDataList
          data={displayedCompanyFields.map(({ key, label }) => [
            label,
            i18nConnectionValue(key, company[key], Case[key]),
          ])}
        />
      </DatalistWrapper>
    </React.Fragment>
  )
}

const i18nConnectionValue = (key, companyValue, caseValue) => {
  switch (key) {
    case 'country':
      return companyValue && <I18n country={companyValue} />
    case 'organizationIsSupplierForUserCompany':
      return caseValue ? <I18n id="Yes" /> : <I18n id="No" />
    case 'organizationIsCustomerForUserCompany':
      return caseValue ? <I18n id="Yes" /> : <I18n id="No" />
    case 'foundationDate':
      return companyValue && companyValue.replace(/\./g, '-')
    default:
      return companyValue
  }
}

const CompanyDataNotFound = () => {
  return (
    <React.Fragment>
      <DatalistWrapper>
        <Title>
          <Link disabled>
            <I18n id="Company" />
          </Link>
        </Title>
        <p>
          <I18n id="case_company_card.company_information_not_available" />
        </p>
      </DatalistWrapper>
    </React.Fragment>
  )
}

const DatalistWrapper = styled.div`
  overflow-x: auto;
  word-break: break-word;
  border-radius: 10px;
  padding: 1.5em;
  background-color: ${({ theme: { primaryBackground } }) => primaryBackground};
`

const Title = styled.div`
  font-size: ${(props) => props.theme.largeText};
  text-overflow: ellipsis;
  flex-grow: 1;
  overflow: hidden;
  white-space: wrap;
  text-align: center;
  margin-bottom: 1em;
  font-weight: bold;
`

export default CaseCompanyCard
