import React from 'react'
import styled from 'styled-components'

const ToggleSwitch = ({ label, value, valueRef, onChangeHandler }) => {
  const change = () => {
    onChangeHandler(value, !value, valueRef)
  }
  return (
    <Wrapper>
      <Label>{label}</Label>
      <Container>
        <Toggle
          type="checkbox"
          checked={value}
          onChange={(e) => change()}
        ></Toggle>
        <Slider className={value ? 'toggled' : ''}></Slider>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  padding: 0.1em;
`

const Label = styled.label`
  flex: 1;
`

const Container = styled.label`
  margin-right: 3em;
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`

const Toggle = styled.input`
  :checked + .slider {
    background-color: #2196f3;
  }
`

const Slider = styled.span`
  border-radius: 17px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &:before {
    border-radius: 50%;
    position: absolute;
    content: '';
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  &.toggled {
    background-color: #2196f3;
    box-shadow: 0 0 1px #2196f3;

    &:before {
      -webkit-transform: translateX(13px);
      -ms-transform: translateX(13px);
      transform: translateX(13px);
    }
  }
`

export default ToggleSwitch
