import React from 'react'
import styled from 'styled-components'
import Button from './Button'
// import { connect } from 'react-redux';
// import { acceptCookies } from '../redux/actions'; // Replace with your real Redux action
import I18n from './I18n'
import ReactDOM from 'react-dom'

const CookieConsentModal = ({ onClose }) => {
  return ReactDOM.createPortal(
    <Overlay>
      <ContentWrapper onClick={(e) => e.stopPropagation()}>
        <Header>
          <h1>
            <I18n id="Cookie_Consent" />
          </h1>
        </Header>
        <Body>
          <Wrapper>
            <Title>
              <I18n id="cookie_info_text" />
            </Title>
            <SubTitle>
              <I18n id="cookie_help_text" />
            </SubTitle>
          </Wrapper>
        </Body>
        <ModalActions>
          <Button
            onClick={() => {
              onClose()
            }}
          >
            <I18n id="Accept_Cookies" />
          </Button>
        </ModalActions>
      </ContentWrapper>
    </Overlay>,
    document.getElementById('modal-root'),
  )
}


const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6); // This grays out the background
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; // This ensures the modal stays on top of other elements
`

const Wrapper = styled.div`
  max-width: 666px;
  margin: 0 auto;
  margin-bottom: 0.75em;
`

const ContentWrapper = styled.div`
  background-color: ${(props) => props.theme.primaryBackground};
  margin: 0 auto;
  min-height: 350px;
  min-width: 450px;
  -moz-box-shadow: 0px 0px 18px 6px #ccc;
  -webkit-box-shadow: 0px 0px 18px 6px #ccc;
  box-shadow: 0px 0px 18px 6px #ccc;
  padding: 2em;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  margin: 0;
  padding-bottom: 12px;
  border-bottom: 2px solid #eee;
`

const Body = styled.div`
  flex-grow: 7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 0 2em;
`

const Title = styled.div`
  font-weight: bold;
`

const SubTitle = styled.div`
  font-style: italic;
`

const ModalActions = styled.div`
  height: 50px;
  display: flex;
  justify-content: flex-end;
  border-top: 2px solid #eee;
  padding-top: 12px;
  & > * {
    margin: 0 1em;
  }
`


export default CookieConsentModal
