import React from 'react'
import OwnershipListItem from './OwnershipListItem'
import RoleListItem from './RoleListItem'
import RelationListItem from './RelationListItem'
import OtherListItem from './OtherListItem'
import {
  RELATION,
} from '../../connectionTypes'
import { 
  USER_OWNERSHIP_CASE,
  USER_ROLE_CASE,
  USER_OTHER_CONNECTION_CASE,
  RELATION_OWNERSHIP_CASE,
  RELATION_ROLE_CASE,
  RELATION_OTHER_CONNECTION_CASE,
 } from '../../caseTypes'

const ProfileCaseListItem = (props) => {
  switch (props.caseType) {
    case RELATION_OWNERSHIP_CASE:
    case USER_OWNERSHIP_CASE:
      return <OwnershipListItem {...props} />
    case RELATION_ROLE_CASE:
    case USER_ROLE_CASE:
      return <RoleListItem {...props} />
    case RELATION_OTHER_CONNECTION_CASE:
    case USER_OTHER_CONNECTION_CASE:
      return <OtherListItem {...props} />
    case RELATION:
      return <RelationListItem {...props} />
    default:
      return null
  }
}

export default ProfileCaseListItem