import { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'

export default createGlobalStyle`
  ${styledNormalize};

  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    background-color: ${({ theme: { pageBackground } }) => pageBackground};
    font-family: Arial, Helvetica, sans-serif;
    font-size: ${({ theme: { normalText } }) => normalText};
    overflow-x: hidden;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  h1 {
    font-size: 1.625rem;
    margin: 1em 0;
    font-weight: normal;
  }

  h2 {
    font-size: ${({ theme: { largeText } }) => largeText};
    margin: 1em 0;
    font-weight: normal;
    color: ${({ theme: { subtleText } }) => subtleText};
  }

  h3 {
    font-size: ${({ theme: { normalText } }) => normalText};
    margin: 1em 0;
    font-weight: normal;
    color: ${({ theme: { subtleText } }) => subtleText};
  }

  .react-datepicker__input-container {
    width: 100%;
  }

  .boldText {
    font-weight: bold;
  }
`
