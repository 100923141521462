import React from 'react'
import { fields as roleProps } from '../ConnectionForm/RoleForm'
import { fields as ownershipProps } from '../ConnectionForm/OwnershipForm'
import { fields as otherConnectionProps } from '../ConnectionForm/OtherConnectionForm'
import { fields as relationProps } from '../ConnectionForm/RelationForm'
import ownership from '../../icons/event_chartLine.svg'
import role from '../../icons/event_role.svg'
import user from '../../icons/event_user.svg'
import other from '../../icons/event_other.svg'
import mail from '../../icons/mail.svg'
import relation from '../../icons/event_relation.svg'
import I18n from '../I18n'
import { getDateWithoutTime, prettyDate } from '../../dateUtils'

export default {
  user_created: {
    image: user,
    renderText: (props) => <I18n id="events.user_created" />,
  },

  user_updated: {
    image: user,
    renderText: (props) => {
      return <I18n id="events.user_updated" />
    },
    payloadProps: [
      {
        key: 'property',
        label: <I18n id="Updated_property" />,
        renderValue: (value) => <I18n id={`user_properties.${value}`} />,
      },
      {
        key: 'propertyOldValue',
        label: <I18n id="Old_value" />,
        renderValue: (value) =>
          typeof value == 'boolean' ? (
            <I18n id={`connection_form.position_of_influence.${value}`} />
          ) : value ? (
            value?.toString().toUpperCase()
          ) : (
            <I18n id="No_value" />
          ),
      },
      {
        key: 'propertyNewValue',
        label: <I18n id="New_value" />,
        renderValue: (value) =>
          typeof value == 'boolean' ? (
            <I18n id={`connection_form.position_of_influence.${value}`} />
          ) : (
            value?.toString().toUpperCase()
          ),
      },
    ],
  },

  data_migration: {
    image: user,
    renderText: (props) => <I18n id="events.data_migration" />,
  },
  grouped_event: {
    image: other,
    renderText: (props) => <I18n id="events.grouped_event" />,
  },
  complete_onboarding: {
    image: user,
    renderText: (props) => <I18n id="events.complete_onboarding" />,
    doNotRenderPayload: true,
  },
  confirm_form: {
    image: user,
    renderText: (props) => <I18n id="events.confirm_form" />,
    doNotRenderPayload: true,
  },
  set_birthdate_and_address: {
    image: user,
    renderText: (props) => <I18n id="events.set_birthdate_and_address" />,
    payloadProps: [
      {
        key: 'birthdate',
        label: <I18n id="Birthdate" />,
        renderValue: (value) => getDateWithoutTime(value),
      },
      { key: 'address', label: <I18n id="Address" /> },
      { key: 'postCode', label: <I18n id="Post_code" /> },
      { key: 'postPlace', label: <I18n id="Post_place" /> },
    ],
  },
  confirmed_birthdate_and_address: {
    image: user,
    renderText: (props) => <I18n id="events.confirmed_birthdate_and_address" />,
    payloadProps: [
      {
        key: 'birthdate',
        label: <I18n id="Birthdate" />,
        renderValue: (value) => getDateWithoutTime(value),
      },
      { key: 'address', label: <I18n id="Address" /> },
      { key: 'postCode', label: <I18n id="Post_code" /> },
      { key: 'postPlace', label: <I18n id="Post_place" /> },
    ],
  },
  set_employment_end_date: {
    image: user,
    renderText: (props) => <I18n id="events.set_employment_end_date" />,
    payloadProps: [
      {
        key: 'employmentEndDate',
        label: <I18n id="Employment_end_date" />,
        renderValue: (value) => value && prettyDate(value),
      },
    ],
  },

  set_address: {
    image: user,
    renderText: (props) => <I18n id="events.set_address" />,
    payloadProps: [
      {
        key: 'address',
        label: <I18n id="Address" />,
        renderValue: (value) => value
      },
      { key: 'postCode', label: <I18n id="Post_code" /> },
      { key: 'postPlace', label: <I18n id="Post_place" /> },
    ],
  },

  set_phone: {
    image: user,
    renderText: (props) => <I18n id="events.set_phone" />,
    payloadProps: [
      {
        key: 'phone',
        label: <I18n id="Phone" />,
        renderValue: (value) => value,
      },
    ],
  },


  automatic_email_reminder: {
    image: mail,
    renderText: (props) => <I18n id="events.automatic_email_reminder" />,
    payloadProps: [],
  },
  manual_email_reminder: {
    image: mail,
    renderText: (props) => <I18n id="events.manual_email_reminder" />,
    payloadProps: [],
  },
  automatic_email_invite: {
    image: mail,
    renderText: (props) => <I18n id="events.automatic_email_invite" />,
    payloadProps: [],
  },
  automatic_email_yearly_reminder: {
    image: mail,
    renderText: (props) => <I18n id="events.automatic_email_yearly_reminder" />,
    payloadProps: [],
  },
  automatic_email_public_data_request: {
    image: mail,
    renderText: (props) => (
      <I18n id="events.automatic_email_public_data_request" />
    ),
    payloadProps: [],
  },
  create_role_application: {
    image: role,
    renderText: (props) => (
      <I18n {...props} id="events.create_role_application" />
    ),
    payloadProps: roleProps,
    group: true,
  },
  create_role: {
    image: role,
    renderText: (props) => <I18n {...props} id="events.create_role" />,
    payloadProps: roleProps,
    group: true,
  },
  update_role: {
    image: role,
    renderText: (props) => <I18n {...props} id="events.update_role" />,
    payloadProps: roleProps,
    group: true,
  },
  remove_role: {
    image: role,
    renderText: (props) => <I18n {...props} id="events.remove_role" />,
    payloadProps: roleProps,
    group: true,
  },
  confirmed_no_user_roles: {
    image: role,
    renderText: (props) => <I18n id="events.confirmed_no_user_roles" />,
    group: true,
  },
  cancelled_confirmed_no_user_roles: {
    image: role,
    renderText: (props) => (
      <I18n id="events.cancelled_confirmed_no_user_roles" />
    ),
    group: true,
  },
  create_ownership_application: {
    image: ownership,
    renderText: (props) => (
      <I18n {...props} id="events.create_ownership_application" />
    ),
    payloadProps: ownershipProps,
    group: true,
  },
  create_ownership: {
    image: ownership,
    renderText: (props) => <I18n {...props} id="events.create_ownership" />,
    payloadProps: ownershipProps,
    group: true,
  },
  update_ownership: {
    image: ownership,
    renderText: (props) => <I18n {...props} id="events.update_ownership" />,
    payloadProps: ownershipProps,
    group: true,
  },
  remove_ownership: {
    image: ownership,
    renderText: (props) => <I18n {...props} id="events.remove_ownership" />,
    payloadProps: ownershipProps,
    group: true,
  },
  confirmed_no_user_ownerships: {
    image: ownership,
    renderText: (props) => <I18n id="events.confirmed_no_user_ownerships" />,
    group: true,
  },
  cancelled_confirmed_no_user_ownerships: {
    image: ownership,
    renderText: (props) => (
      <I18n id="events.cancelled_confirmed_no_user_ownerships" />
    ),
    group: true,
  },
  create_other_connection_application: {
    image: other,
    renderText: (props) => (
      <I18n {...props} id="events.create_other_connection_application" />
    ),
    payloadProps: otherConnectionProps,
    group: true,
  },
  create_other_connection: {
    image: other,
    renderText: (props) => (
      <I18n {...props} id="events.create_other_connection" />
    ),
    payloadProps: otherConnectionProps,
    group: true,
  },
  update_other_connection: {
    image: other,
    renderText: (props) => (
      <I18n {...props} id="events.update_other_connection" />
    ),
    payloadProps: otherConnectionProps,
    group: true,
  },
  remove_other_connection: {
    image: other,
    renderText: (props) => (
      <I18n {...props} id="events.remove_other_connection" />
    ),
    payloadProps: otherConnectionProps,
    group: true,
  },
  confirmed_no_user_other_connections: {
    image: other,
    renderText: (props) => (
      <I18n id="events.confirmed_no_user_other_connections" />
    ),
    group: true,
  },
  cancelled_confirmed_no_user_other_connections: {
    image: other,
    renderText: (props) => (
      <I18n id="events.cancelled_confirmed_no_user_other_connections" />
    ),
    group: true,
  },

  create_relation: {
    image: relation,
    renderText: (props) => <I18n {...props} id="events.create_relation" />,
    payloadProps: relationProps,
    group: true,
  },
  update_relation: {
    image: relation,
    renderText: (props) => <I18n {...props} id="events.update_relation" />,
    payloadProps: relationProps,
    group: true,
  },
  remove_relation: {
    image: relation,
    renderText: (props) => <I18n {...props} id="events.remove_relation" />,
    payloadProps: relationProps,
    group: true,
  },
  confirmed_no_relations: {
    image: relation,
    renderText: (props) => <I18n id="events.confirmed_no_relations" />,
    group: true,
  },
  cancelled_confirmed_no_relations: {
    image: relation,
    renderText: (props) => (
      <I18n id="events.cancelled_confirmed_no_relations" />
    ),
    group: true,
  },

  create_relation_role: {
    image: role,
    renderText: (props) => <I18n {...props} id="events.create_relation_role" />,
    payloadProps: roleProps,
    group: true,
  },
  update_relation_role: {
    image: role,
    renderText: (props) => <I18n {...props} id="events.update_relation_role" />,
    payloadProps: roleProps,
    group: true,
  },
  remove_relation_role: {
    image: role,
    renderText: (props) => <I18n {...props} id="events.remove_relation_role" />,
    payloadProps: roleProps,
    group: true,
  },

  create_relation_ownership: {
    image: ownership,
    renderText: (props) => (
      <I18n {...props} id="events.create_relation_ownership" />
    ),
    payloadProps: ownershipProps,
    group: true,
  },
  update_relation_ownership: {
    image: ownership,
    renderText: (props) => (
      <I18n {...props} id="events.update_relation_ownership" />
    ),
    payloadProps: ownershipProps,
    group: true,
  },
  remove_relation_ownership: {
    image: ownership,
    renderText: (props) => (
      <I18n {...props} id="events.remove_relation_ownership" />
    ),
    payloadProps: ownershipProps,
    group: true,
  },

  create_relation_other_connection: {
    image: other,
    renderText: (props) => (
      <I18n {...props} id="events.create_relation_other_connection" />
    ),
    payloadProps: otherConnectionProps,
    group: true,
  },
  update_relation_other_connection: {
    image: other,
    renderText: (props) => (
      <I18n {...props} id="events.update_relation_other_connection" />
    ),
    payloadProps: otherConnectionProps,
    group: true,
  },
  remove_relation_other_connection: {
    image: other,
    renderText: (props) => (
      <I18n {...props} id="events.remove_relation_other_connection" />
    ),
    payloadProps: otherConnectionProps,
    group: true,
  },
}
