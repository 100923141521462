import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { logOut } from '../redux/actions'
import { getCurrentUser, isLogOutPending } from '../redux/selectors'
import Page from '../components/Page'
import Button from '../components/Button'
import { height } from '../components/Header'
import smileyIcon from '../icons/smiley.svg'
import I18n from '../components/I18n'

const Thanks = ({ logOut, pending, currentUser }) => (
  <Page>
    <Wrapper>
      <Content>
        <img src={smileyIcon} alt="" />
        <p style={{ fontSize: 18 }}>
          <I18n id="Thanks_for_updating" />
        </p>
        <p style={{ fontSize: 26 }}>
          <I18n id="Have_a_nice_day" />
        </p>
        <Buttons>
          <ButtonWrapper>
            <Button as={Link} secondary="true" to={`/users/${currentUser?.id}`}>
              <I18n id="Back_to_profile" />
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button onClick={logOut} pending={pending}>
              <I18n id="Log_out" />
            </Button>
          </ButtonWrapper>
        </Buttons>
      </Content>
    </Wrapper>
  </Page>
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${height});
`

const Content = styled.div`
  margin: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme: { primary } }) => primary};
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
`

const ButtonWrapper = styled.div`
  padding: 1em;
`

const mapStateToProps = (state) => ({
  pending: isLogOutPending(state),
  currentUser: getCurrentUser(state),
})

const mapDispatchToProps = { logOut }

export default connect(mapStateToProps, mapDispatchToProps)(Thanks)
