import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Route, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { isAuthPending, isAuthenticated } from './redux/selectors'
import Page from './components/Page'
import AuthVerify from './pages/AuthVerify'
import InsufficientPermissions from './pages/InsufficientPermissions'
import Login from './pages/Login'
import { createBrowserHistory } from 'history'
import {
  getCurrentUser,
  isCurrentUserFetching,
  hasCompletedOnboarding,
  hasAcceptedCookies,
} from './redux/selectors'
import CookieConsentModal from './components/Cookies'
import {  acceptCookies } from './redux/actions'
import { isContentPending } from './redux/selectors'

const browserHistory = createBrowserHistory()

const ProtectedRoute = (props) => {
  const history = useHistory({ forceRefresh: true })
  const [locationSearch, setLocationSearch] = useState()
  const qs = queryString.parse(locationSearch)

  useEffect(() => {
    setLocationSearch(window.location.search)
    browserHistory.listen((location) => {
      setLocationSearch(location.search)
    })
  }, [])

  if (qs.code || qs.state || qs.error || qs.error_description) {
    return (
      <AuthVerify
        code={qs.code}
        state={qs.state}
        error={qs.error}
        errorDescription={qs.error_description}
      />
    )
  }
  if (props.pending || props.pendingContent) return <Page pending={true} /> 
  if (!props.authenticated) return <Login />
  if (!props.isAllowed) return <InsufficientPermissions />
  if (
    props.path.includes('/users/:userId') &&
    props.computedMatch.params.userId != props.currentUserId &&
    !props.isManager &&
    !props.isAdmin
  ) {
    return <InsufficientPermissions />
  }
  
  if (props.redirectToOnboarding) {
    history.push('/onboarding')
  }

  if (!props.pendingFetching && props.showCookiesDisclamer) {
    return (
      <>
        <Route {...props} />
        <CookieConsentModal onClose={() => props.acceptCookies()} />
      </>
    );
  }

  
  return <Route {...props} />
}



const mapDispatchToProps = {
  acceptCookies,
}

const mapStateToProps = (state, props) => {
  const pendingFetching = isCurrentUserFetching(state)
  const currentUser = getCurrentUser(state)
  const userHasCompletedOnboarding =
    getCurrentUser(state) && hasCompletedOnboarding(state)
  const hasAcceptedCookiesDisclamer = getCurrentUser(state) && hasAcceptedCookies(state)
  const redirectToOnboarding =
    currentUser &&
    !userHasCompletedOnboarding &&
    !props.path.includes('/onboarding')
  const showCookiesDisclamer =
    currentUser &&
    !hasAcceptedCookiesDisclamer &&
    !props.path.includes('/onboarding')

  return {
    pendingFetching: pendingFetching,
    pendingContent: isContentPending(state),
    pending: isAuthPending(state),
    authenticated: isAuthenticated(state),
    redirectToOnboarding: redirectToOnboarding,
    showCookiesDisclamer: showCookiesDisclamer,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute)
