import { useEffect, useReducer, useCallback, useMemo } from 'react'

const toggleActions = {
  TOGGLE: 'TOGGLE',
  TOGGLE_ALL: 'TOGGLE_ALL',
  CLEAR: 'CLEAR',
}

const selectedCasesReducer = (state, action) => {
  switch (action.type) {
    case toggleActions.TOGGLE:
      return {
        ...state,
        [action.caseId]: !state[action.caseId],
      }
    case toggleActions.TOGGLE_ALL:
      return action.caseIds.reduce(
        (acc, caseId) => ({ ...acc, [caseId]: action.toggled }),
        {},
      )
    case toggleActions.CLEAR:
      return {}
    default:
      throw new Error(`Unknown action ${action.type}`)
  }
}

const useToggleCases = (cases = []) => {
  const sortedCaseIds = useMemo(() => {
    return cases.map((u) => u.id).sort()
  }, [cases])
  const [selectedCases, dispatch] = useReducer(selectedCasesReducer, {})
  useEffect(() => {
    dispatch({ type: toggleActions.CLEAR })
  }, [JSON.stringify(sortedCaseIds)])
  const toggleCase = useCallback(
    (caseId) => {
      dispatch({ type: toggleActions.TOGGLE, caseId })
    },
    [dispatch],
  )

  const selectedCaseIds = useMemo(
    () => Object.keys(selectedCases).filter((key) => selectedCases[key]),
    [selectedCases],
  )
  const toggleAllCases = useCallback(() => {
    dispatch({
      type: toggleActions.TOGGLE_ALL,
      caseIds: sortedCaseIds,
      toggled: selectedCaseIds.length === 0,
    })
  }, [dispatch, sortedCaseIds, selectedCaseIds])

  const clearAllCases = useCallback(() => {
    dispatch({
      type: toggleActions.CLEAR,
    })
  }, [dispatch])

  return {
    toggleAllCases,
    selectedCaseIds,
    toggleCase,
    selectedCases,
    clearAllCases
  }
}

export default useToggleCases