import { combineReducers } from 'redux'
export { default as sagas } from './sagas'

export const FETCH_COMPANY = 'FETCH_COMPANY'
export const FETCH_COMPANY_SUCCEEDED = 'FETCH_COMPANY_SUCCEEDED'
export const FETCH_COMPANY_FAILED = 'FETCH_COMPANY_FAILED'

export const FETCH_COMPANY_CONNECTIONS = 'FETCH_COMPANY_CONNECTIONS'
export const FETCH_COMPANY_CONNECTIONS_SUCCEEDED =
  'FETCH_COMPANY_CONNECTIONS_SUCCEEDED'
export const FETCH_COMPANY_CONNECTIONS_FAILED =
  'FETCH_COMPANY_CONNECTIONS_FAILED'

export const FETCH_COMPANIES = 'FETCH_COMPANIES'
export const FETCH_COMPANIES_SUCCEEDED = 'FETCH_COMPANIES_SUCCEEDED'
export const FETCH_COMPANIES_FAILED = 'FETCH_COMPANIES_FAILED'

export const POST_SUPPLIERS = 'POST_SUPPLIERS'
export const POST_SUPPLIERS_SUCCEEDED = 'POST_SUPPLIERS_SUCCEEDED'
export const POST_SUPPLIERS_FAILED = 'POST_SUPPLIERS_FAILED'

export const POST_CUSTOMERS = 'POST_CUSTOMERS'
export const POST_CUSTOMERS_SUCCEEDED = 'POST_CUSTOMERS_SUCCEEDED'
export const POST_CUSTOMERS_FAILED = 'POST_CUSTOMERS_FAILED'

export const FETCH_LATEST_SUPPLIER_UPDATE_LOG =
  'FETCH_LATEST_SUPPLIER_UPDATE_LOG'
export const FETCH_LATEST_SUPPLIER_UPDATE_LOG_SUCCEEDED =
  'FETCH_LATEST_SUPPLIER_UPDATE_LOG_SUCCEEDED'
export const FETCH_LATEST_SUPPLIER_UPDATE_LOG_FAILED =
  'FETCH_LATEST_SUPPLIER_UPDATE_LOG_FAILED'

  export const FETCH_LATEST_CUSTOMER_UPDATE_LOG =
  'FETCH_LATEST_CUSTOMER_UPDATE_LOG'
export const FETCH_LATEST_CUSTOMER_UPDATE_LOG_SUCCEEDED =
  'FETCH_LATEST_CUSTOMER_UPDATE_LOG_SUCCEEDED'
export const FETCH_LATEST_CUSTOMER_UPDATE_LOG_FAILED =
  'FETCH_LATEST_CUSTOMER_UPDATE_LOG_FAILED'

export const fetchCompany = (companyId) => ({
  type: FETCH_COMPANY,
  companyId,
})

export const fetchCompanyConnections = (companyId) => ({
  type: FETCH_COMPANY_CONNECTIONS,
  companyId,
})

export const fetchCompanies = (
  pagination = { query: '', pageIndex: 1, perPage: 20 },
) => ({
  type: FETCH_COMPANIES,
  pagination,
})

export const postSuppliers = (file) => ({
  type: POST_SUPPLIERS,
  file,
})

export const postCustomers = (file) => ({
  type: POST_CUSTOMERS,
  file,
})


export const fetchLatestSupplierUpdateLog = () => ({
  type: FETCH_LATEST_SUPPLIER_UPDATE_LOG,
})

export const fetchLatestCustomerUpdateLog = () => ({
  type: FETCH_LATEST_CUSTOMER_UPDATE_LOG,
})

export const pagination = (state = {}, action) => {
  switch (action.type) {
    case FETCH_COMPANIES_SUCCEEDED:
      return {
        ...action.payload.pagination,
      }
    default:
      return state
  }
}

const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_COMPANIES_SUCCEEDED:
      return {
        ...action.payload.entities?.company,
      }
    case POST_SUPPLIERS_SUCCEEDED:
      return Object.entries(action.payload.entities?.company || {}).reduce(
        (state, [id, company]) => {
          // local mutation for improved performance
          state[id] = {
            ...state[id],
            ...company,
          }
          return state
        },
        state,
      )
    case POST_CUSTOMERS_SUCCEEDED:
      return Object.entries(action.payload.entities?.company || {}).reduce(
        (state, [id, company]) => {
          // local mutation for improved performance
          state[id] = {
            ...state[id],
            ...company,
          }
          return state
        },
        state,
      )

    case FETCH_COMPANY_SUCCEEDED:
    case FETCH_COMPANY_CONNECTIONS_SUCCEEDED:
      return Object.entries(action.payload.entities.company || {}).reduce(
        (state, [id, company]) => ({
          ...state,
          [id]: {
            ...state[id],
            ...company,
            status: 'succeeded',
          },
        }),
        state,
      )

    case FETCH_COMPANY:
      return {
        ...state,
        [action.companyId]: {
          ...(state[action.companyId] || {}),
          status: 'fetching',
        },
      }

    case FETCH_COMPANY_CONNECTIONS:
      return {
        ...state,
        [action.companyId]: {
          ...(state[action.companyId] || {}),
          status: 'fetching',
        },
      }

    case FETCH_COMPANY_FAILED:
      return {
        ...state,
        [action.companyId]: {
          ...(state[action.companyId] || {}),
          status: 'failed',
        },
      }

    case FETCH_COMPANY_CONNECTIONS_FAILED:
      return {
        ...state,
        [action.companyId]: {
          ...(state[action.companyId] || {}),
          status: 'failed',
        },
      }

    default:
      return state
  }
}

export const getCompanies = (state) => Object.values(state.byId)

export const getCompanyConnections = (state, companyId) => state.byId[companyId]

export const getCompany = (state, companyId) => state.byId[companyId]

const companiesStatus = (state = 'not fetched', action) => {
  switch (action.type) {
    case FETCH_COMPANIES:
      return 'fetching'

    case FETCH_COMPANIES_SUCCEEDED:
      return 'succeeded'

    case FETCH_COMPANIES_FAILED:
      return 'failed'

    default:
      return state
  }
}

const postSuppliersStatus = (state = 'not fetched', action) => {
  switch (action.type) {
    case POST_SUPPLIERS:
      return 'fetching'

    case POST_SUPPLIERS_SUCCEEDED:
      return 'succeeded'

    case POST_SUPPLIERS_FAILED:
      return 'failed'

    default:
      return state
  }
}

const postCustomersStatus = (state = 'not fetched', action) => {
  switch (action.type) {
    case POST_CUSTOMERS:
      return 'fetching'

    case POST_CUSTOMERS_SUCCEEDED:
      return 'succeeded'

    case POST_CUSTOMERS_FAILED:
      return 'failed'

    default:
      return state
  }
}


export const shouldFetchCompanies = (state) =>
  !['succeeded', 'failed'].includes(state.companiesStatus)

export const shouldFetchCompanyConnections = (state, companyId) =>
  !['succeeded', 'failed', 'fetching'].includes(
    (state.byId[companyId] || {}).status,
  )

export const shouldFetchCompany = (state, companyId) =>
  !['succeeded', 'failed', 'fetching'].includes(
    (state.byId[companyId] || {}).status,
  )

export const isCompaniesPending = (state) =>
  ['fetching', 'not fetched'].includes(state.companiesStatus)

export const isCompanyConnectionsPending = (state, companyId) =>
  !state.byId[companyId] ||
  !state.byId[companyId].status ||
  'fetching' === state.byId[companyId].status

export const isCompanyPending = (state, companyId) =>
  !state.byId[companyId] ||
  !state.byId[companyId].status ||
  'fetching' === state.byId[companyId].status

export const isPostSuppliersPending = (state) =>
  'fetching' === state.postSuppliersStatus

export const isPostCustomersPending = (state) =>
  'fetching' === state.postCustomersStatus

export const getLatestSupplierUpdateLog = (state) =>
  state?.supplierDataUpdateLog

export const getLatestCustomerUpdateLog = (state) =>
  state?.customerDataUpdateLog

export const getLatestSupplierFileUploadEvent = (state) =>
  state?.supplierFileUploadEvent

export const getLatestCustomerFileUploadEvent = (state) =>
  state?.customerFileUploadEvent

const supplierFileUploadEvent = (state = null, action) => {
  switch (action.type) {
    case POST_SUPPLIERS_SUCCEEDED:
    case FETCH_LATEST_SUPPLIER_UPDATE_LOG_SUCCEEDED:
      return {
        ...state,
        ...action.payload.result?.fileUploadEvent,
      }
    default:
      return state
  }
}

const customerFileUploadEvent = (state = null, action) => {
  switch (action.type) {
    case POST_CUSTOMERS_SUCCEEDED:
    case FETCH_LATEST_CUSTOMER_UPDATE_LOG_SUCCEEDED:
      return {
        ...state,
        ...action.payload.result?.fileUploadEvent,
      }
    default:
      return state
  }
}

const supplierDataUpdateLog = (state = null, action) => {
  switch (action.type) {
    case FETCH_LATEST_SUPPLIER_UPDATE_LOG_SUCCEEDED:
      return {
        ...state,
        ...action.payload.result?.dataUpdateLog,
      }
    default:
      return state
  }
}

const customerDataUpdateLog = (state = null, action) => {
  switch (action.type) {
    case FETCH_LATEST_CUSTOMER_UPDATE_LOG_SUCCEEDED:
      return {
        ...state,
        ...action.payload.result?.dataUpdateLog,
      }
    default:
      return state
  }
}

export const getCompanyPagination = (state) => state.pagination

export default combineReducers({
  byId,
  companiesStatus,
  postSuppliersStatus,
  postCustomersStatus,
  pagination,
  supplierFileUploadEvent,
  customerFileUploadEvent,
  supplierDataUpdateLog,
  customerDataUpdateLog,
})
