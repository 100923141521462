import {
  CHECK_AUTH_STATUS_SUCCEEDED,
  VERIFY_AUTH_CODE_SUCCEEDED,
  LOG_OUT_SUCCEEDED,
} from './actionTypes'

const admin = (state = false, action) => {
  switch (action.type) {
    case CHECK_AUTH_STATUS_SUCCEEDED:
    case VERIFY_AUTH_CODE_SUCCEEDED:
      return action.admin

    case LOG_OUT_SUCCEEDED:
      return false

    default:
      return state
  }
}

export const isAdmin = (state) => state

export default admin
