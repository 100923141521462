import { combineReducers } from 'redux'
import { contentTexts } from './contentTexts'
import pendingContent from './pendingContent'
import language from './language'

export { default as sagas } from './sagas'

// Define action types
export const FETCH_APP_CONTENT = 'FETCH_APP_CONTENT'
export const FETCH_APP_CONTENT_SUCCEEDED = 'FETCH_APP_CONTENT_SUCCEEDED'
export const FETCH_APP_CONTENT_FAILED = 'FETCH_APP_CONTENT_FAILED'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const SET_PUBLIC_TEXTS = 'SET_PUBLIC_TEXTS'


export const setTextsToState = (text) => {
  const action = {
      type: FETCH_APP_CONTENT_SUCCEEDED,
      payload: text,
  };
  return action;
};


export const setPublicTexts = (text) => {
  const action = {
      type: SET_PUBLIC_TEXTS,
      payload: text,
  };
  return action;
};

export const fetchAllTexts = () => {
  return {
    type: FETCH_APP_CONTENT,
  }
}


export const changeLanguage = (language) => ({
  type: SET_LANGUAGE,
  payload: language,
})

// Combine reducers
export default combineReducers({
  contentTexts,
  pendingContent,
  language,
})

export const getContent = (state) => state.contentTexts

export const getOnboardingSlides = (state) => state.contentTexts.onboarding.slides.slideContent

export const isContentPending = (state) => state.pendingContent

export const getLanguage = (state) => state.language

