import React, { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from '../tabs'
import styled from 'styled-components'
import ProfileCaseSection from './ProfileCaseSection'
import {
  USER_OWNERSHIP,
  USER_ROLE,
  USER_OTHER_CONNECTION,
  RELATION,
} from '../../connectionTypes'
import TabContent from '../TabContent'
import { useI18nFunction } from '../I18n'
import { connect } from 'react-redux'
import {
  getUserCasesOfType,
  getUserRelations,
  isAdmin,
  isReminderFetching,
  getUser,
  getCurrentUser,
  isCaseTypeRegistrationCompleted,
} from '../../redux/selectors'
import { unsetActiveConnection } from '../../redux/actions'
import {
  USER_OWNERSHIP_CASE,
  USER_ROLE_CASE,
  USER_OTHER_CONNECTION_CASE,
} from '../../caseTypes'
import ownershipIcon from '../../icons/chartLine.svg'
import roleIcon from '../../icons/role.svg'
import relationIcon from '../../icons/relation.svg'
import otherIcon from '../../icons/other.svg'

const ProfileConnectionTabs = ({
  currentUser,
  userId,
  userOwnershipCases,
  userRoleCases,
  userOtherConnectionCases,
  userRelations,
  ownershipRegistrationCompleted,
  roleRegistrationCompleted,
  relationRegistrationCompleted,
  otherConnectionRegistrationCompleted,
  unsetActiveConnection,
}) => {
  const [tabIndex, setTabIndex] = useState(0)
  const I18nFunction = useI18nFunction();


  let tabs = [
    {
      enabled: true,
      ...I18nFunction( { id: 'connection_form.tabs.ownership', returnRawObject:true } ),
      image: ownershipIcon,
      connectionType: USER_OWNERSHIP,
      caseType: USER_OWNERSHIP_CASE,
      connectionFormTabName: 'ownership',
      items: userOwnershipCases,
      hasApplicationComponents:
        process.env.REACT_APP_APPLICATIONS_ENABLED?.toLowerCase() === 'true',
      connectionKeys: {
        publicData: 'userPublicDataOwnership',
        userRegistered: 'userOwnership',
        applications: 'ownershipApplications',
      },
      completed: ownershipRegistrationCompleted,
    },
    {
      enabled: true,
      ...I18nFunction( { id: 'connection_form.tabs.role', returnRawObject:true } ),
      image: roleIcon,
      connectionType: USER_ROLE,
      caseType: USER_ROLE_CASE,
      connectionFormTabName: 'role',
      items: userRoleCases,
      hasApplicationComponents:
        process.env.REACT_APP_APPLICATIONS_ENABLED?.toLowerCase() === 'true',
      connectionKeys: {
        publicData: 'userPublicDataRole',
        userRegistered: 'userRole',
        applications: 'roleApplications',
      },
      completed: roleRegistrationCompleted,
    },
    {
      enabled:
        process.env.REACT_APP_RELATIONS_ENABLED?.toLowerCase() === 'true',
      ...I18nFunction( { id: 'connection_form.tabs.relation', returnRawObject:true } ),
      image: relationIcon,
      connectionType: RELATION,
      caseType: RELATION,
      connectionFormTabName: 'relation',
      items: userRelations,
      hasApplicationComponents: false,
      completed: relationRegistrationCompleted,
    },
    {
      enabled: true,
      ...I18nFunction( { id: 'connection_form.tabs.other', returnRawObject:true } ),
      image: otherIcon,
      connectionType: USER_OTHER_CONNECTION,
      caseType: USER_OTHER_CONNECTION_CASE,
      connectionFormTabName: 'other',
      items: userOtherConnectionCases,
      hasApplicationComponents: false,
      connectionKeys: {
        publicData: null,
        userRegistered: 'userOtherConnection',
        applications: null,
      },
      completed: otherConnectionRegistrationCompleted,
    },
  ]

  const onTabClicked = (index) => {
    if (index !== tabIndex) {
      unsetActiveConnection()
      setTabIndex(index)
    }
  }
  return (
    <TabsWrapper>
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(tabIndex) => onTabClicked(tabIndex)}
      >
        <TabList>
          {tabs
            .filter((t) => t.enabled === true)
            .map((props, index) => {
              return (
                <Tab key={index}>
                  <TabContent {...props} index={index} />
                </Tab>
              )
            })}
        </TabList>

        {tabs
          .filter((t) => t.enabled === true)
          .map((props, index) => (
            <TabPanel key={index}>
              <ProfileCaseSection
                {...props}
                userId={userId}
                isCurrentUsersProfile={userId === currentUser.id}
              />
            </TabPanel>
          ))}
      </Tabs>
    </TabsWrapper>
  )
}

const TabsWrapper = styled.div`
  margin-bottom: 5em;
`

const mapDispatchToProps = {
  unsetActiveConnection,
}

const mapStateToProps = (state, props) => {
  const user = getUser(state, props.userId) || {}
  const currentUser = getCurrentUser(state)
  return {
    currentUser,
    isAdmin: isAdmin(state),
    confirmedFormOn: user.confirmedFormOn,
    lastLoggedIn: user.lastLoggedIn,
    isReminderFetching: isReminderFetching(state),

    userOwnershipCases: getUserCasesOfType(
      state,
      props.userId,
      USER_OWNERSHIP_CASE,
    ),
    userRoleCases: getUserCasesOfType(state, props.userId, USER_ROLE_CASE),
    userOtherConnectionCases: getUserCasesOfType(
      state,
      props.userId,
      USER_OTHER_CONNECTION_CASE,
    ),
    userRelations: getUserRelations(state, props.userId),

    ownershipRegistrationCompleted: isCaseTypeRegistrationCompleted(
      state,
      props.userId,
      USER_OWNERSHIP_CASE,
      USER_OWNERSHIP,
    ),
    roleRegistrationCompleted: isCaseTypeRegistrationCompleted(
      state,
      props.userId,
      USER_ROLE_CASE,
      USER_ROLE,
    ),
    relationRegistrationCompleted: isCaseTypeRegistrationCompleted(
      state,
      props.userId,
      undefined,
      RELATION,
    ),
    otherConnectionRegistrationCompleted: isCaseTypeRegistrationCompleted(
      state,
      props.userId,
      USER_OTHER_CONNECTION_CASE,
      USER_OTHER_CONNECTION,
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileConnectionTabs)
