import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import uuid from 'uuid/v4'
import I18n from './I18n'
import styled from 'styled-components'
import Button from './Button'
import { getLatestApplication, getApplications } from '../components/caseProps'
import ConnectionForm from './ConnectionForm'
import { getActiveConnection, getCurrentUser } from '../redux/selectors'
import { applyConnection, unsetActiveConnection } from '../redux/actions'
import {
  prefilledApplicationPropertiesForCaseType,
  existingCaseApplicationLockedFields,
  caseTypeToApplicationType,
} from '../components/caseProps'
import { statusColor } from '../CaseStatus'
import CaseApplicationListItem from './CaseApplicationListItem'
import ChangeApplicationStatusModal from './ChangeApplicationStatusModal'
import CaseApplicationList from './CaseApplicationList'
import BoardStatementModal from './BoardStatementModal'
import ManagerStatementModal from './ManagerStatementModal'

const CaseActiveApplicationItem = ({
  Case,
  isAdmin,
  isManager,
  activeConnection,
  addApplication,
  currentUser,
  unsetActiveConnection,
}) => {
  const [hasActiveApplication, setHasActiveApplication] = useState(false)
  const [latestApplication, setLatestApplication] = useState({})
  const [showNewApplicationForm, setShowNewApplicationForm] = useState(false)
  const [showAllApplications, setshowAllApplications] = useState(false)
  const [hasFormerApplications, setHasFormerApplications] = useState(false)


  const [latestDocumentUpload, setLatestDocumentUpload] = useState({})

  const getLatestDocumentUpload = (Case) => {
    if (Array.isArray(Case?.documentUploads) && Case?.documentUploads?.length) {
      const applicationUploads = Case?.documentUploads?.filter(
        (c) =>
          c?.applicationId && c?.documentAction != 'case_document_download_api',
      )
      if (Array.isArray(applicationUploads) && applicationUploads?.length) {
        let latest = applicationUploads?.reduce((prev, current) => {
          return prev?.id > current?.id ? prev : current
        })
        return latest
      }
    }
  }

  useEffect(() => {
    let latest = getLatestDocumentUpload(Case)
    setLatestDocumentUpload(latest)
  }, [Case])

  const showApplicationList = () =>
    setshowAllApplications((showAllApplications) => !showAllApplications)
  const [
    showChangeApplicationStatusModal,
    setShowChangeApplicationStatusModal,
  ] = useState(false)

  const onChangeStatusButtonClicked = () =>
    setShowChangeApplicationStatusModal(true)

  const [showManagerStatementModal, setShowManagerStatementModal] =
    useState(false)

  const [showBoardStatementModal, setShowBoardStatementModal] = useState(false)

  const onAddManagerStatementButtonClicked = () =>
    setShowManagerStatementModal(true)
  const onAddBoardStatementButtonClicked = () =>
    setShowBoardStatementModal(true)

  useEffect(() => {
    const latestApplication = getLatestApplication(Case)
    setLatestApplication(latestApplication)

    setHasActiveApplication(
      latestApplication && ['in_progress'].includes(latestApplication.status),
    )
    setHasFormerApplications(
      getApplications(Case)?.filter((a) =>
        ['approved', 'not_approved'].includes(a.status),
      ).length > 0,
    )
  }, [Case])

  const onNewApplicationClicked = () => {
    if (!activeConnection && showNewApplicationForm) {
      setShowNewApplicationForm((current) => !current)
    }
    setShowNewApplicationForm((current) => !current)
    addApplication(
      Case,
      caseTypeToApplicationType(Case.caseType),
      prefilledApplicationPropertiesForCaseType(Case),
    )
  }

  return (
    <React.Fragment>
      {showChangeApplicationStatusModal && (
        <ChangeApplicationStatusModal
          Case={Case}
          Application={latestApplication}
          onClose={() => setShowChangeApplicationStatusModal(false)}
        />
      )}
      {showNewApplicationForm && activeConnection !== undefined && (
        <ConnectionFormContainer>
          <ConnectionForm
            key={activeConnection.id}
            id={activeConnection.id}
            connectionType={activeConnection.connectionType}
            userId={Case.userId}
            caseId={Case.id}
            lockedFields={existingCaseApplicationLockedFields(Case.caseType)}
            connection={activeConnection}
            unsetActiveConnection={unsetActiveConnection}
          />
        </ConnectionFormContainer>
      )}
      {showManagerStatementModal && (
        <ManagerStatementModal
          caseId={Case.id}
          applicationId={latestApplication.id}
          status={latestApplication.status}
          managerStatement={latestApplication.managerStatement}
          onClose={() => setShowManagerStatementModal(false)}
        ></ManagerStatementModal>
      )}
      {showBoardStatementModal && (
        <BoardStatementModal
          caseId={Case.id}
          applicationId={latestApplication.id}
          status={latestApplication.status}
          boardStatement={latestApplication.boardStatement}
          onClose={() => setShowBoardStatementModal(false)}
          latestDocumentUpload={latestDocumentUpload}
        ></BoardStatementModal>
      )}
      {hasActiveApplication ? (
        <DatalistWrapper>
          <Header>
            <div>
              <ApplicationNumber>
                <I18n id="Application" /> #{latestApplication.id}
              </ApplicationNumber>
            </div>
            <Title>
              <I18n id="activeApplication" />
            </Title>
            <div>
              <ApplicationStatus
                statusColor={statusColor(latestApplication.status)}
              >
                <I18n id={`case_statuses.${latestApplication.status}`} />
              </ApplicationStatus>
            </div>
          </Header>
          <List>
            {hasActiveApplication && (
              <CaseApplicationListItem
                key={latestApplication.id}
                Case={Case}
                application={latestApplication}
                isLatest={true}
                isAdmin={isAdmin}
                activeView={true}
              />
            )}
          </List>
          <CommentButtonWrapper>
            {isAdmin && currentUser.id != Case.userId && (
              <ActionButton
                small
                subtle
                onClick={() => onChangeStatusButtonClicked()}
                className={'status'}
              >
                <I18n id="Edit" /> Status{' '}
              </ActionButton>
            )}
            {(isManager || isAdmin) && currentUser.id != Case.userId && (
              <ActionButton
                small
                subtle
                onClick={() => onAddManagerStatementButtonClicked()}
                className={'statement'}
              >
                <span>+ </span>
                {latestApplication.managerStatement ? (
                  <I18n id="change_manager_statement" />
                ) : (
                  <I18n id="provide_manager_statement" />
                )}
              </ActionButton>
            )}
            {isAdmin && currentUser.id != Case.userId && (
              <ActionButton
                small
                subtle
                onClick={() => onAddBoardStatementButtonClicked()}
                className={'statement'}
              >
                <span>+ </span>
                {latestApplication.boardStatement ? (
                  <I18n id="change_board_statement" />
                ) : (
                  <I18n id="provide_board_statement" />
                )}
              </ActionButton>
            )}
            {hasFormerApplications && (
              <MoreInfo onClick={showApplicationList}>
                {showAllApplications ? (
                  <I18n id="ShowLess" />
                ) : (
                  <I18n id="ShowAllApplications" />
                )}
              </MoreInfo>
            )}
          </CommentButtonWrapper>
        </DatalistWrapper>
      ) : (
        !Case.caseType.includes('userOtherConnectionCase') && (
          <>
            <NewApplicationWrapper>
              {currentUser.id == Case.userId && (
                <ActionButton
                  small
                  subtle
                  inactive={hasActiveApplication || activeConnection}
                  disabled={hasActiveApplication || activeConnection}
                  onClick={() => onNewApplicationClicked()}
                  className={'newApplication'}
                >
                  <span>+ </span>
                  <I18n id="New" /> <I18n id="Application" />
                </ActionButton>
              )}
            </NewApplicationWrapper>
            <DatalistWrapper>
              <Title>
                <I18n id="noActiveApplication" />
              </Title>
              {hasFormerApplications && (
                <MoreInfo onClick={showApplicationList}>
                  {showAllApplications ? (
                    <I18n id="ShowLess" />
                  ) : (
                    <I18n id="ShowAllApplications" />
                  )}
                </MoreInfo>
              )}
            </DatalistWrapper>
          </>
        )
      )}
      {showAllApplications && (
        <DatalistWrapper>
          <CaseApplicationList Case={Case} isAdmin={isAdmin} />
        </DatalistWrapper>
      )}
    </React.Fragment>
  )
}

const NewApplicationWrapper = styled.div`
  display: flex;
  padding-top: 1em;
  flex-direction: column;
  justify-content: space-between;
`

const ActionButton = styled(Button)`
  color: ${(props) => (props.inactive ? 'grey' : '')};
  font-weight: bold;
  &.newApplication {
    padding: 1em;
    align-self: flex-end;
  }
  &.statement {
    padding: 1em 1em 0.5em 0;
  }
  &.status {
    padding-right: 1em;
    padding-bottom: 0.5em;
  }
`
const DatalistWrapper = styled.div`
  margin-top: 1em;
  overflow-x: auto;
  word-break: break-word;
  border-radius: 10px;
  padding: 1.5em;
  background-color: ${({ theme: { primaryBackground } }) => primaryBackground};
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
`
const Title = styled.div`
  font-size: ${(props) => props.theme.largeText};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
  text-align: center;
`

const ApplicationNumber = styled.div`
  margin-left: 1.5em;
`

const ApplicationStatus = styled.div`
  margin-right: 1.5em;
  border-bottom: ${({ statusColor }) => `5px solid ${statusColor}`};
`
const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  & > li:nth-child(2n) {
    background-color: ${({ theme: { pageBackground } }) => pageBackground};
  }

  & > li:not(:last-child) {
  }
`
const ConnectionFormContainer = styled.div`
  margin: 0.5em;
  border-radius: 10px;
  box-shadow: ${({ theme: { primary } }) => primary} 0px 1px 2px 0px;
`
const CommentButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const MoreInfo = styled.div`
  color: ${({ theme }) => theme.secondary};
  margin: 0;
  padding-top: 1em;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  &:disabled {
    cursor: initial;
  }
`

const mapStateToProps = (state, props) => {
  return {
    activeConnection: getActiveConnection(state),
    currentUser: getCurrentUser(state),
  }
}
const mapDispatchToProps = (dispatch) => ({
  unsetActiveConnection: () => dispatch(unsetActiveConnection()),
  addApplication: (Case, connectionType, prefilledProperties) =>
    dispatch(
      applyConnection({
        id: uuid(),
        userId: Case.userId,
        connectionType,
        prefilledProperties,
      }),
    ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CaseActiveApplicationItem)
