import uuid from 'uuid/v4'

export const getActiveConnectionDataFromClickedListItem = (Case, relation) => {
    let connection = undefined
  
    if (relation) return {...relation}
  
    if (Case.userOwnership) connection = {...Case.userOwnership}
    else if (Case.userRole) connection = {...Case.userRole}
    else if (Case.userOtherConnection) connection = {...Case.userOtherConnection}
    else if (Case.relationOwnership) connection = {...Case.relationOwnership}
    else if (Case.relationRole) connection = {...Case.relationRole}
    else if (Case.relationOtherConnection) connection = {...Case.relationOtherConnection}
  
    else if (Case.userPublicDataOwnership) connection = {...Case.userPublicDataOwnership, id: uuid()}
    else if (Case.userPublicDataRole) connection = {...Case.userPublicDataRole, id: uuid()}
    else if (Case.relationPublicDataOwnership) connection = {...Case.relationPublicDataOwnership, id: uuid()}
    else if (Case.relationPublicDataRole) connection = {...Case.relationPublicDataRole, id: uuid()}
  
    return {
      ...connection,
      country: connection?.country || 'NO',
      isCompanyRegistered: connection?.isCompanyRegistered || 'yes',
    }
  }