import {
  ADD_CONNECTION,
  REMOVE_CONNECTION_SUCCEEDED,
  CREATE_CONNECTION_SUCCEEDED,
  UPDATE_CONNECTION_SUCCEEDED,
  APPLY_CONNECTION,
  CREATE_CONNECTION_APPLICATION_SUCCEEDED,
  CHANGE_CONNECTION,
  CREATE_CONNECTION,
  UPDATE_CONNECTION,
  REMOVE_CONNECTION,
} from '../connections'
import {
  REMOVE_RELATION,
  REMOVE_RELATION_SUCCEEDED,
  CREATE_RELATION_SUCCEEDED,
  UPDATE_RELATION,
  UPDATE_RELATION_SUCCEEDED,
  CREATE_RELATION,
} from '../relations'
import {
  USER_ROLE,
  USER_OWNERSHIP,
  USER_OTHER_CONNECTION,
  RELATION_ROLE,
  RELATION_OWNERSHIP,
} from '../../connectionTypes'

const ROUTER_LOCATION_CHANGE = '@@router/LOCATION_CHANGE'

export const SET_ACTIVE_CONNECTION = 'SET_ACTIVE_CONNECTION'
export const UNSET_ACTIVE_CONNECTION = 'UNSET_ACTIVE_CONNECTION'

export const SET_CONNECTIONS_FORM_TAB_INDEX = 'SET_CONNECTIONS_FORM_TAB_INDEX'

export const setActiveConnection = ({
  id,
  caseId,
  relationId,
  connectionType,
  connection,
}) => ({
  type: SET_ACTIVE_CONNECTION,
  id,
  caseId,
  relationId,
  connectionType,
  connection,
})

export const unsetActiveConnection = () => ({
  type: UNSET_ACTIVE_CONNECTION,
})

export const isPending = (state) => state.isPending

export const getActiveConnection = (state) =>
  state.activeConnection
    ? {
        ...state.activeConnection,
        ...((state.activeConnection || {}).updates || {}),
      }
    : undefined

export const getActiveConnectionHasUpdates = (state) => {
  return Object.keys(getActiveConnection(state)?.updates || {}).some((key) => {
    const connection = state.activeConnection
    return String(connection[key]) !== String(connection.updates[key])
  })
}

export const hasUnsavedChanges = (state) => getActiveConnectionHasUpdates(state)

export default (
  state = {
    activeConnection: undefined,
  },
  action,
) => {
  switch (action.type) {
    case ADD_CONNECTION:
      return {
        ...state,
        activeConnection: {
          id: action.id,
          caseId: action.caseId,
          relationId: action.relationId,
          connectionType: action.connectionType,
          ...([
            USER_ROLE,
            USER_OWNERSHIP,
            RELATION_ROLE,
            RELATION_OWNERSHIP,
          ].includes(action.connectionType)
            ? {
                country: 'NO',
                isCompanyRegistered: 'yes',
                isApplication: false,
              }
            : {}),
        },
      }
    case APPLY_CONNECTION:
      return {
        ...state,
        activeConnection: {
          id: action.id,
          caseId: action.caseId,
          relationId: action.relationId,
          connectionType: action.connectionType,
          ...([USER_ROLE, USER_OWNERSHIP, USER_OTHER_CONNECTION].includes(
            action.connectionType,
          )
            ? {
                country: 'NO',
                isCompanyRegistered: 'yes',
                isApplication: true,
                ...action.prefilledProperties,
              }
            : {}),
        },
      }
    case CHANGE_CONNECTION:
      return {
        ...state,
        activeConnection: {
          ...state.activeConnection,
          updates: {
            ...((state.activeConnection || {}).updates || {}),
            ...action.props,
          },
        },
        isPending: false,
      }
    case SET_ACTIVE_CONNECTION:
      return {
        ...state,
        activeConnection: {
          ...action.connection,
          id: action.id,
          caseId: action.caseId,
          relationId: action.relationId,
          connectionType: action.connectionType,
        },
        isPending: false,
      }

    case CREATE_CONNECTION:
    case CREATE_RELATION:
    case UPDATE_CONNECTION:
    case UPDATE_RELATION:
    case REMOVE_RELATION:
    case REMOVE_CONNECTION:
      return {
        ...state,
        isPending: true,
      }

    case UNSET_ACTIVE_CONNECTION:
    case REMOVE_CONNECTION_SUCCEEDED:
    case REMOVE_RELATION_SUCCEEDED:
    case ROUTER_LOCATION_CHANGE:
      return {
        ...state,
        activeConnection: undefined,
        isPending: false,
      }

    case UPDATE_RELATION_SUCCEEDED:
    case CREATE_RELATION_SUCCEEDED: {
      return {
        ...state,
        activeConnection: {
          ...state.activeConnection,
          ...Object.values(action.payload.entities.relation)[0],
          updates: {},
        },
        isPending: false,
      }
    }

    case UPDATE_CONNECTION_SUCCEEDED:
    case CREATE_CONNECTION_SUCCEEDED: {
      const connection = updateActiveConnectionFromReturnedCase(
        Object.values(action.payload.entities.case)[0],
      )
      return {
        ...state,
        activeConnection: {
          ...state.activeConnection,
          ...connection,
          updates: {},
        },
        isPending: false,
      }
    }
    case CREATE_CONNECTION_APPLICATION_SUCCEEDED: {
      const connection = updateActiveConnectionFromReturnedCase(
        Object.values(action.payload.entities.case)[0],
      )
      return {
        ...state,
        activeConnection: {
          ...state.activeConnection,
          ...connection,
          updates: {},
          isApplication: true,
        },
        isPending: false,
      }
    }

    case SET_CONNECTIONS_FORM_TAB_INDEX:
      return {
        ...state,
      }
    default:
      return state
  }
}

const updateActiveConnectionFromReturnedCase = (Case) => {
  if (Case.userOwnership) return { ...Case.userOwnership }
  if (Case.userRole) return { ...Case.userRole }
  if (Case.userOtherConnection) return { ...Case.userOtherConnection }
  if (Case.relationOwnership) return { ...Case.relationOwnership }
  if (Case.relationRole) return { ...Case.relationRole }
  if (Case.relationOtherConnection) return { ...Case.relationOtherConnection }

  if (Case.ownershipApplications?.length > 0)
    return { ...Case.ownershipApplications[0] }
  if (Case.roleApplications?.length > 0) return { ...Case.roleApplications[0] }

  return undefined
}
