import React from 'react'
import checkmarkIcon from '../icons/checkmark_circled_filled.svg'
import attentionIcon from '../icons/circled_alert_icon.svg'
import styled from 'styled-components'
import { useI18nFunction } from './I18n'

const TabContent = ({ title, completed, items, image }) => {
  const I18nFunction = useI18nFunction();
  return (
    <>
      <Logo src={image} alt="" />

      <span>
        {title} ({items.length})
      </span>

      <img
        src={completed ? checkmarkIcon : attentionIcon}
        style={{ height: 20 }}
        title={`${I18nFunction({ id:'Registration' })} ${completed ? '' : I18nFunction( { id:'not' } )} ${I18nFunction( { id:'completed' })}`}

      />
    </>
  )
}

const Logo = styled.img`
  margin: 4px;
  width: 32px;
  height: 32px;
`

export default TabContent
