import React, { useContext, useCallback } from 'react'
import lodash from 'lodash'
import deepdash from 'deepdash'
import countries from './form/CountrySelector/countries.json'
import renderUtils from '../renderUtils/I18nRenderUtils'
import { AssembleContent } from '../renderUtils/htmlRenderUtils'
import { getCurrentUser } from '../redux/selectors'
import { connect } from 'react-redux'
import { getContent, getLanguage } from '../redux/selectors'
import { LanguageContext } from './LanguageProvider'


const _ = deepdash(lodash)

const isObjectAndhasChildren = (obj) =>
  obj && typeof obj === 'object' && Object.keys(obj).length > 0



const I18n = ({texts, language, I18nFunction, ...props}) => {
  // Immediately check for country codes to shortcut if that's the intent.
  if (props.country) {
    const countryCodes = countries[language] || countries.no;
    return countryCodes[props.country] || null;
  }
  
  // Attempt to find the app content with path `props.id`
  const content = _.get(texts, props.id, null);

  if (!content && content !== '') {
    console.error('Content NOT found!\nprops.id: ', props.id);
    return null; // Or some fallback UI
  }

  if (typeof content === 'string') {
    return content;
  }

  // Check if the content is an array or raw data is explicitly requested.
  if (Array.isArray(content) || props.returnRawObject === true) {
    return content;
  }

  // If content is an object with children, attempt to process it as a component or HTML.
  if (isObjectAndhasChildren(content)) {
    const Component = _.get(renderUtils, props.id, null); // Attempt to find the corresponding function with the same path `props.id`
    if (Component) {
      return (
        <Component
          {...props}
          HABREG_CLIENT_NAME={texts.client_organization_name}
          functionData={content}
          I18nFunction={I18nFunction} // Pass I18n as I18nFunction
        />
      );
    } else { // If no function is found, then the object in content is assumed data for HTML code. The code is assembled to HTML with <AssembleContent />
      return <AssembleContent data={content} />;
    }
  }
  // If it reaches this point, it's not a string, blob, or object with children, so return the content directly.
  return content;
};



const I18nFunctionTemplate = ({texts, language, I18nFunction, ...props} ) => {
  // Immediately check for country codes to shortcut if that's the intent.
  if (props.country) {
    const countryCodes = countries[language] || countries.no;
    return countryCodes[props.country] || null;
  }

  // If props.id is not provided, return the whole texts object
  if (!props.id && props.returnRawObject=== true) {
    return texts;
  }
  
  // Attempt to find the app content with path `props.id`
  const content = _.get(texts, props.id, null);

  if (!content && content !== '') {
    console.error('Content NOT found!\nprops.id: ', props.id);
    return null; // Or some fallback UI
  }

  if (typeof content === 'string') {
    return content;
  }

  // Check if the content is an array or raw data is explicitly requested.
  if (Array.isArray(content) || props.returnRawObject === true) {
    return content;
  }

  // If content is an object with children, attempt to process it as a component or HTML.
  if (isObjectAndhasChildren(content)) {
    const Component = _.get(renderUtils, props.id, null); // Attempt to find the corresponding function with the same path `props.id`
    if (Component) {
      return Component({
        ...props,
        HABREG_CLIENT_NAME: texts.client_organization_name,
        functionData: content,
        I18nFunction,
      })
    } else { // If no function is found, then the object in content is assumed data for HTML code. The code is assembled to HTML with <AssembleContent />
      return content;
    }
  }
  // If it reaches this point, it's not a string, blob, or object with children, so return the content directly.
  return content;
};



export const useI18nFunction = () => {
  const { texts, language } = useContext(LanguageContext);

  const I18nFunction = useCallback(
    (props) => I18nFunctionTemplate({ texts, language, I18nFunction, ...props }),
    [texts, language] // Dependencies: only recreate if texts or language change
  );

  return I18nFunction;
};

const mapStateToProps = (state) => {
  const texts = getContent(state);
  const language = getLanguage(state);

  const I18nFunction = (translationProps) => I18nFunctionTemplate({ texts, language, I18nFunction, ...translationProps });

  return {
    currentUser: getCurrentUser(state),
    texts: texts,
    language: language,
    I18nFunction: I18nFunction
  };
};

export default connect(mapStateToProps)(I18n);
