import React from 'react'
import styled from 'styled-components'
import ProffConnectionListItem from './ProffConnectionListItem'

const ProffConnectionList = ({ connections }) => {
  return (
    <Scrollbox>
      <List>
        {connections?.map((Connection, index) => (
          <ProffConnectionListItem
            key={index + Connection.organizationNumber}
            Connection={Connection}
          />
        ))}
      </List>
    </Scrollbox>
  )
}

const Scrollbox = styled.div`
  margin: 0;
  border-radius: 5px;

  position: relative;
  left: -50px;

  padding: 0em 1em;
  max-height: 170px;
  overflow: auto;
  --scrollbarBG: rgba(0, 0, 0, 0.1);
  --thumbBG: #90a4ae;

  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
`

const List = styled.ul`
  list-style: none;

  & > :not(:last-child) {
    margin-bottom: 0.5em;
  }
`
export default ProffConnectionList
