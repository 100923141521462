import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import auth from './auth'
import events from './events'
import users from './users'
import currentUser from './currentUser'
import suggestions from './suggestions'
import connectionsForm from './connectionsForm'
import proffSelector from './proffSelector'
import companies from './companies'
import errors from './errors'
import reminder from './reminder'
import cases from './cases'
import applications from './applications'
import appSettings from './appSettings'
import appContent from './appContent'
import relations from './relations'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    events,
    users,
    relations,
    connectionsForm,
    proffSelector,
    suggestions,
    currentUser,
    companies,
    errors,
    reminder,
    cases,
    applications,
    appSettings,
    appContent,
  })