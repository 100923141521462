import React from 'react'
import OwnershipForm from './OwnershipForm'
import RoleForm from './RoleForm'
import RelationForm from './RelationForm'
import OtherConnectionForm from './OtherConnectionForm'
import {
  USER_ROLE,
  PUBLIC_DATA_ROLE,
  USER_OWNERSHIP,
  PUBLIC_DATA_OWNERSHIP,
  USER_OTHER_CONNECTION,
  RELATION,
  RELATION_ROLE,
  RELATION_OWNERSHIP,
  RELATION_OTHER_CONNECTION,
  RELATION_PUBLIC_DATA_OWNERSHIP,
  RELATION_PUBLIC_DATA_ROLE,
} from '../../connectionTypes'

const ConnectionForm = (props) => {
  switch (props.connectionType) {
    case USER_OWNERSHIP:
    case RELATION_OWNERSHIP:
    case PUBLIC_DATA_OWNERSHIP:
    case RELATION_PUBLIC_DATA_OWNERSHIP:
      return <OwnershipForm {...props} />
    case USER_ROLE:
    case RELATION_ROLE:
    case PUBLIC_DATA_ROLE:
    case RELATION_PUBLIC_DATA_ROLE:
      return <RoleForm {...props} />
    case USER_OTHER_CONNECTION:
    case RELATION_OTHER_CONNECTION:
      return <OtherConnectionForm {...props} />
    case RELATION:
      return <RelationForm {...props} />
    default:
      throw new Error('Unknown connection type')
  }
}

export default ConnectionForm
