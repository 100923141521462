import React from 'react'
import styled from 'styled-components'
import BackButton from '../components/BackButton'
import userIcon from '../icons/user.svg'
import companyIcon from '../icons/company.svg'
import flagIcon from '../icons/red_flag.svg'
import I18n, { useI18nFunction } from './I18n'
import {
  USER_OWNERSHIP_CASE,
  RELATION_OWNERSHIP_CASE,
  USER_ROLE_CASE,
  RELATION_ROLE_CASE,
  USER_OTHER_CONNECTION_CASE,
  RELATION_OTHER_CONNECTION_CASE,
} from '../caseTypes'
import { StatusBadge } from '../CaseStatus'
import {
  getOwnershipStockCount,
  getOwnershipShareType,
  getOwnershipStakePercentage,
  getOtherConnectionDescription,
  getRoleTitle,
} from './caseProps'

const CaseHeader = ({ user, relation, Case, company }) => {
  
  const I18nFunction = useI18nFunction()

  return (
    <OuterWrapper>
        <BackButton 
          subtle 
          text={I18nFunction( { id:"Back" } )}
        />
      <Wrapper>
        <SummaryWrapper>
          <Top>
            <CaseNumber>
              <I18n id="Case" /> #{Case.id}
            </CaseNumber>
          </Top>

          <CaseSummary>
            <HeaderColumn>
              <Icon src={userIcon} />
              <Person
                relation={relation.id ? relation : undefined}
                user={user}
                connectionDescription={connectionDescription(Case, I18nFunction)}
                companyName={
                  company?.organizationName || Case?.organizationName || '-'
                }
                positionOfInfluence={user.positionOfInfluence}
                isBoardMember={user.isBoardMember}
              />
            </HeaderColumn>
            <HeaderColumn>
              <Icon src={companyIcon} />
              {company && (
                <InCompany
                  Case={Case}
                  company={company}
                  companyName={
                    company.organizationName || Case.organizationName || '-'
                  }
                />
              )}
              {!company && Case.organizationName && (
                <HeaderItemTitle>
                  {Case.organizationName || '-'}
                </HeaderItemTitle>
              )}
            </HeaderColumn>
            <HeaderColumn>
              <StatusBadge status={Case.status} large={true} />
            </HeaderColumn>
          </CaseSummary>
        </SummaryWrapper>
      </Wrapper>
    </OuterWrapper>
  )
}

export default CaseHeader

const connectionDescription = (Case, I18nFunction) => {
  switch (Case.caseType) {
    case USER_OWNERSHIP_CASE:
    case RELATION_OWNERSHIP_CASE:
      return `${I18nFunction({
        id: 'connection_list_item.ownership_description',
        stockCount: getOwnershipStockCount(Case),
        shareType: getOwnershipShareType(Case),
        stakePercentage: getOwnershipStakePercentage(Case),
      })} ${I18nFunction({ id: 'in' })} `
    case USER_ROLE_CASE:
    case RELATION_ROLE_CASE:
      return `${I18nFunction({ id: 'is' })} ${getRoleTitle(
        Case,
      )} ${I18nFunction({ id: 'in' })} `
    case USER_OTHER_CONNECTION_CASE:
    case RELATION_OTHER_CONNECTION_CASE:
      return `${I18nFunction({ id: 'is' })} ${getOtherConnectionDescription(
        Case,
      )} ${I18nFunction({ id: 'in' })}`
    default:
      return ''
  }
}

const Top = styled.div`
  display: flex;
  justify-content: space-between;
`

const OuterWrapper = styled.div`
  color: black;
  padding: 0.5em;
  background-color: ${(props) => props.theme.secondaryBackground};
`

const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
`

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.primaryBackground};
  display: flex;
  flex-direction: column;
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
  margin: 0 auto;
  box-sizing: border-box;
  background: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 20px;
`

const CaseNumber = styled.h2`
  margin: 0 0;
  align-self: flex-start;
`

const CaseSummary = styled.div`
  padding: 0.5em;
  display: flex;
  justify-content: center;
  align-items: stretch;

  & > * {
    margin: 0 2em;
  }
  & > :nth-child(1) {
    display: flex;
    border-right: solid 2px #0d3688;
    padding-left: 5em;
    padding-right: 4em;
  }
  & > :nth-child(2) {
    display: flex;
  }
  & > :nth-child(3) {
    border-left: solid 2px #0d3688;
    padding-right: 5em;
    padding-left: 4em;
    text-align: center;
  }
`

const HeaderColumn = styled.div``

const Icon = styled.img`
  height: 35px;
  margin-right: 1em;
`

const Person = ({
  user,
  relation,
  connectionDescription,
  companyName,
  positionOfInfluence,
  isBoardMember,
}) => {
  return (
    <ColumnHeaderItemWrapper>
      <HeaderItemTitle>
        {relation && (
          <>
            <HeaderItemRelationTitle>
              {user.givenName + ' ' + user.surName + `'s `}
            </HeaderItemRelationTitle>
            <HeaderItemRelationRelation>
              <I18n id="connection_form.headings.relation" />:
            </HeaderItemRelationRelation>
          </>
        )}
        {relation ? relation.firstName + ' ' + relation.lastName : user.name}
      </HeaderItemTitle>
      <HeaderOwnershipDetails className={'details'}>
        {connectionDescription}
        {companyName}
      </HeaderOwnershipDetails>
      <HeaderOwnershipDetails>
        {positionOfInfluence &&
          (relation ? (
            <Warning>
              <WarningIcon src={flagIcon} />
              {user.name} <I18n id="has" /> <I18n id="a" />{' '}
              <I18n id="positionOfInfluence" />
            </Warning>
          ) : (
            <Warning>
              <WarningIcon src={flagIcon} />
              <I18n id="has" /> <I18n id="positionOfInfluence" />
            </Warning>
          ))}
        {isBoardMember &&
          (relation ? (
            <Warning>
              <WarningIcon src={flagIcon} />
              <I18n id="relation_owner" />
              <I18n id="has" />
              <I18n id="a" /> <I18n id="boardRole" />
            </Warning>
          ) : (
            <Warning>
              <WarningIcon src={flagIcon} />
              <I18n id="has" /> <I18n id="boardRole" />
            </Warning>
          ))}
      </HeaderOwnershipDetails>
    </ColumnHeaderItemWrapper>
  )
}

const InCompany = ({ Case, companyName, company }) => {
  return (
    <ColumnHeaderItemWrapper>
      <HeaderItemTitle>{companyName}</HeaderItemTitle>
      {Case.organizationIsSupplierForUserCompany && (
        <Warning>
          <WarningIcon src={flagIcon} />
          <I18n id="is" /> <I18n id="is_supplier" /> <I18n id="for" />{' '}
          <I18n id="client_organization_name" />
        </Warning>
      )}
      {Case.organizationIsCustomerForUserCompany && (
        <Warning>
          <WarningIcon src={flagIcon} />
          <I18n id="is" /> <I18n id="is_client" /> <I18n id="of" />{' '}
          <I18n id="client_organization_name" />
        </Warning>
      )}
      {Case.userIsBenificalOwnerForCaseCompany && (
        <Warning>
          <WarningIcon src={flagIcon} />
          <I18n id="employee" /> <I18n id="is" />{' '}
          <I18n id="is_benificial_owner" />
        </Warning>
      )}
    </ColumnHeaderItemWrapper>
  )
}

const WarningIcon = styled.img`
  height: 1em;
  width: 1em;
  margin-right: 0.5em;
  filter: invert(42%) sepia(89%) saturate(979%) hue-rotate(0deg)
    brightness(104%) contrast(105%);
`

const Warning = styled.div`
  font-size: ${(props) => props.theme.normalText};
  padding-top: 0.25em;
  font-weight: bold;
`

const ColumnHeaderItemWrapper = styled.div`
  flex-direction: column;
  flex-wrap: wrap;
  align-items: left;
  justify-content: space-around;
`

const HeaderItemTitle = styled.h1`
  margin: 0;
  font-size: 25px;
  font-weight: bold;
  position: relative;
`

const HeaderItemRelationTitle = styled.div`
  position: absolute;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  top: -18px;
  max-width: 185px;
  text-align: left;
  color: ${(props) => props.theme.primary};
  font-size: 15px;
  margin-bottom: 0.5em;
`
const HeaderItemRelationRelation = styled.div`
  position: absolute;
  top: -18px;
  left: 190px;
  max-width: 300px;
  text-align: left;
  color: ${(props) => props.theme.primary};
  font-size: 15px;
  margin-bottom: 0.5em;
`
const HeaderOwnershipDetails = styled.div`
  font-size: ${(props) => props.theme.normalText};
  padding-top: 0.25em;
  &.details {
    font-style: italic;
  }
`
