import React from 'react'
import styled from 'styled-components'
import Page from '../components/Page'
import FaqList from '../components/FaqList'
import I18n, { useI18nFunction } from '../components/I18n'

const Faq = () => {
  const I18nFunction = useI18nFunction();
  const faqs = I18nFunction({ id: 'faq.faqs' });


  return (
    <Page>
      <Wrapper>
        <div>
          <h1>
            <I18n id="faq.header" />
          </h1>
        </div>
        <FaqList faqs={faqs} />
      </Wrapper>
    </Page>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0 5%;
  padding-bottom: 5%;
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
`

export default Faq
