import React, { useEffect, useCallback, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  getUserList,
  isUserListFetching,
  isReminderFetching,
} from '../redux/selectors'
import { fetchUsers, sendReminder } from '../redux/actions'
import Page from '../components/Page'
import Button from '../components/Button'
import UserList from '../components/UserList'
import I18n, { useI18nFunction } from '../components/I18n'

const ReminderConfirm = ({
  userIds,
  users,
  fetchUsers,
  pending,
  sendReminder,
  isReminderFetching,
}) => {
  const I18nFunction = useI18nFunction();
  const [infoText, setInfoText] = useState(I18nFunction({ id: 'default_email_text' }));
  useEffect(() => {
    !users && fetchUsers()
  }, [users, fetchUsers])
  const sendReminders = useCallback(() => {
    sendReminder(userIds, infoText)
  }, [sendReminder, userIds, infoText])
  return (
    <Page pending={pending}>
      <Content>
        <h1>
          <I18n id="Receiver_list" />
        </h1>
        <UserList users={users} />
        <h2>
          <I18n id="Reminder" />
        </h2>
        <Info
          value={infoText}
          onChange={(e) => setInfoText(e.target.value)}
          required
        />
        <Button
          pending={isReminderFetching ? 1 : 0}
          type="submit"
          onClick={sendReminders}
        >
          <I18n id="Send_reminder" />
        </Button>
      </Content>
    </Page>
  )
}

const Info = styled.textarea`
  display: block;
  width: 100%;
  min-height: 20em;
  border: none;
  margin: 2rem auto;
  padding: 2rem;
  background: #fff;
`

const Content = styled.form`
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
  margin: 0 auto;
`

const mapStateToProps = (state, props) => {
  const users = getUserList(state)
  return {
    users: users
      ? users.filter((user) => props.userIds.includes(user.id))
      : undefined,
    pending: isUserListFetching(state),
    isReminderFetching: isReminderFetching(state),
  }
}

const mapDispatchToProps = { fetchUsers, sendReminder }

export default connect(mapStateToProps, mapDispatchToProps)(ReminderConfirm)
