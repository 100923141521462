import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { setActiveConnection, unsetActiveConnection } from '../../redux/actions'
import checkmarkIcon from '../../icons/checkmark_circled_filled.svg'
import attentionIcon from '../../icons/circled_alert_icon.svg'
import { ReactComponent as PublicInfoIcon } from '../../icons/building-columns-solid.svg'
import I18n from '../I18n'
import { StatusBadge } from '../../CaseStatus'
import { Link } from 'react-router-dom'
import { ReactComponent as DraftIcon } from '../../icons/file-pen-solid.svg'
import { getActiveConnectionDataFromClickedListItem } from '../ConnectionForm/utils'

const ListItem = ({
  itemIsApplication,
  draft,
  Case,
  checked,
  image,
  title,
  description,
  small,
  connectionType,
  active,
  setActiveConnection,
  unsetActiveConnection,
  from,
  isCurrentUsersProfile,
  relation,
}) => {
  let disabledInteraction = false
  if (!isCurrentUsersProfile || itemIsApplication) disabledInteraction = true

  const onClick = (e) => {
    const activeConnectionData = getActiveConnectionDataFromClickedListItem(
      Case,
      relation,
    )
    if (!disabledInteraction) {
      unsetActiveConnection()
      active
        ? unsetActiveConnection()
        : setActiveConnection({
            id: activeConnectionData.id,
            caseId: relation ? undefined : Case?.id,
            relationId: Case?.relationId,
            connectionType,
            connection: activeConnectionData,
          })
    }
  }

  const noPropagation = (e) => e.stopPropagation()
  if (draft) {
    return <Draft onClick={onClick} />
  }
  return (
    <Wrapper
      className={[
        active && 'active',
        !checked && 'attention',
        disabledInteraction && 'disabledInteraction',
      ]}
      onClick={onClick}
    >
      {(Case?.userPublicDataRole || Case?.userPublicDataOwnership) && (
        <PublicInfoIcon
          style={{
            position: 'absolute',
            stroke: '#ccc',
            fill: '#ccc',
            width: '1em',
            height: '1em',
            top: '0.25em',
            left: '1.5em',
          }}
          title={<I18n id='case_details.public' /> }
        />
      )}
      <CheckedOrAttentionMarker
        src={checked ? checkmarkIcon : attentionIcon}
        title={
          checked ? (
            <I18n id="Confirmed" />
          ) : (
            <I18n id="Waiting_for_confirmation" />
          )
        }
      />
      {image && <Icon src={image} alt="" />}
      <Content>
        <Title small={small} title={title}>
          {title}
        </Title>
        <Subtitle>
          <Description small={small} description={description}>
            {description}
          </Description>
          <From>
            {from === undefined ? (
              ''
            ) : (
              <>
                <I18n id="Updated" /> {from}
              </>
            )}
          </From>
        </Subtitle>
      </Content>
      <RightFlexBox>
        {Case && (
          <StatusWrapper>
            <StatusBadge status={Case?.status} />
            {checked || !isCurrentUsersProfile ? (
              <CaseLink to={`/cases/${Case.id}`} onClick={noPropagation}>
                <LinkText>
                  <I18n id="Go_to_case" />
                </LinkText>
              </CaseLink>
            ) : (
              <CaseLink to={''}>
                <LinkText>
                  <I18n id="Confirm" />
                </LinkText>
              </CaseLink>
            )}
          </StatusWrapper>
        )}
      </RightFlexBox>
    </Wrapper>
  )
}

const CheckedOrAttentionMarker = styled.img`
  position: absolute;
  left: -2.5em;
  top: 50%;
  transform: translate(-0%, -50%);
`

const Draft = ({ onClick }) => {
  const draftIconStyle = {
    display: 'block',
    marginRight: '1.5em',
    marginLeft: '0.5em',
    fill: '#3C91D4',
    width: '2em',
    height: '2em',
    flexShrink: 0,
    alignmentBaseline: 'central',
  }

  return (
    <React.Fragment>
      <Wrapper className={['active', 'attention']} onClick={onClick}>
        <DraftIcon style={draftIconStyle} />
        <Content>
          <Title>
            <I18n id="connection_list_item.new_relationship" />
          </Title>
          <Subtitle>
            <Description>
              <I18n id="connection_list_item.description" />
            </Description>
          </Subtitle>
        </Content>
      </Wrapper>
    </React.Fragment>
  )
}

const RightFlexBox = styled.div`
  display: flex;

  justify-content: flex-end;
`
const StatusWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`
const CaseLink = styled(Link)`
  text-decoration: none;
  padding-left: 1em;
`
const LinkText = styled.div`
  min-width: 70px;
  flex-grow: 0;
  flex-basis: initial;
  white-space: nowrap;
  color: ${({ theme: { secondary } }) => secondary};
  font-size: ${({ theme: { largeText } }) => largeText};
`

const Wrapper = styled.div`
  box-shadow: rgb(219 219 219) 0px 2px 8px;
  border-radius: 5px;
  position: relative;
  background-color: ${({ theme }) => theme.primaryBackground};
  display: flex;
  align-items: center;
  padding: 1em;
  border: solid 2px ${({ theme: { primaryBackground } }) => primaryBackground};
  cursor: pointer;

  &.active {
    border-color: ${({ theme: { primary } }) => primary};
  }
  &.attention {
    box-shadow: rgb(180 180 200) 0px 2px 8px;
  }
  &.disabledInteraction {
    background-color: ${({ theme }) => theme.pageBackground};
  }
`

const Content = styled.div`
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
`

const Title = styled.div`
  font-size: ${({ small, theme: { normalText, largeText } }) =>
    small ? normalText : largeText};
  ${({ title, theme: { secondary } }) => !title && 'color: ' + secondary}
  margin-bottom: 0.2em;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Subtitle = styled.div`
  display: flex;
  justify-content: start;
  overflow: hidden;
`

const Description = styled.span`
  ${({ description, small, theme: { secondary, subtleText } }) =>
    !description ? 'color: ' + secondary : small && 'color: ' + subtleText}
  overflow: hidden;
  text-overflow: ellipsis;
`

const From = styled.div`
  font-size: 0.825em;
  flex-shrink: 0;
  margin-left: 10px;
  align-self: end;
`

const Icon = styled.img`
  margin-right: 1.5em;
  height: 2em;
  width: 2em;
  flex-shrink: 0;
`

const mapStateToProps = (state, props) => {
  return {}
}

const mapDispatchToProps = { setActiveConnection, unsetActiveConnection }

export default connect(mapStateToProps, mapDispatchToProps)(ListItem)
