import crossFetch from 'cross-fetch'
import { camelizeKeys, decamelizeKeys } from 'humps'

const fetch = (url, options) => crossFetch(`/api${url}`, options)

export const post = (url, data) =>
  fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(decamelizeKeys(data)),
  })
    .then((res) => res.json())
    .then((json) => camelizeKeys(json))

export const postFile = (url, file) => {
  let formData = new window.FormData()

  formData.append('file', file)

  return fetch(url, {
    method: 'POST',
    headers: { Accept: 'application/json' },
    body: formData,
  })
    .then((res) => res.json())
    .then((json) => camelizeKeys(json))
}

export const remove = (url) =>
  fetch(url, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((res) => (res.ok ? {} : res.json()))
    .then((json) => camelizeKeys(json))

export const get = (url) =>
  fetch(url, {})
    .then((res) => res.json())
    .then((json) => camelizeKeys(json))

export const getUnCamelized = (url) =>
  fetch(url, {})
    .then((res) => res.json())