import React, { useContext } from 'react'
import { OrganizationInput } from '../form'
import { ConnectionFormContext } from './ConnectionForm'

const ConnectionInput = (props) => {
  const { connection, handleChange, handleBlur, visited, errors } = useContext(
    ConnectionFormContext,
  )
  return (
    <OrganizationInput
      {...props}
      value={connection[props.name]}
      onChange={handleChange}
      onBlur={handleBlur}
      visited={visited[props.name]}
      error={errors[props.name]}
      disabled={props.disabled || connection.fetchedOn || connection.updatedOn}
    />
  )
}

export default ConnectionInput
