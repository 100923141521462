import React, { useState } from 'react'
import ReactTextarea from 'react-textarea-autosize'
import styled from 'styled-components'
import { statusColor } from '../CaseStatus'
import Button from './Button'
import {
  updateBoardStatement,
  updateApplicationBoardStatement,
  postBoardStatement,
  postApplicationBoardStatement,
} from '../redux/actions'
import { isPostBoardStatementPending } from '../redux/selectors'
import { connect } from 'react-redux'
import I18n, { useI18nFunction } from './I18n'
import UploadButton from './UploadButton'

const BoardStatementModal = ({
  caseId,
  applicationId,
  boardStatement,
  latestDocumentUpload,
  status,
  updateBoardStatement,
  updateApplicationBoardStatement,
  postBoardStatement,
  postApplicationBoardStatement,
  isPostBoardStatementPending,
  onClose,
}) => {
  const I18nFunction = useI18nFunction()
  const [statement, setStatement] = useState(boardStatement)
  const [initialStatement] = useState(statement ? statement : '')

  const onSubmitBoardStatement = () => {
    applicationId
      ? updateApplicationBoardStatement(caseId, applicationId, statement)
      : updateBoardStatement(caseId, statement)
    onClose()
  }

  return (
    <Overlay onClick={onClose}>
      <ContentWrapper onClick={(e) => e.stopPropagation()}>
        <Header>
          <CurrentStatusWrapper>
            <CurrentStatusPrefix>
              <I18n id="Case" /> #{caseId}
            </CurrentStatusPrefix>
            <Status statusColor={statusColor(status)}>
              <I18n id={`case_statuses.${status}`} />
            </Status>
          </CurrentStatusWrapper>
        </Header>
        <Body>
          <Title>
            <I18n id="Board_statement" />:
          </Title>
          <SubTitle>
            <I18n id="change_status_disclaimer" />
          </SubTitle>
          <StatementTextArea
            placeholder={statement || I18nFunction( { id:'board_statement_placeholder' })}
            value={statement || ''}
            onChange={(e) => setStatement(e.target.value)}
            required
            minRows={2}
            maxRows={5}
          ></StatementTextArea>
          {latestDocumentUpload?.id && (
            <>
              <Title>
                <I18n id="board_statement_document" />:
                <span> {`(${latestDocumentUpload?.updatedOn})`}</span>
              </Title>
              <SubTitle>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    applicationId
                      ? `${window.location.origin}/api/cases/${caseId}/applications/${applicationId}/download_board_statement`
                      : `${window.location.origin}/api/cases/${caseId}/download_board_statement`
                  }
                >
                  <span>{latestDocumentUpload?.originFilename}</span>
                </a>
              </SubTitle>
            </>
          )}
        </Body>
        <ModalActions>
          <UploadButton
            postAction={
              applicationId ? postApplicationBoardStatement : postBoardStatement
            }
            actionParams={[caseId, applicationId]}
            isPostPending={isPostBoardStatementPending}
            btnLabel={ <I18n id='board_statement_upload'/>}
            fileType={'.pdf'}
          />
          <Button
            inactive={statement ? statement === initialStatement : true}
            disabled={statement ? statement === initialStatement : true}
            onClick={() => onSubmitBoardStatement()}
            small
          >
            <I18n id="Save" />
          </Button>
          <Button subtle onClick={onClose} small>
            <I18n id="Close" />
          </Button>
        </ModalActions>
      </ContentWrapper>
    </Overlay>
  )
}

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
`

const ContentWrapper = styled.div`
  background-color: ${(props) => props.theme.primaryBackground};
  margin: 0 auto;
  min-height: 350px;
  min-width: 450px;
  -moz-box-shadow: 0px 0px 18px 6px #ccc;
  -webkit-box-shadow: 0px 0px 18px 6px #ccc;
  box-shadow: 0px 0px 18px 6px #ccc;
  padding: 2em;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  margin: 0;
  padding-bottom: 12px;
  border-bottom: 2px solid #eee;
`

const CurrentStatusPrefix = styled.h1`
  margin: 0;
`

const CurrentStatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const Status = styled.h1`
  align-self: flex-end;
  margin: 0;
  margin-left: 2em;
  border-bottom: ${({ statusColor }) => `5px solid ${statusColor}`};
`

const Body = styled.div`
  flex-grow: 7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 0 2em;
`

const Title = styled.p`
  font-weight: bold;
`

const SubTitle = styled.p`
  font-style: italic;
`

const StatementTextArea = styled(ReactTextarea)`
  display: block;
  width: 100%;
  resize: none;
  padding: 1em;
  margin: 2em;
  border: none;
  line-height: inherit;
  margin: 1em auto;
`

const ModalActions = styled.div`
  height: 50px;
  display: flex;
  justify-content: flex-end;
  border-top: 2px solid #eee;
  padding-top: 12px;
  & > * {
    margin: 0 1em;
  }
`

const mapStateToProps = (state, props) => ({
  isPostBoardStatementPending: isPostBoardStatementPending(state),
})

const mapDispatchToProps = {
  updateBoardStatement,
  updateApplicationBoardStatement,
  postBoardStatement,
  postApplicationBoardStatement,
}

export default connect(mapStateToProps, mapDispatchToProps)(BoardStatementModal)
