import React, { useState, useEffect } from 'react'
import Router from './Router'
import TooSmallScreen from './pages/TooSmallScreen'

const App = () => {
  const [large, setLarge] = useState(true)

  function handleResize() {
    setLarge(window.matchMedia('(min-width: 1024px)').matches)
  }

  useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return large ? <Router /> : <TooSmallScreen />
}

export default App
