export const CHECK_AUTH_STATUS = 'CHECK_AUTH_STATUS'
export const CHECK_AUTH_STATUS_SUCCEEDED = 'CHECK_AUTH_STATUS_SUCCEEDED'
export const CHECK_AUTH_STATUS_FAILED = 'CHECK_AUTH_STATUS_FAILED'

export const LOG_OUT = 'LOG_OUT'
export const LOG_OUT_SUCCEEDED = 'LOG_OUT_SUCCEEDED'
export const LOG_OUT_FAILED = 'LOG_OUT_FAILED'

export const VERIFY_AUTH_CODE = 'VERIFY_AUTH_CODE'
export const VERIFY_AUTH_CODE_SUCCEEDED = 'VERIFY_AUTH_CODE_SUCCEEDED'
export const VERIFY_AUTH_CODE_FAILED = 'VERIFY_AUTH_CODE_FAILED'
