import React from 'react'
import Input from './Input'
import ConnectionForm from './ConnectionForm'
import * as schema from '../../validation'
import {
  organizationName,
  organizationNumber,
  country,
  fromDate,
  toDate,
  comment,
  isCompanyRegistered,
  postCodeAndPlace,
  monthlyReward,
  monthlyTimeCommitment,
  isClientInfo,
  isSupplierInfo,
  roleType,
  roleTitle,
  isCaseRelevant,
} from './fields'
import I18n, { useI18nFunction } from '../I18n'

const RoleForm = (props) => {
  const I18nFunction = useI18nFunction();

  return (
    <ConnectionForm
      {...props}
      heading={<I18n id="connection_form.headings.role" />}
      schema={schema.role(I18nFunction)}
      fields={fields}
    />
  )
}

export const fields =
  process.env.REACT_APP_SET_RELEVANT_CONNECTION_ENABLED?.toLowerCase() ===
  'true'
    ? [
        isCompanyRegistered(),
        country(),
        organizationName(),
        organizationNumber(),
        isSupplierInfo(),
        isClientInfo(),
        roleType(),
        roleTitle(),
        postCodeAndPlace(),
        monthlyReward(),
        {
          condition: (props) =>
            props.publicDataTitle && props.publicDataTitle !== props.title,
          key: 'publicDataTitle',
          label: <I18n id="connection_form.labels.public_data_title" />,
          Input: () => <Input type="text" name="publicDataTitle" disabled />,
        },
        monthlyTimeCommitment(),
        fromDate(<I18n id="connection_form.labels.position_from" />),
        toDate(<I18n id="connection_form.labels.position_to" />),
        comment(),
        isCaseRelevant(),
      ]
    : [
        isCompanyRegistered(),
        country(),
        organizationName(),
        organizationNumber(),
        isSupplierInfo(),
        isClientInfo(),
        roleType(),
        roleTitle(),
        postCodeAndPlace(),
        monthlyReward(),
        {
          condition: (props) =>
            props.publicDataTitle && props.publicDataTitle !== props.title,
          key: 'publicDataTitle',
          label: <I18n id="connection_form.labels.public_data_title" />,
          Input: () => <Input type="text" name="publicDataTitle" disabled />,
        },
        monthlyTimeCommitment(),
        fromDate(<I18n id="connection_form.labels.position_from" />),
        toDate(<I18n id="connection_form.labels.position_to" />),
        comment(),
      ]

export default RoleForm
