import React, { useContext } from 'react'
import { BirthdateInput as BaseBirthdateInput } from '../form'
import { ConnectionFormContext } from './ConnectionForm'

const BirthdateInput = () => {
  const { connection, handleChange, handleBlur, errors, visited } = useContext(
    ConnectionFormContext,
  )

  return (
    <BaseBirthdateInput
      name="birthdate"
      day={connection.birthdate_day}
      month={connection.birthdate_month}
      year={connection.birthdate_year}
      onChange={handleChange}
      onBlur={handleBlur}
      errors={errors}
      visited={visited}
    />
  )
}

export default BirthdateInput
