import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { getErrorMessages } from '../redux/selectors'
import { closeErrorMessage } from '../redux/actions'
import I18n from './I18n'
import { Link } from 'react-router-dom'

const FlashErrors = ({ className, errorMessages, closeErrorMessage }) => {
  return (
    errorMessages.length > 0 && (
      <List className={className}>
        {errorMessages.map(({ detail, id }) => (
          <FlashError key={id}>
            <Details detail={detail} close={closeErrorMessage} id={id} />
            <CloseButton onClick={() => closeErrorMessage(id)}>
              <I18n id="Close" />
            </CloseButton>
          </FlashError>
        ))}
      </List>
    )
  )
}

const Details = ({ detail, close, id }) => {
  const msg = detail.substring(0, detail.indexOf('$$'))
  const linkData = detail
    .slice(detail.indexOf('$$') + 2, detail.lastIndexOf('$$'))
    .split(':')
  const url = linkData[1]
  const linkText = linkData[0]
  return (
    <div>
      <div>
        {msg || detail}
        {url && (
          <TheLink to={'/' + url} onClick={() => close(id)}>
            <LinkText>{linkText}</LinkText>
          </TheLink>
        )}
      </div>
    </div>
  )
}

const TheLink = styled(Link)`
  text-decoration: none;
  width: 10%;
  display: inline-block;
`

const LinkText = styled.div`
  flex-grow: 0;
  flex-basis: initial;
  white-space: nowrap;
`

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  cursor: pointer;
`

const FlashError = styled.div`
  margin: 0 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.error};
  color: ${(props) => props.theme.inverseText};
  padding: 1em;
  font-size: ${(props) => props.theme.largeText};
`

const List = styled.div`
  margin: 0 auto;
  max-width: ${(props) => props.theme.maxWidth};

  & > :not(:last-child) {
    margin-bottom: 0.5em;
  }
`

const mapStateToProps = (state, props) => ({
  errorMessages: getErrorMessages(state),
})

const mapDispatchToProps = { closeErrorMessage }

export default connect(mapStateToProps, mapDispatchToProps)(FlashErrors)
