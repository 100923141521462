import React from 'react'
import styled from 'styled-components'
import BackButton from '../components/BackButton'
import companyIcon from '../icons/company.svg'
import proffLogo from '../icons/Proff_360x135.png'
import mailIcon from '../icons/mail.svg'
import phoneIcon from '../icons/phone.svg'
import I18n, { useI18nFunction } from './I18n'

const CompanyHeader = ({
  organizationName,
  industryCode,
  organizationNumber,
  foundationDate,
  visitorAddress,
  numberOfEmployees,
  contactPerson,
  email,
  phoneNumber,
  customerRelations,
  supplierRelations,
}) => {
  
  const I18nFunction = useI18nFunction()

  return (
    <OuterWrapper>
      <Wrapper>
      <BackButton 
        subtle
        text={I18nFunction( { id:"company_header.back" } )}
      />
        <Content>
          <Person>
            <Avatar src={companyIcon} alt="" />
            <PersonInfo>
              <h1>{organizationName}</h1>
              <div>{industryCode}</div>

              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://proff.no/selskap/-/-/-/${organizationNumber}`}
              >
                <ProffLogo src={proffLogo} alt="" />
              </a>
            </PersonInfo>
          </Person>

          <Info>
            <div>
              <Label>
                <I18n id="company_header.organization_number" />:{' '}
              </Label>
              <span>{organizationNumber}</span>
            </div>
            <div>
              <Label>
                <I18n id="company_header.established" />:{' '}
              </Label>
              <span>{foundationDate || '-'}</span>
            </div>
            <div>
              <Label>
                <I18n id="company_header.employee_count" />:{' '}
              </Label>
              <span>{numberOfEmployees || '-'}</span>
            </div>
            <div>
              <Label>
                <I18n id="company_header.contact_person" />:{' '}
              </Label>
              <span>{contactPerson || '-'}</span>
            </div>
          </Info>
          <Info>
            <div>
              <Label>
                <I18n id="Address" />:{' '}
              </Label>
              <span>{visitorAddress || '-'}</span>
            </div>
            <Field>
              <img src={mailIcon} alt="" />
              <div>{email || '-'}</div>
            </Field>
            <Field>
              <img src={phoneIcon} alt="" />
              <div>{phoneNumber || '-'}</div>
            </Field>
          </Info>
        </Content>
        <StatusWrapper>
          <Status>
            <I18n
              id="Current_supplier_status"
              supplierRelations={supplierRelations}
            />
          </Status>
          <Status>
            <I18n
              id="Current_client_status"
              customerRelations={customerRelations}
            />
          </Status>
        </StatusWrapper>
      </Wrapper>
    </OuterWrapper>
  )
}

const ProffLogo = styled.img`
  height: 2em;
`

const StatusWrapper = styled.div`
  margin-top: 1em;
  display: flex;
  align-items: stretch;

  & > :not(:last-child) {
    margin-right: 1em;
  }
`

const Status = styled.div`
  padding: 1.25em 2.25em;
  font-size: ${(props) => props.theme.largeText};
  background-color: ${({ theme: { primaryBackground } }) => primaryBackground};
`

const Field = styled.div`
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 0.75em;
  }
`
const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Label = styled.span`
  color: ${({ theme: { subtleText } }) => subtleText};
`

const Avatar = styled.img`
  height: 3em;
`

const PersonInfo = styled.div`
  & > * {
    margin: 0.33em 0;
  }
`

const Person = styled.div`
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 1.5em;
  }
`
const OuterWrapper = styled.div`
  background-color: ${({ theme: { secondaryBackground } }) =>
    secondaryBackground};
  padding: 1.5em 0;
`

const Wrapper = styled.div`
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
  margin: 0 auto;
`

const Content = styled.div`
  background-color: ${({ theme: { primaryBackground } }) => primaryBackground};
  display: flex;

  & > * {
    width: 33%;
    margin: 1.5em 0;
    padding: 0.25em 1.5em;
  }

  & > :not(:first-child) {
    border-left: solid 0.33em ${({ theme: { primary } }) => primary};
  }
`

export default CompanyHeader
