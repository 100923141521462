import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import ReactTextarea from 'react-textarea-autosize'
import Button from '../Button'
import I18n, { useI18nFunction } from '../I18n'
import { connect } from 'react-redux'
import { getCurrentUser } from '../../redux/selectors'

const CaseCommentField = ({
  currentUser,
  Case,
  isCommentOnCasePending,
  commentOnCase,
  isAdmin,
  isManager,
}) => {
  const I18nFunction = useI18nFunction();
  const [comment, setComment] = useState('')
  const [commentType, setcommentType] = useState('all')
  useEffect(() => {
    if (!isCommentOnCasePending) {
      setComment('')
    }
  }, [isCommentOnCasePending])
  return (
    <Form onSubmit={() => commentOnCase(Case.id, comment, commentType)}>
      {(isAdmin || isManager) && currentUser.id != Case.userId && (
        <CommentChoice>
          <Text
            type="button"
            onClick={() => setcommentType('all')}
            className={commentType.includes('all') ? 'selected' : ''}
          >
            <I18n id="All" />
          </Text>
          <Text
            onClick={() => setcommentType('management')}
            className={commentType.includes('management') ? 'selected' : ''}
          >
            <I18n id="Leader" />
          </Text>
        </CommentChoice>
      )}
      <StyledTextarea
        placeholder={I18nFunction({ id: 'comment_placeholder' })}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        required
        minRows={2}
        disabled={isCommentOnCasePending}
        className={commentType.includes('all') ? 'all' : 'management'}
      />
      <Button
        pending={false}
        type="submit"
        small
        onClick={() => commentOnCase(Case.id, comment, commentType)}
        disabled={!comment}
        inactive={!comment}
      >
        <I18n id="Comment" />
      </Button>
    </Form>
  )
}

const CommentChoice = styled.div`
  display: flex;
`

const Text = styled.div`
  padding: 1em;
  cursor: pointer;
  display: block;

  &.selected {
    text-decoration: underline;
    font-weight: bold;
  }
`
const Form = styled.form`
  text-align: right;
`

const StyledTextarea = styled(ReactTextarea)`
  display: block;
  width: 100%;
  resize: none;
  padding: 1em;
  border: none;
  line-height: inherit;
  margin: 1em auto;
  border-radius: 10px;

  &.all {
    background-color: ${({ theme: { allCommentBackground } }) =>
      allCommentBackground};
  }
  &.management {
    background-color: ${({ theme: { leaderCommentBackground } }) =>
      leaderCommentBackground};
  }
`

const mapStateToProps = (state, props) => {
  return {
    currentUser: getCurrentUser(state),
  }
}

export default connect(mapStateToProps)(CaseCommentField)
