import React, { useState } from 'react'
import styled from 'styled-components'
import CardDataList from './CardDataList'
import I18n from './I18n'
import { prettyDateTime } from '../dateUtils'

const displayedFields = [
  {
    label: <I18n id="upload_respons_statistics.entries_in_file" />,
    key: 'entriesInFile',
  },
  {
    label: <I18n id="upload_respons_statistics.parsed_entries" />,
    key: 'parsedEntries',
  },
  {
    label: <I18n id="upload_respons_statistics.parse_errors" />,
    key: 'parseErrors',
  },
  {
    label: <I18n id="upload_respons_statistics.added_entries" />,
    key: 'addedEntries',
  },
  {
    label: <I18n id="upload_respons_statistics.removed_entries" />,
    key: 'removedEntries',
  },
  {
    label: <I18n id="upload_respons_statistics.updated_entries" />,
    key: 'updatedEntries',
  },
  {
    label: <I18n id="upload_respons_statistics.updated_cases" />,
    key: 'updatedCases',
  },
  {
    label: <I18n id="upload_respons_statistics.total_entries_pre_update" />,
    key: 'totalEntriesPreUpdate',
  },
  {
    label: <I18n id="upload_respons_statistics.total_entries_post_update" />,
    key: 'totalEntriesPostUpdate',
  },
  {
    label: <I18n id="upload_respons_statistics.update_time_sec" />,
    key: 'updateTime',
  },
]

const UploadStatistics = ({ stats }) => {
  const [open, setOpen] = useState(false)
  return (
    <Wrapper>
      <Header onClick={() => setOpen(!open)}>
        <span>
          <I18n id="upload_respons_statistics_header" />:{' '}
          {prettyDateTime(stats?.createdOn)}
        </span>
        <LinkText>
          <I18n id={open ? 'Hide_details' : 'Show_details'} />
        </LinkText>
      </Header>
      {open && (
        <Content>
          <CardDataList
            data={displayedFields.map(({ key, label }) => [label, stats[key]])}
          />
        </Content>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.primaryBackground};

  &:not(:last-child) {
    margin-bottom: 0.75em;
  }
`

const Header = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  border: none;
  cursor: pointer;
  padding: 1em;

  & > *:not(:last-child) {
    margin-right: 1em;
  }
`

const LinkText = styled.div`
  margin-left: auto;
  flex-grow: 0;
  flex-basis: initial;
  white-space: nowrap;
  color: ${(props) => props.theme.secondary};
  font-size: ${(props) => props.theme.largeText};
`

const Content = styled.div`
  padding: 1em;
`

export default UploadStatistics
