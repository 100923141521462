import React from 'react'
import styled from 'styled-components'

const DataListColumns = ({ props }) => {
  return (
    <Columns>
      {props?.columns.map((column) => (
        <Column key={column?.key} width={column?.width}>
          {column?.label}
        </Column>
      ))}
    </Columns>
  )
}

const Columns = styled.div`
  margin-left: 10px;
  padding: 1em 1.5em;
  width: 100%;
  font-weight: bold;
  display: flex;
`

const Column = styled.div`
  display: flex;
  width: ${({ width }) => width};
  margin-left: ${({ marginLeft }) => marginLeft};
`

export default DataListColumns
