import { combineReducers } from 'redux'
import byApplicationType from './byApplicationType'
import fetchStatus, * as fromFetchStatus from './fetchStatus'

export { default as sagas } from './sagas'

export const FETCH_ALL_APPLICATIONS = 'FETCH_ALL_APPLICATIONS'
export const FETCH_ALL_APPLICATIONS_SUCCEEDED =
  'FETCH_ALL_APPLICATIONS_SUCCEEDED'
export const FETCH_ALL_APPLICATIONS_FAILED = 'FETCH_ALL_APPLICATIONS_FAILED'

export const fetchAllApplications = (
  pagination = { query: '', pageIndex: 1, perPage: 20 },
) => ({
  type: FETCH_ALL_APPLICATIONS,
  pagination,
})

const pagination = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ALL_APPLICATIONS_SUCCEEDED:
      return {
        ...action.payload.pagination,
      }
    default:
      return state
  }
}

export const getAllApplications = (state) => [
  ...Object.values(state.ownershipApplication.byId),
  ...Object.values(state.roleApplication.byId),
]

export const shouldFetchApplications = (state) =>
  fromFetchStatus.shouldFetchApplications(state.fetchStatus)

export const isApplicationsPending = (state) =>
  fromFetchStatus.isApplicationsPending(state.fetchStatus)

export const getApplicationPagination = (state) => state.pagination

export default combineReducers({
  ownershipApplication: byApplicationType('ownershipApplication'),
  roleApplication: byApplicationType('roleApplication'),
  fetchStatus,
  pagination,
})
