import React from 'react'
import styled from 'styled-components'
import Page from '../components/Page'
import { height } from '../components/Header'
import I18n from '../components/I18n'

const TooSmallScreen = () => (
  <Page>
    <Wrapper>
      <Content>
        <I18n id="Screen_too_small" />
      </Content>
    </Wrapper>
  </Page>
)

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - ${height});
`

const Content = styled.div`
  max-width: 400px;
  width: 90%;
  text-align: center;
  font-size: ${({ theme: { largeText } }) => largeText};
  margin: auto;
`

export default TooSmallScreen
