import {
  FETCH_ALL_APPLICATIONS,
  FETCH_ALL_APPLICATIONS_SUCCEEDED,
  FETCH_ALL_APPLICATIONS_FAILED,
} from './index'

const fetchStatus = (state = 'not fetched', action) => {
  switch (action.type) {
    case FETCH_ALL_APPLICATIONS:
      return 'fetching'

    case FETCH_ALL_APPLICATIONS_SUCCEEDED:
      return 'succeeded'

    case FETCH_ALL_APPLICATIONS_FAILED:
      return 'failed'

    default:
      return state
  }
}

export const shouldFetchApplications = (state) =>
  !['succeeded', 'failed'].includes(state)

export const isApplicationsPending = (state) =>
  ['fetching', 'not fetched'].includes(state)

export default fetchStatus
