import React, { useEffect, useState } from 'react'
import I18n from '../I18n'
import CaseCommentField from './CaseCommentField'
import CaseHistoryItem from './CaseHistoryItem'
import styled from 'styled-components'
import eventCommentIcon from '../../icons/event_comment.svg'
import eventInfoIcon from '../../icons/event_info.svg'
import {
  isAdmin,
  isManager,
  isCommentOnCasePending,
} from '../../redux/selectors'
import { commentOnCase } from '../../redux/actions'
import { connect } from 'react-redux'

const CaseHistory = ({
  currentUser,
  isAdmin,
  isManager,
  Case,
  commentOnCase,
  isCommentOnCasePending,
}) => {
  const [activeFilter, setActiveFilter] = useState('all')
  const [allLogEntries, setAllLogEntries] = useState([])
  const [filteredLogEntries, setFilteredLogEntries] = useState([])

  useEffect(() => {
    const mergedLogEntries = []

    Case.comments?.map((comment) =>
      mergedLogEntries.push(itemizeComment(comment)),
    )
    Case.events?.map((event) => mergedLogEntries.push(itemizeEvent(event)))

    mergedLogEntries.sort((a, b) =>
      new Date(a.time).getTime() > new Date(b.time).getTime() ? 1 : -1,
    )
    setAllLogEntries(mergedLogEntries)
  }, [Case.comments, Case.events])

  useEffect(() => {
    filterHistory(activeFilter)
  }, [allLogEntries, activeFilter])

  const filterHistory = (f) => {
    setActiveFilter(f)
    if (f === 'user-dialogue') {
      setFilteredLogEntries(() =>
        allLogEntries?.filter(
          (e) => e.isComment && e.commentType?.includes('all'),
        ),
      )
    } else if (f === 'management-dialogue') {
      setFilteredLogEntries(
        allLogEntries?.filter(
          (e) => e.isComment && e.commentType?.includes('management'),
        ),
      )
    } else if (f === 'events') {
      setFilteredLogEntries(allLogEntries?.filter((e) => !e.isComment))
    } else {
      setFilteredLogEntries(allLogEntries)
    }
  }
  return (
    <React.Fragment>
      <Wrapper>
        <Title>
          <I18n id="History" />
        </Title>
        <List>
          <div>
            {filteredLogEntries?.length > 0 &&
              filteredLogEntries.map((logEntry) => {
                return (
                  <CaseHistoryItem
                    key={logEntry.id + '_' + logEntry.eventType}
                    item={logEntry}
                  />
                )
              })}
            {filteredLogEntries?.length == 0 && <p>Ingenting å vise</p>}
          </div>
        </List>
        <CaseCommentField
          Case={Case}
          isAdmin={isAdmin}
          isManager={isManager}
          commentOnCase={commentOnCase}
          isCommentOnCasePending={isCommentOnCasePending}
        />
      </Wrapper>
    </React.Fragment>
  )
}

const itemizeComment = (comment) => {
  return {
    id: comment.id,
    isComment: true,
    icon: eventCommentIcon,
    eventType: 'comment',
    commentType: comment.commentType,
    payload: comment.payload,
    time: comment.createdOn,
    userId: comment.userId,
    commentByUserName: comment.userName,
  }
}

const itemizeEvent = (event) => {
  return {
    id: event.id,
    isComment: false,
    icon: eventInfoIcon,
    eventType: event.eventType,
    payload: event.payload,
    time: event.createdOn,
  }
}

const Wrapper = styled.div`
  overflow-x: auto;
  word-break: break-word;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  border-radius: 10px;

  padding: 1.5em;
  background-color: ${({ theme: { primaryBackground } }) => primaryBackground};
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column-reverse;
  & > :not(:last-child) {
    margin-bottom: 0.5em;
  }

  max-height: 500px;
  overflow: auto;

  --scrollbarBG: transparent;
  --thumbBG: #90a4ae;

  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
`
const Title = styled.div`
  font-size: ${(props) => props.theme.largeText};
  text-overflow: ellipsis;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  margin-bottom: 1em;
  font-weight: bold;
`

const mapStateToProps = (state, props) => ({
  isManager: isManager(state),
  isAdmin: isAdmin(state),
  isCommentOnCasePending: isCommentOnCasePending(state, props.Case.id),
})

const mapDispatchToProps = { commentOnCase }

export default connect(mapStateToProps, mapDispatchToProps)(CaseHistory)
