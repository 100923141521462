import React, { useContext } from 'react'
import { Input } from '../form'
import { ConnectionFormContext } from './ConnectionForm'

const ConnectionInput = (props) => {
  const { connection, handleChange, handleBlur, visited, errors } = useContext(
    ConnectionFormContext,
  )

  return (
    <Input
      {...props}
      value={connection[props.name]}
      onChange={handleChange}
      onBlur={handleBlur}
      visited={visited[props.name]}
      error={errors[props.name]}
      disabled={
        (props.disabled || connection.fetchedOn || connection.updatedOn) &&
        connection.country == 'NO'
      }
    />
  )
}

export default ConnectionInput
