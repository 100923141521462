import React, { useEffect } from 'react'
import styled from 'styled-components'

import { connect } from 'react-redux'
import ProffSelector from './ProffSelector'
import {
  getActiveProffPerson,
  getProffPersonSuggestions,
  getRelation,
  hasConfirmedProffSelection,
  isProffSuggestionsFetching,
} from '../../redux/selectors'

import {
  setRelationProffPersonId,
  setActiveProffPerson,
  unsetActiveProffPerson,
  fetchRelationProffPersonSuggestions,
} from '../../redux/actions'

const ProffSelectorModal = ({
  activeProffPerson,
  fetchRelationProffPersonSuggestions,
  proffSuggestions,
  isProffSuggestionsFetching,
  setRelationProffPersonId,
  setActiveProffPerson,
  unsetActiveProffPerson,
  onClose,
  relationId,
  relation,
}) => {
  useEffect(() => {
    !isProffSuggestionsFetching &&
      relationId &&
      fetchRelationProffPersonSuggestions(relationId)
  }, [fetchRelationProffPersonSuggestions])
  return (
    <Overlay onClick={onClose}>
      <ContentWrapper onClick={(e) => e.stopPropagation()}>
        <ProffSelector
          activeProffPerson={activeProffPerson}
          currentUser={relation}
          proffSuggestions={proffSuggestions}
          setProffPersonId={setRelationProffPersonId}
          setActiveProffPerson={setActiveProffPerson}
          unsetActiveProffPerson={unsetActiveProffPerson}
          personType={'relations'}
          onClose={onClose}
          isProffSuggestionsFetching={isProffSuggestionsFetching}
        />
      </ContentWrapper>
    </Overlay>
  )
}

const Overlay = styled.div`
  animation: none;
  width: 100%;
  height: 90%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
`

const ContentWrapper = styled.div`
  background-color: ${(props) => props.theme.primaryBackground};
  margin: 0 auto;
  height: 770px;
  min-width: 1000px;
  -moz-box-shadow: 0px 0px 18px 6px #ccc;
  -webkit-box-shadow: 0px 0px 18px 6px #ccc;
  box-shadow: 0px 0px 18px 6px #ccc;
  padding: 2em;
  display: flex;
  flex-direction: column;
`

const mapStateToProps = (state, props) => {
  return {
    activeProffPerson: getActiveProffPerson(state),
    proffSuggestions: getProffPersonSuggestions(state),
    isProffSuggestionsFetching: isProffSuggestionsFetching(state),
    hasConfirmedProffSelection: hasConfirmedProffSelection(state),
    relation: getRelation(state, props.relationId) || {},
  }
}

const mapDispatchToProps = {
  fetchRelationProffPersonSuggestions,
  setRelationProffPersonId,
  setActiveProffPerson,
  unsetActiveProffPerson,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProffSelectorModal)
