import { call, put, takeEvery, select } from 'redux-saga/effects'
import lodash from 'lodash'
import deepdash from 'deepdash'
import {
  FETCH_APP_SETTINGS,
  FETCH_APP_SETTINGS_SUCCEEDED,
  FETCH_APP_SETTINGS_FAILED,
  SAVE_APP_SETTINGS,
  SAVE_APP_SETTINGS_SUCCEEDED,
  SAVE_APP_SETTINGS_FAILED,
} from './index'
import * as schema from '../schema'
import { fetchSaga } from '../sagas'
import { get, post } from '../fetch'
import { getAppSettings } from '../selectors'

const _ = deepdash(lodash)

function* saveAppSettings() {
  try {
    const appSettings = yield select(getAppSettings)

    const { data, errors } = yield call(() =>
      post(`/settings`, { ..._.omitDeep(appSettings, 'valueRef') }),
    )

    if (errors) {
      yield put({
        type: SAVE_APP_SETTINGS_FAILED,
        errors,
      })
    } else {
      yield put({
        type: SAVE_APP_SETTINGS_SUCCEEDED,
        payload: data,
      })
    }
  } catch (error) {
    console.error(error)
    yield put({
      type: SAVE_APP_SETTINGS_FAILED,
      errors: [{ detail: error.message }],
    })
  }
}

function* saga() {
  yield takeEvery(
    FETCH_APP_SETTINGS,
    fetchSaga({
      SUCCEEDED: FETCH_APP_SETTINGS_SUCCEEDED,
      FAILED: FETCH_APP_SETTINGS_FAILED,
      schema: schema.appSettingsResponse,
      fetch: () => get(`/settings`),
    }),
  ),
    yield takeEvery(SAVE_APP_SETTINGS, saveAppSettings)
}

export default saga
