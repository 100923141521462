import { call, put, takeEvery, all } from 'redux-saga/effects'
import {
  FETCH_APP_CONTENT,
  FETCH_APP_CONTENT_FAILED,
  SET_LANGUAGE,
} from './index'
import { LOG_OUT_SUCCEEDED } from '../auth/actionTypes'
import {
  setTextsToState,
  setPublicTexts,
} from './index'
import {
  setLocalStorageLanguage,
  getDefaultLanguage,
} from './languageUtils'
import { getUnCamelized } from '../fetch'
import publicContent from './public_default'



let englishTexts = publicContent['en']
let norwegianTexts = publicContent['no']

export function* logOutClearTexts() {
  const language = getDefaultLanguage()
  englishTexts = publicContent['en']
  norwegianTexts = publicContent['no']

  if (language === 'en') {
    yield put(setPublicTexts(englishTexts))
  } else {
    yield put(setPublicTexts(norwegianTexts))
  }
}

function* fetchMergedText(language) {
  try {
    let { data, errors } = yield call(() =>
      getUnCamelized(`/content/${language}`),
    )

    if (errors) {
      yield put({
        type: FETCH_APP_CONTENT_FAILED,
        errors,
      })
      return null // Return null if there were errors.
    } else {
      return data.content
    }
  } catch (error) {
    console.error(error)
    yield put({
      type: FETCH_APP_CONTENT_FAILED,
      errors: [{ detail: error.message }],
    })
    return null // Return null also in case of exceptions.
  }
}


function* fetchAllTextsSaga() {
  try {
    // Setting the global variables to the retrieved data
    [englishTexts, norwegianTexts] = yield all([
      call(fetchMergedText, 'en'),
      call(fetchMergedText, 'no'),
    ])

    if (getDefaultLanguage() === 'en') {
      yield put(setTextsToState(englishTexts))
    } else {
      yield put(setTextsToState(norwegianTexts))
    }

  } catch (error) {
    console.error(error)
    yield put({
      type: FETCH_APP_CONTENT_FAILED,
      errors: [{ detail: error.message }],
    })
  }
}

function* setLanguageSaga(action) {
  const newLanguage = action.payload
  yield setLocalStorageLanguage(newLanguage)

  if (newLanguage === 'en') {
    yield put(setTextsToState(englishTexts))
  } else {
    yield put(setTextsToState(norwegianTexts))
  }
}

function* saga() {
  yield takeEvery(FETCH_APP_CONTENT, fetchAllTextsSaga)
  yield takeEvery(SET_LANGUAGE, setLanguageSaga)
  yield takeEvery(LOG_OUT_SUCCEEDED, logOutClearTexts)
}

export default saga
