const defaultLanguage = 'no';

export const setLocalStorageLanguage = (language) => {
  if (!window.localStorage) return
  window.localStorage.setItem('language', language)
}

export const getDefaultLanguage = () => {
  if (!window.localStorage) return defaultLanguage
  let language = window.localStorage.getItem('language')
  if (!language) {
    setLocalStorageLanguage(defaultLanguage)
    language = window.localStorage.getItem('language')
  }
  return language;
}