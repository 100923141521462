import React, { useContext } from 'react'
import { TextAreaInput } from '../form'
import { ConnectionFormContext } from './ConnectionForm'

const ConnectionTextarea = (props) => {
  const { connection, handleChange, handleBlur, errors, visited } = useContext(
    ConnectionFormContext,
  )

  return (
    <TextAreaInput
      {...props}
      value={connection[props.name] || ''}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errors[props.name]}
      visited={visited[props.name]}
      minRows={2}
      disabled={props.disabled}
    />
  )
}

export default ConnectionTextarea
