import React from 'react'
import styled from 'styled-components'
import Page from '../components/Page'
import { height } from '../components/Header'
import BackButton from '../components/BackButton'
import I18n, { useI18nFunction }  from '../components/I18n'

const CaseNotFound = () => {
  
  const I18nFunction = useI18nFunction()

  return(
  <Page>
    <Wrapper>
      <Content>
        <h1>
          <I18n id="Case_Not_Found" />
        </h1>
        <BackButton 
          text={I18nFunction( { id:"GoBack" } )}
        />
      </Content>
    </Wrapper>
  </Page>
  )
}

const Content = styled.div`
  margin: auto;
  text-align: center;
  font-size: ${(props) => props.theme.largeText};
  padding-bottom: 3em;
`

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - ${height});
`

export default CaseNotFound
