import { useEffect, useReducer, useCallback, useMemo } from 'react'

const toggleActions = {
  TOGGLE: 'TOGGLE',
  TOGGLE_ALL: 'TOGGLE_ALL',
  CLEAR: 'CLEAR',
}

const selectedUsersReducer = (state, action) => {
  switch (action.type) {
    case toggleActions.TOGGLE:
      return {
        ...state,
        [action.userId]: !state[action.userId],
      }
    case toggleActions.TOGGLE_ALL:
      return action.userIds.reduce(
        (acc, userId) => ({ ...acc, [userId]: action.toggled }),
        {},
      )
    case toggleActions.CLEAR:
      return {}
    default:
      throw new Error(`Unknown action ${action.type}`)
  }
}

const useToggleUsers = (users = []) => {
  const sortedUserIds = useMemo(() => {
    return users.map((u) => u.id).sort()
  }, [users])
  const [selectedUsers, dispatch] = useReducer(selectedUsersReducer, {})
  useEffect(() => {
    dispatch({ type: toggleActions.CLEAR })
  }, [JSON.stringify(sortedUserIds)])
  const toggleUser = useCallback(
    (userId) => {
      dispatch({ type: toggleActions.TOGGLE, userId })
    },
    [dispatch],
  )

  const selectedUserIds = useMemo(
    () => Object.keys(selectedUsers).filter((key) => selectedUsers[key]),
    [selectedUsers],
  )
  const toggleAllUsers = useCallback(() => {
    dispatch({
      type: toggleActions.TOGGLE_ALL,
      userIds: sortedUserIds,
      toggled: selectedUserIds.length === 0,
    })
  }, [dispatch, sortedUserIds, selectedUserIds])
  return {
    toggleAllUsers,
    selectedUserIds,
    toggleUser,
    selectedUsers,
  }
}

export default useToggleUsers
