import React, { useEffect } from 'react'
import Page from '../components/Page'
import TermsAndConditions from '../components/TermsAndConditions'
import BirthdateForm from '../components/BirthdateForm'
import { connect } from 'react-redux'
import CookieConsentModal from '../components/Cookies'
import {
  getCurrentUser,
  getUser,
  hasConfirmedTermsAndConditions,
  hasAcceptedCookies,
  isCompleteOnboardingPending,
  isCurrentUserFetching,
  isSetBirthdateAndAddressPending,
  getActiveProffPerson,
  getProffPersonSuggestions,
  hasConfirmedProffSelection,
  hasConfirmedBirthdateAndAddress,
  isProffSuggestionsFetching,
} from '../redux/selectors'
import {
  completeOnboarding,
  setBirthdateAndAddress,
  setProffPersonId,
  setActiveProffPerson,
  unsetActiveProffPerson,
  acceptCookies,
} from '../redux/actions'
import ProffSelector from '../components/ProffSelector/ProffSelector'
import { fetchProffPersonSuggestions } from '../redux/actions'
import { useHistory } from 'react-router-dom'
const Onboarding = ({
  pending,
  currentUser,
  userId,
  isCompleteOnboardingPending,
  showTermsAndConditions,
  displayCookies,
  showBirthdateAndAdressForm,
  completeOnboarding,
  acceptCookies,
  setBirthdateAndAddress,
  isSetBirthdateAndAddressPending,
  activeProffPerson,
  fetchProffPersonSuggestions,
  proffSuggestions,
  isProffSuggestionsFetching,
  showProffSelector,
  setProffPersonId,
  setActiveProffPerson,
  unsetActiveProffPerson,
  birthdate,
}) => {

  useEffect(() => {birthdate && userId && fetchProffPersonSuggestions(userId)}, [birthdate]) 

  useEffect(() => {
    showProffSelector && userId && fetchProffPersonSuggestions(userId)
  }, [fetchProffPersonSuggestions, userId, showProffSelector])
  const history = useHistory({ forceRefresh: true })
  useEffect(() => {
    if (
      !pending &&
      !showTermsAndConditions &&
      !showBirthdateAndAdressForm &&
      !showProffSelector &&
      !displayCookies
    ) {
      history.push('/profile')
    }
  }, [
    pending,
    showTermsAndConditions,
    showBirthdateAndAdressForm,
    hasConfirmedProffSelection,
    showProffSelector,
    displayCookies,
  ])
  return (
    <>
    {displayCookies && (
      <CookieConsentModal
        onClose={() => acceptCookies()} 
      />
    )}

    <Page pending={pending}>
      

      {showTermsAndConditions && (
        <TermsAndConditions
          complete={() => completeOnboarding()}
          completePending={isCompleteOnboardingPending}
        />
      )}

      {showBirthdateAndAdressForm && (
        <BirthdateForm
          currentUser={currentUser}
          pending={isSetBirthdateAndAddressPending}
          setBirthdateAndAddress={setBirthdateAndAddress}
        />
      )}
      {showProffSelector && currentUser && (
        <ProffSelector
          activeProffPerson={activeProffPerson}
          currentUser={currentUser}
          proffSuggestions={proffSuggestions}
          setProffPersonId={setProffPersonId}
          setActiveProffPerson={setActiveProffPerson}
          unsetActiveProffPerson={unsetActiveProffPerson}
          personType={'users'}
          isProffSuggestionsFetching={isProffSuggestionsFetching}
        />
      )}
    </Page>
    </>
  )
}

const mapDispatchToProps = {
  completeOnboarding,
  acceptCookies,
  setBirthdateAndAddress,
  fetchProffPersonSuggestions,
  setProffPersonId,
  setActiveProffPerson,
  unsetActiveProffPerson,
}

const mapStateToProps = (state) => {
  const pending = isCurrentUserFetching(state)
  const currentUser = getCurrentUser(state)
  if (pending)
    return {
      pending: true,
    }
  const hasBirthdateAndAddress =
    currentUser.birthdate != null &&
    currentUser.address &&
    currentUser.postCode &&
    currentUser.postPlace
  const userId = currentUser.id
  const birthdate = getUser(state, userId).birthdate
  return {
    pending,
    currentUser,
    userId: userId,
    birthdate,
    isCompleteOnboardingPending: isCompleteOnboardingPending(state),
    isSetBirthdateAndAddressPending: isSetBirthdateAndAddressPending(state),
    showTermsAndConditions: !hasConfirmedTermsAndConditions(state),
    displayCookies: !hasAcceptedCookies(state),
    showBirthdateAndAdressForm:
      hasConfirmedTermsAndConditions(state) &&
      (!hasBirthdateAndAddress || !hasConfirmedBirthdateAndAddress(state)),
    showProffSelector:
      hasConfirmedTermsAndConditions(state) &&
      hasBirthdateAndAddress &&
      hasConfirmedBirthdateAndAddress(state) &&
      !hasConfirmedProffSelection(state),
    activeProffPerson: getActiveProffPerson(state),
    proffSuggestions: getProffPersonSuggestions(state),
    isProffSuggestionsFetching: isProffSuggestionsFetching(state),
    hasConfirmedProffSelection: hasConfirmedProffSelection(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding)
