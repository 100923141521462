import React from 'react'
import I18n from '../I18n'

export default {
  case_created: {
    title: (props) => <I18n {...props} id="notifications.case_created.title" />,
    message: (props) => (
      <I18n {...props} id="notifications.case_created.message" />
    ),
  },
  case_updated: {
    title: (props) => <I18n {...props} id="notifications.case_updated.title" />,
    message: (props) => (
      <I18n
        {...props}
        property={props.payload?.property}
        propertyOldValue={props.payload?.propertyOldValue}
        propertyNewValue={props.payload?.propertyNewValue}
        id="notifications.case_updated.message"
      />
    ),
  },
  case_company_updated: {
    title: (props) => (
      <I18n {...props} id="notifications.case_company_updated.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.case_company_updated.message" />
    ),
  },
  case_data_mismatch: {
    title: (props) => (
      <I18n {...props} id="notifications.case_data_mismatch.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.case_data_mismatch.message" />
    ),
  },
  case_commented: {
    title: (props) => (
      <I18n {...props} id="notifications.case_commented.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.case_commented.message" />
    ),
  },
  application_created: {
    title: (props) => (
      <I18n {...props} id="notifications.application_created.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.application_created.message" />
    ),
  },
  application_updated: {
    title: (props) => (
      <I18n {...props} id="notifications.application_updated.title" />
    ),
    message: (props) => (
      <I18n
        {...props}
        property={props.payload?.property}
        propertyOldValue={props.payload?.propertyOldValue}
        propertyNewValue={props.payload?.propertyNewValue}
        id="notifications.application_updated.message"
      />
    ),
  },

  user_created: {
    title: (props) => <I18n {...props} id="notifications.user_created.title" />,
    message: (props) => (
      <I18n {...props} id="notifications.user_created.message" />
    ),
  },
  user_updated: {
    title: (props) => <I18n {...props} id="notifications.user_updated.title" />,
    message: (props) => (
      <I18n {...props} id="notifications.user_updated.message" />
    ),
  },
  complete_onboarding: {
    title: (props) => (
      <I18n {...props} id="notifications.complete_onboarding.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.complete_onboarding.message" />
    ),
  },
  confirm_form: {
    title: (props) => <I18n {...props} id="notifications.confirm_form.title" />,
    message: (props) => (
      <I18n {...props} id="notifications.confirm_form.message" />
    ),
  },
  set_birthdate_and_address: {
    title: (props) => (
      <I18n {...props} id="notifications.set_birthdate_and_address.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.set_birthdate_and_address.message" />
    ),
  },
  confirmed_birthdate_and_address: {
    title: (props) => (
      <I18n {...props} id="notifications.set_birthdate_and_address.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.set_birthdate_and_address.message" />
    ),
  },
  set_employment_end_date: {
    title: (props) => (
      <I18n {...props} id="notifications.set_employment_end_date.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.set_employment_end_date.message" />
    ),
  },

  set_address: {
    title: (props) => (
      <I18n {...props} id="notifications.set_address.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.set_address.message" />
    ),
  },

  set_phone: {
    title: (props) => (
      <I18n {...props} id="notifications.set_phone.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.set_phone.message" />
    ),
  },

  // EMAILS
  automatic_email_reminder: {
    title: (props) => (
      <I18n {...props} id="notifications.automatic_email_reminder.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.automatic_email_reminder.message" />
    ),
  },
  manual_email_reminder: {
    title: (props) => (
      <I18n {...props} id="notifications.manual_email_reminder.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.manual_email_reminder.message" />
    ),
  },
  automatic_email_invite: {
    title: (props) => (
      <I18n {...props} id="notifications.automatic_email_invite.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.automatic_email_invite.message" />
    ),
  },
  automatic_email_yearly_reminder: {
    title: (props) => (
      <I18n
        {...props}
        id="notifications.automatic_email_yearly_reminder.title"
      />
    ),
    message: (props) => (
      <I18n
        {...props}
        id="notifications.automatic_email_yearly_reminder.message"
      />
    ),
  },
  automatic_email_public_data_request: {
    title: (props) => (
      <I18n
        {...props}
        id="notifications.automatic_email_public_data_request.title"
      />
    ),
    message: (props) => (
      <I18n
        {...props}
        id="notifications.automatic_email_public_data_request.message"
      />
    ),
  },
  send_email_to_relation: {
    title: (props) => (
      <I18n {...props} id="notifications.send_email_to_relation.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.send_email_to_relation.message" />
    ),
  },

  // EMAILS

  //form actions
  confirmed_no_user_ownerships: {
    title: (props) => (
      <I18n {...props} id="notifications.confirmed_no_user_ownerships.title" />
    ),
    message: (props) => (
      <I18n
        {...props}
        id="notifications.confirmed_no_user_ownerships.message"
      />
    ),
  },
  cancelled_confirmed_no_user_ownerships: {
    title: (props) => (
      <I18n
        {...props}
        id="notifications.cancelled_confirmed_no_user_ownerships.title"
      />
    ),
    message: (props) => (
      <I18n
        {...props}
        id="notifications.cancelled_confirmed_no_user_ownerships.message"
      />
    ),
  },
  confirmed_no_user_roles: {
    title: (props) => (
      <I18n {...props} id="notifications.confirmed_no_user_roles.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.confirmed_no_user_roles.message" />
    ),
  },
  cancelled_confirmed_no_user_roles: {
    title: (props) => (
      <I18n
        {...props}
        id="notifications.cancelled_confirmed_no_user_roles.title"
      />
    ),
    message: (props) => (
      <I18n
        {...props}
        id="notifications.cancelled_confirmed_no_user_roles.message"
      />
    ),
  },
  confirmed_no_user_other_connections: {
    title: (props) => (
      <I18n
        {...props}
        id="notifications.confirmed_no_user_other_connections.title"
      />
    ),
    message: (props) => (
      <I18n
        {...props}
        id="notifications.confirmed_no_user_other_connections.message"
      />
    ),
  },
  cancelled_confirmed_no_user_other_connections: {
    title: (props) => (
      <I18n
        {...props}
        id="notifications.cancelled_confirmed_no_user_other_connections.title"
      />
    ),
    message: (props) => (
      <I18n
        {...props}
        id="notifications.cancelled_confirmed_no_user_other_connections.message"
      />
    ),
  },
  confirmed_no_relations: {
    title: (props) => (
      <I18n {...props} id="notifications.confirmed_no_relations.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.confirmed_no_relations.message" />
    ),
  },
  cancelled_confirmed_no_relations: {
    title: (props) => (
      <I18n
        {...props}
        id="notifications.cancelled_confirmed_no_relations.title"
      />
    ),
    message: (props) => (
      <I18n
        {...props}
        id="notifications.cancelled_confirmed_no_relations.message"
      />
    ),
  },

  create_ownership: {
    title: (props) => (
      <I18n {...props} id="notifications.create_ownership.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.create_ownership.message" />
    ),
  },
  update_ownership: {
    title: (props) => (
      <I18n {...props} id="notifications.update_ownership.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.update_ownership.message" />
    ),
  },
  remove_ownership: {
    title: (props) => (
      <I18n {...props} id="notifications.remove_ownership.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.remove_ownership.message" />
    ),
  },
  create_ownership_application: {
    title: (props) => (
      <I18n {...props} id="notifications.create_ownership_application.title" />
    ),
    message: (props) => (
      <I18n
        {...props}
        id="notifications.create_ownership_application.message"
      />
    ),
  },
  update_ownership_application: {
    title: (props) => (
      <I18n {...props} id="notifications.update_ownership_application.title" />
    ),
    message: (props) => (
      <I18n
        {...props}
        id="notifications.update_ownership_application.message"
      />
    ),
  },
  remove_ownership_application: {
    title: (props) => (
      <I18n {...props} id="notifications.remove_ownership_application.title" />
    ),
    message: (props) => (
      <I18n
        {...props}
        id="notifications.remove_ownership_application.message"
      />
    ),
  },
  create_role: {
    title: (props) => <I18n {...props} id="notifications.create_role.title" />,
    message: (props) => (
      <I18n {...props} id="notifications.create_role.message" />
    ),
  },
  update_role: {
    title: (props) => <I18n {...props} id="notifications.update_role.title" />,
    message: (props) => (
      <I18n {...props} id="notifications.update_role.message" />
    ),
  },
  remove_role: {
    title: (props) => <I18n {...props} id="notifications.remove_role.title" />,
    message: (props) => (
      <I18n {...props} id="notifications.remove_role.message" />
    ),
  },
  create_role_application: {
    title: (props) => (
      <I18n {...props} id="notifications.create_role_application.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.create_role_application.message" />
    ),
  },
  update_role_application: {
    title: (props) => (
      <I18n {...props} id="notifications.update_role_application.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.update_role_application.message" />
    ),
  },
  remove_role_application: {
    title: (props) => (
      <I18n {...props} id="notifications.remove_role_application.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.remove_role_application.message" />
    ),
  },
  create_other_connection: {
    title: (props) => (
      <I18n {...props} id="notifications.create_other_connection.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.create_other_connection.message" />
    ),
  },
  update_other_connection: {
    title: (props) => (
      <I18n {...props} id="notifications.update_other_connection.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.update_other_connection.message" />
    ),
  },
  remove_other_connection: {
    title: (props) => (
      <I18n {...props} id="notifications.remove_other_connection.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.remove_other_connection.message" />
    ),
  },
  create_relation: {
    title: (props) => (
      <I18n {...props} id="notifications.create_relation.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.create_relation.message" />
    ),
  },
  update_relation: {
    title: (props) => (
      <I18n {...props} id="notifications.update_relation.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.update_relation.message" />
    ),
  },
  remove_relation: {
    title: (props) => (
      <I18n {...props} id="notifications.remove_relation.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.remove_relation.message" />
    ),
  },

  create_relation_ownership: {
    title: (props) => (
      <I18n {...props} id="notifications.create_relation_ownership.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.create_relation_ownership.message" />
    ),
  },
  update_relation_ownership: {
    title: (props) => (
      <I18n {...props} id="notifications.update_relation_ownership.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.update_relation_ownership.message" />
    ),
  },
  remove_relation_ownership: {
    title: (props) => (
      <I18n {...props} id="notifications.remove_relation_ownership.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.remove_relation_ownership.message" />
    ),
  },

  create_relation_role: {
    title: (props) => (
      <I18n {...props} id="notifications.create_relation_role.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.create_relation_role.message" />
    ),
  },
  update_relation_role: {
    title: (props) => (
      <I18n {...props} id="notifications.update_relation_role.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.update_relation_role.message" />
    ),
  },
  remove_relation_role: {
    title: (props) => (
      <I18n {...props} id="notifications.remove_relation_role.title" />
    ),
    message: (props) => (
      <I18n {...props} id="notifications.remove_relation_role.message" />
    ),
  },
  create_relation_other_connection: {
    title: (props) => (
      <I18n
        {...props}
        id="notifications.create_relation_other_connection.title"
      />
    ),
    message: (props) => (
      <I18n
        {...props}
        id="notifications.create_relation_other_connection.message"
      />
    ),
  },
  update_relation_other_connection: {
    title: (props) => (
      <I18n
        {...props}
        id="notifications.update_relation_other_connection.title"
      />
    ),
    message: (props) => (
      <I18n
        {...props}
        id="notifications.update_relation_other_connection.message"
      />
    ),
  },
  remove_relation_other_connection: {
    title: (props) => (
      <I18n
        {...props}
        id="notifications.remove_relation_other_connection.title"
      />
    ),
    message: (props) => (
      <I18n
        {...props}
        id="notifications.remove_relation_other_connection.message"
      />
    ),
  },
}
