const byId = (state = {}, action) => {
  if (
    action.payload &&
    action.payload.entities &&
    action.payload.entities.event
  ) {
    return {
      ...state,
      ...action.payload.entities.event,
    }
  }

  return state
}

export const getEvent = (state, id) => state[id]

export default byId
