import React from 'react'
import styled from 'styled-components'
import { prettyDate } from '../../dateUtils'
import { getCurrentUser } from '../../redux/selectors'
import { connect } from 'react-redux'
import caseEventProps from '../caseEventProps'
import eyeIcon from '../../icons/eye.svg'
import systemEvent from '../../icons/file-pen-solid.svg'
import I18n from '../I18n'

const CaseHistoryItem = ({ item, currentUser }) => {
  const { title } = caseEventProps[item.eventType] || {}
  const itemIsComment = () => item.isComment
  const currentUsersComment = () => item?.userId === currentUser?.id
  if (title || itemIsComment) {
    return (
      <Wrapper>
        <Grid
          itemIsComment={itemIsComment()}
          commentType={item.commentType}
          currentUsersComment={currentUsersComment()}
        >
          <Header>
            {itemIsComment()
              ? item.commentByUserName
              : title && title(item.payload)}
          </Header>
          <Date>{prettyDate(item.time)}</Date>
          <Content>
            {item.payload.commentDefinition ? (
              <I18n
                id={`case_event_comment_definition.${item.payload.commentDefinition}`}
              />
            ) : (
              item.payload?.comment || ''
            )}
          </Content>
          {itemIsComment() ? (
            <VisibilityContent>
              <VisibilityText>
                {!item.commentType.includes('management') ? (
                  <I18n id="All" />
                ) : (
                  <I18n id="Leader" />
                )}
              </VisibilityText>
              <Icon src={eyeIcon} />
            </VisibilityContent>
          ) : (
            <HistoryEventType>
              {['application_created', 'application_updated'].includes(
                item.eventType,
              ) ? (
                <I18n {...item.payload} id="application_id_updates" />
              ) : (
                <I18n {...item.payload} id="case_id_updates" />
              )}
              <Icon src={systemEvent} />
            </HistoryEventType>
          )}
        </Grid>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  margin: 0.5em 0;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 5fr 2.5fr;
  grid-template-rows: auto;
  ${({ hasPayload }) => hasPayload && 'cursor: pointer'};
  background-color: ${({
    itemIsComment,
    commentType,
    theme: { allCommentBackground, leaderCommentBackground, pageBackground },
  }) =>
    itemIsComment
      ? commentType?.includes('management')
        ? leaderCommentBackground
        : allCommentBackground
      : pageBackground};
  border-radius: 10px;
  padding: 0.5em 0em;
  border: 1px solid #ccc;
  & > :not(:last-child) {
    padding: 0.3em 0.5em;
  }
`

const Header = styled.div`
  font-weight: bold;

  font-size: ${(props) => props.theme.normalText};
  color: ${({ theme: { normalText } }) => normalText};
`

const Date = styled.div`
  color: ${({ theme: { subtleText } }) => subtleText};
  align-self: end;
  justify-self: end;
  margin-bottom: auto;
`
const Content = styled.div`
  font-size: ${(props) => props.theme.normalText};
`
const VisibilityContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  color: ${({ theme: { subtleText } }) => subtleText};
  font-size: ${({ theme: { smallText } }) => smallText};
`

const VisibilityText = styled.div`
  text-align: right;
  color: ${({ theme: { subtleText } }) => subtleText};
  font-size: ${({ theme: { smallText } }) => smallText};
`

const HistoryEventType = styled.div`
  align-self: end;
  justify-self: end;
  color: ${({ theme: { subtleText } }) => subtleText};
  font-size: ${({ theme: { smallText } }) => smallText};
`

const Icon = styled.img`
  height: 1em;
  width: 1em;
  margin: 0 0.5em;
`
const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
})

export default connect(mapStateToProps)(CaseHistoryItem)
