import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import queryString from 'query-string'
import ProtectedRoute from './ProtectedRoute'
import Profile from './pages/Profile'
import ReminderConfirm from './pages/ReminderConfirm'
import ReminderSuccess from './pages/ReminderSuccess'
import Users from './pages/Users'
import Companies from './pages/Companies'
import Company from './pages/Company'
import Case from './pages/Case'
import Cases from './pages/Cases'
import Applications from './pages/Applications'
import Thanks from './pages/Thanks'
import NotFound from './pages/NotFound'
import About from './pages/About'
import Faq from './pages/Faq'
import PrivacyStatement from './pages/PrivacyStatement'
import Settings from './pages/Settings'
import TermsAndConditions from './pages/Onboarding'
import { isAdmin, isManager, getCurrentUserId } from './redux/selectors'

const Router = (props) => (
  <Switch>
    <ProtectedRoute
      exact
      path="/"
      isAllowed={true}
      isManager={props.isManager}
      isAdmin={props.isAdmin}
      currentUserId={props.currentUserId}
      component={Profile}
    />
    <ProtectedRoute path="/profile" isAllowed={true} component={Profile} />
    <ProtectedRoute
      path="/onboarding"
      isAllowed={true}
      component={TermsAndConditions}
    />
    <ProtectedRoute
      path="/users/:userId"
      isAllowed={true}
      isManager={
        props.isManager &&
        process.env.REACT_APP_MANAGER_SHOW_EMLOYEES_ENABLED?.toLowerCase() ===
          'true'
      }
      isAdmin={props.isAdmin}
      currentUserId={props.currentUserId}
      component={(props) => (
        <Profile {...props} userId={props.match.params.userId} />
      )}
    />
    <ProtectedRoute
      path="/reminder/confirm"
      isAllowed={true}
      render={(props) => {
        const usersQs = queryString.parse(props.location.search).users
        const userIds = usersQs ? usersQs.split(',') : []
        return <ReminderConfirm userIds={userIds} />
      }}
    />
    <ProtectedRoute
      path="/reminder/success"
      isAllowed={true}
      render={ReminderSuccess}
    />
    <ProtectedRoute
      path="/users"
      isAllowed={
        props.isAdmin ||
        (props.isManager &&
          process.env.REACT_APP_MANAGER_SHOW_EMLOYEES_ENABLED?.toLowerCase() ===
            'true')
      }
      component={Users}
    />
    <ProtectedRoute
      path="/companies/:companyId"
      isAllowed={props.isAdmin}
      component={(props) => (
        <Company
          {...props}
          companyId={decodeURIComponent(props.match.params.companyId)}
        />
      )}
    />
    <ProtectedRoute
      path="/companies"
      isAllowed={props.isAdmin}
      component={Companies}
    />
    <ProtectedRoute
      path="/cases/:caseId"
      isAllowed={true}
      isManager={
        props.isManager &&
        process.env.REACT_APP_MANAGER_SHOW_EMLOYEES_ENABLED?.toLowerCase() ===
          'true'
      }
      isAdmin={props.isAdmin}
      currentUserId={props.currentUserId}
      component={(props) => (
        <Case {...props} caseId={props.match.params.caseId} />
      )}
    />
    <ProtectedRoute
      path="/cases"
      isAllowed={
        props.isAdmin ||
        (props.isManager &&
          process.env.REACT_APP_MANAGER_SHOW_EMLOYEES_ENABLED?.toLowerCase() ===
            'true')
      }
      component={Cases}
    />
    <ProtectedRoute
      path="/applications"
      isAllowed={
        (props.isAdmin || props.isManager) &&
        process.env.REACT_APP_APPLICATIONS_ENABLED?.toLowerCase() === 'true'
      }
      component={Applications}
    />
    <ProtectedRoute path="/thanks" isAllowed={true} component={Thanks} />
    <ProtectedRoute path="/about" isAllowed={true} component={About} />
    <ProtectedRoute path="/faq" isAllowed={true} component={Faq} />
    <ProtectedRoute
      path="/privacy"
      isAllowed={true}
      component={PrivacyStatement}
    />
    <ProtectedRoute
      path="/settings"
      isAllowed={props.isAdmin}
      component={Settings}
    />
    <Route path="/" isAllowed={true} component={NotFound} />
  </Switch>
)

const mapStateToProps = (state) => ({
  currentUserId: getCurrentUserId(state),
  isAdmin: isAdmin(state),
  isManager: isManager(state),
})

export default connect(mapStateToProps)(Router)
