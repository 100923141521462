import React from 'react'
import ListItem from './ListItem'
import otherIcon from '../../icons/other.svg'
import { useI18nFunction } from '../I18n'
import {
  USER_OTHER_CONNECTION,
  RELATION_OTHER_CONNECTION,
} from '../../connectionTypes'

const OtherListItem = (props) => {
  const I18nFunction = useI18nFunction();
  const connectionType = () => {
    if (props.item?.userOtherConnection != null) {
      return USER_OTHER_CONNECTION
    } else if (props.item?.relationOtherConnection != null) {
      return RELATION_OTHER_CONNECTION
    }
  }

  return (
    <li>
      <ListItem
        {...props}
        checked={true}
        image={otherIcon}
        title={
          props.userOtherConnection?.organizationName ||
          props.relationOtherConnection?.organizationName ||
          I18nFunction({ id: 'connection_list_item.other_title_fallback' })
        }
        description={
          props.item?.userOtherConnection?.title ||
          I18nFunction({ id: 'connection_list_item.other_description_fallback' })
        }
        connectionType={connectionType()}
        Case={props.item}
      />
    </li>
  )
}

export default OtherListItem
