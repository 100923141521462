import React from 'react'
import styled from 'styled-components'
import I18n from '../I18n'

const ProffRelationHeader = () => {
  return (
    <div>
      <Header>
        <h1>
          <I18n id="proff_id_form.relation_header" />
        </h1>
        <p>
          <I18n id="proff_id_form.sub_header" />
        </p>
      </Header>
      <h1>
        <I18n id="proff_id_form.form_header" />
      </h1>
      <Description>
        <I18n id="proff_id_form.relation_description" />
      </Description>
    </div>
  )
}

const Header = styled.section`
  background-color: ${({ theme: { secondaryBackground } }) =>
    secondaryBackground};
  padding: 1em 0.5em 1em;
  border-radius: 1em;
  & > * {
    max-width: ${({ theme: { maxWidth } }) => maxWidth};
    margin: 0.5em auto;
  }

  & > p {
    font-size: ${(props) => props.theme.largeText};
  }
`

const Description = styled.div`
  max-width: 800px;
`
export default ProffRelationHeader
