import React from 'react'
import styled from 'styled-components'
import I18n from './../I18n'
import Button from './../Button'

const ConfirmActionModal = ({confirm, cancel}) => {

    return (
        <Overlay onClick={cancel}>
            <Wrapper onClick={(e) => e.stopPropagation()}>
                  
                <Title>
                    <I18n id="connection_form.delete_connection" />
                </Title>
                <Body>
                    <I18n id="connection_form.deletion_info" />
                </Body>
                <ModalActions>
                    <Button onClick={confirm}>
                        <I18n id="Delete" />
                    </Button>
                    <Button subtle onClick={cancel} small>
                        <I18n id="Cancel" />
                    </Button>
                </ModalActions>
            </Wrapper>
        </Overlay>
    )
}

const Title = styled.h1`
  margin: 0;
  padding-bottom: 12px;
  border-bottom: 2px solid #eee;
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
`

const Wrapper = styled.div`
  color: ${(props) => props.theme.text};
  background-color: ${(props) => props.theme.primaryBackground};
  margin: 0 auto;
  min-height: 150px;
  min-width: 450px;
  -moz-box-shadow: 0px 0px 18px 6px #ccc;
  -webkit-box-shadow: 0px 0px 18px 6px #ccc;
  box-shadow: 0px 0px 18px 6px #ccc;
  padding: 2em;
  display: flex;
  flex-direction: column;
`


const Body = styled.div`
  flex-grow: 7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 2em 0em;
`

const ModalActions = styled.div`
  height: 50px;
  display: flex;
  justify-content: flex-end;
  border-top: 2px solid #eee;
  padding-top: 12px;
  & > * {
    margin: 0 1em;
  }
`

export default ConfirmActionModal