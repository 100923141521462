import React from 'react'
import ConnectionForm from './ConnectionForm'
import * as schema from '../../validation'
import I18n, { useI18nFunction } from '../I18n'
import {
  organizationName,
  organizationNumber,
  country,
  fromDate,
  toDate,
  comment,
  isCompanyRegistered,
  shareType,
  isSupplierInfo,
  isClientInfo,
  ownershipStatus,
  isCompanyListed,
  stockCount,
  stakePercentage,
  isCaseRelevant,
} from './fields'

const OwnershipForm = (props) => {
  const I18nFunction = useI18nFunction();
  
  return (
    <ConnectionForm
      {...props}
      heading={<I18n id="connection_form.headings.ownership" />}
      schema={schema.ownership(I18nFunction)}
      fields={fields}
    />
  )
}

export const fields =
  process.env.REACT_APP_SET_RELEVANT_CONNECTION_ENABLED?.toLowerCase() ===
  'true'
    ? [
        isCompanyRegistered(),
        country(),
        organizationName(),
        organizationNumber(),
        isSupplierInfo(),
        isClientInfo(),
        isCompanyListed(),
        shareType(),
        stockCount(),
        stakePercentage(),
        ownershipStatus(),
        fromDate(
          <I18n id="connection_form.labels.ownership_from" />,
          <I18n id="connection_form.help_texts.from_date_ownership" />,
        ),
        toDate(
          <I18n id="connection_form.labels.ownership_to" />,
          <I18n id="connection_form.help_texts.to_date_ownership" />,
        ),
        comment(),
        isCaseRelevant(),
      ]
    : [
        isCompanyRegistered(),
        country(),
        organizationName(),
        organizationNumber(),
        isSupplierInfo(),
        isClientInfo(),
        isCompanyListed(),
        shareType(),
        stockCount(),
        stakePercentage(),
        ownershipStatus(),
        fromDate(
          <I18n id="connection_form.labels.ownership_from" />,
          <I18n id="connection_form.help_texts.from_date_ownership" />,
        ),
        toDate(
          <I18n id="connection_form.labels.ownership_to" />,
          <I18n id="connection_form.help_texts.to_date_ownership" />,
        ),
        comment(),
      ]

export default OwnershipForm
