import React, { useState} from 'react'
import styled from 'styled-components'
import ApplicationDetailsModal from './ApplicationDetailsModal'
import I18n, {  useI18nFunction } from './I18n'
import { statusColor } from '../CaseStatus'
import {
  USER_OWNERSHIP_CASE,
  USER_ROLE_CASE,
  USER_OTHER_CONNECTION_CASE,
} from '../caseTypes'
import { camelize } from 'humps'
import { prettyDate } from '../dateUtils'

const CaseApplicationListItem = ({
  Case,
  application,
  isLatest,
  isAdmin,
  activeView,
}) => {
  const [showApplicationModal, setShowApplicationModal] = useState(false)
  const I18nFunction = useI18nFunction();
  switch (activeView) {
    case true:
      return (
        <WrapperActive>
          <Field>
            <Label>
              <I18n id="Apply_for" />{' '}
            </Label>
            <Value>
              {applicationDescription(Case, application, I18nFunction)}{' '}
              {Case.organizationName}{' '}
            </Value>
          </Field>
          <Field>
            <Label><I18n id='application_received' /></Label> 
            <Value>{prettyDate(application.createdOn)}</Value>
          </Field>
        </WrapperActive>
      )
    case false:
      return (
        <React.Fragment>
          <Wrapper onClick={() => setShowApplicationModal(true)}>
            <div>
              <I18n id="Application" /> #{application.id}
            </div>
            <ApplicationSummary>
              {applicationDescription(Case, application)}{' '}
              {Case.organizationName}
            </ApplicationSummary>
            <ApplicationStatus statusColor={statusColor(application.status)}>
              <I18n id={`case_statuses.${application.status}`} />
            </ApplicationStatus>
          </Wrapper>
          {showApplicationModal && (
            <ApplicationDetailsModal
              Case={Case}
              application={application}
              canEditStatus={isLatest}
              isAdmin={isAdmin}
              onClose={() => setShowApplicationModal(false)}
            />
          )}
        </React.Fragment>
      )
  }
}

const applicationDescription = (Case, application, I18nFunction) => {
  if (!Case.caseType) return ''
  switch (camelize(Case.caseType)) {
    case USER_OWNERSHIP_CASE:
      return `${I18nFunction({
        id: 'connection_list_item.ownership_application_description',
        stockCount: application.stockCount, 
        shareType: application.shareType,
      })} ${I18nFunction({id: 'in'})} `
    case USER_ROLE_CASE:
      return `${I18nFunction({id: 'is'})} ${Case.caseSubType} ${I18nFunction({id: 'in'})}`
    case USER_OTHER_CONNECTION_CASE:
      return `${I18nFunction({id: 'is'})} ${application.title} ${I18nFunction({id: 'in'})}`
    default:
      return ''
  }
}

const ApplicationSummary = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const Field = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5em;
  border-bottom: 1px solid #aaa;
`
const Label = styled.div`
  align-self: flex-start;
  width: 30%
  color: ${({ theme: { subtleText } }) => subtleText};
`

const Value = styled.div``

const ApplicationStatus = styled.div`
  border-left: ${({ statusColor }) => `12px solid ${statusColor}`};
  width: 20%;
  padding-left: 5px;
`

const Wrapper = styled.div`
  display: flex;
  text-decoration: none;
  border-bottom: 1px solid #aaa;
  color: inherit;
  background-color: ${(props) => props.theme.primaryBackground};
  align-items: center;
  padding: 1em;
  cursor: pointer;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  & > :not(:last-child) {
    margin-right: 1.5em;
  }

  &.active {
    border-color: ${({ theme: { primary } }) => primary};
  }
`
const WrapperActive = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-decoration: none;
  color: inherit;
  background-color: ${(props) => props.theme.primaryBackground};
  padding: 1em;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`

export default CaseApplicationListItem
