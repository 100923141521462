import React from 'react'
import styled from 'styled-components'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import nb from 'date-fns/locale/nb'
import { format } from 'date-fns'
import withErrorMessage from './withErrorMessage'
import { inputStyles } from './Input'

import 'react-datepicker/dist/react-datepicker.css'

registerLocale('nb', nb)

const DatePicker = (props) => (
  <StyledDatePicker
    {...props}
    value={undefined} // janky react-datepicker api
    autoComplete="off"
    id={props.name}
    selected={props.value ? new Date(props.value) : undefined}
    maxDate={
      props.name == 'fromDate' && !props.isApplication ? new Date() : undefined
    }
    minDate={
      (props.name == 'fromDate' || props.name == 'toDate') &&
      props.isApplication
        ? new Date()
        : undefined
    }
    showYearDropdown
    scrollableYearDropdown
    onChange={(date) =>
      props.onChange({
        target: {
          name: props.name,
          value: date ? format(date, 'yyyy-MM-dd') : null,
        },
      })
    }
    locale="nb"
    dateFormat="dd.MM.yyyy"
    disabled={props.disabled}
  />
)

const StyledDatePicker = styled(ReactDatePicker)`
  ${inputStyles};
`

export default withErrorMessage(DatePicker)
