import React, { useState } from 'react'
import styled from 'styled-components'
import { prettyDate } from '../../dateUtils'
import eventProps from './eventProps'
import caretDownSecondaryIcon from '../../icons/caret-down_secondary.svg'

const Event = (props) => {
  const [open, setOpen] = useState(false)

  const { image, renderText } = eventProps[props.eventType] || {}
  if (!image) return <div>{props.eventType}</div>

  return (
    <div className={props.className}>
      <Wrapper
        className={open ? 'toggle open' : 'toggle'}
        hasPayload={props.hasContent}
        onClick={props.hasContent ? () => setOpen((open) => !open) : undefined}
        tabIndex={props.hasContent ? '0' : undefined}
        onKeyDown={(e) => {
          if ([' ', 'Enter'].includes(e.key)) {
            e.preventDefault()
            setOpen((open) => !open)
          }
        }}
      >
        <Icon src={image} alt="" />
        <div className="date">{props.time || prettyDate(props.createdOn)}</div>
        <div>{renderText(props)}</div>
        {props.hasContent && (
          <Caret src={caretDownSecondaryIcon} alt="" open={open} />
        )}
      </Wrapper>
      {open && <Content className="content">{props.children}</Content>}
    </div>
  )
}

const Caret = styled.img`
  margin-left: auto;
  ${({ open }) => open && 'transform: rotate(180deg)'};
`

const Content = styled.div`
  overflow-x: auto;
  word-break: break-word;
`

const Wrapper = styled.div`
  ${({ hasPayload }) => hasPayload && 'cursor: pointer'};
  padding: 1.25em 1.5em;
  background-color: ${({ theme: { secondaryBackground } }) =>
    secondaryBackground};
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 1.5em;
  }

  & > .date {
    color: ${({ theme: { subtleText } }) => subtleText};
    flex-shrink: 0;
  }
`

const Icon = styled.img`
  width: 1.5em;
`

export default Event
