import React from 'react'
import styled from 'styled-components'
import SmallSpinner from '../SmallSpinner'

const DataListSpinner = () => {
  return (
    <CenterFlex>
      <SmallSpinner size={'3em'} color={'secondary'} />
    </CenterFlex>
  )
}

const CenterFlex = styled.div`
  display: flex;
  justify-content: center;
  padding: 2em;
`

export default DataListSpinner
