import React from 'react'
import styled from 'styled-components'
import I18n from '../I18n'
import Button from '../Button'
import ProffPersonList from './ProffPersonList'
import ProffConnectionList from './ProffConnectionsList'
import ProffUserHeader from './ProffUserHeader'
import ProffRelationHeader from './ProffRelationHeader'
import Spinner from '../Spinner'

const ProffSelector = ({
  activeProffPerson,
  currentUser,
  pending,
  proffSuggestions,
  setProffPersonId,
  setActiveProffPerson,
  unsetActiveProffPerson,
  personType,
  onClose,
  isProffSuggestionsFetching,
}) => {
  const handleSubmit = () => {
    if (personType == 'users') {
      setProffPersonId({
        userId: activeProffPerson.userId,
        proffShareholderId: activeProffPerson.proffShareholderId,
        proffPersonId: activeProffPerson.proffPersonId,
      })
    } else if (personType == 'relations') {
      setProffPersonId({
        relationId: activeProffPerson.userId,
        userId: currentUser.userId,
        proffShareholderId: activeProffPerson.proffShareholderId,
        proffPersonId: activeProffPerson.proffPersonId,
      })
      onClose()
    }
  }
  return (
    <div>
      {personType == 'users' && <ProffUserHeader currentUser={currentUser} />}
      {personType == 'relations' && <ProffRelationHeader />}
      {isProffSuggestionsFetching && personType == 'users' ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Layout>
            <PersonListWrapper>
              <Heading className="proff">
                <I18n id="proff_id_form.proff_hits" />{' '}
                {'(' + proffSuggestions.length + ')'}
              </Heading>
              <ProffPersonList
                proffSuggestions={proffSuggestions}
                activeProffPerson={activeProffPerson}
                userId={currentUser.id}
                setActiveProffPerson={setActiveProffPerson}
                unsetActiveProffPerson={unsetActiveProffPerson}
                personType={personType}
                isProffSuggestionsFetching={isProffSuggestionsFetching}
              />
              <ButtonWrapper>
                <SubmitButton
                  type="submit"
                  onClick={() => handleSubmit()}
                  pending={pending}
                  disabled={activeProffPerson.userId == undefined}
                  inactive={activeProffPerson.userId == undefined}
                >
                  <I18n id="Confirm" />
                </SubmitButton>
                {onClose && (
                  <Button subtle onClick={onClose} small>
                    <I18n id="Close" />
                  </Button>
                )}
              </ButtonWrapper>
            </PersonListWrapper>
            <ProffConnectionWrapper>
              {activeProffPerson !== undefined &&
                activeProffPerson.index !== undefined &&
                activeProffPerson.index !== 99 && (
                  <ConnectionWrapper>
                    {proffSuggestions[activeProffPerson.index]?.ownerships &&
                      proffSuggestions[activeProffPerson.index]?.ownerships
                        .length > 0 && (
                        <>
                          <Heading>
                            <I18n id="proff_id_form.Ownership_count" />{' '}
                            {'(' +
                              proffSuggestions[activeProffPerson.index]
                                .ownerships.length +
                              ')'}
                          </Heading>
                          <ProffConnectionList
                            connections={
                              proffSuggestions[activeProffPerson.index]
                                .ownerships
                            }
                          />
                        </>
                      )}
                    {proffSuggestions[activeProffPerson.index]?.roles &&
                      proffSuggestions[activeProffPerson.index]?.roles.length >
                        0 && (
                        <>
                          <Heading>
                            <I18n id="proff_id_form.Role_count" />{' '}
                            {'(' +
                              proffSuggestions[activeProffPerson.index].roles
                                .length +
                              ')'}
                          </Heading>
                          <ProffConnectionList
                            connections={
                              proffSuggestions[activeProffPerson.index].roles
                            }
                          />
                        </>
                      )}
                  </ConnectionWrapper>
                )}
            </ProffConnectionWrapper>
          </Layout>
        </Form>
      )}
    </div>
  )
}

const Heading = styled.label`
  font-size: ${(props) => props.theme.largeText};
  display: block;
  padding-top: 1em;
`
const Layout = styled.div`
  padding-top: 1em;
  display: flex;
`

const PersonListWrapper = styled.div`
  flex: 1;
`

const ProffConnectionWrapper = styled.div`
  flex: 0.8;
`

const ConnectionWrapper = styled.div``

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  :last-child {
    padding-right: 3em;
  }
`

const SubmitButton = styled(Button)`
  margin-top: 1em;
`

const Form = styled.form`
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
  margin: 0 auto;
`

const SpinnerWrapper = styled.div`
  padding-top: 5em;
`

export default ProffSelector
