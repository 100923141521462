import React from 'react'
import styled from 'styled-components'
import CaseListItem from './CaseListItem'
import { RELATION_CASE } from '../caseTypes'

const List = styled.ul`
  list-style: none;
  margin: 1em 0 2em;
  padding: 0;

  & > :not(:last-child) {
    margin-bottom: 1.5em;
  }
`

const CaseList = (props) => (
  <List>
    {props.cases &&
      props.cases.map((Case, index) => (
        <CaseListItem
          {...props}
          key={Case.caseType + Case.id}
          Case={Case}
          caseType={Case.caseType}
        />
      ))}
    {props.relations &&
      props.relations.map((relation, index) => (
        <CaseListItem
          {...props}
          key={relation.id}
          relation={relation}
          caseType={RELATION_CASE}
        />
      ))}
  </List>
)

export default CaseList
