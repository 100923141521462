// reducer.js
import {
  FETCH_APP_CONTENT_SUCCEEDED,
  FETCH_APP_CONTENT_FAILED,
  SET_PUBLIC_TEXTS,
} from './index'
import publicContent from './public_default'
import { getDefaultLanguage } from './languageUtils';

const language = getDefaultLanguage();

const initialState = publicContent[language] // Load the public content for the default language



export const contentTexts = (state = initialState, action) => {

  if (!action) {
    console.error('Reducer was called without an action')
    return state
  }
  switch (action.type) {
    case FETCH_APP_CONTENT_FAILED:
      return {
        ...state,
        error: action.errors,
      }
    case FETCH_APP_CONTENT_SUCCEEDED:
    case SET_PUBLIC_TEXTS:
    
      return action.payload
      
    default:
      return { ...state }
  }
}
