import { combineReducers } from 'redux'
import { CHECK_AUTH_STATUS, LOG_OUT, VERIFY_AUTH_CODE } from './actionTypes'
import authenticated, * as fromAuthenticated from './authenticated'
import admin, * as fromAdmin from './admin'
import pending, * as fromPending from './pending'
import logOutPending, * as fromLogOutPending from './logOutPending'

export { default as sagas } from './sagas'
export * from './actionTypes'

export const checkAuthStatus = () => ({ type: CHECK_AUTH_STATUS })

export const logOut = () => ({ type: LOG_OUT })

export const verifyAuthCode = ({ code, state }) => ({
  type: VERIFY_AUTH_CODE,
  code,
  state,
})

export const isAuthenticated = (state) =>
  fromAuthenticated.isAuthenticated(state.authenticated)

export const isAdmin = (state) => fromAdmin.isAdmin(state.admin)

export const isAuthPending = (state) => fromPending.isAuthPending(state.pending)

export const isLogOutPending = (state) =>
  fromLogOutPending.isPending(state.logOutPending)

export default combineReducers({
  authenticated,
  admin,
  pending,
  logOutPending,
})
