import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import I18n from './I18n'
import Link from './Link'
import CardDataList from './CardDataList'
import { isAdmin, isManager, getCurrentUserId } from '../redux/selectors'

//Icons
import PhoneIcon from '../icons/phone.svg'
import MailIcon from '../icons/mail.svg'
import usericon from '../icons/user.svg'

const showUserCardTitle = (user, isAdmin, isManager, currentUserId) => {
  if (
    (process.env.REACT_APP_MANAGER_SHOW_EMLOYEES_ENABLED?.toLowerCase() ===
      'true' &&
      isManager) ||
    isAdmin ||
    currentUserId == user.id
  ) {
    return <Link to={`/users/${user.id}`}>{user.name}</Link>
  }
  return <span>{user.name}</span>
}

const CaseUserCard = ({ user, isAdmin, isManager, currentUserId }) => {
  return (
    <React.Fragment>
      <DatalistWrapper>
        <Title>
          {showUserCardTitle(user, isAdmin, isManager, currentUserId)}
        </Title>
        <CardDataList
          data={displayedUserFields.map(
            ({ key, label, icon, hideIfValueIsFalsy }) => [
              label,
              i18nConnectionValue(key, user[key]),
              icon,
              hideIfValueIsFalsy,
            ],
          )}
        />
        <Line></Line>
        <SubTitle>
          <I18n id="user_header.manager" />
        </SubTitle>
        <CardDataList
          data={displayedManagerFields.map(({ key, label, icon }) => [
            label,
            i18nConnectionValue(key, user[key]),
            icon,
          ])}
        />
      </DatalistWrapper>
    </React.Fragment>
  )
}

const i18nConnectionValue = (key, value) => {
  switch (key) {
    case 'positionOfInfluence':
      return value ? (
        <I18n id="case_risk_categories.position_of_influence" />
      ) : (
        <I18n id="case_risk_categories.no_position_of_influence" />
      )
    case 'isBoardMember':
      return value ? <I18n id="user_properties.is_board_member" /> : undefined
    default:
      return value
  }
}

const displayedUserFields = [
  {
    label: <I18n id="user_properties.companyName" />,
    key: 'employeeOfOrganizationName',
  },
  {
    label: <I18n id="user_properties.department" />,
    key: 'department',
  },
  {
    label: <I18n id="user_properties.email" />,
    key: 'email',
    icon: MailIcon,
  },
  {
    label: <I18n id="user_properties.phone" />,
    key: 'phone',
    icon: PhoneIcon,
  },
  {
    label: <I18n id="user_properties.position_of_influence" />,
    key: 'positionOfInfluence',
    icon: usericon,
  },
  {
    label: <I18n id="user_properties.is_board_member" />,
    key: 'isBoardMember',
    icon: usericon,
    hideIfValueIsFalsy: true,
  },
]

const displayedManagerFields = [
  {
    label: <I18n id="user_properties.manager" />,
    key: 'managerName',
    icon: usericon,
  },
  {
    label: <I18n id="user_properties.manager_email" />,
    key: 'managerEmail',
    icon: MailIcon,
  },
  {
    label: <I18n id="user_properties.manager_phone" />,
    key: 'managerPhone',
    icon: PhoneIcon,
  },
]

const Line = styled.div`
  border-bottom: 2px dotted #000;
  margin: 1em;
`

const DatalistWrapper = styled.div`
  overflow-x: auto;
  word-break: break-word;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  border-radius: 10px;

  padding: 1.5em;
  background-color: ${({ theme: { primaryBackground } }) => primaryBackground};
`
const Title = styled.div`
  font-size: ${(props) => props.theme.largeText};
  text-overflow: ellipsis;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  margin-bottom: 1em;
  font-weight: bold;
`

const SubTitle = styled.div`
  font-size: ${(props) => props.theme.normalText};
  text-overflow: ellipsis;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  margin-bottom: 1em;
`

const mapStateToProps = (state, props) => {
  return {
    isAdmin: isAdmin(state),
    isManager: isManager(state),
    currentUserId: getCurrentUserId(state),
  }
}

export default connect(mapStateToProps)(CaseUserCard)
