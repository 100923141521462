import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled, { keyframes } from 'styled-components'
import { RELATION } from '../../connectionTypes'
import {
  currentUserhasConfirmedNoConnections,
  getActiveConnection,
  isConfirmNoConnectionsPending,
  isCancelConfirmNoConnectionsPending,
  isUserProfileDataFetching,
} from '../../redux/selectors'
import {
  confirmNoConnections,
  cancelConfirmNoConnections,
  unsetActiveConnection,
  applyConnection,
  addConnection,
} from '../../redux/actions'
import InfoBox from '../InfoBox'
import ProfileCaseList from './ProfileCaseList'
import ConnectionForm from '../ConnectionForm'
import AddConnectionButton from '../AddConnectionButton'
import Button from '../Button'
import checkmarkIcon from '../../icons/checkmark_circled_filled.svg'
import I18n, { useI18nFunction } from '../I18n'

const ProfileCaseSection = ({
  isUserProfileDataFetching,
  isCurrentUsersProfile,
  connectionFormTabName,
  userId,
  connectionType,
  addButtonText,
  applyForButtonText,
  confirmNoConnectionsButtonText,
  confirmedNoConnectionsButtonText,
  activeConnection,
  unsetActiveConnection,
  confirmNoConnections,
  hasConfirmedNoConnections,
  previousItems,
  currentItems,
  caseType,
  applicationItems,
  isConfirmNoConnectionsPending,
  cancelConfirmNoConnections,
  isCancelConfirmNoConnectionsPending,
  hasTransientConnection,
  hasApplicationComponents,
  items,
  applyConnection,
  addConnection,
}) => {
  const I18nFunction = useI18nFunction();

  const CURRENT = 'Current'
  const PREVIOUS = 'Previous'
  const APPLICATIONS = 'Applications'

  let sectionTabs = [
    {
      type: CURRENT,
      renderedItems: currentItems,
      condition: true,
    },
    {
      type: PREVIOUS,
      renderedItems: previousItems,
      condition: caseType !== RELATION,
    },
    {
      type: APPLICATIONS,
      renderedItems: applicationItems,
      condition: hasApplicationComponents,
    },
  ]

  const [tabIndex, setTabIndex] = useState(0)

  const onChangeTab = (tabIndex) => {
    setTabIndex(tabIndex)
    unsetActiveConnection()
    
  }
  
  return (
    <Wrapper>
      <SubMenuBar>
        <RegistrationTypeTabs>
          {sectionTabs
            .filter((t) => t.condition)
            .map((tab, index) => (
              <RegistrationTypeTabOption
                key={index}
                className={index === tabIndex ? 'active' : ''}
                onClick={() => onChangeTab(index)}
              >
                {I18nFunction({ id: `${tab.type}`})} ({tab.renderedItems.length})
              </RegistrationTypeTabOption>
            ))}
        </RegistrationTypeTabs>
      </SubMenuBar>

      <Layout>
        <ConnectionListWrapper>
          <ProfileCaseList
            isUserProfileDataFetching={isUserProfileDataFetching}
            items={sectionTabs[tabIndex].renderedItems}
            isCurrentUsersProfile={isCurrentUsersProfile}
            activeConnection={activeConnection}
            caseType={caseType}
            editable
          />
        </ConnectionListWrapper>

        {isCurrentUsersProfile && (
          <FormWrapper>
            {activeConnection && (
              <ConnectionFormWrapper>
                <ConnectionForm
                  key={activeConnection.id}
                  id={activeConnection.id}
                  caseId={activeConnection.caseId}
                  connectionType={activeConnection.connectionType}
                  userId={userId}
                  unsetActiveConnection={unsetActiveConnection}
                  connection={activeConnection}
                />
              </ConnectionFormWrapper>
            )}

            {!activeConnection && (
              <>
                {(!hasConfirmedNoConnections || items.length > 0) && (
                  <CenterFlex>
                    <AddConnectionButton
                      connectionType={connectionType}
                      userId={userId}
                      hasTransientConnection={hasTransientConnection}
                      action={
                        sectionTabs[tabIndex].type !== APPLICATIONS
                          ? addConnection
                          : applyConnection
                      }
                    >
                      {sectionTabs[tabIndex].type !== APPLICATIONS
                        ? addButtonText
                        : applyForButtonText}
                    </AddConnectionButton>
                  </CenterFlex>
                )}

                {!hasConfirmedNoConnections && items.length === 0 && (
                  <CenterFlex>
                    <I18n id="Or" />
                  </CenterFlex>
                )}

                <CenterFlex>
                  {items.length == 0 && (
                    <ButtonWrapper>
                      {hasConfirmedNoConnections && (
                        <Checkmark src={checkmarkIcon} alt="" />
                      )}
                      <Button
                        secondary={!hasConfirmedNoConnections ? 1 : 0}
                        onClick={
                          hasConfirmedNoConnections
                            ? undefined
                            : () => confirmNoConnections(connectionType)
                        }
                        pending={isConfirmNoConnectionsPending}
                      >
                        {hasConfirmedNoConnections
                          ? confirmedNoConnectionsButtonText
                          : confirmNoConnectionsButtonText}
                      </Button>
                    </ButtonWrapper>
                  )}
                  {hasConfirmedNoConnections && items.length === 0 && (
                    <CancelButton
                      subtle
                      onClick={() => cancelConfirmNoConnections(connectionType)}
                      pending={isCancelConfirmNoConnectionsPending}
                    >
                      <I18n id="Withdraw_confirmation" />
                    </CancelButton>
                  )}
                </CenterFlex>

                <InfoBox>
                  <I18n
                    id={`connection_form.tabs.${connectionFormTabName}.renderInfo`}
                  />
                </InfoBox>
              </>
            )}
          </FormWrapper>
        )}
      </Layout>
    </Wrapper>
  )
}

const float = keyframes`
  from {
    transform: translateX(700px);
  }
  to {
    transform: translateX(0px);
  }
`

const ConnectionFormWrapper = styled.div`
  animation: ${float} 200ms alternate ease-in-out;
`

const FormWrapper = styled.div`
  flex: 0.8;
`

const CenterFlex = styled.div`
  margin: 2em;
  display: flex;
  justify-content: center;
`

const SubMenuBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
`
const RegistrationTypeTabs = styled.div`
  display: flex;
  align-items: center;
`
const RegistrationTypeTabOption = styled.div`
  cursor: pointer;
  font-size: ${({ theme: { largeText } }) => largeText};
  font-weight: bold;

  color: ${({ theme: { subtleText } }) => subtleText};
  border-bottom: 2px solid
    ${({ theme: { secondaryBackground } }) => secondaryBackground};

  padding: 0.25em 0.5em;
  margin-right: 1em;
  &:hover {
    border-bottom: 3px solid
      ${({ theme: { secondaryBackground } }) => secondaryBackground};
  }
  &.active {
    color: ${({ theme: { text } }) => text};
    border-bottom: 3px solid ${({ theme: { primary } }) => primary};
  }
`

const CancelButton = styled(Button)`
  margin-top: 1em;
`

const ConnectionListWrapper = styled.div`
  flex: 1;
  min-width: 55%;
  position: relative;
`

const ButtonWrapper = styled.div`
  position: relative;
`

const Checkmark = styled.img`
  position: absolute;
  left: -2.5em;
  top: 50%;
  transform: translate(0, -50%);
`

const Layout = styled.div`
  padding-top: 1em;
  display: flex;
`
const Wrapper = styled.div`
  padding-top: 1em;
  display: flex;
  flex-direction: column;
`

const mapStateToProps = (state, props) => {
  const { caseType, connectionKeys, items } = props

  let applicationItems = []
  let previousItems = []
  let currentItems = []

  if (caseType != RELATION) {
    items
      .sort(sortByUpdatedOn)
      .sort((a, b) => sortByWhetherUserConfirmed(a, b, connectionKeys))

    let nonApplicationItems = items?.filter(
      (c) => c[connectionKeys.publicData] || c[connectionKeys.userRegistered],
    )

    previousItems = nonApplicationItems.filter((c) =>
      hasEnded(c[connectionKeys.userRegistered]),
    )
    currentItems = nonApplicationItems.filter(
      (c) => !hasEnded(c[connectionKeys.userRegistered]),
    )
    applicationItems = items?.filter(
      (c) => !c[connectionKeys.publicData] && !c[connectionKeys.userRegistered],
    )
  }
  if (caseType == RELATION) {
    currentItems = items.sort(sortByUpdatedOn)
  }

  return {
    isUserProfileDataFetching: isUserProfileDataFetching(state, props.userId),
    previousItems: previousItems,
    currentItems: currentItems,
    applicationItems: applicationItems,
    activeConnection: getActiveConnection(state),
    hasConfirmedNoConnections: currentUserhasConfirmedNoConnections(
      state,
      props.connectionType,
    ),
    isConfirmNoConnectionsPending: isConfirmNoConnectionsPending(
      state,
      props.connectionType,
    ),
    isCancelConfirmNoConnectionsPending: isCancelConfirmNoConnectionsPending(
      state,
      props.connectionType,
    ),
  }
}

const mapDispatchToProps = {
  unsetActiveConnection,
  confirmNoConnections,
  cancelConfirmNoConnections,
  applyConnection,
  addConnection,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCaseSection)

const hasEnded = (userRegisteredConnection) =>
  Date.parse(userRegisteredConnection?.toDate) < Date.now()

const sortByUpdatedOn = (a, b) => {
  if (a.updatedOn > b.updatedOn) return -1
  if (a.updatedOn < b.updatedOn) return 1
  return 0
}

const sortByWhetherUserConfirmed = (a, b, connectionKeys) => {
  if (!a[connectionKeys.userRegistered] && b[connectionKeys.userRegistered])
    return -1
  if (a[connectionKeys.userRegistered] && !b[connectionKeys.userRegistered])
    return 1
  return 0
}
