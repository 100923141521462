import React from 'react'
import styled from 'styled-components'
import I18n from '../I18n'
import ProffPersonListItem from './ProffPersonListItem'
import Spinner from '../Spinner'

const ProffPersonList = ({
  proffSuggestions,
  activeProffPerson,
  userId,
  setActiveProffPerson,
  unsetActiveProffPerson,
  personType,
  isProffSuggestionsFetching,
}) => {
  const onClick = (id) => {
    unsetActiveProffPerson()
    activeProffPerson && activeProffPerson.index == 99
      ? unsetActiveProffPerson()
      : setActiveProffPerson({
          index: id,
          userId: userId,
          proffPersonId: null,
          proffShareholderId: null,
        })
  }
  if (isProffSuggestionsFetching) return <Spinner />
  return (
    <>
      <Scrollbox>
        <List>
          {Object.keys(proffSuggestions).length == 0 && (
            <NoHitWrapper>
              <I18n id="proff_id_form.No_proff_person_hit" />
            </NoHitWrapper>
          )}
          {Object.keys(proffSuggestions).length !== 0 &&
            proffSuggestions.map((Person, index) => (
              <ProffPersonListItem
                key={index}
                active={activeProffPerson && activeProffPerson.index == index}
                activeProffPerson={activeProffPerson}
                Person={Person}
                listIndex={index}
                userId={userId}
                setActiveProffPerson={setActiveProffPerson}
                unsetActiveProffPerson={unsetActiveProffPerson}
              />
            ))}
        </List>
      </Scrollbox>
      <Wrapper
        key={userId}
        className={[
          activeProffPerson && activeProffPerson.index == 99 && 'active',
        ]}
        onClick={() => onClick(99)}
      >
        <Title>
          {personType == 'users' ? (
            <I18n id="proff_id_form.Not_in_proff" />
          ) : (
            <I18n id="proff_id_form.Relation_not_in_proff" />
          )}
        </Title>
      </Wrapper>
    </>
  )
}

const Scrollbox = styled.div`
  margin: 0;
  border-radius: 5px;
  position: relative;
  left: -50px;

  padding: 0em 1em;
  max-height: 300px;
  overflow: auto;
  --scrollbarBG: rgba(0, 0, 0, 0.1);
  --thumbBG: #90a4ae;

  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
`

const List = styled.ul`
  list-style: none;

  & > :not(:last-child) {
    margin-bottom: 1em;
  }
`

const NoHitWrapper = styled.div`
  font-weight: bold;
`
const Title = styled.div`
  font-size: ${({ theme: { largeText } }) => largeText};
  margin-bottom: 0.2em;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Wrapper = styled.li`
  box-shadow: rgb(219 219 219) 0px 2px 8px;
  border-radius: 5px;
  position: relative;
  background-color: ${({ theme }) => theme.primaryBackground};
  display: flex;
  align-items: center;
  padding: 1em;
  border: solid 2px ${({ theme: { primaryBackground } }) => primaryBackground};
  cursor: pointer;
  margin-top: 1em;
  margin-left: 0.25em;
  max-width: 92%;
  &.active {
    border-color: ${({ theme: { primary } }) => primary};
    box-shadow: rgb(180 180 200) 0px 2px 8px;
  }
`
export default ProffPersonList
