import React from 'react'
import styled from 'styled-components'
import { prettyTime } from '../../dateUtils'
import Event from './Event'

const StyledEvent = styled(Event)`
  & > .toggle {
    background-color: ${({ theme: { pageBackground } }) => pageBackground};
    border: solid 2px ${(props) => props.theme.pageBackground};
  }
  & > .toggle.open {
    background-color: ${(props) => props.theme.primaryBackground};
    border-color: ${(props) => props.theme.primary};
  }

  & > .content {
    background-color: ${(props) => props.theme.secondaryBackground};
  }
`

const SubEvent = (props) => (
  <StyledEvent {...props} time={prettyTime(props.createdOn)} />
)

export default SubEvent
