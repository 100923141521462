import React from 'react'
import styled from 'styled-components'
import ProfileCaseListItem from '../ProfileCaseListItem'
import ListItem from '../ProfileCaseListItem/ListItem'
import I18n from '../I18n'
import { isValidUUID } from '../../utils'
import { RELATION } from '../../connectionTypes'

const ProfileCaseListDefault = (props) => {
  if (props.items?.length == 0 && !isValidUUID(props.activeConnection?.id)) {
    return (
      <div>
        <I18n id="connection_form.No_connections_registered" />{' '}
      </div>
    )
  }

  const displayDraft = () => {
    const isTransientConnection = isValidUUID(props.activeConnection?.id)
    const isConfirmingPublicData = props.activeConnection?.connectionType
      ?.toLowerCase()
      .includes('public')
    const belongsToRelationCaseList =
      props.relationId === props.activeConnection?.relationId
    return (
      isTransientConnection &&
      !isConfirmingPublicData &&
      belongsToRelationCaseList
    )
  }

  return (
    <Scrollbox>
      <List>
        {displayDraft() && (
          <li>
            <ListItem draft={true} />
          </li>
        )}

        {props.items
          ?.filter((r) => !isValidUUID(r.id))
          .map((item, index) => (
            <ProfileCaseListItem
              key={props.caseType + item.id}
              {...props}
              {...item}
              item={item}
              active={
                (props.caseType !== RELATION &&
                  props.activeConnection?.caseId === item.id) ||
                (props.caseType === RELATION &&
                  props.activeConnection?.id === item.id)
              }
            />
          ))}
      </List>
    </Scrollbox>
  )
}

const Scrollbox = styled.div`
  margin: 0;
  border-radius: 5px;

  position: relative;
  left: -50px;

  padding: 0em 1em;
  max-height: 600px;
  overflow: auto;
  --scrollbarBG: rgba(0, 0, 0, 0.1);
  --thumbBG: #90a4ae;

  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
`

const List = styled.ul`
  list-style: none;

  & > :not(:last-child) {
    margin-bottom: 1em;
  }
`

export default ProfileCaseListDefault
