import React from 'react'
import styled from 'styled-components'
import companyIcon from '../../icons/company.svg'
import { prettyDate } from '../../dateUtils'
import companyEventProps from './companyEventProps'

const CompanyEvents = ({ events }) => {
    return (
      <List>
        {events.map((event, i) => (
          <li key={i}>
              <CompanyEvent {...event} />
          </li>
        ))}
      </List>
    )
  }


export default CompanyEvents

const List = styled.ul`
  list-style: none;
  margin: 1em 0 0 0;
  padding: 0;

  & > :not(:last-child) {
    margin-bottom: 0.75em;
  }
`

const CompanyEvent = (props) => {
  const { renderText } = companyEventProps[props.eventType] || {}
  return (
    <Wrapper>
      <Icon src={companyIcon} alt="" />
      <div className="date">{prettyDate(props.createdOn)}</div>
      <div>{renderText(props)}</div>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  ${({ hasPayload }) => hasPayload && 'cursor: pointer'};
  padding: 1.25em 1.5em;
  background-color: ${({ theme: { secondaryBackground } }) =>
    secondaryBackground};
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 1.5em;
  }

  & > .date {
    color: ${({ theme: { subtleText } }) => subtleText};
    flex-shrink: 0;
  }
`

const Icon = styled.img`
  width: 1.5em;
`