import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import I18n, { useI18nFunction } from '../I18n'
import { statusColor, StatusBadge } from '../../CaseStatus'
import {
  USER_OWNERSHIP_CASE,
  RELATION_OWNERSHIP_CASE,
  USER_ROLE_CASE,
  RELATION_ROLE_CASE,
  USER_OTHER_CONNECTION_CASE,
  RELATION_OTHER_CONNECTION_CASE,
} from '../../caseTypes'
import { camelize } from 'humps'
import caseProps from '../caseProps'
import { prettyDate } from '../../dateUtils'
import { fetchRelation, fetchUser } from '../../redux/actions'
import {
  getOwnershipStockCount,
  getOwnershipShareType,
  getOwnershipStakePercentage,
  getOtherConnectionDescription,
} from '../caseProps'
import CasePriorityWarningMarker from '../CaseListItem/CasePriorityWarningMarker'

const DataListItem = ({
  Item,
  userName,
  relation,
  isRelationCase,
  fetchUser,
  fetchRelation,
  secondary,
  Case,
  selectedCases,
  toggleCase,
  caseIdsToApprove
}) => {

  const I18nFunction = useI18nFunction();
  useEffect(() => {
    Item.userId && fetchUser(Item.userId)
  }, [fetchUser, Item.userId])

  useEffect(() => {
    Item.relationId && fetchRelation(Item.relationId, Item.userId)
  }, [fetchRelation, Item.relationId])

  const showRiskWarning =
    Item.positionOfInfluence ||
    Item.organizationIsCustomerForUserCompany ||
    Item.organizationIsSupplierForUserCompany ||
    Item.isClient ||
    Item.isSupplier ||
    Item.isBoardMember

  return (
    <Wrapper statusColor={statusColor(Item.status)}>
      {toggleCase && (
        <ToggleWrapper>
          <input
            type="checkbox"
            checked={!!selectedCases[Item.id]}
            onChange={() => toggleCase(Item.id)}
            disabled={!caseIdsToApprove.includes(Item.id)}
          />
        </ToggleWrapper>
      )}
      <CaseNumber>#{Item.caseId ? Item.caseId : Item.id}</CaseNumber>
      <CaseCreatedOn>{prettyDate(Item.createdOn)}</CaseCreatedOn>
      <CaseUpdatedOn>{prettyDate(Item.updatedOn)}</CaseUpdatedOn>
      <CaseRisk>
        {showRiskWarning && (
          <CasePriorityWarningMarker Item={Item.caseId ? Case : Item} />
        )}
      </CaseRisk>

      <IconWrapper>
        <Icon
          src={
            Item.caseId
              ? Case?.caseType
                ? caseProps[camelize(Case?.caseType)].image
                : undefined
              : Item?.caseType
              ? caseProps[camelize(Item?.caseType)].image
              : undefined
          }
          alt=""
        />
      </IconWrapper>
      <CaseSummary>
        <div>
          {isRelationCase && (
            <RelationOfUser>
              <I18n id="Relation" /> <I18n id="of" /> {userName}
            </RelationOfUser>
          )}
          {isRelationCase
            ? relation.firstName + ' ' + relation.lastName
            : userName}{' '}
          {Item.caseId
            ? `${I18nFunction({ id: 'Application' })} ${I18nFunction({ id: 'about' })} ${applicationDescription(Case, Item)} ${I18nFunction({ id: 'in' })} `
            : connectionDescription(Item, I18nFunction)}{' '}
          {Item.organizationName}
        </div>
      </CaseSummary>
      <CaseStatus>
        <StatusBadge status={Item.status} />
      </CaseStatus>
      <CaseLink
        to={`/cases/${Item.caseId ? Item.caseId : Item.id}`}
        secondary={secondary ? 'yes' : null}
      >
        <LinkText>
          <I18n id="Go_to_case" />
        </LinkText>
      </CaseLink>
    </Wrapper>
  )
}

const connectionDescription = (Item, I18nFunction) => {
  if (!Item?.caseType) return ''
  switch (camelize(Item?.caseType)) {
    case USER_OWNERSHIP_CASE:
    case RELATION_OWNERSHIP_CASE:
      return `${I18nFunction({ 
        id: 'connection_list_item.ownership_description', 
        stockCount: getOwnershipStockCount(Item),
        shareType: getOwnershipShareType(Item),
        stakePercentage: getOwnershipStakePercentage(Item)
      })} ${I18nFunction({ id: 'in' })} `;
    case USER_ROLE_CASE:
    case RELATION_ROLE_CASE:
      return `${I18nFunction({ id: 'is' })} ${Item.caseSubType} ${I18nFunction({ id: 'in' })}`;
    case USER_OTHER_CONNECTION_CASE:
    case RELATION_OTHER_CONNECTION_CASE:
      return `${I18nFunction({ id: 'is' })} ${getOtherConnectionDescription(Item)} ${I18nFunction({ id: 'in' })}`;
    default:
      return ''
  }
}

const applicationDescription = (Case, application) => {
  if (!Case?.caseType) return ''
  switch (camelize(Case?.caseType)) {
    case USER_OWNERSHIP_CASE:
    case RELATION_OWNERSHIP_CASE:
      return `${application.shareType}`
    case USER_ROLE_CASE:
    case RELATION_ROLE_CASE:
      return `${application.title}`
    default:
      return ''
  }
}

const RelationOfUser = styled.div`
  position: absolute;
  color: #aaa;
  top: 0.5em;
`

const Wrapper = styled.div`
  border-radius: 5px;
  position: relative;

  color: ${({ theme: { text } }) => text};
  display: flex;
  align-items: center;
  padding: 1em 1.5em;
  background-color: ${(props) =>
    props.secondary
      ? props.theme.secondaryBackground
      : props.theme.primaryBackground};
`

const Icon = styled.img`
  height: 2em;
  width: 2em;
`
const IconWrapper = styled.div`
  width: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CaseNumber = styled.h3`
  margin: 0;
  width: 7.5%;
`

const CaseCreatedOn = styled.h3`
  margin: 0;
  width: 10%;
`

const CaseUpdatedOn = styled.h3`
  margin: 0;
  width: 10%;
`

const CaseRisk = styled.div`
  width: 5%;
`

const CaseSummary = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
`

const CaseStatus = styled.div`
  border-left: ${({ statusColor }) => `12px solid ${statusColor}`};
  width: 15%;
`

const CaseLink = styled(Link)`
  text-decoration: none;
  width: 10%;
`

const LinkText = styled.div`
  flex-grow: 0;
  flex-basis: initial;
  white-space: nowrap;
  color: ${({ theme: { secondary } }) => secondary};
  font-size: ${({ theme: { largeText } }) => largeText};
`

const ToggleWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: calc(100% + 1em);
`

const mapDispatchToProps = { fetchRelation, fetchUser }

const mapStateToProps = (state, props) => {
  const users = state.users.byId || {}
  const relation = state.relations.byId[props.Item.relationId] || {}
  const cases = state.cases.byId || {}
  return {
    userName: users[props.Item.userId] && users[props.Item.userId].name,
    positionOfInfluence:
      users[props.Item.userId] && users[props.Item.userId].positionOfInfluence,
    relation,
    isRelationCase: props.Item.relationId ? true : false,
    Case: cases[props.Item.caseId],
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataListItem)
