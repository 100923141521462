// These must be the first lines in src/index.js
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { ThemeProvider } from 'styled-components'
import LanguageProvider from './components/LanguageProvider'
import store, { history } from './redux'
import App from './App'
import theme from './theme'
import GlobalStyle from './GlobalStyle'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <Provider store={store}>
    <LanguageProvider>
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <GlobalStyle />
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </React.Fragment>
      </ThemeProvider>
    </LanguageProvider>
  </Provider>
)
