import { useState } from 'react'
import { orderBy } from 'lodash'

const useSort = (initialValue) => {
  const [state, setState] = useState([initialValue])

  const iteratees = state.map((option) => option.value.iteratee)
  const orders = state.map((option) => option.value.order)

  return {
    setSort: (option) => setState((state) => [option, ...state]),
    sort: state[0],
    sortFunction: (collection) => orderBy(collection, iteratees, orders),
  }
}

export default useSort
