import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Page from '../components/Page'
import styled from 'styled-components'
import ToggleSwitch from '../components/ToggleSwitch'
import {
  fetchAppSettings,
  saveAppSettings,
  updateAppSetting,
} from '../redux/actions'
import {
  getAppSettings,
  appSettingsIsLoading,
  appSettingsHasLoaded,
  appSettingsHaveUpdates,
} from '../redux/selectors'
import Button from '../components/Button'
import I18n from '../components/I18n'

const Settings = ({
  pending,
  fetchAppSettings,
  saveAppSettings,
  updateAppSetting,
  appSettings,
  appSettingsIsLoading,
  appSettingsHasLoaded,
  appSettingsHaveUpdates,
}) => {
  const [sortedEventSettings, setSortedEventSettings] = useState({})

  const sortEventSettingsByCategory = (eSettings) => {
    const sortedEventSettingsObject = {}
    Object.values(eSettings)?.map((e) => {
      if (!(e.category in sortedEventSettingsObject)) {
        sortedEventSettingsObject[e.category] = [e]
      } else {
        sortedEventSettingsObject[e.category] = [
          ...sortedEventSettingsObject[e.category],
          e,
        ]
      }
    })

    return sortedEventSettingsObject
  }

  useEffect(() => {
    fetchAppSettings()
  }, [])

  useEffect(() => {
    if (
      appSettingsHasLoaded &&
      appSettings?.eventSettings &&
      Object.values(appSettings?.eventSettings).length > 0
    ) {
      setSortedEventSettings(
        sortEventSettingsByCategory(appSettings?.eventSettings),
      )
    }
  }, [appSettingsHasLoaded, appSettings])

  const saveAppSettingsBtnHandler = () => {
    saveAppSettings()
  }

  const settingValueChangeHandler = (previousValue, newValue, valueRef) => {
    updateAppSetting(previousValue, newValue, valueRef)
  }

  const settingsObject = {
    Settings: {
      sections: {},
    },
  }

  const [selectedSection, setSelectedSection] = useState(
    Object.keys(settingsObject)[0],
  )

  return (
    <Page pending={pending}>
      <Title>
        <I18n id="Settings" />
      </Title>
      <PageContent>
        <Menu>
          {Object.keys(settingsObject).map((k, i) => (
            <MenuItem
              key={i}
              active={k === selectedSection}
              sectionName={k}
              clickHandler={setSelectedSection}
            />
          ))}
        </Menu>
        <Controls>
          <CategoryTitle>
            <I18n id="notification_title" />
          </CategoryTitle>
          <SubTitle>
            <I18n id="settings.notification_description" />
          </SubTitle>
          {Object.keys(sortedEventSettings).map((category, index) => (
            <CategoryWrapper key={index}>
              <CategoryHeader>
                <I18n id={category} />
              </CategoryHeader>
              <EventNotificationControlItemHeaderWrapper>
                <p style={{ maxWidth: '20%' }}>
                  <I18n id="settings.type" />
                </p>
                <p>
                  <I18n id="settings.description" />
                </p>
                <Flex>
                  <p>
                    <I18n id="Admin_role" />
                  </p>
                  <p>
                    <I18n id="user_properties.manager" />
                  </p>
                  <p>
                    <I18n id="User" />
                  </p>
                </Flex>
              </EventNotificationControlItemHeaderWrapper>
              {sortedEventSettings[category]?.map((e, index) => (
                <EventNotificationControlItem
                  key={e.eventType + index}
                  onChangeHandler={settingValueChangeHandler}
                  event={e}
                />
              ))}
            </CategoryWrapper>
          ))}
        </Controls>
      </PageContent>
      <ActionBar>
        <Button
          disabled={!appSettingsHaveUpdates || appSettingsIsLoading}
          inactive={!appSettingsHaveUpdates}
          small
          onClick={() => saveAppSettingsBtnHandler()}
        >
          <I18n id="Save_changes" />
        </Button>
      </ActionBar>
    </Page>
  )
}

const MenuItem = ({ clickHandler, sectionName, active }) => {
  return (
    <MenuItemStyle
      key={sectionName}
      className={active ? 'active' : ''}
      onClick={() => clickHandler(sectionName)}
    >
      <I18n id={sectionName} />
    </MenuItemStyle>
  )
}

const EventNotificationControlItem = ({ event, onChangeHandler }) => {
  return (
    <EventNotificationControlItemWrapper>
      <EventNotificationControlItemContent>
        <p style={{ maxWidth: '20%' }}>
          <I18n id={`settings.event_type.${event.eventType}`} />
        </p>
        <p>
          <I18n id={`settings.event_description.${event.eventType}`} />
        </p>
        {event.notifyeeGroups && (
          <Flex>
            {Object.keys(event.notifyeeGroups).map((group, index) => (
              <div key={index}>
                <ToggleSwitch
                  onChangeHandler={onChangeHandler}
                  label={<I18n id="notification_title" />}
                  value={event.notifyeeGroups[group].notification.value}
                  valueRef={event.notifyeeGroups[group].notification.valueRef}
                ></ToggleSwitch>
                <ToggleSwitch
                  onChangeHandler={onChangeHandler}
                  label={<I18n id="Email" />}
                  value={event.notifyeeGroups[group].email.value}
                  valueRef={event.notifyeeGroups[group].email.valueRef}
                ></ToggleSwitch>
              </div>
            ))}
          </Flex>
        )}
      </EventNotificationControlItemContent>
      <div>
        {event.specificPropertyUpdateSettings?.settings?.map((s, index) => (
          <SubEventNotificationControlItem
            key={s.propertyName + index}
            sub_event={s}
            onChangeHandler={onChangeHandler}
          ></SubEventNotificationControlItem>
        ))}
      </div>
    </EventNotificationControlItemWrapper>
  )
}

const SubEventNotificationControlItem = ({ sub_event, onChangeHandler }) => {
  return (
    <SubEventNotificationControlItemContent>
      <p>
        <I18n id={`settings.event_type.sub_events.${sub_event.propertyName}`} />
      </p>
      {/* <p>{sub_event.propertyName}</p> */}
      <p>-</p>
      <Flex>
        {Object.keys(sub_event.notifyeeGroups).map((group, index) => (
          <div key={index}>
            <ToggleSwitch
              onChangeHandler={onChangeHandler}
              label={<I18n id="notification_title" />}
              value={sub_event.notifyeeGroups[group].notification.value}
              valueRef={sub_event.notifyeeGroups[group].notification.valueRef}
            ></ToggleSwitch>
            <ToggleSwitch
              onChangeHandler={onChangeHandler}
              label={<I18n id="Email" />}
              value={sub_event.notifyeeGroups[group].email.value}
              valueRef={sub_event.notifyeeGroups[group].email.valueRef}
            ></ToggleSwitch>
          </div>
        ))}
      </Flex>
    </SubEventNotificationControlItemContent>
  )
}

const CategoryHeader = styled.h1`
  margin: 0;
`

const CategoryWrapper = styled.div`
  margin-top: 3em;
`

const ActionBar = styled.div`
  position: sticky;
  display: flex;

  flex-direction: row;
  justify-content: flex-end;

  bottom: 0;

  width: 100%;
  background-color: ${({ theme: { pageBackground } }) => pageBackground};
  padding: 0.5em 4em;
  box-shadow: #ccc 0px 2px 6px 8px;
`

const SubEventNotificationControlItemContent = styled.div`
  background-color: #ddd;
  margin-left: 2em;
  width: calc(100%-4em);
  display: flex;
  align-items: center;
  > * {
    flex: 1;
  }
`

const Flex = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  > * {
    flex: 1;
  }
`

const EventNotificationControlItemContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  > * {
    flex: 1;
  }
`

const EventNotificationControlItemWrapper = styled.div`
  margin-bottom: 0.5em;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #ccc;
`

const EventNotificationControlItemHeaderWrapper = styled.div`
  padding: 0 1em;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #ccc;
  > * {
    flex: 1;
    font-weight: bold;
  }
`

const MenuItemStyle = styled.div`
  font-weight: bold;

  padding: 1em;
  color: #333;
  cursor: pointer;
  border-radius: 5px;

  &.active {
    color: white;
    background-color: ${({ theme: { secondary } }) => secondary};
  }
`

const PageContent = styled.div`
  display: flex;
  padding: 0 3em;
  margin: auto;
  margin-bottom: 100px;
`

const Menu = styled.div`
  border-right: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  width: 10%;
`

const Controls = styled.div`
  padding: 2em;
  padding-top: 0;
`

const CategoryTitle = styled.h1`
  margin: 0;
  font-weight: bold;
`

const Title = styled.h1`
  padding: 0 2em;
`

const SubTitle = styled.h2`
  padding: 0 1em;
`

const mapStateToProps = (state, props) => ({
  appSettings: getAppSettings(state),
  appSettingsIsLoading: appSettingsIsLoading(state),
  appSettingsHasLoaded: appSettingsHasLoaded(state),
  appSettingsHaveUpdates: appSettingsHaveUpdates(state),
})

const mapDispatchToProps = {
  fetchAppSettings,
  saveAppSettings,
  updateAppSetting,
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
