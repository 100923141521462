import React, { useContext } from 'react'
import { ConnectionFormContext } from './ConnectionForm'
import { ShareTypeSelector } from '../form'

const ConnectionInput = (props) => {
  const { connection, handleChange, handleBlur, visited, errors } = useContext(
    ConnectionFormContext,
  )

  return (
    <ShareTypeSelector
      name="shareType"
      value={connection.shareType}
      visited={visited.shareType}
      error={errors.shareType}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={
        connection.shareType != null && (props.disabled || connection.caseId)
      }
    />
  )
}

export default ConnectionInput
