import { combineReducers } from 'redux'
import byId, * as fromById from './byId'
import fetchStatus, * as fromFetchStatus from './fetchStatus'

export { default as sagas } from './sagas'

export const FETCH_CASE = 'FETCH_CASE'
export const FETCH_CASE_SUCCEEDED = 'FETCH_CASE_SUCCEEDED'
export const FETCH_CASE_FAILED = 'FETCH_CASE_FAILED'

export const FETCH_USER_RELATION_CASES = 'FETCH_USER_RELATION_CASES'
export const FETCH_USER_RELATION_CASES_SUCCEEDED =
  'FETCH_USER_RELATION_CASES_SUCCEEDED'
export const FETCH_USER_RELATION_CASES_FAILED =
  'FETCH_USER_RELATION_CASES_FAILED'

export const FETCH_ALL_CASES = 'FETCH_ALL_CASES'
export const FETCH_ALL_CASES_SUCCEEDED = 'FETCH_ALL_CASES_SUCCEEDED'
export const FETCH_ALL_CASES_FAILED = 'FETCH_ALL_CASES_FAILED'

export const COMMENT_ON_CASE = 'COMMENT_ON_CASE'
export const COMMENT_ON_CASE_SUCCEEDED = 'COMMENT_ON_CASE_SUCCEEDED'
export const COMMENT_ON_CASE_FAILED = 'COMMENT_ON_CASE_FAILED'

export const UPDATE_MANAGER_STATEMENT = 'UPDATE_MANAGER_STATEMENT'
export const UPDATE_MANAGER_STATEMENT_SUCCEEDED =
  'UPDATE_MANAGER_STATEMENT_SUCCEEDED'
export const UPDATE_MANAGER_STATEMENT_FAILED = 'UPDATE_MANAGER_STATEMENT_FAILED'

export const UPDATE_APPLICATION_MANAGER_STATEMENT =
  'UPDATE_APPLICATION_MANAGER_STATEMENT'
export const UPDATE_APPLICATION_MANAGER_STATEMENT_SUCCEEDED =
  'UPDATE_APPLICATION_MANAGER_STATEMENT_SUCCEEDED'
export const UPDATE_APPLICATION_MANAGER_STATEMENT_FAILED =
  'UPDATE_APPLICATION_MANAGER_STATEMENT_FAILED'

export const UPDATE_BOARD_STATEMENT = 'UPDATE_BOARD_STATEMENT'
export const UPDATE_BOARD_STATEMENT_SUCCEEDED =
  'UPDATE_BOARD_STATEMENT_SUCCEEDED'
export const UPDATE_BOARD_STATEMENT_FAILED = 'UPDATE_BOARD_STATEMENT_FAILED'

export const UPDATE_APPLICATION_BOARD_STATEMENT =
  'UPDATE_APPLICATION_BOARD_STATEMENT'
export const UPDATE_APPLICATION_BOARD_STATEMENT_SUCCEEDED =
  'UPDATE_APPLICATION_BOARD_STATEMENT_SUCCEEDED'
export const UPDATE_APPLICATION_BOARD_STATEMENT_FAILED =
  'UPDATE_APPLICATION_BOARD_STATEMENT_FAILED'

export const POST_BOARD_STATEMENT = 'POST_BOARD_STATEMENT'
export const POST_BOARD_STATEMENT_SUCCEEDED = 'POST_BOARD_STATEMENT_SUCCEEDED'
export const POST_BOARD_STATEMENT_FAILED = 'POST_BOARD_STATEMENT_FAILED'

export const POST_APPLICATION_BOARD_STATEMENT =
  'POST_APPLICATION_BOARD_STATEMENT'
export const POST_APPLICATION_BOARD_STATEMENT_SUCCEEDED =
  'POST_APPLICATION_BOARD_STATEMENT_SUCCEEDED'
export const POST_APPLICATION_BOARD_STATEMENT_FAILED =
  'POST_APPLICATION_BOARD_STATEMENT_FAILED'

export const UPDATE_CASE_STATUS = 'UPDATE_CASE_STATUS'
export const UPDATE_CASE_STATUS_SUCCEEDED = 'UPDATE_CASE_STATUS_SUCCEEDED'
export const UPDATE_CASE_STATUS_FAILED = 'UPDATE_CASE_STATUS_FAILED'

export const MULTI_APPROVE_CASES = 'MULTI_APPROVE_CASES'
export const MULTI_APPROVE_CASES_SUCCEEDED = 'MULTI_APPROVE_CASES_SUCCEEDED'
export const MULTI_APPROVE_CASES_FAILED = 'MULTI_APPROVE_CASES_FAILED'

export const UPDATE_APPLICATION_STATUS = 'UPDATE_APPLICATION_STATUS'
export const UPDATE_APPLICATION_STATUS_SUCCEEDED =
  'UPDATE_APPLICATION_STATUS_SUCCEEDED'
export const UPDATE_APPLICATION_STATUS_FAILED =
  'UPDATE_APPLICATION_STATUS_FAILED'

export const fetchCase = (caseId) => ({
  type: FETCH_CASE,
  caseId,
})

export const fetchAllCases = (
  pagination = { query: '', pageIndex: 1, perPage: 20 },
) => ({
  type: FETCH_ALL_CASES,
  pagination,
})

const pagination = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ALL_CASES_SUCCEEDED:
      return {
        ...action.payload.pagination,
      }
    default:
      return state
  }
}

export const commentOnCase = (caseId, comment, commentType) => ({
  type: COMMENT_ON_CASE,
  caseId,
  comment,
  commentType,
})

export const updateManagerStatement = (caseId, managerStatement) => ({
  type: UPDATE_MANAGER_STATEMENT,
  caseId,
  managerStatement,
})

export const updateApplicationManagerStatement = (
  caseId,
  applicationId,
  managerStatement,
) => ({
  type: UPDATE_APPLICATION_MANAGER_STATEMENT,
  caseId,
  applicationId,
  managerStatement,
})

export const updateBoardStatement = (caseId, boardStatement) => ({
  type: UPDATE_BOARD_STATEMENT,
  caseId,
  boardStatement,
})

export const updateApplicationBoardStatement = (
  caseId,
  applicationId,
  boardStatement,
) => ({
  type: UPDATE_APPLICATION_BOARD_STATEMENT,
  caseId,
  applicationId,
  boardStatement,
})

export const postBoardStatement = (file, caseId) => ({
  type: POST_BOARD_STATEMENT,
  file,
  caseId,
})

export const postApplicationBoardStatement = (file, caseId, applicationId) => ({
  type: POST_APPLICATION_BOARD_STATEMENT,
  file,
  caseId,
  applicationId,
})

export const updateApplicationStatus = (
  caseId,
  applicationId,
  newStatus,
  comment,
) => ({
  type: UPDATE_APPLICATION_STATUS,
  caseId,
  applicationId,
  newStatus,
  comment,
})

export const updateCaseStatus = (caseId, newStatus, comment) => ({
  type: UPDATE_CASE_STATUS,
  caseId,
  newStatus,
  comment,
})

export const multiApproveCases = (cases, newStatus, comment) => ({
  type: MULTI_APPROVE_CASES,
  cases,
  newStatus,
  comment,
})

export const getAllCases = (state) => fromById.getAllCases(state.byId)

export const shouldFetchCases = (state) =>
  fromFetchStatus.shouldFetchCases(state.fetchStatus)

export const isCasesPending = (state) =>
  fromFetchStatus.isCasesPending(state.fetchStatus)

export const getCase = (state, id) => fromById.getCase(state.byId, id)
export const shouldFetchCase = (state, id) =>
  fromById.shouldFetchCase(state.byId, id)
export const getCaseFetchStatus = (state, id) =>
  fromById.getCaseFetchStatus(state.byId, id)

export const getUserCasesOfType = (state, userId, caseType) =>
  fromById.getUserCasesOfType(state.byId, userId, caseType)

export const getAllUserRelationCases = (state, userId) =>
  fromById.getAllUserRelationCases(state.byId, userId)

export const getRelationCases = (state, relationId) =>
  fromById.getRelationCases(state.byId, relationId)

export const isCommentOnCasePending = (state, caseId) =>
  fromById.isCommentOnCasePending(state.byId, caseId)

export const getCasePagination = (state) => state.pagination

const postBoardStatementStatus = (state = 'not fetched', action) => {
  switch (action.type) {
    case POST_BOARD_STATEMENT:
    case POST_APPLICATION_BOARD_STATEMENT:
      return 'fetching'

    case POST_BOARD_STATEMENT_SUCCEEDED:
    case POST_APPLICATION_BOARD_STATEMENT_SUCCEEDED:
      return 'succeeded'

    case POST_BOARD_STATEMENT_FAILED:
    case POST_APPLICATION_BOARD_STATEMENT_FAILED:
      return 'failed'

    default:
      return state
  }
}

export const isPostBoardStatementPending = (state) =>
  'fetching' === state.postBoardStatementStatus

export default combineReducers({
  byId,
  fetchStatus,
  pagination,
  postBoardStatementStatus,
})
