import React from 'react'
import { prettyDate } from '../../dateUtils'
import CaseListItem from './CaseListItem'
import caseProps from '../caseProps'

const RoleCaseListItem = (props) => {
  return (
    <li>
      <CaseListItem
        {...props}
        image={caseProps[props.Case.caseType].image}
        title={props.Case.organizationName}
        description={props.Case.caseSubType}
        from={prettyDate(props.Case.updatedOn)}
        userConnection={
          !props.Case.caseType.includes('relation')
            ? props.Case.userRole
            : props.Case.relationRole
        }
        publicConnection={
          !props.Case.caseType.includes('relation')
            ? props.Case.userPublicDataRole
            : props.Case.relationPublicDataRole
        }
        userName={props.Case.userName}
        relationName={props.Case.relationName}
      />
    </li>
  )
}

export default RoleCaseListItem
