import { combineReducers } from 'redux'
import {
  FETCH_USER_EVENTS,
  FETCH_USER_EVENTS_SUCCEEDED,
  FETCH_USER_EVENTS_FAILED,
} from './index'

const ids = (state = {}, action) => {
  switch (action.type) {
    case FETCH_USER_EVENTS:
      return {
        ...state,
        [action.userId]: null,
      }

    case FETCH_USER_EVENTS_SUCCEEDED:
      return {
        ...state,
        [action.userId]: action.payload.result,
      }

    default:
      return state
  }
}

const status = (state = {}, action) => {
  switch (action.type) {
    case FETCH_USER_EVENTS:
      return {
        ...state,
        [action.userId]: 'fetching',
      }

    case FETCH_USER_EVENTS_SUCCEEDED:
      return {
        ...state,
        [action.userId]: 'succeeded',
      }

    case FETCH_USER_EVENTS_FAILED:
      return {
        ...state,
        [action.userId]: 'failed',
      }

    default:
      return state
  }
}

export const getIds = (state, userId) => state.ids[userId]

export const isFetching = (state, userId) => state.status[userId] === 'fetching'

export const shouldFetch = (state, userId) =>
  !['succeeded', 'failed'].includes(state.status[userId])

export default combineReducers({
  ids,
  status,
})
