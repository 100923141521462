export const USER_ROLE = 'userRole'
export const USER_OWNERSHIP = 'userOwnership'
export const USER_OTHER_CONNECTION = 'userOtherConnection'
export const RELATION = 'relation'
export const RELATION_ROLE = 'relationRole'
export const RELATION_OWNERSHIP = 'relationOwnership'
export const RELATION_OTHER_CONNECTION = 'relationOtherConnection'
export const PUBLIC_DATA_ROLE = 'publicDataRole'
export const PUBLIC_DATA_OWNERSHIP = 'publicDataOwnership'
export const RELATION_PUBLIC_DATA_ROLE = 'relationPublicDataRole'
export const RELATION_PUBLIC_DATA_OWNERSHIP = 'relationPublicDataOwnership'
