import styled from 'styled-components'
import {
  Tabs as RTabs,
  TabList as RTabList,
  Tab as RTab,
  TabPanel as RTabPanel,
} from 'react-tabs'

export const Tabs = styled(RTabs)`
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
  margin: 2em auto 0;
  padding: 0 3em;
`

export const TabList = styled(RTabList)`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-start;

  & > :not(:last-child) {
    margin-right: 2.5em;
  }
`

export const Tab = styled(RTab)`
  box-shadow: rgb(219 219 219) 0px 2px 8px;
  border-radius: 5px;
  flex-basis: 0px;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0px;
  padding: 1em 0;
  background-color: ${({ theme: { primaryBackground } }) => primaryBackground};
  font-size: ${({ theme: { largeText } }) => largeText};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme: { secondaryBackground } }) =>
      secondaryBackground};
  }

  &.react-tabs__tab--selected {
    background-color: ${({ theme: { primary } }) => primary};
    color: ${({ theme: { inverseText } }) => inverseText};
    padding-bottom: 1.5em;
    cursor: auto;
  }

  & > :not(:last-child) {
    margin-right: 1em;
  }
`

export const TabPanel = styled(RTabPanel)`
`
