import React from 'react'
import styled from 'styled-components'
import I18n from './I18n'
//import Link from './Link'
import CardDataList from './CardDataList'
import { fields as relationFields } from './ConnectionForm/RelationForm'
import { capitalize } from '../utils'
import { getDateWithoutTime } from '../dateUtils'

const CaseRelationCard = ({ relation }) => {
  return (
    <React.Fragment>
      <UserDetails relation={relation} />
    </React.Fragment>
  )
}

const UserDetails = ({ relation }) => {
  return (
    <React.Fragment>
      <DatalistWrapper>
        <Title>
          <I18n
            id={`connection_form.relation_types.${relation.relationType}`}
          />{' '}
          Relasjon: {relation.firstName} {relation.lastName}
        </Title>
        <CardDataList
          data={relationFields.map(({ key, label }) => [
            label,
            i18nConnectionValue(key, relation[key]),
          ])}
        />
      </DatalistWrapper>
    </React.Fragment>
  )
}

const i18nConnectionValue = (key, value) => {
  switch (key) {
    case 'isResidentAbroad':
      return value && <I18n id={capitalize(value)} />
    case 'relationType':
      return <I18n id={`connection_form.relation_types.${value}`} />
    case 'birthdate':
      return value && getDateWithoutTime(value)
    default:
      return value
  }
}

const DatalistWrapper = styled.div`
  overflow-x: auto;
  word-break: break-word;
  margin: 0.33em 0 0.7em 0;
  padding: 1.5em;
  background-color: ${({ theme: { primaryBackground } }) => primaryBackground};
  box-sizing: border-box;

  background: #ffffff;
  border-radius: 10px;
`

const Title = styled.div`
  font-size: ${(props) => props.theme.largeText};
  text-overflow: ellipsis;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  margin-bottom: 1em;
  font-weight: bold;
`

export default CaseRelationCard
