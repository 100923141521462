import { combineReducers } from 'redux'
import { UNSET_ACTIVE_CONNECTION } from '../connectionsForm'

export const SET_ACTIVE_PROFF_PERSON = 'SET_ACTIVE_PROFF_PERSON'
export const UNSET_ACTIVE_PROFF_PERSON = 'UNSET_ACTIVE_PROFF_PERSON'
export const FETCH_PROFF_PERSON_SUGGESTIONS = 'FETCH_PROFF_PERSON_SUGGESTIONS'
export const FETCH_PROFF_PERSON_SUGGESTIONS_SUCCEEDED =
  'FETCH_PROFF_PERSON_SUGGESTIONS_SUCCEEDED'
export const FETCH_PROFF_PERSON_SUGGESTIONS_FAILED =
  'FETCH_PROFF_PERSON_SUGGESTIONS_FAILED'

export const SET_PROFF_PERSON = 'SET_PROFF_PERSON'
export const SET_PROFF_PERSON_SUCCEEDED = 'SET_PROFF_PERSON_SUCCEEDED'
export const SET_PROFF_PERSON_FAILED = 'SET_PROFF_PERSON_FAILED'

export const SET_RELATION_PROFF_PERSON = 'SET_RELATION_PROFF_PERSON'
export const SET_RELATION_PROFF_PERSON_SUCCEEDED =
  'SET_RELATION_PROFF_PERSON_SUCCEEDED'
export const SET_RELATION_PROFF_PERSON_FAILED =
  'SET_RELATION_PROFF_PERSON_FAILED'

export const FETCH_RELATION_PROFF_PERSON_SUGGESTIONS =
  'FETCH_RELATION_PROFF_PERSON_SUGGESTIONS'
export const FETCH_RELATION_PROFF_PERSON_SUGGESTIONS_FAILED =
  'FETCH_RELATION_PROFF_PERSON_SUGGESTIONS_FAILED'
export const FETCH_RELATION_PROFF_PERSON_SUGGESTIONS_SUCCEEDED =
  'FETCH_RELATION_PROFF_PERSON_SUGGESTIONS_SUCCEEDED'

export { default as sagas } from './sagas'

export const fetchProffPersonSuggestions = (userId) => ({
  type: FETCH_PROFF_PERSON_SUGGESTIONS,
  userId,
})

export const fetchRelationProffPersonSuggestions = (relationId) => ({
  type: FETCH_RELATION_PROFF_PERSON_SUGGESTIONS,
  relationId,
})

export const setActiveProffPerson = ({
  index,
  userId,
  proffPersonId,
  proffShareholderId,
}) => ({
  type: SET_ACTIVE_PROFF_PERSON,
  index,
  userId,
  proffPersonId,
  proffShareholderId,
})

export const unsetActiveProffPerson = () => ({
  type: UNSET_ACTIVE_PROFF_PERSON,
})

export const getActiveProffPerson = (state) => state.activeProffPerson

export const setProffPersonId = ({
  userId,
  proffShareholderId,
  proffPersonId,
}) => ({
  type: SET_PROFF_PERSON,
  userId,
  proffShareholderId,
  proffPersonId,
})

export const setRelationProffPersonId = ({
  relationId,
  userId,
  proffShareholderId,
  proffPersonId,
}) => ({
  type: SET_RELATION_PROFF_PERSON,
  relationId,
  userId,
  proffShareholderId,
  proffPersonId,
})

export const getProffPersonSuggestions = (state) =>
  Object.values(state.userProffSuggestions)

export const isProffSuggestionsFetching = (state) =>
  ['fetching'].includes(state.userProffSuggestionsStatus)

export const activeProffPerson = (
  state = {
    index: undefined,
  },
  action,
) => {
  switch (action.type) {
    case SET_ACTIVE_PROFF_PERSON:
      return {
        ...state,
        index: action.index,
        userId: action.userId,
        proffPersonId: action.proffPersonId,
        proffShareholderId: action.proffShareholderId,
      }
    case UNSET_ACTIVE_PROFF_PERSON:
    case SET_PROFF_PERSON_SUCCEEDED:
    case SET_RELATION_PROFF_PERSON_SUCCEEDED:
    case UNSET_ACTIVE_CONNECTION:
      return {}
    default:
      return state
  }
}

const userProffSuggestions = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PROFF_PERSON_SUGGESTIONS:
    case FETCH_RELATION_PROFF_PERSON_SUGGESTIONS:
      return {
        ...state,
      }

    case FETCH_PROFF_PERSON_SUGGESTIONS_SUCCEEDED:
    case FETCH_RELATION_PROFF_PERSON_SUGGESTIONS_SUCCEEDED:
      if (Array.isArray(action.payload.result) && !action.payload.result.length){
        return {}
      }
      return {
        ...state,
        ...Object.values(action.payload.result),
      }

    case FETCH_PROFF_PERSON_SUGGESTIONS_FAILED:
    case FETCH_RELATION_PROFF_PERSON_SUGGESTIONS_FAILED:
      return {
        ...state,
      }
    case SET_PROFF_PERSON_SUCCEEDED:
    case SET_RELATION_PROFF_PERSON_SUCCEEDED:
    case UNSET_ACTIVE_CONNECTION:
      return {}
    default:
      return state
  }
}

const userProffSuggestionsStatus = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PROFF_PERSON_SUGGESTIONS:
    case FETCH_RELATION_PROFF_PERSON_SUGGESTIONS:
      return 'fetching'

    case FETCH_PROFF_PERSON_SUGGESTIONS_SUCCEEDED:
    case FETCH_RELATION_PROFF_PERSON_SUGGESTIONS_SUCCEEDED:
      return 'succeeded'

    case FETCH_PROFF_PERSON_SUGGESTIONS_FAILED:
    case FETCH_RELATION_PROFF_PERSON_SUGGESTIONS_FAILED:
      return 'failed'

    default:
      return state
  }
}

export default combineReducers({
  activeProffPerson,
  userProffSuggestions,
  userProffSuggestionsStatus,
})
