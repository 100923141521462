import React from 'react'
import CaseListItem from './CaseListItem'
import caseProps from '../caseProps'
import I18n from '../I18n'

const OtherCaseListItem = (props) => {
  return (
    <li>
      <CaseListItem
        {...props}
        image={caseProps[props.Case.caseType].image}
        title={props.Case.organizationName}
        titleFallback={<I18n id="connection_list_item.other_title_fallback" />}
        description={
          props.Case.userOtherConnection && props.Case.userOtherConnection.title
        }
        descriptionFallback={
          <I18n id="connection_list_item.other_description_fallback" />
        }
        userConnection={
          !props.Case.caseType.includes('relation')
            ? props.Case.userOtherConnection
            : props.Case.relationOtherConnection
        }
      />
    </li>
  )
}

export default OtherCaseListItem
