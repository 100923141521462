import styled, { css } from 'styled-components'

export const Dropdown = styled.div`
  box-shadow: rgb(219 219 219) 0px 2px 8px;
  border-radius: 5px;
  top: 100%;
  right: 0;
  min-width: 170px;
  position: absolute;
  margin-top: 1em;
  z-index: 1;
  & > :not(:last-child) {
    border-bottom: 1px solid #eee;
  }
`

export const MenuItem = styled.button`
  display: flex;
  align-items: center;
  text-align: left;
  min-width: 100%;
  background-color: transparent;
  border: none;
  padding: 1.2em;
  color: ${({ theme: { text } }) => text};
  background-color: ${({ theme: { primaryBackground } }) => primaryBackground};
  cursor: pointer;

  &:hover {
    background-color: #efefef;
  }

  & > :not(:last-child) {
    margin-right: 0.5em;
  }

  ${({ inactive }) => inactive && inactiveStyle};
`

const inactiveStyle = css`
  color: #a3a3a3;
  background-color: #efefef;
  cursor: initial;
  &:hover {
    background-color: #efefef;
  }
`
