import React from 'react'
import { connect } from 'react-redux'
import { getContent } from '../redux/selectors'
import { changeLanguage } from '../redux/actions'


export const LanguageContext = React.createContext()

const LanguageProvider = ({ children, texts }) => {

    const updateDocumentTitle = (title, organizationName) => {
        document.title = `${title} | ${organizationName}`;
    };
    updateDocumentTitle(texts.title, texts.client_organization_name)

  return (
    <LanguageContext.Provider
      value={{
        texts,
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}



const mapStateToProps = (state) => {
  return {
    texts: getContent(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return { 
    setReduxLanguage: (language) => dispatch(changeLanguage(language)) 
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageProvider)