import React from 'react'
import ErrorMessage from '../ErrorMessage'

const withErrorMessage = (WrappedComponent) =>
  function WithErrorMessage(props) {
    return (
      <div>
        <WrappedComponent {...props} />
        {props.visited && props.error && (
          <ErrorMessage>{props.error}</ErrorMessage>
        )}
      </div>
    )
  }

export default withErrorMessage
