import React from 'react'
import FaqListItem from '../components/FaqListItem'
import styled from 'styled-components'

const FaqList = ({ faqs }) => {
  return (
    <List>
      {faqs.map((faq, index) => {
        return (
          faq.question &&
          faq.answer && (
            <li key={index}>
              <FaqListItem question={faq.question} answer={faq.answer} index={index} />
            </li>
          )
        );
      })}
    </List>
  );
}

const List = styled.ul`
  list-style: none;
  margin: 0;
  margin-bottom: 5em;
  padding: 0;

  & > :not(:last-child) {
    margin-bottom: 0.5em;
  }
`

export default FaqList
