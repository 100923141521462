import React, { useState } from 'react'
import { connect } from 'react-redux'
import uuid from 'uuid/v4'
import styled from 'styled-components'
import {
  getRelationCases,
  hasConfirmedRelationProffSelection,
} from '../../redux/selectors'
import { addConnection, unsetActiveConnection } from '../../redux/actions'
import ListItem from './ListItem'
import Button from '../Button'
import {
  RELATION_ROLE,
  RELATION_OWNERSHIP,
  RELATION_OTHER_CONNECTION,
  RELATION,
} from '../../connectionTypes'
import relationIcon from '../../icons/relation.svg'
import I18n, { useI18nFunction } from '../I18n'
import ProfileCaseList from '../Profile/ProfileCaseList'

const RelationListItem = ({
  id: relationId,
  item,
  firstName,
  lastName,
  relationType,
  addConnection,
  activeConnection,
  active,
  connectionType,
  editable,
  connection,
  relationCases,
  isCurrentUsersProfile,
  disabledInteraction,
  hasConfirmedProffSelection,
  currentItems,
  previousItems,
}) => {
  const I18nFunction = useI18nFunction();

  const CURRENT = 'Current'
  const PREVIOUS = 'Previous'

  let sectionTabs = [
    {
      type: CURRENT,
      renderedItems: currentItems,
      condition: true,
    },
    {
      type: PREVIOUS,
      renderedItems: previousItems,
      condition: true,
    },
  ]

  const [tabIndex, setTabIndex] = useState(0)

  const onChangeTab = (tabIndex) => {
    setTabIndex(tabIndex)
    unsetActiveConnection()
  }

  return (
    <li>
      <ListItem
        connectionType={RELATION}
        relation={item}
        id={relationId}
        active={active}
        checked={hasConfirmedProffSelection}
        connection={connection}
        editable={editable}
        image={relationIcon}
        disabledInteraction={false}
        isCurrentUsersProfile={isCurrentUsersProfile}
        title={
          firstName && lastName
            ? `${firstName} ${lastName}`
            : firstName
            ? firstName
            : lastName
            ? lastName
            : undefined
        }
        titleFallback={
          <I18n id="connection_list_item.relation_title_fallback" />
        }
        description={I18nFunction({ id: `connection_form.relation_types.${relationType}` })}
        descriptionFallback={
          <I18n id="connection_list_item.relation_description_fallback" />
        }
      />
      <Children>
        {editable && (
          <>
            <ButtonsWrapper>
              {isCurrentUsersProfile && (
                <>
                  <Label>
                    <I18n id="Add" />:
                  </Label>
                  <Buttons>
                    <Button
                      small="true"
                      onClick={() => addConnection(RELATION_OWNERSHIP)}
                    >
                      <I18n id="Ownership" />
                    </Button>
                    <Button
                      small="true"
                      onClick={() => addConnection(RELATION_ROLE)}
                    >
                      <I18n id="Role" />
                    </Button>
                    <Button
                      small="true"
                      onClick={() => addConnection(RELATION_OTHER_CONNECTION)}
                    >
                      <I18n id="Other" />
                    </Button>
                  </Buttons>
                </>
              )}
            </ButtonsWrapper>
            <SubMenuBar>
              <RegistrationTypeTabs>
                {sectionTabs
                  .filter((t) => t.condition)
                  .map((tab, index) => (
                    <RegistrationTypeTabOption
                      key={index}
                      className={index === tabIndex ? 'active' : ''}
                      onClick={() => onChangeTab(index)}
                    >
                      {I18nFunction({ id: tab.type })} ({tab.renderedItems.length})
                    </RegistrationTypeTabOption>
                  ))}
              </RegistrationTypeTabs>
            </SubMenuBar>
          </>
        )}
        <ProfileCaseList
          relationId={relationId}
          isCurrentUsersProfile={isCurrentUsersProfile}
          items={sectionTabs[tabIndex].renderedItems}
          activeConnection={activeConnection}
          editable
          small
        />
      </Children>
    </li>
  )
}

const ButtonsWrapper = styled.div`
  margin: 1em 0;
  display: flex;
  align-items: center;
`

const Label = styled.div`
  color: ${({ theme: { subtleText } }) => subtleText};
  margin-right: 1em;
`

const Buttons = styled.div`
  flex-grow: 1;
  display: flex;

  & > * {
    flex-grow: 1;
    flex-basis: 0px;
    flex-shrink: 0;
    min-width: 0px;
  }

  & > :not(:last-child) {
    margin-right: 1em;
  }
`

const SubMenuBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
`
const RegistrationTypeTabs = styled.div`
  display: flex;
  align-items: center;
  padding: 1em 0;
`
const RegistrationTypeTabOption = styled.div`
  cursor: pointer;
  font-size: ${({ theme: { largeText } }) => largeText};
  font-weight: bold;

  color: ${({ theme: { subtleText } }) => subtleText};
  border-bottom: 2px solid
    ${({ theme: { secondaryBackground } }) => secondaryBackground};

  padding: 0.25em 0.5em;
  margin-right: 1em;
  &:hover {
    border-bottom: 3px solid
      ${({ theme: { secondaryBackground } }) => secondaryBackground};
  }
  &.active {
    color: ${({ theme: { text } }) => text};
    border-bottom: 3px solid ${({ theme: { primary } }) => primary};
  }
`

const Children = styled.div`
  margin-left: 7em;
`

const mapStateToProps = (state, props) => {
  const connectionKeys = {
    relationPublicDataOwnership: 'relationPublicDataOwnership',
    relationOwnership: 'relationOwnership',
    relationPublicDataRole: 'relationPublicDataRole',
    relationRole: 'relationRole',
    relationOtherConnection: 'relationOtherConnection',
  }
  const relationCases = getRelationCases(state, props.id)
  const hasConfirmedProffSelection = hasConfirmedRelationProffSelection(
    state,
    props.id,
  )
  const items = relationCases
  let previousItems = []
  let currentItems = []
  items
    .sort(sortByUpdatedOn)
    .sort((a, b) => sortByWhetherUserConfirmed(a, b, connectionKeys))

  let nonApplicationItems = items?.filter(
    (c) =>
      c[connectionKeys.relationPublicDataOwnership] ||
      c[connectionKeys.relationOwnership] ||
      c[connectionKeys.relationPublicDataRole] ||
      c[connectionKeys.relationRole] ||
      c[connectionKeys.relationOtherConnection],
  )

  previousItems = nonApplicationItems.filter((c) =>
    hasEnded(
      c[connectionKeys.relationOwnership] ||
        c[connectionKeys.relationRole] ||
        c[connectionKeys.relationOtherConnection],
    ),
  )
  currentItems = nonApplicationItems.filter(
    (c) =>
      !hasEnded(
        c[connectionKeys.relationOwnership] ||
          c[connectionKeys.relationRole] ||
          c[connectionKeys.relationOtherConnection],
      ),
  )

  return {
    previousItems: previousItems,
    currentItems: currentItems,
    relationCases,
    hasConfirmedProffSelection,
  }
}

const mapDispatchToProps = (dispatch, { id: relationId }) => ({
  addConnection: (connectionType) =>
    dispatch(
      addConnection({
        id: uuid(),
        relationId,
        connectionType,
      }),
    ),
  unsetActiveConnection: () => dispatch(unsetActiveConnection),
})

export default connect(mapStateToProps, mapDispatchToProps)(RelationListItem)

const hasEnded = (userRegisteredConnection) =>
  Date.parse(userRegisteredConnection?.toDate) < Date.now()

const sortByUpdatedOn = (a, b) => {
  if (a.updatedOn > b.updatedOn) return -1
  if (a.updatedOn < b.updatedOn) return 1
  return 0
}

const sortByWhetherUserConfirmed = (a, b, connectionKeys) => {
  if (!a[connectionKeys.userRegistered] && b[connectionKeys.userRegistered])
    return -1
  if (a[connectionKeys.userRegistered] && !b[connectionKeys.userRegistered])
    return 1
  return 0
}
