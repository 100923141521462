import React from 'react'
import { SimpleSelect as Select } from '../../form'
import shareTypes from './shareTypes.json'
import { useSelector } from 'react-redux'
import { getLanguage } from '../../../redux/selectors'

const ShareTypeSelector = (props) => {
  const language = useSelector((state) => getLanguage(state) )

  const share_types = shareTypes[language] || shareTypes.no
  return (
    <Select
      {...props}
      options={Object.entries(share_types).map(([code, name]) => ({
        label: name,
        value: code,
      }))}
      disabled={props.disabled}
      value={props.value}
    />
  )
}

export default ShareTypeSelector
