import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { isAdmin, isManager } from '../redux/selectors'
import I18n from './I18n'
import { connect } from 'react-redux'
import { getCurrentUser, isAuthenticated } from '../redux/selectors'

const Footer = ({ pending, isManager, isAdmin, isAuthenticated, currentUser }) => {
  return (
    <FooterWrapper>
      <I18n id="footer_copy_right" />
      <p>
        {!pending && isAuthenticated && currentUser && (
          <>
            <Link to="/about">
              <I18n id="Footer_About" />
            </Link>

            {' | '}
            <Link to="/faq">
              <I18n id="Footer_Faq" />
            </Link>

            {' | '}
            <Link to="/privacy">
              <I18n id="Footer_Privacy" />
            </Link>
          </>
        )}

        {!pending && isAdmin && isAuthenticated && currentUser && (
          <>
            {' | '}
            <FooterLink
              href={`https://csm.kpmg.no/csm`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <I18n id="service_desk_link" />
            </FooterLink>
          </>
        )}
        
      </p>
    </FooterWrapper>
  )
}

const FooterLink = styled.a`
  text-decoration: underline;
  color: white;
  &:hover {
    color: #ddd;
  }
`
export const footerHeight = '5.3em'

const FooterWrapper = styled.footer`
  height: ${footerHeight};
  background-color: ${({ theme: { primary } }) => primary};
  padding: 1px 0;
  text-align: center;
  color: white;
  a {
    color: white;
    &:hover {
      color: #ddd;
    }
  }
`

const mapStateToProps = (state) => ({
  isManager: isManager(state),
  isAdmin: isAdmin(state),
  isAuthenticated: isAuthenticated(state),
  currentUser: getCurrentUser(state),
})

export default connect(mapStateToProps)(Footer)
