import React, { useState } from 'react'
import I18n, {  useI18nFunction } from './I18n'
import styled from 'styled-components'
import Button from './Button'
import { statusColor } from '../CaseStatus'
import CaseStatusSelector from './CaseStatusSelector'
import { connect } from 'react-redux'
import { updateApplicationStatus } from '../redux/actions'
import ReactTextarea from 'react-textarea-autosize'

const ChangeApplicationStatusModal = ({
  Case,
  onClose,
  Application,
  updateApplicationStatus,
}) => {
  const [newStatus, setNewStatus] = useState(Application.status)
  const [statusChangeComment, setStatusChangeComment] = useState('')
  const I18nFunction = useI18nFunction();


  const onStatusSelected = (selectedStatus) => {
    setNewStatus(selectedStatus)
  }

  const onUpdateApplicationStatus = () => {
    updateApplicationStatus(
      Case.id,
      Application.id,
      newStatus,
      statusChangeComment,
    )
    onClose()
  }

  return (
    <Overlay onClick={onClose}>
      <ContentWrapper onClick={(e) => e.stopPropagation()}>
        <Title>
          <CurrentStatusWrapper>
            <CurrentStatusPrefix>
              <I18n id="Application" /> #{Application.id}
            </CurrentStatusPrefix>
            <Status statusColor={statusColor(newStatus)}>
              <I18n id={`case_statuses.${newStatus}`} />
            </Status>
          </CurrentStatusWrapper>
        </Title>
        <Body>
          <ChangeStatusWrapper>
            <NewStatus>
              <I18n id="New" /> status:
            </NewStatus>
            <CaseStatusSelector
              currentStatus={newStatus}
              onChange={onStatusSelected}
            />
          </ChangeStatusWrapper>
          <StyledTextarea
            placeholder={I18nFunction({ id: 'comment_placeholder' })}
            value={statusChangeComment}
            onChange={(e) => setStatusChangeComment(e.target.value)}
            required
            minRows={2}
          />
        </Body>
        <ModalActions>
          <SaveBtn
            inactive={
              newStatus === Application.status ||
              statusChangeComment.length === 0
            }
            disabled={
              newStatus === Application.status ||
              statusChangeComment.length === 0
            }
            onClick={() => onUpdateApplicationStatus()}
            small
          >
            <I18n id="Save" />
          </SaveBtn>
          <CloseBtn subtle onClick={onClose} small>
            <I18n id="Cancel" />
          </CloseBtn>
        </ModalActions>
      </ContentWrapper>
    </Overlay>
  )
}

const StyledTextarea = styled(ReactTextarea)`
  display: block;
  width: 100%;
  resize: none;
  padding: 1em;
  margin: 2em;
  border: none;
  line-height: inherit;
  margin: 1em auto;
`

const NewStatus = styled.p`
  font-weight: bold;
`

const ChangeStatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
`

const Status = styled.h1`
  align-self: flex-end;
  margin: 0;
  margin-left: 2em;
  border-bottom: ${({ statusColor }) => `5px solid ${statusColor}`};
`

const CurrentStatusPrefix = styled.h1`
  margin: 0;
`

const CurrentStatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const SaveBtn = styled(Button)``

const CloseBtn = styled(Button)``

const ModalActions = styled.div`
  height: 50px;
  display: flex;
  justify-content: flex-end;
  border-top: 2px solid #eee;
  padding-top: 12px;
  & > * {
    margin: 0 1em;
  }
`

const Body = styled.div`
  flex-grow: 7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2em;
`

const Title = styled.div`
  margin: 0;
  padding-bottom: 12px;
  border-bottom: 2px solid #eee;
`

const ContentWrapper = styled.div`
  background-color: ${(props) => props.theme.primaryBackground};
  margin: 0 auto;
  height: 350px;
  width: 450px;
  -moz-box-shadow: 0px 0px 18px 6px #ccc;
  -webkit-box-shadow: 0px 0px 18px 6px #ccc;
  box-shadow: 0px 0px 18px 6px #ccc;
  padding: 2em;
  display: flex;
  flex-direction: column;
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
`

const mapStateToProps = (state, props) => {
  return {}
}

const mapDispatchToProps = {
  updateApplicationStatus,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangeApplicationStatusModal)
