import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Page from '../components/Page'
import { height } from '../components/Header'
import Button from '../components/Button'
import I18n from '../components/I18n'

const InsufficientPermissions = () => (
  <Page>
    <Wrapper>
      <Content>
        <h1>
          <I18n id="Insufficient_permissions" />
        </h1>
        <Button as={Link} to="/">
          <I18n id="Go_to_front_page" />
        </Button>
      </Content>
    </Wrapper>
  </Page>
)

const Content = styled.div`
  margin: auto;
  text-align: center;
  font-size: ${(props) => props.theme.largeText};
  padding-bottom: 3em;
`

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - ${height});
`

export default InsufficientPermissions
