import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { hasValidConfirmationStatus } from '../utils'
import { prettyDate } from '../dateUtils'
import I18n from './I18n'
import checkmark from '../icons/checkmark_circled_filled.svg'
import redFlag from '../icons/red_flag.svg'
import SmallSpinner from './SmallSpinner'

const UserList = ({ users = [], selectedUsers, toggleUser, pending }) => {
  return (
    <>
      {pending ? (
        <CenterFlex>
          <SmallSpinner size={'3em'} color={'secondary'} />
        </CenterFlex>
      ) : (
        <List>
          {users.map(
            ({
              id,
              name,
              confirmedFormOn,
              lastLoggedIn,
              isBoardMember,
              admin,
            }) => (
              <Row key={id}>
                {toggleUser && (
                  <ToggleWrapper>
                    <input
                      type="checkbox"
                      checked={!!selectedUsers[id]}
                      onChange={() => toggleUser(id)}
                    />
                  </ToggleWrapper>
                )}
                <User to={`/users/${id}`}>
                  <Name>{name}</Name>
                  <Permissions>
                    <span>
                      {isBoardMember ? <>{' '}<I18n id='BoardRole' /></> : ''}
                      {isBoardMember && admin ? ',' : ''}
                      {admin ? <>{' '}<I18n id='Admin_role' /></> : ''}
                    </span>
                  </Permissions>
                  <div>
                    {lastLoggedIn ? (
                      prettyDate(lastLoggedIn)
                    ) : (
                      <I18n id="Never" />
                    )}
                  </div>
                  <LastConfirmed>
                    <Icon
                      src={
                        hasValidConfirmationStatus(confirmedFormOn)
                          ? checkmark
                          : redFlag
                      }
                      alt=""
                    />
                    {confirmedFormOn ? (
                      prettyDate(confirmedFormOn)
                    ) : (
                      <I18n id="Never" />
                    )}
                  </LastConfirmed>
                  <LinkText>
                    <I18n id="Go_to_profile" />
                  </LinkText>
                </User>
              </Row>
            ),
          )}
        </List>
      )}
    </>
  )
}

const CenterFlex = styled.div`
  display: flex;
  justify-content: center;
  padding: 2em;
`

const LastConfirmed = styled.div`
  display: flex;
  align-items: center;
`

const Icon = styled.img`
  height: 1.25em;
  width: 1.25em;
  margin-right: 1em;
`

const Row = styled.li`
  position: relative;
`

const ToggleWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: calc(100% + 1em);
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  & > :not(:last-child) {
    margin-bottom: 1em;
  }
`

const User = styled(Link)`
  text-decoration: none;
  color: ${({ theme: { text } }) => text};
  display: flex;
  padding: 1em 1.5em;
  width: 100%;
  background-color: ${({ theme: { primaryBackground } }) => primaryBackground};

  & > * {
    flex-grow: 1;
    flex-basis: 0px;
    flex-shrink: 0;
    min-width: 0px;
  }
`

const Name = styled.div`
  font-size: ${({ theme: { largeText } }) => largeText};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1.25;
`
const Permissions = styled.div`
  font-size: ${({ theme: { largeText } }) => largeText};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1.25;
`

const LinkText = styled.div`
  flex-grow: 0;
  flex-basis: auto;
  white-space: nowrap;
  color: ${({ theme: { secondary } }) => secondary};
  font-size: ${({ theme: { largeText } }) => largeText};
`

export default UserList
