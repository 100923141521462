export const SEND_REMINDER = 'SEND_REMINDER'
export const SEND_REMINDER_SUCCESS = 'SEND_REMINDER_SUCCESS'
export const SEND_REMINDER_FAILED = 'SEND_REMINDER_FAILED'

export { default as sagas } from './sagas'

export const sendReminder = (users, message) => ({
  type: SEND_REMINDER,
  users,
  message,
})

export const isFetcing = (state) => state.fetching

const initialState = {
  fetching: false,
  errorMessage: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SEND_REMINDER:
      return { fetching: true, errorMessage: null }
    case SEND_REMINDER_SUCCESS:
      return { fetching: false, errorMessage: null }
    case SEND_REMINDER_FAILED:
      return { fetching: false, errorMessage: action.message }
    default:
      return state
  }
}
