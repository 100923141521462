import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import makeAnimated from 'react-select/animated'

const MultiSelectFilter = ({
  filterOptions,
  placeholder,
  activeFilters,
  setActiveFilters,
  setFilterHandler,
}) => {
  const animatedComponents = makeAnimated()

  const handleToggleFilter = (filters) => {
    //Add toggled options
    filters
      .filter((filter) => !activeFilters.includes(filter))
      .forEach((filter) => {
        activeFilters.push(filter)
      })
    //Remove untoggled options
    filterOptions
      .map((o) => o.value)
      .filter((filter) => !filters.includes(filter))
      .forEach((filter) => {
        const index = activeFilters.indexOf(filter)
        if (index > -1) {
          activeFilters.splice(index, 1)
        }
      })
    setActiveFilters(activeFilters)
    setFilterHandler(activeFilters?.join(' '))
  }

  return (
    <Wrapper>
      <Select
        options={filterOptions}
        value={filterOptions.filter((o) => activeFilters.includes(o.value))}
        isMulti
        isClearable
        closeMenuOnSelect={true}
        placeholder={placeholder}
        isSearchable={false}
        components={animatedComponents}
        onChange={(options) => handleToggleFilter(options.map((o) => o.value))}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 0.5em 0.5em 0.5em 0.5em;
  position: relative;
`

export default MultiSelectFilter
