import React, { useEffect } from 'react'
import { replace } from 'connected-react-router'
import Page from '../components/Page'
import { connect } from 'react-redux'
import { verifyAuthCode } from '../redux/actions'
import { getErrorMessages } from '../redux/selectors'

const AuthVerify = ({ code, state, hasErrors, verifyAuthCode, replace }) => {
  useEffect(() => {
    verifyAuthCode({ code, state })
  }, [verifyAuthCode, code, state])

  useEffect(() => {
    hasErrors && window.history.replaceState(null, '', './') && replace('/')
  }, [hasErrors, replace])

  return <Page pending={true} /> 
}

const mapStateToProps = (state) => ({
  hasErrors: getErrorMessages(state).length > 0,
})

const mapDispatchToProps = { verifyAuthCode, replace }

export default connect(mapStateToProps, mapDispatchToProps)(AuthVerify)
