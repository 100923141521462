import ownership from '../icons/chartLine.svg'
import role from '../icons/role.svg'
import relation from '../icons/relation.svg'
import other from '../icons/other.svg'
import {
  USER_OWNERSHIP_CASE,
  USER_ROLE_CASE,
  USER_OTHER_CONNECTION_CASE,
} from './../caseTypes'
import {
  USER_ROLE,
  USER_OWNERSHIP,
  USER_OTHER_CONNECTION,
} from './../connectionTypes'

export default {
  userOwnershipCase: {
    image: ownership,
    title: '',
    description: '',
  },
  userRoleCase: {
    image: role,
    title: '',
    description: '',
  },
  userOtherConnectionCase: {
    image: other,
    title: '',
    description: '',
  },
  relation: {
    image: relation,
    title: '',
    description: '',
  },
  relationOwnershipCase: {
    image: ownership,
    title: '',
    description: '',
  },
  relationRoleCase: {
    image: role,
    title: '',
    description: '',
  },
  relationOtherConnectionCase: {
    image: other,
    title: '',
    description: '',
  },
}

export const getRoleTitle = (Case) => {
  const userRole = !isRelationCase(Case) ? Case.userRole : Case.relationRole
  const publicDataRole = !isRelationCase(Case)
    ? Case.userPublicDataRole
    : Case.relationPublicDataRole
  if (userRole) return userRole.title
  if (publicDataRole) return publicDataRole.title

  return Case ? getLatestApplication(Case).title : ''
}

export const getOwnershipStockCount = (Case) => {
  const userOwnership = !isRelationCase(Case)
    ? Case.userOwnership
    : Case.relationOwnership
  const publicDataOwnership = !isRelationCase(Case)
    ? Case.userPublicDataOwnership
    : Case.relationPublicDataOwnership
  if (userOwnership) return userOwnership.stockCount
  if (publicDataOwnership) return publicDataOwnership.stockCount

  return Case ? getLatestApplication(Case).stockCount : ''
}

export const getOwnershipShareType = (Case) => {
  const userOwnership = !isRelationCase(Case)
    ? Case.userOwnership
    : Case.relationOwnership
  const publicDataOwnership = !isRelationCase(Case)
    ? Case.userPublicDataOwnership
    : Case.relationPublicDataOwnership
  if (publicDataOwnership) return publicDataOwnership.shareType
  if (userOwnership) return userOwnership.shareType
  return getLatestApplication(Case).shareType
}

export const getOwnershipStakePercentage = (Case) => {
  const userOwnership = !isRelationCase(Case)
    ? Case.userOwnership
    : Case.relationOwnership
  const publicDataOwnership = !isRelationCase(Case)
    ? Case.userPublicDataOwnership
    : Case.relationPublicDataOwnership
  if (publicDataOwnership) {
    const num = publicDataOwnership.stakePercentage
    return parseFloat(num).toFixed(2)
  }
  if (userOwnership) {
    const num = userOwnership.stakePercentage
    return parseFloat(num).toFixed(2)
  }
  const num = getLatestApplication(Case).stakePercentage
  return parseFloat(num).toFixed(2)
}

export const getOtherConnectionDescription = (Case) => {
  const otherConnection = !isRelationCase(Case)
    ? Case.userOtherConnection
    : Case.relationOtherConnection
  if (otherConnection) return otherConnection.title
  return getLatestApplication(Case).title
}

const isRelationCase = (Case) => {
  return Case.caseType.includes('relation')
}

export const getLatestApplication = (Case) => {
  let applications = null
  if (Case.ownershipApplications.length > 0)
    applications = Case.ownershipApplications
  if (Case.roleApplications.length > 0) applications = Case.roleApplications
  if (Case.otherConnectionApplications.length > 0)
    applications = Case.otherConnectionApplications

  const latestApplication = applications
    ? applications.sort((a, b) => (a.createdOn < b.createdOn ? 1 : -1))[0]
    : null
  return latestApplication || {}
}

export const getApplications = (Case) => {
  let applications = null
  if (Case.ownershipApplications.length > 0)
    applications = Case.ownershipApplications
  if (Case.roleApplications.length > 0) applications = Case.roleApplications
  if (Case.otherConnectionApplications.length > 0)
    applications = Case.otherConnectionApplications
  return applications
}

export const prefilledApplicationPropertiesForCaseType = (Case) => {
  switch (Case.caseType) {
    case USER_OWNERSHIP_CASE:
      return {
        caseId: Case.id,
        organizationNumber: Case.organizationNumber,
        organizationName: Case.organizationName,
        isCompanyListed:
          (Case.userOwnership && Case.userOwnership.isCompanyListed) ||
          (Case.userPublicDataOwnership &&
            Case.userPublicDataOwnership.isCompanyListed),
        shareType: Case.caseSubType,
      }
    case USER_ROLE_CASE:
      return {
        caseId: Case.id,
        organizationNumber: Case.organizationNumber,
        organizationName: Case.organizationName,
        roleType:
          (Case.userRole && Case.userRole.roleType) ||
          (Case.userPublicDataRole && Case.userPublicDataRole.roleType),
        title: Case.caseSubType,
      }
    case USER_OTHER_CONNECTION_CASE:
      return {
        caseId: Case.id,
        organizationName: Case.organizationName,
        title: Case.caseSubType,
      }
    default:
      return {}
  }
}

export const existingCaseApplicationLockedFields = (caseType) => {
  switch (caseType) {
    case USER_OWNERSHIP_CASE:
      return [
        'isCompanyRegistered',
        'country',
        'organizationName',
        'organizationNumber',
        'shareType',
      ]
    case USER_ROLE_CASE:
      return [
        'isCompanyRegistered',
        'country',
        'organizationName',
        'organizationNumber',
        'roleType',
        'title',
      ]
    case USER_OTHER_CONNECTION_CASE:
      return ['organizationName', 'title']
    default:
      return []
  }
}

export const caseTypeToApplicationType = (caseType) => {
  switch (caseType) {
    case USER_OWNERSHIP_CASE:
      return USER_OWNERSHIP
    case USER_ROLE_CASE:
      return USER_ROLE
    case USER_OTHER_CONNECTION_CASE:
      return USER_OTHER_CONNECTION
    default:
      return []
  }
}
