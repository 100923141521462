import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { Dropdown, MenuItem } from './Dropdown'
import { getCurrentUser, isLogOutPending } from '../../redux/selectors'
import { logOut } from '../../redux/actions'
import Spinner from '../SmallSpinner'
import userIcon from '../../icons/user.svg'
import I18n from '../I18n'

const UserMenu = ({
  Backdrop,
  selected,
  onToolClicked,
  logOut,
  isLogOutPending,
  givenName,
  surName,
  isManager,
  admin,
}) => {
  const [isOpen, setIsOpen] = useState(selected)


  return (
    <Wrapper>
      <UserMenuStyle onClick={() => onToolClicked()}>
        {givenName && surName ? (
          <DataInitials
            data-initials={`${givenName?.charAt(0)}${surName?.charAt(0)}`}
          ></DataInitials>
        ) : (
          <img src={userIcon} alt="" />
        )}
      </UserMenuStyle>
      {isOpen && (
        <>
          <Backdrop />
          <Dropdown>
            {admin && (
              <MenuItemLink
                to="/settings"
                onClick={() => {
                  setIsOpen(false)
                }}
              >
                <I18n id="Settings" />
              </MenuItemLink>
            )}
            <MenuItem onClick={logOut}>
              {isLogOutPending && <Spinner color="text" />}
              <span>
                <I18n id="Log_out" />
              </span>
            </MenuItem>
          </Dropdown>
        </>
      )}
    </Wrapper>
  )
}

const DataInitials = styled.div`
  &:before {
    background: #3c91d4;
    color: white;
    opacity: 1;
    content: attr(data-initials);
    display: inline-block;

    border-radius: 50%;
    vertical-align: middle;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: ${({ theme: { normalText } }) => normalText};
  }
  &:hover {
    opacity: 0.8;
  }
`

const Wrapper = styled.div`
  position: relative;
  align-self: stretch;
`

const UserMenuStyle = styled.button`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: inherit;
`

const MenuItemLink = ({ children, ...rest }) => (
  <MenuItemLinkStyle {...rest}>
    <span>{children}</span>
  </MenuItemLinkStyle>
)

const MenuItemLinkStyle = styled(NavLink)`
  display: flex;
  align-items: center;
  text-align: left;
  min-width: 100%;
  background-color: transparent;
  text-decoration: none;
  border: none;
  padding: 1.2em;
  color: ${({ theme: { text } }) => text};
  background-color: ${({ theme: { primaryBackground } }) => primaryBackground};
  cursor: pointer;

  &:hover {
    background-color: #efefef;
  }

  & > :not(:last-child) {
    margin-right: 0.5em;
  }
`


const mapStateToProps = (state) => ({
  ...getCurrentUser(state),
  isLogOutPending: isLogOutPending(state),
})

const mapDispatchToProps = { logOut }

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu)
