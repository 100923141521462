import React, { useState } from 'react'
import styled from 'styled-components'
import { height } from './Header'
import { footerHeight } from './Footer'
import Button from './Button'
import I18n from './I18n'
import infoPrimaryIcon from '../icons/info_primary.svg'
import { useHistory } from 'react-router-dom'
import CheckBox from './CheckBox'
import { getOnboardingSlides } from '../redux/selectors'
import { connect } from 'react-redux'
import ImageComponent from './ImageComponent'
import theme from '../theme'

const TermsAndConditions = ({
  content,
  complete,
  completePending,
  hasConfirmedTermsAndConditions,
}) => {
  const [page, setPage] = useState(0)
  const initialCheckboxStates = content.map((slide) =>
    slide?.checkboxLabel ? false : null,
  )

  const checkBoxRequiredSlides = content.map((slide) =>
    slide?.checkboxLabel ? true : false,
  )

  const [checkboxStates, setCheckboxStates] = useState(initialCheckboxStates)
  const initialClickedNextStates = content.map(() => false)
  const [clickedNextcheckboxStates, setClickedNextCheckboxStates] = useState(
    initialClickedNextStates,
  )

  const handleCheckboxChange = (index, checked) => {
    const newCheckboxStates = [...checkboxStates]
    newCheckboxStates[index] = checked
    setCheckboxStates(newCheckboxStates)
  }

  const updateClickedNextCheckboxState = (pageIndex) => {
    const newClickedNextStates = [...clickedNextcheckboxStates]
    newClickedNextStates[pageIndex] = true
    setClickedNextCheckboxStates(newClickedNextStates)
  }

  const setError = (page) => {
    const checkboxRequired = checkBoxRequiredSlides[page]
    if (checkboxRequired) {
      return (
        !hasConfirmedTermsAndConditions &&
          clickedNextcheckboxStates[page] ? (
          <Error>
            <img src={infoPrimaryIcon} alt="" />
            <span>
              <I18n id={`onboarding.slides.slideContent[${page}].checkboxErrorText`} />
            </span>
          </Error>
        ) : (
          <Error></Error>
        )
      )
    }
  }


  const setNextButton = (page) => {
    const history = useHistory()
    const checkboxRequired = checkBoxRequiredSlides[page]
    const isCheckboxChecked = checkboxStates[page]
    const isLastSlide = page === content.length - 1

    if (checkboxRequired) {
      return (
        <ButtonWrapper>
          <Button
            onClick={() => {
              if (
                isLastSlide &&
                isCheckboxChecked &&
                !hasConfirmedTermsAndConditions
              ) {
                complete()
              } else if (
                !isCheckboxChecked &&
                !hasConfirmedTermsAndConditions
              ) {
                updateClickedNextCheckboxState(page)
              } else if (hasConfirmedTermsAndConditions && isLastSlide) {
                history.push('/') // This will redirect to "/"
              } else {
                setPage((page) => page + 1)
              }
            }}
            pending={completePending ? 1 : undefined}
            inactive={
              !isCheckboxChecked && !hasConfirmedTermsAndConditions
                ? 1
                : undefined
            }
          >
            <span>
              <I18n
                id={
                  isLastSlide
                    ? 'onboarding.to_next_section'
                    : 'onboarding.next_step'
                }
              />
            </span>
          </Button>
        </ButtonWrapper>
      )
    } else {
      return (
        <ButtonWrapper>
          <Button onClick={() => setPage((page) => page + 1)}>
            <span>
              <I18n
                id={
                  isLastSlide
                    ? 'onboarding.to_next_section'
                    : 'onboarding.next_step'
                }
              />
            </span>
          </Button>
        </ButtonWrapper>
      )
    }
  }

  return (
    <Wrapper>
      <ContentWrapper>
        <ColumnsWrapper>
          <LeftColumn>
            <ImageComponent fileName={content[page].image} />
          </LeftColumn>
          <RightColumn>
            <TextContent>
              <I18n id={`onboarding.slides.slideContent[${page}].text`} />
            </TextContent>
            {!hasConfirmedTermsAndConditions && checkBoxRequiredSlides[page] && (
              <CheckBox
                checked={checkboxStates[page]}
                handleCheckboxChange={handleCheckboxChange}
                page={page}
              />
            )}
            <div>{setError(page)}</div>
            <Buttons>
              <ButtonWrapper>
                {page === 0 ? (
                  <ButtonWrapper />
                ) : (
                  <Button
                      secondary
                      onClick={() => setPage((page) => page - 1)}
                    >
                    <span style={{ color: theme.primary }}>
                      <I18n id="onboarding.previous_step" />
                    </span>
                  </Button>
                )}
              </ButtonWrapper>
              <div>{setNextButton(page)}</div>
            </Buttons>
          </RightColumn>
        </ColumnsWrapper>
        <IndicatorsWrapper>
          <Indicators>
            {content.map((_, i) => (
              <Indicator key={i} active={page === i} />
            ))}
          </Indicators>
        </IndicatorsWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  display: flex;
  overflow: auto;
  height: calc(100vh - ${height} - ${footerHeight});
`

const ContentWrapper = styled.div`
  margin: auto;
  width: 90%;
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
`

const ColumnsWrapper = styled.div`
  display: flex;
`

const LeftColumn = styled.div`  
  padding: 1em;
`

const RightColumn = styled.div`
  padding: 1em;
  font-size: ${({ theme: { largeText } }) => largeText};
`

const TextContent = styled.div`
  overflow: auto;
  height: 350px;
`

const Error = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;

  & > :not(:last-child) {
    margin-right: 0.5em;
  }
`

const Buttons = styled.div`
  padding: 1em;
  display: flex;
  float: right;
`

const ButtonWrapper = styled.div`
  padding-left: 1em;
`

const IndicatorsWrapper = styled.div`
`

const Indicators = styled.ul`
  justify-content: center;
  display: flex;
  list-style: none;
  margin: 0;

  & > :not(:last-child) {
    margin-right: 1em;
  }
`

const Indicator = styled.li`
  height: 1.15em;
  width: 1.15em;
  border-radius: 100%;
  background-color: ${({ theme: { primary, secondaryBackground }, active }) =>
  active ? primary : secondaryBackground};
`

const mapStateToProps = (state) => {
  return {
    content: getOnboardingSlides(state)
  }
}

export default connect(mapStateToProps)(TermsAndConditions)

