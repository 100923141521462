import { combineReducers } from 'redux'

import { eventSettings, isLoading, hasLoaded, updates } from './eventSettings'

export { default as sagas } from './sagas'

export const FETCH_APP_SETTINGS = 'FETCH_APP_SETTINGS'
export const FETCH_APP_SETTINGS_SUCCEEDED = 'FETCH_APP_SETTINGS_SUCCEEDED'
export const FETCH_APP_SETTINGS_FAILED = 'FETCH_APP_SETTINGS_FAILED'

export const SAVE_APP_SETTINGS = 'SAVE_APP_SETTINGS'
export const SAVE_APP_SETTINGS_SUCCEEDED = 'SAVE_APP_SETTINGS_SUCCEEDED'
export const SAVE_APP_SETTINGS_FAILED = 'SAVE_APP_SETTINGS_FAILED'

export const UPDATE_APP_SETTING = 'UPDATE_APP_SETTING'
export const UPDATE_APP_SETTING_SUCCEEDED = 'UPDATE_APP_SETTING_SUCCEEDED'
export const UPDATE_APP_SETTING_FAILED = 'UPDATE_APP_SETTING_FAILED'

export const fetchAppSettings = () => ({
  type: FETCH_APP_SETTINGS,
})

export const saveAppSettings = () => ({
  type: SAVE_APP_SETTINGS,
})

export const updateAppSetting = (previousValue, newValue, valueRef) => ({
  type: UPDATE_APP_SETTING,
  previousValue,
  newValue,
  valueRef,
})

export const getEventSettings = (state) => Object.values(state.eventSettings)
export const getAppSettings = (state) => state
export const appSettingsIsLoading = (state) => state.isLoading
export const appSettingsHasLoaded = (state) => state.hasLoaded
export const appSettingsHaveUpdates = (state) =>
  Object.values(state.updates).length > 0

export default combineReducers({
  eventSettings,
  isLoading,
  hasLoaded,
  updates,
})
