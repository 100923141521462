import { takeEvery } from 'redux-saga/effects'
import {
  FETCH_USER_EVENTS,
  FETCH_USER_EVENTS_SUCCEEDED,
  FETCH_USER_EVENTS_FAILED,
} from './index'
import * as schema from '../schema'
import { fetchSaga } from '../sagas'
import { get } from '../fetch'

function* saga() {
  yield takeEvery(
    FETCH_USER_EVENTS,
    fetchSaga({
      SUCCEEDED: FETCH_USER_EVENTS_SUCCEEDED,
      FAILED: FETCH_USER_EVENTS_FAILED,
      schema: schema.arrayOfEvents,
      fetch: ({ userId }) => get(`/users/${userId}/events`),
    }),
  )
}

export default saga
