import { combineReducers } from 'redux'
import { FETCH_USER_PROFILE_DATA_SUCCEEDED } from '../users'
import { isBefore, subYears } from 'date-fns'
import { SET_RELATION_PROFF_PERSON_SUCCEEDED } from '../proffSelector'
import { FETCH_COMPANY_CONNECTIONS_SUCCEEDED } from '../companies'

export const FETCH_RELATION = 'FETCH_RELATION'
export const FETCH_RELATION_SUCCEEDED = 'FETCH_RELATION_SUCCEEDED'
export const FETCH_RELATION_FAILED = 'FETCH_RELATION_FAILED'

export const CONFIRM_RELATION = 'CONFIRM_RELATION'
export const CONFIRM_RELATION_SUCCEEDED = 'CONFIRM_RELATION_SUCCEEDED'
export const CONFIRM_RELATION_FAILED = 'CONFIRM_RELATION_FAILED'

export const CREATE_RELATION = 'CREATE_RELATION'
export const CREATE_RELATION_SUCCEEDED = 'CREATE_RELATION_SUCCEEDED'
export const CREATE_RELATION_FAILED = 'CREATE_RELATION_FAILED'

export const UPDATE_RELATION = 'UPDATE_RELATION'
export const UPDATE_RELATION_SUCCEEDED = 'UPDATE_RELATION_SUCCEEDED'
export const UPDATE_RELATION_FAILED = 'UPDATE_RELATION_FAILED'

export const REMOVE_RELATION = 'REMOVE_RELATION'
export const REMOVE_RELATION_SUCCEEDED = 'REMOVE_RELATION_SUCCEEDED'
export const REMOVE_RELATION_FAILED = 'REMOVE_RELATION_FAILED'

export { default as sagas } from './sagas'

export const fetchRelation = (id, userId) => ({
  type: FETCH_RELATION,
  id,
  userId,
})

export const confirmRelation = ({ id, userId }) => ({
  type: CONFIRM_RELATION,
  id,
  userId,
})

export const removeRelation = ({ id, userId }) => ({
  type: REMOVE_RELATION,
  id,
  userId,
})

const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_RELATION:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          fetchStatus: 'fetching',
        },
      }
    case FETCH_RELATION_FAILED:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          fetchStatus: 'failed',
        },
      }

    case REMOVE_RELATION_SUCCEEDED: {
      let newState = { ...state }
      delete newState[action.id]
      return newState
    }

    case FETCH_RELATION_SUCCEEDED:
    case CREATE_RELATION_SUCCEEDED:
    case UPDATE_RELATION_SUCCEEDED:
    case FETCH_USER_PROFILE_DATA_SUCCEEDED:
    case FETCH_COMPANY_CONNECTIONS_SUCCEEDED:
    case SET_RELATION_PROFF_PERSON_SUCCEEDED:
      return Object.entries(action.payload.entities.relation || {}).reduce(
        (state, [id, relation]) => ({
          ...state,
          [id]: {
            ...state[id],
            ...relation,
            fetchStatus: 'succeeded',
          },
        }),
        state,
      )

    default:
      return state
  }
}

export const shouldFetchRelation = (state, id) =>
  !state.byId[id] ||
  !['succeeded', 'fetching'].includes(state.byId[id].fetchStatus)
export const getRelation = (state, id) => state.byId[id]
export const getUserRelations = (state, userId) =>
  Object.values(state.byId).filter((r) => r.userId === userId)

export const hasConfirmedRelationProffSelection = (state, relationId) =>
  state.byId[relationId].completedProffSelection
    ? isBefore(
        subYears(Date.now(), 1),
        new Date(state.byId[relationId].completedProffSelection),
      )
    : false

export default combineReducers({
  byId,
})
