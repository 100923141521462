import { useState } from 'react'

export default () => {
  const [visited, setVisited] = useState({})

  const visitField = (field) =>
    setVisited((visited) => ({
      ...visited,
      [field]: true,
    }))

  const visitFields = (fields) =>
    setVisited((visited) => ({
      ...visited,
      ...fields.reduce((obj, field) => ({ ...obj, [field]: true }), {}),
    }))

  return { visited, visitField, visitFields }
}
