import React from 'react'
import styled, { css } from 'styled-components'
import withErrorMessage from './withErrorMessage'

const WrappedInput = (props) => (
  <Input
    disabled={props.disabled}
    id={props.name}
    {...props}
    value={props.value || ''}
  />
)

export const inputStyles = css`
  padding: 0.5em 0.64em 0.43em;
  border: none;
  line-height: inherit;
  width: 100%;
  border-bottom: 1px solid
    ${({ theme: { secondaryBackground } }) => secondaryBackground};

  ${({ visited, error, theme }) =>
    visited && error && 'outline-color: ' + theme.error};

  ${({ visited, error, theme }) =>
    visited && error && 'border-color: ' + theme.error};

  &:focus {
    border-color: ${({ visited, error, theme: { primary } }) =>
      !(visited && error) && primary};
  }

  &:disabled {
    background-color: #dadada;
  }
`

const Input = styled.input`
  ${inputStyles};
`

export default withErrorMessage(WrappedInput)
