import React from 'react'
import styled, { css } from 'styled-components'
import withErrorMessage from './withErrorMessage'

const WrappedtextArea = (props) => (
  <TextAreaInput
    disabled={props.disabled}
    id={props.name}
    {...props}
    value={props.value || ''}
  />
)

export const inputStyles = css`
  padding: 0.5em 0.64em 0.43em;
  border: none;
  line-height: inherit;
  width: 100%;
  border-bottom: 1px solid
    ${({ theme: { secondaryBackground } }) => secondaryBackground};

  ${({ error, theme, visited }) =>
    visited && error && 'outline-color: ' + theme.error};

  ${({ error, theme, visited }) =>
    visited && error && 'border-color: ' + theme.error};

  &:disabled {
    background-color: #dadada;
  }
`

const TextAreaInput = styled.textarea`
  ${inputStyles};
`

export default withErrorMessage(WrappedtextArea)
