import {
    SET_LANGUAGE
  } from './index'
import { getDefaultLanguage } from './languageUtils'

let initial_state = getDefaultLanguage()


const language = (state = initial_state, action) => {
    switch (action.type) {
        case SET_LANGUAGE:
        return action.payload

        default:
        return state
    }
}



export default language
  