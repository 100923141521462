import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import I18n, { useI18nFunction } from './I18n'
import {
  getCurrentUser,
  getUser,
  isAdmin,
  isSetBirthdatePending,
  isSetEmploymentEndDatePending,
  isSetAddressPending,
  isSetPhonePending,
} from '../redux/selectors'
import { setBirthdate, setEmploymentEndDate, setAddress, setPhone, fetchMe } from '../redux/actions'
import userIcon from '../icons/user.svg'
import mailIcon from '../icons/mail.svg'
import phoneIcon from '../icons/phone.svg'
import Button from './Button'
import BackButton from '../components/BackButton'
import { DatePicker, Input } from './form'
import { prettyDate } from '../dateUtils'
import flagIcon from '../icons/red_flag.svg'
import useValidation from '../hooks/useValidation'
import useVisited from '../hooks/useVisited'
import * as schema from '../validation'

const UserHeader = ({
  name,
  department,
  employmentEndDate,
  birthdate,
  address,
  postCode,
  postPlace,
  email,
  phone,
  managerPhone,
  managerName,
  managerEmail,
  admin,
  visitingUserIsAdmin,
  setBirthdate,
  setEmploymentEndDate,
  setAddress,
  setPhone,
  isSetBirthdatePending,
  isSetEmploymentEndDatePending,
  isSetAddressPending,
  isSetPhonePending,
  userId,
  jobTitle,
  positionOfInfluence,
  isBoardMember,
  boardMemberships,
  isCurrentUsersProfile,
  pending,
  fetchMe,
  hideEditBirthdate
}) => {
  const [editing, setEditing] = useState(false)
  const [editingBirthdate, setEditingBirthdate] = useState(false)
  const [editingAddress, setEditingAddress] = useState(false)
  const [editingPhone, setEditingPhone] = useState(false)
  const [tempEmploymentEndDate, setTempEmploymentEndDate] = useState(employmentEndDate)
  const [tempBirthdate, setTempBirthdate] = useState(birthdate)
  const [tempPhone, setTempPhone] = useState(phone)

  const [state, setState] = useState({
    address: address,
    postCode: postCode,
    postPlace: postPlace,
  })

  const I18nFunction = useI18nFunction()

  const { errors, validate } = useValidation(
    schema.addressForm(I18nFunction),
  )

  useEffect(() => {
    const cancelablePromise = validate(state)
    return cancelablePromise.cancel
  }, [validate, state])

  const { visited, visitField, visitFields } = useVisited()

  const handleBlur = (e) => visitField(e.target.name)

  const handleChange = (e) =>
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })

  const hasErrors = Object.keys(errors).length > 0

  const handleSubmit = () => {
    //e.preventDefault()
    if (hasErrors) {
      visitFields(Object.keys(errors))
    } else {
      setAddress(userId, state.address, state.postCode, state.postPlace)
    }
  }

  // const addressRegEx = /[^\p{L}\p{N}\s.,-/'#]/gu
   const numRegEx = /[^0-9]/g
  // const postPlaceRegEx = /[^\p{L}\s.,-/'#]/gu
  // const postCodeRegEx = /[^\p{L}\p{N}\s-]/gu

  const handlePhone = event => {
    let sanitized = event.target.value.replace(numRegEx, '');
    setTempPhone(sanitized)
  }

  useEffect(() => {
    if (!isSetBirthdatePending) {
      setEditingBirthdate(false)
    }
  }, [isSetBirthdatePending])

  useEffect(() => {
      fetchMe()
  }, [birthdate])

  useEffect(() => {
    if (!isSetEmploymentEndDatePending) {
      setEditing(false)
    }
  }, [isSetEmploymentEndDatePending])

  useEffect(() => {
    if (!isSetAddressPending) {
      setEditingAddress(false)
    }
  }, [isSetAddressPending])

  useEffect(() => {
    if (!isSetPhonePending) {
      setEditingPhone(false)
    }
  }, [isSetPhonePending])

  return (
    <OuterWrapper>
      {!isCurrentUsersProfile && visitingUserIsAdmin && (
        <BackButton 
          subtle 
          text={I18nFunction( { id:"user_header.back" } )}
        />
      )}
      <Wrapper>
        <Person>
          <Avatar src={userIcon} alt="" />
          <PersonInfo>
            <Name>{name || '-'}</Name>
            <Department>{department || '-'}</Department>
            <JobTitle>{jobTitle}</JobTitle>
            {positionOfInfluence && !isCurrentUsersProfile && (
              <Bold>
                <Warning>
                  <WarningIcon src={flagIcon} />
                  <I18n id="user_header.positionOfInfluence" />
                </Warning>
              </Bold>
            )}
            {isBoardMember && (
              <BoardRole>
                <Bold>
                  <I18n id="BoardRole" />
                </Bold>
                <BoardMemberships>
                  {boardMemberships?.map((b) => (
                    <BoardMembershipItem>
                      -{b.title} <I18n id="for" /> {b.organizationName} (
                      {b.organizationNumber})
                    </BoardMembershipItem>
                  ))}
                </BoardMemberships>
              </BoardRole>
            )}
            {admin && (
              <BoardRole>
                <Bold>
                  <I18n id="Admin_role" />
                </Bold>
              </BoardRole>
            )}
          </PersonInfo>
        </Person>
        <Info>
          {visitingUserIsAdmin && (
            <Field>
              <Label>
                <I18n id="user_header.end_date" />
              </Label>
              {editing ? (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault()

                    setEmploymentEndDate(userId, tempEmploymentEndDate)
                  }}
                >
                  <DatePicker
                    value={tempEmploymentEndDate}
                    onChange={(e) => setTempEmploymentEndDate(e.target.value)}
                    autoFocus
                  />
                  <Button
                    subtle
                    type="submit"
                    pending={isSetEmploymentEndDatePending}
                  >
                    <I18n id="Save" />
                  </Button>
                </Form>
              ) : (
                <>
                  <div>
                    {employmentEndDate ? prettyDate(employmentEndDate) : '-'}
                  </div>
                  <Button subtle onClick={() => setEditing(true)}>
                    <I18n id="Edit" />
                  </Button>
                </>
              )}
            </Field>
          )}
          <Field>
            <Label>
              <I18n id="user_header.birthdate" />
            </Label>
            {editingBirthdate ? (
              <Form
                onSubmit={(e) => {
                  e.preventDefault()
                  if(tempBirthdate){
                    setBirthdate(userId, tempBirthdate)
                  }
                }}
              >
                <BirthdateForm>
                    <DatePicker
                      value={tempBirthdate}
                      onChange={(e) => setTempBirthdate(e.target.value)}
                      autoFocus
                    />
                {!tempBirthdate && 
                  <BirthdateErrorMsg>
                    <I18n id="validation.required" />
                  </BirthdateErrorMsg>}
                </BirthdateForm>
              <Button
                    subtle
                    type="submit"
                    disabled={!tempBirthdate}
                    pending={isSetBirthdatePending}
                    >
                      <I18n id="Save" />
                    </Button>
            </Form>
            ) : (
              <>
                <div>
                  {birthdate ? prettyDate(birthdate) : '-'}
                </div>
                { !hideEditBirthdate &&
                  <Button subtle onClick={() => setEditingBirthdate(true)}>
                    <I18n id="Edit" />
                  </Button>
                }
              </>
            )}
          </Field>
          <Field>
            {editingAddress ? (
              <Form onSubmit={handleSubmit}>
                <AddressForm>
                  <AddressField>
                    <Label>
                      <I18n id="user_header.address_line" />
                    </Label>
                    <Input
                      name="address"
                      id="address"
                      value={state.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.address}
                      visited={visited.address}
                    />
                  </AddressField>
                  <AddressField>
                    <Label>
                      <I18n id="user_header.postCode" />
                    </Label>
                    <Input
                      name="postCode"
                      id="postCode"
                      value={state.postCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.postCode}
                      visited={visited.postCode}
                    />
                  </AddressField>
                  <AddressField>
                    <Label>
                      <I18n id="user_header.postPlace" />
                    </Label>
                    <Input
                      name="postPlace"
                      id="postPlace"
                      value={state.postPlace}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.postPlace}
                      visited={visited.postPlace}
                    />
                  </AddressField>
                </AddressForm>
                <Button
                  textOnly
                  type="submit"
                  onClick={() => handleSubmit()}
                  inactive={hasErrors}
                  pending={pending}
                >
                  <I18n id="Save" />
                </Button>
              </Form>
            ) : (
              <>
                <Label>
                  <I18n id="user_header.address" />
                </Label>
                {address && postCode && postPlace ? (
                  <>
                    <div>
                      {address + ", " + postCode + ", " + postPlace}
                    </div>
                    <Button subtle onClick={() => setEditingAddress(true)}>
                      <I18n id="Edit" />
                    </Button>
                  </>
                ) : (
                <div>-</div>
                )}
              </>
            )}
          </Field>
          <Field>
            <img src={mailIcon} alt="" />
            <Email>{email || '-'}</Email>
          </Field>
          <Field>
            <img src={phoneIcon} alt="" />
            {editingPhone ? (
              <Form
                onSubmit={(e) => {
                  e.preventDefault()
                  setPhone(userId, tempPhone)
                }}>
                <Input value={tempPhone}
                  onChange={(e) => handlePhone(e)}
                />
                <Button
                  subtle
                  type="submit"
                  pending={isSetPhonePending}
                >
                  <I18n id="Save" />
                </Button>
              </Form>
            ) : (
              <>
                <div>
                  {phone ? phone : '-'}
                </div>
              
                {/* Added functionality for change phone number field. The button is commented out, because the phone number is fetched from AD. */}
                {/* <Button subtle onClick={() => setEditingPhone(true)}>
                  <I18n id="Edit" />
                </Button> */}
              </>
            )}
          </Field>
        </Info>
        <Info>
          <Field>
            <Label>
              <I18n id="user_header.manager" />:
            </Label>
            <div>{managerName || '-'}</div>
          </Field>
          <Field>
            <img src={mailIcon} alt="" />
            <Email>{managerEmail || '-'}</Email>
          </Field>
          <Field>
            <img src={phoneIcon} alt="" />
            <div>{managerPhone || '-'}</div>
          </Field>
        </Info>
      </Wrapper>
    </OuterWrapper>
  )
}

const Form = styled.form`
  display: flex;

  & > :not(:last-child) {
    margin-right: 0.5em;
  }
`

const AddressForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1em;

`

const BirthdateForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2em;
`

const AddressField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > :not(:last-child) {
    margin-right: 0.5em;
  }
`

const Avatar = styled.img`
  height: 3em;
`

const PersonInfo = styled.div`
  & > * {
    margin: 0.33em 0;
  }
`

const Name = styled.h1``

const Department = styled.div``

const JobTitle = styled.div``

const Bold = styled.div`
  font-weight: bold;
`

const Person = styled.div`
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 1.5em;
  }
`

const Email = styled.div`
  word-break: break-all;
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & > :not(:last-child) {
    margin-bottom: 1em;
  }
`

const Label = styled.div`
  color: ${({ theme: { subtleText } }) => subtleText};
`

const BirthdateErrorMsg = styled.div`
  color: ${({ theme: { error } }) => error};
`

const Field = styled.div`
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 0.75em;
  }
`

const OuterWrapper = styled.div`
  background-color: ${({ theme: { secondaryBackground } }) =>
    secondaryBackground};
  padding: 1.5em 0;
`

const Wrapper = styled.div`
  border-radius: 5px;
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
  background-color: ${({ theme: { primaryBackground } }) => primaryBackground};
  margin: 0 auto;
  display: flex;

  & > * {
    width: 33%;
    margin: 1.5em 0;
    padding: 0.25em 1.5em;
  }

  & > :not(:first-child) {
    border-left: solid 0.33em ${({ theme: { primary } }) => primary};
  }
`

const BoardRole = styled.div`
  display: flex;
`
const BoardMemberships = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1em;
`

const BoardMembershipItem = styled.div`
  font-size: ${({ theme: { smallText } }) => smallText};
  padding: 0.25em;
`

const Warning = styled.div`
  font-size: ${(props) => props.theme.normalText};
  padding-top: 0.25em;
  font-weight: bold;
`

const WarningIcon = styled.img`
  height: 1em;
  width: 1em;
  margin-right: 0.5em;
  filter: invert(42%) sepia(89%) saturate(979%) hue-rotate(0deg)
    brightness(104%) contrast(105%);
`

const mapStateToProps = (state, props) => {
  const currentUser = getCurrentUser(state)
  return {
    ...getUser(state, props.userId),
    isCurrentUsersProfile: currentUser.id == props.userId,
    visitingUserIsAdmin: isAdmin(state),
    isSetBirthdatePending: isSetBirthdatePending(
      state,
      props.userId,
    ),
    isSetEmploymentEndDatePending: isSetEmploymentEndDatePending(
      state,
      props.userId,
    ),
    isSetAddressPending: isSetAddressPending(
      state,
      props.userId,
    ),
    isSetPhonePending: isSetPhonePending(
      state,
      props.userId,
    ),
  }
}

const mapDispatchToProps = { setBirthdate, setEmploymentEndDate, setAddress, setPhone, fetchMe }

export default connect(mapStateToProps, mapDispatchToProps)(UserHeader)
