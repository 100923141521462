import React from 'react'
import I18n from './I18n'

export default function CheckBox(props) {
  return (
    <p>
      <input
        type="checkbox"
        id="coc_checkbox"
        checked={props.checked}
        onChange={() => props.handleCheckboxChange(props.page, !props.checked)}
        style={{ marginRight: '0.5em' }}
      ></input>
      <label htmlFor="coc_checkbox">
        <I18n id={`onboarding.slides.slideContent[${props.page}].checkboxLabel`} />
      </label>
    </p>
  )
}
