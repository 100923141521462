export { default as sagas } from './sagas'

export const FETCH_SUGGESTIONS = 'FETCH_SUGGESTIONS'
export const FETCH_SUGGESTIONS_SUCCEEDED = 'FETCH_SUGGESTIONS_SUCCEEDED'
export const FETCH_SUGGESTIONS_FAILED = 'FETCH_SUGGESTIONS_FAILED'

export const fetchSuggestions = (query) => ({
  type: FETCH_SUGGESTIONS,
  query,
})

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_SUGGESTIONS_SUCCEEDED:
      return {
        ...state,
        [action.query]: Object.values(action.payload.entities.suggestion || {}),
      }
    default:
      return state
  }
}

export const getSuggestions = (state, query) => state[query]
