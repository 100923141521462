import React from 'react'
import styled from 'styled-components'
import { ReactComponent as PublicInfoIcon } from '../../icons/building-columns-solid.svg'
import { ReactComponent as ApplicationIcon } from '../../icons/file-pen-solid.svg'
import { ReactComponent as UserReportedIcon } from '../../icons/user-solid.svg'
import { useI18nFunction } from '../I18n'

const CaseDataSourceMarker = ({ Case, withTooltip, color }) => {
  const I18nFunction = useI18nFunction();
  const iconStyle = {
    display: 'block',
    margin: 'auto',
    fill: color ? color : '#999',
    width: '1em',
    height: '1em',
    alignmentBaseline: 'central',
  }

  const markerTypes = {
    application: {
      icon: <ApplicationIcon style={iconStyle}></ApplicationIcon>,
      text: I18nFunction({ id: 'connection_data_source.application' }),
    },
    public: {
      icon: <PublicInfoIcon style={iconStyle}></PublicInfoIcon>,
      text: I18nFunction({ id: 'connection_data_source.public_information' }),
    },
    self_registered: {
      icon: <UserReportedIcon style={iconStyle}></UserReportedIcon>,
      text: I18nFunction({ id: 'connection_data_source.self_registered' }),
    },
  }

  return (
    <Wrapper>
      {(Case.userPublicDataOwnership ||
        Case.userPublicDataRole ||
        Case.relationPublicDataOwnership ||
        Case.relationPublicDataRole) && (
        <MarkerWrapper>
          {markerTypes.public.icon}
          {withTooltip !== 'no' && <Tooltip>{markerTypes.public.text}</Tooltip>}
        </MarkerWrapper>
      )}
      {(Case.userOwnership ||
        Case.userRole ||
        Case.userOtherConnection ||
        Case.relationOwnership ||
        Case.relationRole ||
        Case.relationOtherConnection) && (
        <MarkerWrapper>
          {markerTypes.self_registered.icon}
          {withTooltip !== 'no' && (
            <Tooltip>{markerTypes.self_registered.text}</Tooltip>
          )}
        </MarkerWrapper>
      )}
      {(Case.ownershipApplications.length > 0 ||
        Case.roleApplications.length > 0 ||
        Case.otherConnectionApplications.length > 0) && (
        <MarkerWrapper>
          {markerTypes.application.icon}
          {withTooltip !== 'no' && (
            <Tooltip>{markerTypes.application.text}</Tooltip>
          )}
        </MarkerWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const MarkerWrapper = styled.div`
  display: inline-block;
  height: 1em;
  width: 1em;
  border-radius: 10px;
  margin: 2px;
`

const Tooltip = styled.p`
  z-index: 1;
  display: none;
  position: absolute;
  margin: 0;
  padding: 1em;
  width: 200px;
  height: auto;
  background-color: ${({ theme: { primary } }) => primary};
  color: ${({ theme: { inverseText } }) => inverseText};
  border-radius: 5px;
  border: 1px black solid;
  ${MarkerWrapper}:hover & {
    display: block;
  }
`

export default CaseDataSourceMarker
