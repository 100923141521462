import React, { useState, useEffect } from 'react'
import { prettyDate } from '../../dateUtils'
import CaseListItem from './CaseListItem'
import { useI18nFunction } from '../I18n'
import caseProps from '../caseProps'

const OwnershipCaseListItem = (props) => {
  const [stockCount, setStockCount] = useState(0)
  const [shareType, setShareType] = useState(0)
  const [stakePercentage, setStakePercentage] = useState(0)
  const I18nFunction = useI18nFunction();

  useEffect(() => {
    const userOwnership = !props.Case.caseType.includes('relation')
      ? props.Case.userOwnership
      : props.Case.relationOwnership
    const publicDataOwnership = !props.Case.caseType.includes('relation')
      ? props.Case.userPublicDataOwnership
      : props.Case.relationPublicDataOwnership
    if (publicDataOwnership) {
      setStockCount(publicDataOwnership.stockCount)
      setShareType(publicDataOwnership.shareType)
      setStakePercentage(publicDataOwnership.stakePercentage)
    }
    if (userOwnership) {
      setStockCount(userOwnership.stockCount)
      setShareType(userOwnership.shareType)
      setStakePercentage(userOwnership.stakePercentage)
    }
  }, [props.Case])

  return (
    <li>
      <CaseListItem
        {...props}
        image={caseProps[props.Case.caseType].image}
        title={props.Case.organizationName}
        description={I18nFunction({
          id: 'connection_list_item.ownership_description',
          stockCount: stockCount,
          shareType: shareType,
          stakePercentage: stakePercentage
        })}
        descriptionFallback={I18nFunction({ id: 'connection_list_item.ownership_description_fallback' })}
        from={prettyDate(props.Case.updatedOn)}
        userConnection={
          !props.Case.caseType.includes('relation')
            ? props.Case.userOwnership
            : props.Case.relationOwnership
        }
        publicConnection={
          !props.Case.caseType.includes('relation')
            ? props.Case.userPublicDataOwnership
            : props.Case.relationPublicDataOwnership
        }
        userName={props.Case.userName}
        relationName={props.Case.relationName}
      />
    </li>
  )
}

export default OwnershipCaseListItem
