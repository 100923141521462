import React from 'react'
import styled from 'styled-components'
import I18n from '../I18n'

const ProffConnectionListItem = ({ Connection }) => {
  return (
    <Wrapper>
      <Content>
        <Title small={false}>{Connection.organizationName}</Title>
        <Subtitle>
          {Connection.totalShares ? (
            <Description small={true} description={Connection.numberOfShares}>
              <I18n id="connection_form.labels.stock_count" />
              {': '}
              {Connection.numberOfShares}
            </Description>
          ) : (
            <Description small={true} description={Connection.roleName}>
              <I18n id="connection_form.labels.title" />
              {': '} {Connection.roleName}
            </Description>
          )}
        </Subtitle>
      </Content>
    </Wrapper>
  )
}

const Content = styled.div`
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
`

const Wrapper = styled.div`
  box-shadow: rgb(219 219 219) 0px 2px 8px;
  border-radius: 5px;
  position: relative;
  background-color: ${({ theme }) => theme.primaryBackground};
  display: flex;
  align-items: center;
  padding: 1em;
  border: solid 2px ${({ theme: { primaryBackground } }) => primaryBackground};

  &.active {
    border-color: ${({ theme: { primary } }) => primary};
    box-shadow: rgb(180 180 200) 0px 2px 8px;
  }
`

const Title = styled.div`
  font-size: ${({ small, theme: { normalText, largeText } }) =>
    small ? normalText : largeText};
  margin-bottom: 0.2em;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Subtitle = styled.div`
  display: flex;
  justify-content: start;
  overflow: hidden;
`

const Description = styled.span`
  ${({ description, small, theme: { secondary, subtleText } }) =>
    !description ? 'color: ' + secondary : small && 'color: ' + subtleText}
  overflow: hidden;
  text-overflow: ellipsis;
`

export default ProffConnectionListItem
