import React from 'react'
import styled from 'styled-components'
import I18n from './I18n'
import { prettyDateTime } from '../dateUtils'

const UploadEvent = ({ event }) => {
  return (
    <Wrapper>
      <Header>
        <span>
          <I18n id="upload_respons_event_last_upload" />:{' '}
          {prettyDateTime(event?.createdOn)}
        </span>
        <span>
          <I18n id="upload_respons_event_response" />:{' '}
          <I18n
            id={`case_event_comment_definition.${event?.payload?.commentDefinition}`}
          />
        </span>
      </Header>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.primaryBackground};

  &:not(:last-child) {
    margin-bottom: 0.75em;
  }
`

const Header = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  border: none;
  cursor: pointer;
  padding: 1em;

  & > *:not(:last-child) {
    margin-right: 1em;
  }
`

export default UploadEvent
