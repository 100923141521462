import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'

const SingleSelectFilter = ({
  filterOptions,
  placeholder,
  setFilterHandler,
  selectedOption,
}) => {
  return (
    <Wrapper>
      <Select
        options={filterOptions}
        value={filterOptions.filter((o) => o.key === selectedOption)}
        hideSelectedOptions
        closeMenuOnSelect={true}
        placeholder={placeholder}
        isSearchable={false}
        onChange={({ key, label }) => setFilterHandler(key)}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 0.5em 0.5em 0.5em 0.5em;
  position: relative;
`

export default SingleSelectFilter
