import React from 'react'
import styled from 'styled-components'
import I18n from '../I18n'
import UserHeader from '../UserHeader'

const ProffUserHeader = ({ currentUser }) => {
  return (
    <Wrapper>
      <Header>
        <SubHeader>
          <I18n id="proff_id_form.header" />
        </SubHeader>
        <HeaderDescription>
          <I18n id="proff_id_form.sub_header" />
        </HeaderDescription>
      </Header>
      <UserHeader userId={currentUser.id} />
      <SubHeader>
        <I18n id="proff_id_form.form_header" />
      </SubHeader>
      <Description>
        <I18n id="proff_id_form.description" />
      </Description>
    </Wrapper>
  )
}

const Header = styled.section`
  background-color: ${({ theme: { secondaryBackground } }) =>
    secondaryBackground};
  padding: 0.1em 0 0.1em;
`

const Description = styled.div`
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
  margin: 0 auto;
  padding-top: 1em;
`

const HeaderDescription = styled.p`
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
  margin: 0 auto;
  padding-top: 1em;
`

const SubHeader = styled.h1`
  max-width: ${({ theme: { maxWidth } }) => maxWidth};
  margin: 0 auto;
  padding-top: 0.5em;
`
const Wrapper = styled.div``

export default ProffUserHeader
