import React from 'react'
import styled from 'styled-components'

import DataListItem from './DataListItem.js'
import DataListSpinner from './DataListSpinner'

const DataList = ({ 
    data,
    pending,
    selectedCases,
    toggleCase,
    caseIdsToApprove
  }) => {

  if (pending) {
    return <DataListSpinner />
  } else {
    return (
      <List>
        {data.map((Item) => ( 
          
          <li key={Item.id ? (Item.caseId ? Item.caseId + '-' + Item.id : Item.id) : -1}>
            <DataListItem 
              Item={Item} 
              selectedCases={selectedCases} 
              toggleCase={toggleCase}
              caseIdsToApprove={caseIdsToApprove}
            />
          </li>
        ))}
      </List>
    )
  }
}

const List = styled.ul`
  list-style: none;
  margin: 0;
  margin-bottom: 5em;
  padding: 0;

  & > :not(:last-child) {
    margin-bottom: 0.5em;
  }
`

export default DataList
