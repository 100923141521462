import React from 'react'
import styled from 'styled-components'
import CaseList from './CaseList'
import userIcon from '../icons/user.svg'
import I18n from './I18n'

const CompanyRelationCaseList = ({ cases }) => {
  const byRelation = cases.reduce(
    (map, Case) => ({
      ...map,
      [Case.relationId]: [...(map[Case.relationId] || []), Case],
    }),
    {},
  )

  return (
    <List>
      {Object.values(byRelation).map((cases) => (
        <li key={cases[0].id}>
          <Wrapper>
            <Icon src={userIcon} alt="" />
            <Title>
              <I18n id="Relation" /> <I18n id="of" /> {cases[0].userName}
            </Title>
          </Wrapper>
          <Children>
            <CaseList cases={cases} small companyConnection />
          </Children>
        </li>
      ))}
    </List>
  )
}

const Icon = styled.img`
  margin-right: 1.5em;
  height: 2em;
  width: 2em;
  flex-shrink: 0;
`

const Title = styled.div`
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${(props) => props.theme.largeText};
`

const Wrapper = styled.div`
  background-color: ${({ theme: { primaryBackground } }) => primaryBackground};
  display: flex;
  align-items: center;
  padding: 1em;
  border: solid 2px ${({ theme: { primaryBackground } }) => primaryBackground};
  cusor: pointer;

  &.active {
    border-color: ${({ theme: { primary } }) => primary};
  }
`

const Children = styled.div`
  margin-left: 7em;
`

const List = styled.ul`
  list-style: none;
  margin: 1em 0 2em;
  padding: 0;

  & > :not(:last-child) {
    margin-bottom: 1.5em;
  }
`

export default CompanyRelationCaseList
