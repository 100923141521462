import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { isProfileRegistrationCompleted } from '../redux/selectors'
import { confirmForm, unsetActiveConnection } from '../redux/actions'
import Button from './Button'
import infoPrimaryIcon from '../icons/info_primary.svg'
import caretRightGrayIcon from '../icons/caret-right_gray.svg'
import caretRightIcon from '../icons/caret-right.svg'
import I18n from './I18n'

const ConfirmProfileButton = ({
  confirmForm,
  disabled,
  pending,
  unsetActiveConnection,
  confirmedFormOn,
}) => {
  const [clicked, setClicked] = useState(false)

  useEffect(() => {
    setClicked(false)
  }, [disabled, unsetActiveConnection, confirmForm])

  const onConfirmForm = () => {
    unsetActiveConnection()
    confirmForm()
  }

  return (
    <Wrapper>
      {clicked && disabled && (
        <Error>
          <img src={infoPrimaryIcon} alt="" />
          <div>
            <I18n id='connection_form.tabs.Confirm_all_sections_of_profile_to_enable_completion' />
          </div>
        </Error>
      )}
      <Button
        small
        onClick={disabled ? () => setClicked(true) : () => onConfirmForm()}
        inactive={disabled ? 1 : 0}
        pending={pending}
      >
        <span>
          {confirmedFormOn ? (
            <I18n id="Update_registry" />
          ) : (
            <I18n id="Complete_registry" />
          )}
        </span>
        <img src={disabled ? caretRightGrayIcon : caretRightIcon} alt="" />
      </Button>
    </Wrapper>
  )
}

const Error = styled.div`
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 0.5em;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 1em;
  }
`

const mapStateToProps = (state, props) => {
  return {
    disabled: !isProfileRegistrationCompleted(state, props.userId),
  }
}

const mapDispatchToProps = {
  confirmForm,
  unsetActiveConnection,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmProfileButton)
