import { useState } from 'react'

const makeCancelable = (promise) => {
  let hasCanceled_ = false

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
      (error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error)),
    )
  })

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true
    },
  }
}

const validateWrapper = (schema, values) =>
  new Promise((resolve) =>
    schema
      .validate(values, {
        abortEarly: false,
      })
      .then(() => {
        resolve({})
      })
      .catch((error) => {
        const newErrors = error.inner.reduce(
          (errors, error) => ({ ...errors, [error.path]: error.errors[0] }),
          {},
        )

        resolve(newErrors)
      }),
  )

const useValidation = (schema) => {
  const [errors, setErrors] = useState({})

  const validate = (values) => {
    const cancelablePromise = makeCancelable(validateWrapper(schema, values))
    cancelablePromise.promise
      .then((newErrors) => {
        if (!isEqual(errors, newErrors)) {
          setErrors(newErrors)
        }
      })
      .catch((reason) => {
        if (!reason.isCanceled) {
          throw new Error(reason)
        }
      })

    return cancelablePromise
  }

  return { errors, validate }
}

export const isEqual = (a, b) => {
  const aProps = Object.getOwnPropertyNames(a)
  const bProps = Object.getOwnPropertyNames(b)

  if (aProps.length !== bProps.length) {
    return false
  }

  for (let i = 0; i < aProps.length; i += 1) {
    const propName = aProps[i]

    if (a[propName] !== b[propName]) {
      return false
    }
  }

  return true
}

export default useValidation
