import { takeEvery } from 'redux-saga/effects'
import {
  FETCH_SUGGESTIONS,
  FETCH_SUGGESTIONS_SUCCEEDED,
  FETCH_SUGGESTIONS_FAILED,
} from './index'
import { fetchSaga } from '../sagas'
import { get } from '../fetch'
import { arrayOfSuggestions } from '../schema'

function* saga() {
  yield takeEvery(
    FETCH_SUGGESTIONS,
    fetchSaga({
      SUCCEEDED: FETCH_SUGGESTIONS_SUCCEEDED,
      FAILED: FETCH_SUGGESTIONS_FAILED,
      schema: arrayOfSuggestions,
      fetch: ({ query }) => get(`/suggestions/company?query=${query}`),
    }),
  )
}

export default saga
