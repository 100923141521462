import { Link } from 'react-router-dom'
import styled from 'styled-components'

export default styled(Link)`
  color: ${({ theme }) => theme.secondary};
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  border: none;

  &:disabled {
    cursor: initial;
    color: ${({ theme: { lightText } }) => lightText}; 
  }
`
