import React from 'react'
import styled from 'styled-components'
import { camelize } from 'humps'
import CardDataList from '../CardDataList'
import { getDateWithoutTime } from '../../dateUtils'
import I18n from '../I18n'

const label = (payloadProps, key) => {
  const props = payloadProps.find((props) => props.key === camelize(key))
  return props ? props.label : key
}

const EventPayload = ({ payloadProps, payload }) => {
  return (
    <Wrapper>
      <CardDataList
        data={
          payloadProps
            ? Array.isArray(payload.diff)
              ? payload.diff.flatMap(([type, field, stuff]) => {
                  switch (type) {
                    case 'add':
                      return [
                        [
                          // Label
                          dontDisplayKeys.includes(field)
                            ? ''
                            : label(payloadProps, stuff[0][0]),
                          // Added value
                          <>
                            <span>
                              {i18nConnectionValue(stuff[0][0], stuff[0][1])}
                            </span>
                          </>,
                        ],
                      ]
                    case 'remove':
                      return [
                        [
                          label(payloadProps, stuff[0][0]),
                          <span style={{ textDecoration: 'line-through' }}>
                            {stuff[0][1]}
                          </span>,
                        ],
                      ]
                    case 'change':
                      return [
                        [
                          // Label
                          dontDisplayKeys.includes(field)
                            ? ''
                            : label(payloadProps, field),
                          // Old value
                          <>
                            <span style={{ textDecoration: 'line-through' }}>
                              {i18nConnectionValue(field, stuff[0])}
                            </span>{' '}
                            {/* New value */}
                            <span>{i18nConnectionValue(field, stuff[1])}</span>
                          </>,
                        ],
                      ]

                    default:
                      return [
                        [
                          // Label
                          label(payloadProps, field),
                          // Old value
                          <>
                            <span style={{ textDecoration: 'line-through' }}>
                              {i18nConnectionValue(field, stuff[0])}
                            </span>{' '}
                            {/* New value */}
                            <span>{i18nConnectionValue(field, stuff[1])}</span>
                          </>,
                        ],
                      ]
                  }
                })
              : payloadProps.map(({ key, label, renderValue }) => [
                  label ? label : '',
                  renderValue ? renderValue(payload[key]) : payload[key],
                ])
            : Object.entries(payload)
        }
      />
    </Wrapper>
  )
}
const dontDisplayKeys = ['updated_on', 'created_on']
const dateKeys = ['to_date', 'from_date']
const plainRenderKeys = [
  'comment',
  'stock_count',
  'stake_percentage',
  'monthly_reward',
  'monthly_time_commitment',
  'post_code',
  'post_place',
  'title',
]
const i18nConnectionValue = (key, value) => {
  switch (true) {
    case dontDisplayKeys.includes(key):
      return null
    case dateKeys.includes(key):
      return getDateWithoutTime(value)
    case plainRenderKeys.includes(key):
      return value && value
    default:
      return <I18n id={`connection_form.${key}.${value}`} />
  }
}

const Wrapper = styled.div`
  padding: 1em;
`

export default EventPayload
