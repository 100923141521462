import {
  CHECK_AUTH_STATUS,
  CHECK_AUTH_STATUS_SUCCEEDED,
  CHECK_AUTH_STATUS_FAILED,
} from './actionTypes'

const pending = (state = false, action) => {
  switch (action.type) {
    case CHECK_AUTH_STATUS:
      return true

    case CHECK_AUTH_STATUS_SUCCEEDED:
    case CHECK_AUTH_STATUS_FAILED:
      return false

    default:
      return state
  }
}

export const isAuthPending = (state) => state

export default pending
