import React from 'react'
import ReactSelect from 'react-select'
import I18n from '../I18n'

const Select = (props) => (
  <ReactSelect
    isDisabled={props.disabled}
    placeholder={<I18n id="Select" />}
    {...props}
  />
)

export default Select
