import React from 'react'
import ConnectionForm from './ConnectionForm'
import * as schema from '../../validation'
import {
  fromDate,
  toDate,
  comment,
  organizationName,
  roleTitle,
} from './fields'
import I18n, { useI18nFunction } from '../I18n'

const OtherConnectionForm = (props) => {
  const I18nFunction = useI18nFunction();
  
  return (
    <ConnectionForm
      {...props}
      heading={<I18n id="connection_form.headings.other" />}
      schema={schema.otherConnection(I18nFunction)}
      fields={fields}
    />
  )
}

export const fields = [
  organizationName(),
  roleTitle(),
  fromDate(<I18n id="connection_form.labels.position_from" />),
  toDate(<I18n id="connection_form.labels.position_to" />),
  comment(),
]

export default OtherConnectionForm
