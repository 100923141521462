export const PENDING = 'pending'
export const IN_PROGRESS = 'in_progress'
export const APPROVED = 'approved'
export const NOT_APPROVED = 'not_approved'
export const NOT_RELEVANT = 'not_relevant'

import React from 'react'
import I18n, { useI18nFunction } from './components/I18n'
import styled from 'styled-components'


export const statusColor = (status) => {
  if (status === PENDING) return '#BBBBBC'
  if (status === IN_PROGRESS) return '#BBBBBC'
  if (status === APPROVED) return '#00B38B'
  if (status === NOT_APPROVED) return '#BA414C'
  if (status === NOT_RELEVANT) return '#BBBBBC'
}

export const StatusBadge = ({ status, large, small }) => {
  const I18nFunction = useI18nFunction();
  const textContent = I18nFunction({id: `case_statuses.${status}`})
  const formattedText = textContent && typeof textContent === 'string' ? textContent.toUpperCase() : textContent;
  if (large) {
    return (
      <LargeBadge statusColor={statusColor(status)}>
        {formattedText}
      </LargeBadge>
    )
  }
  if (small) {
    return (
      <SmallBadge statusColor={statusColor(status)}>
        {formattedText}
      </SmallBadge>
    )
  }
  return (
    <Badge statusColor={statusColor(status)}>
      <I18n id={`case_statuses.${status}`} />
    </Badge>
  )
}

const SmallBadge = styled.div`
  min-width: 150px;
  font-size: 12px;
  white-space: nowrap;
  padding: 0.25em;
  color: ${({ statusColor }) =>
    `${statusColor === '#BBBBBC' ? 'black' : 'white'}`};
  border-radius: 2px;
  background-color: ${({ statusColor }) => `${statusColor}`};
  width: 50%;
  text-align: center;
`

const Badge = styled.div`
  min-width: 150px;
  white-space: nowrap;
  padding: 0.5em;
  color: ${({ statusColor }) =>
    `${statusColor === '#BBBBBC' ? 'black' : 'white'}`};
  font-weight: bold;
  border-radius: 2px;
  background-color: ${({ statusColor }) => `${statusColor}`};
  width: 75%;
  text-align: center;
`

const LargeBadge = styled.div`
  font-size: 25px;
  white-space: nowrap;
  padding: 0 0.5em;

  color: black;
  font-weight: bold;
  border-bottom: 4px solid ${({ statusColor }) => `${statusColor}`};
`
