import uuid from 'uuid/v4'

const errors = (state = {}, action) => {
  if (action.errors) {
    return action.errors.reduce((state, error) => {
      const id = uuid()
      return {
        ...state,
        [id]: { ...error, id },
      }
    }, state)
  }

  if (action.type === CLOSE_ERROR_MESSAGE) {
    let newState = { ...state }
    newState[action.id].closed = true
    return newState
  }

  return state
}

const CLOSE_ERROR_MESSAGE = 'CLOSE_ERROR_MESSAGE'

export const closeErrorMessage = (id) => ({
  type: CLOSE_ERROR_MESSAGE,
  id,
})

export const getErrorMessages = (state) =>
  Object.values(state).filter(({ closed }) => !closed)

export const getUserSessionExpired = (state) => {
  return (
    Object.values(state).length > 0 &&
    Object.values(state).filter(({ detail }) =>
      detail.includes('session expired'),
    ).length > 0
  )
}

export default errors
