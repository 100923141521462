import React from 'react'
import Page from '../components/Page'
import TermsAndConditions from '../components/TermsAndConditions'
import { connect } from 'react-redux'
import {
  isCurrentUserFetching,
  hasConfirmedTermsAndConditions,
} from '../redux/selectors'

const About = ({ pending, hasConfirmedTermsAndConditions }) => {
  return (
    <Page pending={pending}>
      <TermsAndConditions hasConfirmedTermsAndConditions={hasConfirmedTermsAndConditions} />
    </Page>
  )
}

const mapStateToProps = (state) => {
  const pending = isCurrentUserFetching(state)
  if (pending)
    return {
      pending: true,
    }

  return {
    hasConfirmedTermsAndConditions: hasConfirmedTermsAndConditions(state),
  }
}

export default connect(mapStateToProps)(About)
