import React, { useState } from 'react'
import styled from 'styled-components'
import ConnectionDetailsCaseItem from '../CaseListItemDetails'
import {
  RELATION_CASE,
} from '../../caseTypes'
import I18n from '../I18n'
import CaseDataSourceMarker from './CaseDataSourceMarker'
import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down-solid.svg'
import { ReactComponent as ChevronUpIcon } from '../../icons/chevron-up-solid.svg'
import theme from '../../theme'
import { statusColor, StatusBadge } from '../../CaseStatus'
import { Link } from 'react-router-dom'
const CaseListItem = ({
  image,
  title,
  titleFallback,
  description,
  descriptionFallback,
  small,
  active,
  activeRef,
  Case,
  from,
  userName,
  relationName,
  companyConnection,
  userConnection,
  publicConnection,
}) => {
  const [open, setOpen] = useState(false)
  const onClick = () => setOpen((open) => !open)

  return (
    <React.Fragment>
      <Wrapper
        ref={active ? activeRef : undefined}
        className={(active || open) && 'active'}
        onClick={onClick}
        onKeyDown={(e) => {
          if ([' ', 'Enter'].includes(e.key)) {
            e.preventDefault()
            onClick()
          }
        }}
        tabIndex="0"
      >
        {Case && <CaseStatus statusColor={statusColor(Case.status)} />}

        <IconWrapper>
          <Icon src={image} alt="" />
          <CaseDataSourceMarkerWrapper>
            {Case && <CaseDataSourceMarker Case={Case} />}
          </CaseDataSourceMarkerWrapper>
        </IconWrapper>

        <Content>
          <Title small={small} title={title}>
            {relationName || userName || title || titleFallback}
          </Title>
          <Subtitle>
            <Description small={small} description={description}>
              {description || descriptionFallback}
            </Description>
            <From>{from === undefined ? '' : `Oppdatert ${from}`}</From>
          </Subtitle>
        </Content>
        <RightFlexBox>
          {Case && (
            <StatusWrapper>
              <StatusBadge status={Case.status} />
              <CaseLink to={`/cases/${Case.id}`}>
                <LinkText>
                  <I18n id="Go_to_case" />
                </LinkText>
              </CaseLink>
            </StatusWrapper>
          )}
          <Indicator>
            {open ? (
              <ChevronUpIcon style={chevronStyle} />
            ) : (
              <ChevronDownIcon style={chevronStyle} />
            )}
          </Indicator>
        </RightFlexBox>
      </Wrapper>
      {open && (
        <ConnectionDetailsCaseItem
          Case={Case}
          userConnection={userConnection}
          publicConnection={publicConnection}
          companyConnection={companyConnection}
          caseType={Case ? Case.caseType : RELATION_CASE}
        />
      )}
    </React.Fragment>
  )
}


const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const CaseDataSourceMarkerWrapper = styled.div`
  margin: 0.2em 0;
`
const StatusWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`
const CaseLink = styled(Link)`
  text-decoration: none;
  padding-left: 1em;
`
const LinkText = styled.div`
  flex-grow: 0;
  flex-basis: initial;
  white-space: nowrap;
  color: ${({ theme: { secondary } }) => secondary};
  font-size: ${({ theme: { largeText } }) => largeText};
`
const chevronStyle = {
  display: 'block',
  margin: 'auto',
  fill: theme.secondary,
  width: '1em',
  height: '1em',
  alignmentBaseline: 'central',
}

const Wrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.primaryBackground};
  display: flex;
  align-items: center;
  padding: 1em;
  border: solid 2px ${({ theme: { primaryBackground } }) => primaryBackground};
  cursor: pointer;

  &.active {
    border-color: ${({ theme: { primary } }) => primary};
  }
`

const Icon = styled.img`
  margin: 0 1.5em;
  height: 2em;
  width: 2em;
  flex-shrink: 0;
`

const Content = styled.div`
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
`

const Title = styled.div`
  font-size: ${({ small, theme: { normalText, largeText } }) =>
    small ? normalText : largeText};
  ${({ title, theme: { secondary } }) => !title && 'color: ' + secondary}
  margin-bottom: 0.2em;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Subtitle = styled.div`
  display: flex;
  justify-content: start;
  overflow: hidden;
`

const Description = styled.span`
  ${({ description, small, theme: { secondary, subtleText } }) =>
    !description ? 'color: ' + secondary : small && 'color: ' + subtleText}
  overflow: hidden;
  text-overflow: ellipsis;
`

const From = styled.div`
  font-size: 0.825em;
  flex-shrink: 0;
  margin-left: 10px;
  align-self: end;
`

const RightFlexBox = styled.div`
  display: flex;
  width: 20em;
`

const Indicator = styled.div`
  margin-top: 0.25em;
  width: 3em;
  text-align: right;
  color: ${({ theme: { secondary } }) => secondary};
  font-size: ${({ theme: { largeText } }) => largeText};
`

const CaseStatus = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: ${({ statusColor }) => statusColor};
  width: 5px;
`

export default CaseListItem
