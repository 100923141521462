import { call, put, fork, select } from 'redux-saga/effects'
import { normalize } from 'normalizr'

import { sagas as auth } from './auth'
import { sagas as events } from './events'
import { sagas as users } from './users'
import { sagas as connections } from './connections'
import { sagas as companies } from './companies'
import { sagas as suggestions } from './suggestions'
import { sagas as currentUser } from './currentUser'
import { sagas as content } from './appContent'
import { sagas as reminder } from './reminder'
import { sagas as cases } from './cases'
import { sagas as applications } from './applications'
import { sagas as appSettings } from './appSettings'
import { sagas as proffSelector } from './proffSelector'
import { sagas as relations } from './relations'

function* saga() {
  yield fork(auth)
  yield fork(events)
  yield fork(users)
  yield fork(connections)
  yield fork(companies)
  yield fork(suggestions)
  yield fork(currentUser)
  yield fork(reminder)
  yield fork(cases)
  yield fork(applications)
  yield fork(appSettings)
  yield fork(content)
  yield fork(proffSelector)
  yield fork(relations)
}

// custom fetchPaginationSaga
export const fetchSagaPagination = ({
  SUCCEEDED,
  FAILED,
  schema,
  fetch,
  selectors = [],
}) =>
  function* saga(props) {
    try {
      let selected = []
      for (let i = 0; i < selectors.length; i++) {
        selected.push(yield select(selectors[i]))
      }
      const { data, errors } = yield call(fetch, { ...props, selected })
      if (errors) {
        yield put({
          ...props,
          type: FAILED,
          errors,
        })
      } else {
        yield put({
          ...props,
          type: SUCCEEDED,
          payload: {
            ...normalize(data.items, schema),
            pagination: data.pagination,
          },
        })
      }
    } catch (error) {
      console.error(error)
      yield put({
        ...props,
        type: FAILED,
        errors: [{ detail: error.message }],
      })
    }
  }

export const fetchSaga = ({
  SUCCEEDED,
  FAILED,
  schema,
  fetch,
  selectors = [],
}) =>
  function* saga(props) {
    try {
      let selected = []
      for (let i = 0; i < selectors.length; i++) {
        selected.push(yield select(selectors[i]))
      }
      const { data, errors } = yield call(fetch, { ...props, selected })
      if (errors) {
        yield put({
          ...props,
          type: FAILED,
          errors,
        })
      } else {
        yield put({
          ...props,
          type: SUCCEEDED,
          payload: normalize(data, schema),
        })
      }
    } catch (error) {
      console.error(error)
      yield put({
        ...props,
        type: FAILED,
        errors: [{ detail: error.message }],
      })
    }
  }

export default saga
