
import React from 'react'
import I18n from '../I18n'


export default {
    company_added: {
      renderText: (props) => <I18n {...props} id="company_events.company_added" />,
    },
    company_customer_relation_updated: {
        renderText: (props) => <I18n {...props} id="company_events.company_customer_relation_updated" />,
    },
    company_supplier_relation_updated: {
        renderText: (props) => <I18n {...props} id="company_events.company_supplier_relation_updated" />,
    },
}