import React, { useState, useEffect } from 'react'
import imageNotFound from '../icons/imageNotFound.png'

const icons = require.context('../icons', true, /\.(svg|png|jpg)$/);

const ImageComponent = ({ fileName, imageUrls, pendingImages }) => {
  const [imageUrl, setImageUrl] = useState(null);
  
  useEffect(() => {
    let isComponentMounted = true;
    // Function to set image from the local path
    const loadImageFromLocalPath = (nameOfFile) => {
      try {
        // Dynamically require the image from src/icons
        const dynamicImage = icons(`./${nameOfFile}`);
        if (isComponentMounted) {
          setImageUrl(dynamicImage);
        }
      } catch (e) {
        if (!isComponentMounted) return;
        // If the image is not found in /client/src/icons/
        if (isComponentMounted) {
            setImageUrl(imageNotFound);
        }
      }
    }

    if (fileName) {
      // If fileName is provided, try to load it from local path
      loadImageFromLocalPath(fileName);
    } else {
      setImageUrl(imageNotFound);
    }

    // Cleanup function for useEffect
    return () => {
      isComponentMounted = false;
    };
  }, [fileName]);


  // Display fallback image if no image URL is set after loading
  if (!imageUrl) {
    return <img src={imageNotFound} alt="Image not found" loading="lazy" />;
  }

  return <img src={imageUrl} alt="Dynamic Image" loading="lazy" />;
};

export default ImageComponent;