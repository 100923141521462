import React from 'react'
import styled from 'styled-components'
import Page from '../components/Page'
import Button from '../components/Button'
import { Link } from 'react-router-dom'
import { height } from '../components/Header'
import checkmarkIcon from '../icons/checkmark_circled_filled.svg'
import I18n from '../components/I18n'

const ReminderSuccess = () => (
  <Page>
    <Wrapper>
      <Content>
        <Heading>
          <img src={checkmarkIcon} alt="" />
          <span>
            <I18n id="Reminder_success" />
          </span>
        </Heading>
        <Button as={Link} to="/users">
          <I18n id="Back_to_users" />
        </Button>
      </Content>
    </Wrapper>
  </Page>
)

const Heading = styled.h1`
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 10px;
  }
`

const Content = styled.div`
  margin: auto;
  text-align: center;
  font-size: ${(props) => props.theme.largeText};
  padding-bottom: 3em;
`

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - ${height});
`

export default ReminderSuccess
