import { combineReducers } from 'redux'
import byId, * as fromById from './byId'
import listByUserId, * as fromListByUserId from './listByUserId'

export { default as sagas } from './sagas'

export const FETCH_USER_EVENTS = 'FETCH_USER_EVENTS'
export const FETCH_USER_EVENTS_SUCCEEDED = 'FETCH_USER_EVENTS_SUCCEEDED'
export const FETCH_USER_EVENTS_FAILED = 'FETCH_USER_EVENTS_FAILED'

export const fetchUserEvents = (userId) => ({
  type: FETCH_USER_EVENTS,
  userId,
})

export const getUserEvents = (state, userId) => {
  const ids = fromListByUserId.getIds(state.listByUserId, userId)

  if (!ids) return undefined

  return ids
    .map((id) => fromById.getEvent(state.byId, id))
    .sort((a, b) => a.createdOn - b.createdOn)
}

export const isUserEventsFetching = (state, userId) =>
  fromListByUserId.isFetching(state.listByUserId, userId)

export const shouldFetchUserEvents = (state, userId) =>
  fromListByUserId.shouldFetch(state.listByUserId, userId)

export default combineReducers({ byId, listByUserId })
