import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FixedSizeList } from 'react-window'
import I18n, { useI18nFunction } from './I18n'
import DataListSpinner from './DataList/DataListSpinner'

const CompanyList = ({ companies, pending }) => {
  const I18nFunction = useI18nFunction();

  const supplierFor = (supplierRelations) => {
    let description = `${I18nFunction({ id: "Is_supplier" })} for: \n`;
    if (supplierRelations?.length > 0) {
      supplierRelations.map(
        (s) =>
          (description = description.concat(
            `${s.supplierOfOrganizationName} (${s.supplierOfOrganizationNumber}), \n`,
          )),
      )
      return description
    }
    return ''
  }

  const customerFor = (customerRelations) => {
    let description = `${I18nFunction({ id: "Is_client" })} for: \n`;
    if (customerRelations?.length > 0) {
      customerRelations.map(
        (s) =>
          (description = description.concat(
            `${s.customerOfOrganizationName} (${s.customerOfOrganizationNumber}), \n`,
          )),
      )
      return description
    }
    return ''
  }

  return (
    <>
      {pending ? (
        <DataListSpinner />
      ) : (
        <List>
          <FixedSizeList
            height={1000}
            itemCount={companies.length}
            itemSize={50}
            width={'100%'}
          >
            {({ index, style }) => {
              const {
                organizationNumber,
                organizationName,
                connectionCount,
                supplierRelations,
                customerRelations,
              } = companies[index]
              return (
                <li style={style} key={organizationNumber}>
                  <Company
                    to={`/companies/${encodeURIComponent(organizationNumber)}`}
                  >
                    <Name>{organizationName}</Name>
                    <div>
                      <I18n id="Connection_count" />: {connectionCount || 0}
                    </div>
                    <CustomerAndSupplierStatus
                      title={supplierFor(supplierRelations)}
                      isSupplierOrCustomer={supplierRelations?.length > 0}
                    >
                      {supplierRelations?.length > 0 ? (
                        <I18n id="Is_supplier" />
                      ) : (
                        <I18n id="Is_not_supplier" />
                      )}
                    </CustomerAndSupplierStatus>
                    <CustomerAndSupplierStatus
                      title={customerFor(customerRelations)}
                      isSupplierOrCustomer={customerRelations?.length > 0}
                    >
                      {customerRelations?.length > 0 ? (
                        <I18n id="Is_client" />
                      ) : (
                        <I18n id="Is_not_client" />
                      )}
                    </CustomerAndSupplierStatus>
                    <LinkText>
                      <I18n id="Go_to_profile" />
                    </LinkText>
                  </Company>
                </li>
              )
            }}
          </FixedSizeList>
        </List>
      )}
    </>
  )
}

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  & > :not(:last-child) {
    margin-bottom: 1em;
  }
`

const Company = styled(Link)`
  text-decoration: none;
  color: ${({ theme: { text } }) => text};
  display: flex;
  padding: 1em 1.5em;
  background-color: ${({ theme: { primaryBackground } }) => primaryBackground};

  & > * {
    flex-grow: 1;
    flex-basis: 0px;
    flex-shrink: 0;
    min-width: 0px;
  }
`

const Name = styled.div`
  font-size: ${({ theme: { largeText } }) => largeText};
`

const CustomerAndSupplierStatus = styled.div`
  color: ${(props) => (props.isSupplierOrCustomer ? props.theme.error : 'inherit')};
`

const LinkText = styled.div`
  flex-grow: 0;
  flex-basis: auto;
  white-space: nowrap;
  color: ${({ theme: { secondary } }) => secondary};
  font-size: ${({ theme: { largeText } }) => largeText};
`

export default CompanyList
